<!DOCTYPE html>
<section class="sign-in-background">
  <app-header></app-header>

  <div class="container-fluid" *ngIf="isLoaded">
    <div class="row">
      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-5 col-xs-1"></div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-10">
        <div class="card animated fadeInDown">
          <div class="card-body">
            <div class="roboto-bold-font">
              <h1 class="h1">{{ "registration" | translate }}</h1>
            </div>

            <div class="pt-4">
              <ngb-alert #selfClosingAlert *ngIf="errorMessage" type="danger" (closed)="errorMessage = ''">{{ errorMessage }}</ngb-alert>
              <form (ngSubmit)="onSubmit()" [formGroup]="registerForm">
                <div class="row">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-name"><em class="fa fa-user" aria-hidden="true"></em></span>
                    </div>
                    <input type="text" formControlName="name" class="form-control"
                      [ngClass]="{ 'is-invalid': registerFormSubmitted && register.name.errors }"
                      placeholder="{{ 'inputs.name.label' | translate }}" aria-label="Noms"
                      aria-describedby="basic-name" required>
                    <div *ngIf="registerFormSubmitted && register.name.errors" class="invalid-feedback">
                      <div *ngIf="register.name.errors.required">{{ "inputs.name.validations.required" | translate }}</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-email">&#64;</span>
                    </div>
                    <input type="email" formControlName="email" class="form-control"
                      [ngClass]="{ 'is-invalid': registerFormSubmitted && register.email.errors }" placeholder="{{ 'inputs.email.label' | translate }}"
                      aria-label="Email" aria-describedby="basic-email" required>
                    <div *ngIf="registerFormSubmitted && register.email.errors" class="invalid-feedback">
                      <div *ngIf="register.email.errors.required">{{ "inputs.email.validations.required" | translate }}</div>
                      <div *ngIf="register.email.errors.email">{{ "inputs.email.validations.valid" | translate }}</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-user"><em class="fa fa-user" aria-hidden="true"></em></span>
                    </div>
                    <input type="text" formControlName="username" class="form-control"
                      [ngClass]="{ 'is-invalid': registerFormSubmitted && register.username.errors }"
                      placeholder="{{ 'inputs.username.label' | translate }}" aria-label="Noms d'utilisateur"
                      aria-describedby="basic-user" required>
                    <div *ngIf="registerFormSubmitted && register.username.errors" class="invalid-feedback">
                      <div *ngIf="register.username.errors.required">{{ "inputs.username.validations.required" | translate }}
                      </div>
                      <div *ngIf="register.username.errors.minlength">
                        {{ "inputs.username.validations.min_length" | translate: {'size': '3'} }}</div>
                      <div *ngIf="register.username.errors.maxlength">
                        {{ "inputs.username.validations.max_length" | translate: {'size': '60'} }}</div>
                    </div>
                  </div>
                </div>

              <div class="row py-2">
                  <div class="input-group mb-3">
                      <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-role"><em class="fa fa-user-secret" aria-hidden="true"></em></span>
                      </div>
                      <ng-select formControlName="role" id="role"
                                 placeholder="{{ 'inputs.role.placeholders' | translate }}" [searchable]="true"
                                 [ngClass]="{ 'is-invalid': registerFormSubmitted && register.role.errors }">
                          <ng-option *ngFor="let role of roles" [value]="role.idrole">{{ role.name | translate }}</ng-option>
                      </ng-select>
                      <div *ngIf="registerFormSubmitted && register.role.errors" class="invalid-feedback">
                          <div *ngIf="register.role.errors.required">{{
                              "inputs.role.validations.required" |
                                  translate }}
                          </div>
                      </div>
                  </div>
              </div>

                <div class="row pt-3">
                  <button type="submit" class="btn btn-pill btn-block bg-warning">{{ "register" | translate }}</button>
                </div>
              </form>
            </div>

            <div class="row pt-4">
              <p class="roboto-bold-font">{{ "unauthorized_use_is_prohibited" | translate }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
    </div>
  </div>
</section>
