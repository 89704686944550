import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Project} from '../../../../../payloads/project.payload';
import {Activity, ActivityANODate} from '../../../../../payloads/activity.payload';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {RestAPIService} from '../../../../../services/rest-api.service';
import {Router} from '@angular/router';
import {NotificationFlashService} from '../../../../../services/notification-flash.service';
import {debounceTime} from 'rxjs/operators';
import {reloadComponent} from '../../../../../_helpers';

interface UpdateActivity {
    project: Project;
    activity: Activity;
}

@Component({
    selector: 'app-ano-dates',
    templateUrl: './ano-dates.component.html',
    styleUrls: ['./ano-dates.component.scss'],
})
export class AnoDatesComponent implements OnInit {
    idProject = Number(sessionStorage.getItem('project'));
    isLoaded: boolean = false;
    project: Project;
    slide1Form: UntypedFormGroup;
    slide1FormSubmitted: boolean = false;
    staticAlertClosed = false;
    errorMessage: string;
    startDate: Date;
    endDate: Date;
    end_anoSubmissionDate: Date;
    hideSave: boolean = false;
    @Input() updateActivity: UpdateActivity;
    activity: Activity;
    @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', {static: false})
    selfClosingAlert: NgbAlert;
    private _danger = new Subject<string>();

    constructor(private formBuilder: UntypedFormBuilder, private translate: TranslateService, private restAPIService: RestAPIService,
                private activeModal: NgbActiveModal, private router: Router, private notification: NotificationFlashService) {
    }

    get csrForm() {
        return this.slide1Form.controls;
    }

    ngOnInit(): void {
        this._danger.subscribe((message) => (this.errorMessage = message));
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.project = this.updateActivity.project;
        this.activity = this.updateActivity.activity;

        if (this.project.closed !== null || this.project.canceled !== null) {
            this.hideSave = true;
        }

        this.startDate = new Date(this.activity.startDate);
        this.endDate = new Date(this.activity.endDate);
        this.end_anoSubmissionDate = this.formatBSDate(this.activity.anoSubmissionDate);

        this.initForm();

        this.isLoaded = true;
    }

    initForm() {
        this.slide1Form = this.formBuilder.group({
            anoSubmissionDate: [this.formatBSDate(this.activity.anoSubmissionDate), [Validators.required]],
            anoObtentionDate: [{value: this.formatBSDate(this.activity.anoObtentionDate), disabled: this.end_anoSubmissionDate === null}]
        });
    }

    minANODate(date: string) {
        if (date) {
            this.slide1Form.get('anoObtentionDate')?.enable();
            this.end_anoSubmissionDate = new Date(date);
        }
    }

    onSubmit() {
        this.errorMessage = null;

        const activityANODate = new ActivityANODate(
            this.slide1Form.value.anoSubmissionDate,
            this.slide1Form.value.anoObtentionDate,
        );

        this.restAPIService
            .activityANO(this.activity.idactivity, activityANODate).subscribe(() => {
                this.closeModal();

                reloadComponent(this.router);

                this.notification.success(
                    this.translate.instant(
                        'notifications.success.activity-modified'
                    )
                );
            },
            (error) => {
                console.error(error);

                this.errorMessage = this.translate.instant(
                    'notifications.danger.activity-not-modified'
                );
            }
        );
    }

    closeModal() {
        this.activeModal.close();
    }

    formatBSDate(date: string): Date {
        return (!date) ? null : new Date(date);
    }
}
