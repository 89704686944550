import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReviewModule} from './review/review.module';
import {MarketModule} from './market/market.module';
import {IndicatorModule} from './indicator/indicator.module';
import {FollowUpModule} from './follow-up/follow-up.module';
import {ActivityModule} from './activity/activity.module';


@NgModule({
    declarations: [],
    imports: [
        ReviewModule,
        MarketModule,
        CommonModule,
        IndicatorModule,
        FollowUpModule,
        ActivityModule
    ],
    exports: [
        MarketModule,
        ReviewModule,
        IndicatorModule,
        FollowUpModule,
        ActivityModule
    ]
})
export class SharedModalModule { }
