import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationFlashService {

  constructor(private snackBar: MatSnackBar) { }

  config: MatSnackBarConfig = {
    horizontalPosition: 'center',
    verticalPosition: 'top'
  };

  success(message: string, timeOut?: number) {
    this.config.panelClass = ['notification', 'notification-success', 'py-4', 'my-5'];
    this.snackBar.open(message, '',{...this.config, duration: timeOut ?? 5000});
  }

  danger(message: string, timeOut?: number) {
    this.config.panelClass = ['notification', 'notification-danger', 'py-4', 'my-5'];
    this.snackBar.open(message, '', {...this.config, duration: timeOut ?? 5000});
  }

  warning(message: string, timeOut?: number) {
    this.config.panelClass = ['notification', 'notification-warning', 'py-4', 'my-5'];
    this.snackBar.open(message, '', {...this.config, duration: timeOut ?? 5000});
  }

  info(message: string, timeOut?: number) {
    this.config.panelClass = ['notification', 'notification-info', 'py-4', 'my-5'];
    this.snackBar.open(message, '', {...this.config, duration: timeOut ?? 5000});
  }
}
