<!-- cashout.component.html -->
<ngb-alert #selfClosingAlert (closed)="errorMessage = ''" *ngIf="errorMessage"
           type="danger">{{ errorMessage }}</ngb-alert>

<h4 class="roboto-bold-font pt-2">{{ "cash-disbursement-forecast" | translate }}</h4>

<form [formGroup]="slide3Form">
    <div class="row">
        <div class="col-xl-7 col-lg-7">
            <div class="row">
                <div class="col-xl-6 col-lg-6">
                    <div class="form-group">
                        <label class="control-label" for="startDate">{{ "inputs.start_date.label" | translate }}
                            <span class="roboto-bold-font text-red">*</span>
                        </label>
                        <input type="text" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                               [daysDisabled]="[7,0]" (ngModelChange)="setStartDate($event)"
                               placeholder="{{ 'date-format' | translate }}" [minDate]="startDate" [maxDate]="endDate"
                               aria-describedby="basic-user"
                               aria-label="Date" class="form-control" formControlName="startDate" id="startDate"
                               [ngClass]="{ 'is-invalid': slide3FormSubmitted && slide3Form.get('start_date').errors }">
                        <div *ngIf="slide3FormSubmitted && slide3Form.get('start_date').errors"
                             class="invalid-feedback">
                            <div>{{ "inputs.start_date.validations.required" | translate }} </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6">
                    <div class="form-group">
                        <label class="control-label" for="numberIntermediateDeliverables">
                            {{ "inputs.numberIntermediateDeliverables.label" | translate }}
                            <span class="roboto-bold-font text-red">*</span>
                        </label>
                        <div class="input-group mb-3">
                            <input (input)="onGenerateFormDeliverable()"
                                   [ngClass]="{ 'is-invalid': slide3FormSubmitted && slide3Form.get('numberIntermediateDeliverables').errors }"
                                   aria-describedby="basic-user" aria-label="numberIntermediateDeliverables"
                                   class="form-control text-right roboto-bold-font"
                                   formControlName="numberIntermediateDeliverables" id="numberIntermediateDeliverables"
                                   min="0" type="number"/>
                            <div *ngIf="slide3FormSubmitted && slide3Form.get('numberIntermediateDeliverables').errors"
                                 class="invalid-feedback">
                                <div *ngIf="slide3Form.get('numberIntermediateDeliverables').errors.required">
                                    {{ "inputs.numberIntermediateDeliverables.validations.required" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6">
                    <div class="form-group">
                        <label class="control-label"
                               for="amountInCurrency">{{ "inputs.amount_in_currency.label" | translate }}
                            <span class="roboto-bold-font text-red">*</span>
                        </label>
                        <div class="input-group mb-3">
                            <input [ngClass]="{ 'is-invalid': slide3FormSubmitted && slide3Form.get('amount').errors }"
                                   aria-describedby="basic-user"
                                   aria-label="Amount" class="form-control text-right roboto-bold-font"
                                   formControlName="amount" id="amountInCurrency"
                                   min="0" readonly="true" type="number">
                            <div class="input-group-append"><span class="input-group-text"
                                                                  id="basic-user1">{{ project.currency }}</span></div>
                            <div *ngIf="slide3FormSubmitted && slide3Form.get('amount').errors"
                                 class="invalid-feedback">
                                <div
                                    *ngIf="slide3Form.get('amount').errors.required">{{ "inputs.amount_in_currency.validations.required" | translate }}
                                </div>
                            </div>
                            <div *ngIf="errorMessage" class="invalid-feedback">
                                <div>{{ errorMessage }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6">
                    <div class="form-group">
                        <label class="control-label" for="amountInFcfa">{{ "inputs.amount_in_fcfa.label" | translate }}
                            <span class="roboto-bold-font text-red">*</span>
                        </label>
                        <div class="input-group mb-3">
                            <input (input)="checkAmount($event.target.value)"
                                   [ngClass]="{ 'is-invalid': slide3FormSubmitted && slide3Form.get('amountFcfa').errors }"
                                   aria-describedby="basic-user" aria-label="amountInFcfa"
                                   class="form-control text-right roboto-bold-font"
                                   formControlName="amountFcfa" id="amountInFcfa" min="0" type="number">
                            <div class="input-group-append"><span class="input-group-text">FCFA</span></div>
                            <div *ngIf="slide3FormSubmitted && slide3Form.get('amountFcfa').errors"
                                 class="invalid-feedback">
                                <div
                                    *ngIf="slide3Form.get('amountFcfa').errors.required">{{ "inputs.amount_in_fcfa.validations.required" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-5 col-lg-5">
            <div class="row">
                <div class="col-xl-12 col-lg-12">
                    <div class="form-group">
                        <label class="control-label"
                               for="description">{{ "inputs.description.label" | translate }}</label>
                        <textarea [ngClass]="{ 'is-invalid': slide3FormSubmitted }" aria-describedby="basic-user"
                                  aria-label="Description"
                                  class="form-control" formControlName="description" id="description"
                                  rows="5"></textarea>
                        <div *ngIf="slide3FormSubmitted" class="invalid-feedback">
                            <div> {{ "inputs.description.validations.required" | translate }} </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Section dynamique pour les livrables -->
    <div class="row" *ngIf="numberOfDeliverables > 0">
        <div class="table-responsive">
            <table class="table table-hover text-center table-striped">
                <caption></caption>
                <thead class="thead-dark">
                <tr>
                    <th class="text-center">{{ "inputs.deliverable_date.label" | translate }}</th>
                    <th class="nowrap">{{ "inputs.deliverable_label.label" | translate }}</th>
                    <th class="nowrap">{{ "inputs.decayRate.label" | translate }}</th>
                </tr>
                </thead>
                <tbody class="text-center">
                <tr>
                    <td colspan="3">{{ "starting-report" | translate }}</td>
                </tr>
                <tr>
                    <td>
                        <div class="form-group">
                            <input type="text" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [minDate]="startingDate"
                                   [daysDisabled]="[7,0]" placeholder="{{ 'date-format' | translate }}" [bsValue]="startingDate" class="form-control"
                                   [ngClass]="{ 'is-invalid': slide3FormSubmitted && slide3Form.get('startReport').errors }"
                                   aria-describedby="basic-user" aria-label="Date" formControlName="startReport" id="startReport">
                            <div *ngIf="slide3FormSubmitted && slide3Form.get('startReport').errors"
                                 class="invalid-feedback">
                                <div>{{ "inputs.startReport.validations.required" | translate }} </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="form-group">
                            <input
                                [ngClass]="{ 'is-invalid': slide3FormSubmitted && slide3Form.get('startReportLabel').errors }"
                                aria-describedby="basic-user"
                                aria-label="startReportLabel" class="form-control" formControlName="startReportLabel"
                                type="text"/>
                            <div *ngIf="slide3FormSubmitted && slide3Form.get('startReportLabel').errors"
                                 class="invalid-feedback">
                                <div>{{ "inputs.startReportLabel.validations.required" | translate }} </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="form-group">
                            <input
                                [ngClass]="{ 'is-invalid': slide3FormSubmitted && slide3Form.get('decayRateStartReport').errors }"
                                aria-describedby="basic-user"
                                aria-label="decayRateStartReport" class="form-control"
                                formControlName="decayRateStartReport" type="number"/>
                            <div *ngIf="slide3FormSubmitted && slide3Form.get('decayRateStartReport').errors"
                                 class="invalid-feedback">
                                <div>{{ "inputs.decayRateStartReport.validations.required" | translate }} </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">{{ "intermediary-report" | translate }}</td>
                </tr>
                <ng-container formArrayName="deliverablesList">
                    <tr *ngFor="let deliverable of getDeliverablesControls(); let i = index" [formGroupName]="i">
                        <td>
                            <div class="form-group">
                                <input type="text" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [minDate]="startingDate"
                                       [daysDisabled]="[7,0]" placeholder="{{ 'date-format' | translate }}" formControlName="deliverable_date"
                                       [ngClass]="{ 'is-invalid': slide3FormSubmitted && deliverable.get('deliverable_date').errors }"
                                       aria-describedby="basic-user" aria-label="Date" class="form-control" />
                                <div *ngIf="slide3FormSubmitted && deliverable.get('deliverable_date').errors"
                                     class="invalid-feedback">
                                    <div>{{ "inputs.deliverable_date.validations.required" | translate }} </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="form-group">
                                <input
                                    [ngClass]="{ 'is-invalid': slide3FormSubmitted && deliverable.get('deliverable_label').errors }"
                                    aria-describedby="basic-user"
                                    aria-label="Date" class="form-control" formControlName="deliverable_label"
                                    type="text"/>
                                <div *ngIf="slide3FormSubmitted && deliverable.get('deliverable_label').errors"
                                     class="invalid-feedback">
                                    <div>{{ "inputs.deliverable_label.validations.required" | translate }} </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="form-group">
                                <input
                                    [ngClass]="{ 'is-invalid': slide3FormSubmitted && deliverable.get('deliverable_rate').errors }"
                                    aria-describedby="basic-user"
                                    aria-label="Date" class="form-control" formControlName="deliverable_rate"
                                    type="number"/>
                                <div *ngIf="slide3FormSubmitted && deliverable.get('deliverable_rate').errors"
                                     class="invalid-feedback">
                                    <div>{{ "inputs.deliverable_rate.validations.required" | translate }} </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-container>
                <tr>
                    <td colspan="3">{{ "final-report" | translate }}</td>
                </tr>
                <tr>
                    <td>
                        <div class="form-group">
                            <input type="text" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [minDate]="startingDate"
                                   [daysDisabled]="[7,0]" placeholder="{{ 'date-format' | translate }}" formControlName="transmissionDateoftheEndReport"
                                   [ngClass]="{ 'is-invalid': slide3FormSubmitted && slide3Form.get('transmissionDateoftheEndReport').errors }"
                                   aria-describedby="basic-user" aria-label="Date" class="form-control" id="transmissionDateoftheEndReport">
                            <div *ngIf="slide3FormSubmitted && slide3Form.get('transmissionDateoftheEndReport').errors"
                                 class="invalid-feedback">
                                <div> {{ "inputs.transmissionDateoftheEndReport.validations.required" | translate }} </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="form-group">
                            <input
                                [ngClass]="{ 'is-invalid': slide3FormSubmitted && slide3Form.get('transmissionDateoftheEndReportLabel').errors }"
                                aria-describedby="basic-user" aria-label="transmissionDateoftheEndReportLabel"
                                class="form-control"
                                formControlName="transmissionDateoftheEndReportLabel"
                                id="transmissionDateoftheEndReportLabel" type="text">
                            <div
                                *ngIf="slide3FormSubmitted && slide3Form.get('transmissionDateoftheEndReportLabel').errors"
                                class="invalid-feedback">
                                <div> {{ "inputs.transmissionDateoftheEndReportLabel.validations.required" | translate }} </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="form-group">
                            <input
                                [ngClass]="{ 'is-invalid': slide3FormSubmitted && slide3Form.get('decayRateEndReport').errors }"
                                aria-describedby="basic-user"
                                aria-label="decayRateEndReport" class="form-control"
                                formControlName="decayRateEndReport" id="decayRateEndReport"
                                type="number">
                            <div *ngIf="slide3FormSubmitted && slide3Form.get('decayRateEndReport').errors"
                                 class="invalid-feedback">
                                <div> {{ "inputs.decayRateEndReport.validations.required" | translate }} </div>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end text-white">
        <a (click)="addCashout()" class="btn btn-pill bg-green my-2 mx-1"><em class="fa fa-plus"></em></a>
    </div>
</form>

<div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="table-responsive">
            <table class="table table-hover table-striped">
                <caption></caption>
                <thead class="thead-dark">
                <tr>
                    <th class="text-center">{{ "inputs.startDate.label" | translate }}</th>
                    <th class="text-center">{{ "inputs.description.label" | translate }}</th>
                    <th class="nowrap">{{ "inputs.amount_in_currency.label" | translate }} ({{ project.currency }})</th>
                    <th class="text-center">{{ "inputs.amount_in_fcfa.label" | translate }}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody *ngFor="let cashout of cashouts; let i = index;">
                <ng-container>
                    <tr>
                        <td class="nowrap text-center">{{ dateFormat(cashout.startDate) }}</td>
                        <td>{{ cashout.description }}</td>
                        <td class="nowrap text-center font-weight-bold">{{ currencyAmount(cashout.amountFcfa) }}</td>
                        <td class="nowrap text-center font-weight-bold">{{ moneyFormat(cashout.amountFcfa) }}</td>
                        <td class="text-center d-flex align-items-center">
                            <a (click)="deleteCashout(i, cashout.idcashout)"
                               class="btn btn-pill bg-danger my-1 mx-1 text-white"><em class="fa fa-times"></em></a>
                            <a (click)="showDeliverable(cashout.idcashout)" *ngIf="cashout.toggle !== 1"
                               class="py-3 mx-2 text-green"
                               title="{{ 'click-to-show-tasks' | translate }}">
                                <em class="fa fa-plus text-green"></em>
                            </a>
                            <a (click)="hideDeliverable(cashout.idcashout)" *ngIf="cashout.toggle === 1"
                               class="py-3 mx-2 text-red"
                               title="{{ 'click-to-show-tasks' | translate }}">
                                <em class="fa fa-minus text-red"></em>
                            </a>
                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="cashout.toggle === 1">
                    <tr>
                        <td colspan="10">
                            <table class="table table-hover table-striped tasks">
                                <caption></caption>
                                <thead class="thead-dark">
                                <tr>
                                    <th class="text-center">{{ "inputs.deliverable_date.label" | translate }}</th>
                                    <th class="nowrap text-center">{{ "inputs.deliverable_label.label" | translate }}</th>
                                    <th class="nowrap text-center">{{ "inputs.decayRate.label" | translate }}</th>
                                    <th class="nowrap text-center">{{ "inputs.amount_in_fcfa.label" | translate }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let deliverable of cashout.deliverablesList let j = index">
                                    <td class="text-center">{{ dateFormat(deliverable.deliverableDate) }}</td>
                                    <td class="text-center">{{ deliverable.deliverableLabel }}</td>
                                    <td class="text-center">{{ deliverable.deliverableRate }}</td>
                                    <td class="text-center font-weight-bold">{{ deliverableAmount(deliverable.deliverableRate, cashout.amountFcfa)}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>
