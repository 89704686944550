import {Document} from './document.payload';
import {Project} from './project.payload';

export class Month {
    january: string;
    february: string;
    march: string;
    april: string;
    may: string;
    june: string;
    july: string;
    august: string;
    september: string;
    october: string;
    november: string;
    december: string;

    constructor(january: string, february: string, march: string, april: string, may: string, june: string,
                july: string, august: string, september: string, october: string, november: string, december: string) {
        this.january = january;
        this.february = february;
        this.march = march;
        this.april = april;
        this.may = may;
        this.june = june;
        this.july = july;
        this.august = august;
        this.september = september;
        this.october = october;
        this.november = november;
        this.december = december;
    }
}

export class ActivityDoc {
    numb: string;
    title: string;
    documents: Document[];

    constructor(numb: string, title: string, documents: Document[]) {
        this.numb = numb;
        this.title = title;
        this.documents = documents;
    }
}

export class MarketDoc {
    numb: string;
    title: string;
    procurementDoc: ProcurementDoc;
    followUpDoc: FollowUpDoc;
    executionDoc: ExecutionDoc;

    constructor(numb: string, title: string, procurementDoc: ProcurementDoc, followUpDoc: FollowUpDoc, executionDoc: ExecutionDoc) {
        this.numb = numb;
        this.title = title;
        this.procurementDoc = procurementDoc;
        this.followUpDoc = followUpDoc;
        this.executionDoc = executionDoc;
    }
}

export class ProcurementDoc {
    target: string;
    documents: Document[];

    constructor(target: string, documents: Document[]) {
        this.target = target;
        this.documents = documents;
    }
}

export class FollowUpDoc {
    target: string;
    documents: Document[];

    constructor(target: string, documents: Document[]) {
        this.target = target;
        this.documents = documents;
    }
}

export class ExecutionDoc {
    target: string;
    documents: Document[];

    constructor(target: string, documents: Document[]) {
        this.target = target;
        this.documents = documents;
    }
}

export class TeamLeadDoc {
    target: string;
    documents: Document[];

    constructor(target: string, documents: Document[]) {
        this.target = target;
        this.documents = documents;
    }
}

export class ClaimDoc {
    target: string;
    documents: Document[];

    constructor(target: string, documents: Document[]) {
        this.target = target;
        this.documents = documents;
    }
}

export class ArchivesDoc {
    numb: string;
    title: string;
    teamLeadDoc: TeamLeadDoc;
    claimDoc: ClaimDoc;

    constructor(numb: string, title: string, teamLeadDoc: TeamLeadDoc, claimDoc: ClaimDoc) {
        this.numb = numb;
        this.title = title;
        this.teamLeadDoc = teamLeadDoc;
        this.claimDoc = claimDoc;
    }
}

export class FollowUpsDoc {
    numb: string;
    title: string;
    preparationDoc: FinanceMobilDoc;
    maturationDoc: MaturationEvalDoc;
    signatureDoc: SignatureProcDoc;
    implementationDoc: ImplementationProcDoc;

    constructor(numb: string, title: string, preparationDoc: FinanceMobilDoc, maturationDoc: MaturationEvalDoc, signatureDoc: SignatureProcDoc,
                implementationDoc: ImplementationProcDoc) {
        this.numb = numb;
        this.title = title;
        this.preparationDoc = preparationDoc;
        this.maturationDoc = maturationDoc;
        this.signatureDoc = signatureDoc;
        this.implementationDoc = implementationDoc;
    }
}

export class FinanceMobilDoc {
    target: string;
    documents: Document[];

    constructor(target: string, documents: Document[]) {
        this.target = target;
        this.documents = documents;
    }
}

export class MaturationEvalDoc {
    target: string;
    documents: Document[];

    constructor(target: string, documents: Document[]) {
        this.target = target;
        this.documents = documents;
    }
}

export class SignatureProcDoc {
    target: string;
    documents: Document[];

    constructor(target: string, documents: Document[]) {
        this.target = target;
        this.documents = documents;
    }
}

export class ImplementationProcDoc {
    target: string;
    documents: Document[];

    constructor(target: string, documents: Document[]) {
        this.target = target;
        this.documents = documents;
    }
}

export class AnalyseElement {
    elementKey: string;
    element: string;
    related: string;
    header: string;

    constructor(elementKey: string, element: string, related: string = null) {
        this.elementKey = elementKey;
        this.element = element;
        this.related = related;
    }
}

export class AnalyseProperty {
    attribute: string;
    value: string;

    constructor(attribute: string, value: string) {
        this.attribute = attribute;
        this.value = value;
    }
}

export class ProjectFile {
    id: number;
    name: string;
    parentId: number;
    subProjectFiles: ProjectFile[];
    completed: boolean;
    acceptYear: boolean;
    hidden: boolean;
    year: string;

    constructor(id: number, name: string, parentId: number = null, subProjectFiles: ProjectFile[] = null, completed: boolean = false,
                acceptYear: boolean = false, hidden: boolean = true, year: string = null) {
        this.id = id;
        this.name = name;
        this.parentId = parentId;
        this.subProjectFiles = subProjectFiles;
        this.completed = completed;
        this.acceptYear = acceptYear;
        this.hidden = hidden;
        this.year = year;
    }
}

export class HarmonisedProjectFile {
    id: number;
    title: string;
    parentId: number;
    year: string;

    constructor(id: number, title: string, parentId: number = null, year: string = null) {
        this.id = id;
        this.title = title;
        this.parentId = parentId;
        this.year = year;
    }
}

export class ProjectReport {
    generalInformations: HarmonisedProjectFile[];
    logicalFrameworks: HarmonisedProjectFile[];
    financialDatas: HarmonisedProjectFile[];
    implementationStates: HarmonisedProjectFile[];

    constructor(generalInformations: HarmonisedProjectFile[], logicalFrameworks: HarmonisedProjectFile[],
                financialDatas: HarmonisedProjectFile[], implementationStates: HarmonisedProjectFile[]) {
        this.generalInformations = generalInformations;
        this.logicalFrameworks = logicalFrameworks;
        this.financialDatas = financialDatas;
        this.implementationStates = implementationStates;
    }
}

export class PreparationState {
    status: string;
    projects: Project[];
    amount?: number;
    advance?: number;
    duration?: number | any;
    disbursementRate?: number | any;
    overdue?: number;
    toggle?: boolean;
}

export class KeyEvent {
    status: string;
    probableDate: string;
    effectiveDate: string

    constructor(status: string, probableDate: string, effectiveDate: string) {
        this.status = status;
        this.probableDate = probableDate;
        this.effectiveDate = effectiveDate;

    }
}
