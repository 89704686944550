import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {RestAPIService} from "../../../../../services/rest-api.service";
import {NotificationFlashService} from "../../../../../services/notification-flash.service";
import {UtilityService} from "../../../../../services/utility.service";
import {Indicator} from "../../../../../payloads/indicator.payload";
import {reloadComponent} from "../../../../../_helpers";

@Component({
  selector: 'app-create-indicator',
  templateUrl: './create-indicator.component.html',
  styleUrls: ['./create-indicator.component.scss']
})
export class CreateIndicatorComponent implements OnInit {
  isLoaded: boolean = false;
  indicatorForm: UntypedFormGroup;
  indicatorFormSubmitted: boolean = false;
  periodicities: string[] = [];
  private _danger = new Subject<string>();

  staticAlertClosed = false;
  errorMessage: string;

  @ViewChild('staticAlert', { static: false }) staticAlert: NgbAlert;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert;
  measuringUnits: string[] = [];

  @Input() idProject: number;

  constructor(private formBuilder: UntypedFormBuilder, private translate: TranslateService, private restAPIService: RestAPIService,
              private activeModal: NgbActiveModal, private router: Router, private notification: NotificationFlashService,
              private utilityService: UtilityService) { }

  ngOnInit(): void {
    this._danger.subscribe((message) => (this.errorMessage = message));
    this._danger.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });

      this.periodicities = this.utilityService.fetchPeriodicities();
      this.measuringUnits = this.utilityService.fetchMeasuringUnits();

      this.initForm();

      this.isLoaded = true;
  }

    get createIndicator() { return this.indicatorForm.controls; }

  initForm() {
    this.indicatorForm = this.formBuilder.group({
      name: [null, [Validators.required]],
      unit_mesure: [null, [Validators.required]],
      reference_year: [null],
      reference_value: [null],
      cible_year: [null],
      cible_value: [null],
      infos_to_collect: [null],
      calcul_method: [null],
      infos_source: [null],
      info_holder: [null],
      info_collector: [null],
      way_to_collect: [null],
      periodicity: [null],
      collection_time: [null],
      info_interpreter: [null],
      tools_to_use: [null],
    });
  }

  closeModal() {
    this.activeModal.close();
  }

  onSubmit() {
    this.errorMessage = null;
      this.indicatorFormSubmitted = true;
      if (this.indicatorForm.invalid) {
          return;
      }

    const indicator = new Indicator(
      this.indicatorForm.value.name,
      this.indicatorForm.value.unit_mesure,
      this.indicatorForm.value.reference_year,
      this.indicatorForm.value.reference_value,
      this.indicatorForm.value.cible_year,
      this.indicatorForm.value.cible_value,
      this.indicatorForm.value.infos_to_collect,
      this.indicatorForm.value.calcul_method,
      this.indicatorForm.value.infos_source,
      this.indicatorForm.value.info_holder,
      this.indicatorForm.value.info_collector,
      this.indicatorForm.value.way_to_collect,
      this.indicatorForm.value.periodicity,
      this.indicatorForm.value.collection_time,
      this.indicatorForm.value.info_interpreter,
      this.indicatorForm.value.tools_to_use,
        this.indicatorForm.value.way_to_collect
    );

    this.restAPIService.createIndicator(this.idProject, indicator).subscribe(() => {
        this.closeModal();

        this.notification.success(
          this.translate.instant('notifications.success.indicator-created'),
        );

        reloadComponent(this.router);
    }, (error) => {
        console.error(error);

        this.errorMessage = this.translate.instant(
            'notifications.danger.indicator-not-created',
        );
    });
  }
}
