import {Document} from './document.payload';
import {Role} from './role.payload';

export class User {
    iduser: number;
    name: string;
    surname: string;
    dob: string;
    pob: string;
    phone: string;
    email: string;
    username: string;
    password: string;
    lang: string;
    active: boolean;
    online: boolean;
    resetPassword: boolean;
    role: Role;
    idrole: number;
    document: Document = null;
    image: string;
    createdAt: string;
    updatedAt: string;
    imgSrc: string = null;

    create(name: string, surname: string, dob: string, pob: string, email: string, phone: string, username: string, lang: string,
           idrole: number, document: Document = null) {
        return {
            name: name,
            surname: surname,
            dob: dob,
            pob: pob,
            email: email,
            phone: phone,
            username: username,
            lang: lang,
            idrole: idrole,
            document: document
        };
    }

    update(name: string, surname: string, dob: string, pob: string, email: string, phone: string, username: string, lang: string,
           idrole: number, document: Document = null) {
        return {
            name: name,
            surname: surname,
            dob: dob,
            pob: pob,
            email: email,
            phone: phone,
            username: username,
            lang: lang,
            idrole: idrole,
            document: document
        };
    }

    forgotPassword(email: string) {
        return {
            email: email
        };
    }

    updatePassword(password: string, newPassword: string) {
        return {
            password: password,
            newPassword: newPassword
        };
    }

    resetsPassword(iduser: number, newPassword: string) {
        return {
            iduser: iduser,
            newPassword: newPassword
        };
    }

    signIn(username: string, password: string, ipAddress: string|null, platform: string) {
        return {
            username: username,
            password: password,
            ipAddress: ipAddress,
            platform: platform
        };
    }

    signUp(name: string, email: string, username: string, idrole: number) {
        return {
            name: name,
            email: email,
            username: username,
            idrole: idrole
        };
    }
}
