<!DOCTYPE html>
<section class="sign-in-background">
  <app-header></app-header>

  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-5 col-xs-1"></div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-10">
        <div class="card animated fadeInDown">
          <div class="card-body">
            <div class="roboto-bold-font">
              <h1 class="h1">{{ "reset_password" | translate }}</h1>
            </div>

            <div class="pt-4">
              <ngb-alert #selfClosingAlert *ngIf="errorMessage" type="danger" (closed)="errorMessage = ''">{{ errorMessage }}</ngb-alert>

              <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-pass"><em class="fa fa-key" aria-hidden="true"></em></span>
                    </div>
                    <input type="password" formControlName="password" class="form-control"
                      [ngClass]="{ 'is-invalid': resetPasswordSubmitted && reset.password.errors }" autocomplete="on"
                      placeholder="{{ 'inputs.password.label' | translate }}" aria-label="Mot de passe" aria-describedby="basic-pass"
                      required>
                    <div *ngIf="resetPasswordSubmitted && reset.password.errors" class="invalid-feedback">
                      <div *ngIf="reset.password.errors.required">{{ "inputs.password.validations.required" | translate }}</div>
                      <div *ngIf="reset.password.errors.minlength">{{ "inputs.password.validations.min_length" | translate: {'size': '6'} }}</div>
                      <div *ngIf="reset.password.errors.maxlength">{{ "inputs.password.validations.max_length" | translate: {'size': '100'} }}</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                    <div class="input-group mb-4">
                        <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-confirm_pass">
                            <em class="fa fa-key" aria-hidden="true"></em></span>
                        </div>
                        <input type="password" formControlName="confirm_password" autocomplete="on" class="form-control"
                               [ngClass]="{ 'is-invalid': resetPasswordSubmitted && reset.confirm_password.errors }"
                               placeholder="{{ 'inputs.confirm_password.label' | translate }}" aria-label="Confirmation nouveau mot de passe"
                               aria-describedby="basic-confirm_pass" required>
                        <div *ngIf="resetPasswordSubmitted && reset.confirm_password.errors" class="invalid-feedback">
                            <div *ngIf="reset.confirm_password.errors.required">{{ "inputs.confirm_password.label" | translate }}</div>
                            <div *ngIf="reset.confirm_password.errors.minlength">{{ "inputs.confirm_password.validations.min_length" | translate: {'size': '6'} }}</div>
                            <div *ngIf="reset.confirm_password.errors.maxlength">{{ "inputs.confirm_password.validations.max_length" | translate: {'size': '100'} }}</div>
                            <div *ngIf="reset.confirm_password.errors.mustMatch">{{ "inputs.confirm_password.validations.match" | translate }}</div>
                        </div>
                    </div>
                </div>

                <div class="row pt-2">
                  <button type="submit" class="btn btn-pill btn-block bg-warning">{{ "reset" | translate }}</button>
                </div>
              </form>
            </div>

            <div class="row pt-4">
              <p class="roboto-bold-font">{{ "unauthorized_use_is_prohibited" | translate }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
    </div>
  </div>
</section>
