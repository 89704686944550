import {INavData} from '@coreui/angular';
import {TranslateService} from '@ngx-translate/core';

export const projectReportsNavItems = (translate: TranslateService): INavData[] => {
    return [
        {
            name: translate.instant('dashboard'),
            url: '/project-portfolio/projects/project/documents/dashboard',
            icon: 'fa fa-tachometer',
        },
        {
            name: translate.instant('activities'),
            url: '/project-portfolio/projects/project/documents/activities',
            icon: 'fa fa-calendar'
        },
        {
            name: translate.instant('markets'),
            url: '/project-portfolio/projects/project/documents/markets',
            icon: 'fa fa-map',
        },
        // {
        //     name: translate.instant('follow_up'),
        //     url: '/project-portfolio/projects/project/documents/follow-up',
        //     icon: 'fa fa-long-arrow-right',
        // },
        {
            name: translate.instant('archives'),
            url: '/project-portfolio/projects/project/documents/archives',
            icon: 'fa fa-archive'
          }
    ];
};
