import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbActiveModal, NgbAlert, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {formatNumber, reloadComponent} from '../../../../../_helpers';
import {NotificationFlashService} from '../../../../../services/notification-flash.service';
import {RestAPIService} from '../../../../../services/rest-api.service';
import {Market} from '../../../../../payloads/market.payload';
import {MarketFollowUp} from '../../../../../payloads/complete-execution.payload';
import {Document} from '../../../../../payloads/document.payload';
import {
    CompleteDeliverable,
    CompletePayment,
    Contract,
    ContractDeliverable,
    ContractPayment,
    Endorsement,
    ServiceOrder
} from '../../../../../payloads/contract.payload';
import {FileViewerComponent} from '../../../../shared-component/file-viewer/file-viewer.component';
import {UtilityService} from '../../../../../services/utility.service';

@Component({
    selector: 'app-complete-execution',
    templateUrl: './complete-execution.component.html',
    styleUrls: ['./complete-execution.component.scss']
})

export class CompleteExecutionComponent implements OnInit {
    isLoaded: boolean = false;
    slide1: boolean = false;
    slide2: boolean = true;
    slide1Form: UntypedFormGroup;
    slide2Form: UntypedFormGroup;
    slide1FormSubmitted: boolean = false;
    slide2FormSubmitted: boolean = false;
    serviceOrderDocument: Document = null;
    endorsementDocument: Document = null;

    todayDate = new Date();

    private _danger = new Subject<string>();

    staticAlertClosed = false;
    errorMessage: string;
    market: Market;
    @Input() execution: any;

    contract: Contract = null;
    contracts: Contract[] = [];
    serviceOrders: ServiceOrder[] = [];
    endorsements: Endorsement[] = [];
    deliverables: ContractDeliverable[] = [];
    payments: ContractPayment[] = [];

    @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;
    orderTypes: string[];
    deliverableDocuments: Document[] = [];
    paymentDocuments: Document[] = [];

    constructor(private formBuilder: UntypedFormBuilder, private activeModal: NgbActiveModal, private restAPIService: RestAPIService,
                private notification: NotificationFlashService, private translate: TranslateService, private router: Router,
                private modalService: NgbModal, private utilityService: UtilityService) {
    }

    ngOnInit(): void {
        this._danger.subscribe(message => this.errorMessage = message);
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.market = this.execution.market;
        this.contracts = [...this.market.contracts];
        this.contracts.sort((contract1: Contract, contract2: Contract) =>
            contract1.numb > contract2.numb ? 1 : -1
        ).forEach((contract: Contract) => {
            if (contract.status !== 'CANCELED') {
                this.contract = contract;
            }
        });
        if (this.contract !== null) {
            if (this.contract.serviceOrders !== null) {
                this.serviceOrders = [...this.contract.serviceOrders];
                this.serviceOrders.sort((serviceOrder1: ServiceOrder, serviceOrder2: ServiceOrder) =>
                    serviceOrder1.idserviceOrder > serviceOrder2.idserviceOrder ? 1 : -1);
            }

            if (this.contract.endorsements !== null) {
                this.endorsements = [...this.contract.endorsements];
                this.endorsements.sort((endorsement1: Endorsement, endorsement2: Endorsement) =>
                    endorsement1.idendorsement > endorsement2.idendorsement ? 1 : -1);
            }

            if (this.contract.deliverables !== null) {
                this.deliverables = [...this.contract.deliverables];
                this.deliverables.sort((contractDeliverable1: ContractDeliverable, contractDeliverable2: ContractDeliverable) =>
                    contractDeliverable1.idcontractDeliverable > contractDeliverable2.idcontractDeliverable ? 1 : -1);
            }

            if (this.contract.contractPayments !== null) {
                this.payments = [...this.contract.contractPayments];
                this.payments.sort((contractPayment1: ContractPayment, contractPayment2: ContractPayment) =>
                    contractPayment1.idcontractPayment > contractPayment2.idcontractPayment ? 1 : -1);
            }
        }

        this.orderTypes = this.utilityService.fetchOrderTypes();

        this.initForm();

        this.isLoaded = true;
    }

    get basicForm() {
        return this.slide1Form.controls;
    }

    get endorseForm() {
        return this.slide2Form.controls;
    }

    initForm() {
        this.slide1Form = this.formBuilder.group({
            title: [null, [Validators.required]],
            orderType: [null, [Validators.required]],
            signatureDate: [null, [Validators.required]],
            receptionDate: [null, [Validators.required]],
            file: [null]
        });

        this.slide2Form = this.formBuilder.group({
            numb: [null, [Validators.required]],
            object: [null, [Validators.required]],
            amount: [null, [Validators.required]],
            date: [null, [Validators.required]],
            file: [null],
            description: [null]
        });
    }

    taskChangeSlide(nextSlide: number) {
        if (nextSlide === 1) {
            this.slide1 = false;
            this.slide2 = true;
        }

        if (nextSlide === 2) {
            this.slide1 = true;
            this.slide2 = false;
        }
    }

    addServiceOrder() {
        this.slide1FormSubmitted = true;
        if (this.slide1Form.invalid) {
            return;
        }

        const serviceOrder = new ServiceOrder(
            this.slide1Form.value.title,
            this.slide1Form.value.orderType,
            this.slide1Form.value.signatureDate,
            this.slide1Form.value.receptionDate,
            this.serviceOrderDocument
        );

        this.serviceOrders.push(serviceOrder);

        this.slide1FormSubmitted = false;

        this.slide1Form.get('title').setValue('');
        this.slide1Form.get('orderType').setValue('');
        this.slide1Form.get('signatureDate').setValue('');
        this.slide1Form.get('receptionDate').setValue('');
        this.slide1Form.get('file').setValue('');
    }

    deleteServiceOrder(i: number) {
        this.serviceOrders.splice(i, 1);
    }

    addEndorsement() {
        this.slide2FormSubmitted = true;
        if (this.slide2Form.invalid) {
            return;
        }

        const endorsement = new Endorsement(
            this.slide2Form.value.numb,
            this.slide2Form.value.object,
            this.slide2Form.value.description,
            this.slide2Form.value.amount,
            this.slide2Form.value.date,
            this.endorsementDocument
        );

        this.endorsements.push(endorsement);

        this.slide2FormSubmitted = false;

        this.slide2Form.get('numb').setValue('');
        this.slide2Form.get('object').setValue('');
        this.slide2Form.get('description').setValue('');
        this.slide2Form.get('amount').setValue('');
        this.slide2Form.get('date').setValue('');
        this.slide2Form.get('file').setValue('');
    }

    deleteEndorsement(i: number) {
        this.endorsements.splice(i, 1);
    }

    onServiceOrderFileSelect(event: any) {
        const file: File = event.target.files[0];

        if (file) {
            // File Preview
            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = (read: any) => {
                this.serviceOrderDocument = new Document(null, file.name, file.type, read.target.result);
            };
        }
    }

    onEndorsementFileSelect(event: any) {
        const file: File = event.target.files[0];

        if (file) {
            // File Preview
            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = (read: any) => {
                this.endorsementDocument = new Document(null, file.name, file.type, read.target.result);
            };
        }
    }

    onDeliverableFileSelect(target: number, event: any) {
        const file: File = event.target.files[0];

        if (file) {
            // File Preview
            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = (read: any) => {
                this.deliverableDocuments.push(
                    new Document(
                        target, file.name,
                        file.type, read.target.result
                    )
                );
            };
        }
    }

    onPaymentFileSelect(target: number, event: any) {
        const file: File = event.target.files[0];

        if (file) {
            // File Preview
            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = (read: any) => {
                this.paymentDocuments.push(
                    new Document(
                        target, file.name,
                        file.type, read.target.result
                    )
                );
            };
        }
    }

    view(idDocument: number) {
        this.modalService.open(FileViewerComponent, {
            windowClass: 'gr-modal-full',
            scrollable: false,
            backdrop: false,
            centered: true
        }).componentInstance.idDocument = idDocument;
    }

    onSubmit(completeContractDeliverableForm, completeContractPaymentForm) {
        this.errorMessage = null;

        const completeDeliverables: CompleteDeliverable[] = [];
        this.deliverables.forEach((contractDeliverable: ContractDeliverable) => {
            const index = contractDeliverable.idcontractDeliverable.toString();

            let importedDocument: Document = null;
            this.deliverableDocuments.forEach((document: Document) => {
                if (document.target === Number(index)) {
                    importedDocument = document;
                }
            });

            const submittedDate = completeContractDeliverableForm['submittedDate_'.concat(index)];
            const validatedDate = completeContractDeliverableForm['validatedDate_'.concat(index)];
            const observation = completeContractDeliverableForm['observation_'.concat(index)];
            const documentFile = importedDocument;

            if (submittedDate !== null) {
                const completeDeliverable: CompleteDeliverable = new CompleteDeliverable(
                    contractDeliverable.idcontractDeliverable, submittedDate, validatedDate, observation, documentFile
                );

                completeDeliverables.push(completeDeliverable);
            }
        });

        const completePayments: CompletePayment[] = [];
        this.payments.forEach((contractPayment: ContractPayment) => {
            const index = contractPayment.idcontractPayment.toString();

            let importedDocument: Document = null;
            this.paymentDocuments.forEach((document: Document) => {
                if (document.target === Number(index)) {
                    importedDocument = document;
                }
            });

            const paymentDate = completeContractPaymentForm['paymentDate_'.concat(index)];
            const observation = completeContractPaymentForm['observation_'.concat(index)];
            const documentFile = importedDocument;

            if (paymentDate !== null) {
                const completePayment: CompletePayment = new CompletePayment(
                    contractPayment.idcontractPayment, paymentDate, observation, documentFile
                );

                completePayments.push(completePayment);
            }
        });

        const marketFollowUp = new MarketFollowUp(
            this.serviceOrders,
            this.endorsements,
            completeDeliverables,
            completePayments
        );

        let changeStatus = 0;

        let count = 0;
        this.deliverables.forEach((deliverable: ContractDeliverable) => {
            if (deliverable.submittedDate !== null && deliverable.validatedDate !== null) {
                ++count;
            }
        });

        if (count > 0 && count < this.deliverables.length) {
            changeStatus = 1;
        } else if (count === this.deliverables.length) {
            changeStatus = 2;
        }

        this.restAPIService.marketFollowUp(this.market.idmarket, marketFollowUp, changeStatus).subscribe(() => {
                this.closeModal();

                reloadComponent(this.router);

                if (changeStatus === 1) {
                    this.notification.success(this.translate.instant('notifications.success.status-modified',
                        {status: this.translate.instant('IN_PROGRESS')}));
                } else if (changeStatus === 2) {
                    this.notification.success(this.translate.instant('notifications.success.status-modified',
                        {status: this.translate.instant('TERMINATED')}));
                } else {
                    this.notification.success(this.translate.instant('notifications.success.market-follow-up-modified'));
                }
            }, (error) => {
                console.error(error);

                if (changeStatus === 1) {
                    this.notification.success(this.translate.instant('notifications.success.status-not-modified',
                        {status: this.translate.instant('IN_PROGRESS')}));
                } else if (changeStatus === 2) {
                    this.notification.success(this.translate.instant('notifications.success.status-not-modified',
                        {status: this.translate.instant('TERMINATED')}));
                } else {
                    this.notification.success(this.translate.instant('notifications.success.market-follow-up-not-modified'));
                }
            }
        );
    }

    dateFormat(date: string): string {
        return new Date(date).toLocaleDateString('fr-FR');
    }

    moneyFormat(amount: number) {
        return formatNumber(amount);
    }

    closeModal() {
        this.activeModal.close();
    }

    setPaymentDisabled(i: number): boolean {
        if (i > 0) {
            // return !this.payments[i - 1].paymentDate;
            return false;
        }
    }

    setDeliverableDisabled(i: number): boolean {
        if (i > 0) {
            return !this.deliverables[i - 1].validatedDate;
        }
    }

    formatBSDate(date: string): Date {
        if (!date) {
            return null;
        }
        return new Date(date);
    }
}
