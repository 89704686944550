import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {Project} from '../../../../../payloads/project.payload';
import {ContReview, Review} from '../../../../../payloads/review.payload';
import {User} from '../../../../../payloads/user.payload';
import {TokenStorageService} from '../../../../../services/security/token-storage.service';
import {NotificationFlashService} from '../../../../../services/notification-flash.service';
import {RestAPIService} from '../../../../../services/rest-api.service';
import {viewTime} from '../../../../../_helpers';

@Component({
    selector: 'app-contin-prog-review',
    templateUrl: './contin-prog-review.component.html',
    styleUrls: ['./contin-prog-review.component.scss']
})
export class ContinProgReviewComponent implements OnInit {
    isLoaded = false;

    idProject = Number(sessionStorage.getItem('project'));

    project: Project;
    contReviews: ContReview[];
    user: User = null;

    contReviewForm: UntypedFormGroup;

    contReviewFormSubmitted = false;
    staticAlertClosed = false;
    errorMessage: string;
    @Input() review: Review;
    @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;
    private _danger = new Subject<string>();

    constructor(private formBuilder: UntypedFormBuilder, private notification: NotificationFlashService, private translate: TranslateService,
                private restAPIService: RestAPIService, private activeModal: NgbActiveModal, private tokenStorage: TokenStorageService) {
    }

    get continuoForm() {
        return this.contReviewForm.controls;
    }

    ngOnInit(): void {
        this._danger.subscribe(message => this.errorMessage = message);
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.user = this.tokenStorage.getUser();

        if (this.review.contReviews.length > 0) {
            this.contReviews = [...this.review.contReviews];
            this.contReviews.sort((contReview1: ContReview, contReview2: ContReview) =>
                (contReview1.createdAt > contReview2.createdAt) ? 1 : -1
            );

            this.contReviews.forEach((contReview: ContReview) => {
                if (contReview.sender.document !== null) {
                    this.restAPIService.downloadDocument(contReview.sender.document.iddocument).subscribe(document => {
                        contReview.sender.image = document.base64;
                        this.isLoaded = true;
                    });
                } else {
                    contReview.sender.image = './assets/img/default-avatar.jpeg';
                    this.isLoaded = true;
                }
            });
        } else {
            this.isLoaded = true;
        }

        this.initForm();
    }

    initForm() {
        this.contReviewForm = this.formBuilder.group({
            message: [null, [Validators.required]]
        });
    }

    onSubmit() {
        this.contReviewFormSubmitted = true;
        if (this.contReviewForm.invalid) {
            return;
        }

        const contReview = new ContReview(
            this.user.iduser,
            this.contReviewForm.value.message,
            this.idProject,
            new Date().toISOString()
        );

        this.restAPIService.continueReview(this.review.idreview, contReview).subscribe(
            (newContReview: ContReview) => {
                this.notification.success(
                    this.translate.instant('notifications.success.review-created'),
                );

                this.review.contReviews.push(newContReview);

                this.closeModal();
            },
            (error) => {
                console.error(error);

                this.errorMessage = this.translate.instant(
                    'notifications.danger.review-not-created',
                );
            },
        );
    }

    isSender(reviewUser: User): string {
        if (this.user.iduser !== reviewUser.iduser) {
            return 'chat-message-left';
        }
        return 'chat-message-right';
    }

    dateFormat(date: string): string {
        return new Date(date).toLocaleDateString('fr-FR');
    }

    timeFormat(date: string): string {
        return viewTime(date, this.translate.defaultLang);
    }

    closeModal() {
        this.activeModal.close();
    }
}
