import {MultiAnnualProgram} from "./finance-data.payload";

export class Indicator {
    idindicator: number;
    name: string;
    measureUnit: string;
    referenceYear: number;
    referenceValue: string;
    targetYear: number;
    targetValue: string;
    infoToCollect: string;
    calculationMethod: string;
    infoSource: string;
    infoHolder: string;
    info_collector: string;
    infoCollector: string;
    periodicity: string;
    collectionTime: string;
    infoInterpreter: string;
    toolsToUse: string;
    validated: number;
    isValidated: boolean;
    inPreparation: boolean;
    wayToCollect: string;
    indicatorStates: IndicatorState[];

    constructor(name: string, measureUnit: string = null, referenceYear: number = 0, referenceValue: string = null,
        targetYear: number = 0,  targetValue: string = null, infoToCollect: string = null, calculationMethod: string = null, infoSource: string = null,
        infoHolder: string = null, info_collector: string = null, infoCollector: string = null, periodicity: string = null, collectionTime: string = null,
        infoInterpreter: string = null, toolsToUse: string = null, wayToCollect: string = null) {
            this.name = name;
            this.measureUnit = measureUnit;
            this.referenceYear = referenceYear;
            this.referenceValue = referenceValue;
            this.targetYear = targetYear;
            this.targetValue = targetValue;
            this.infoToCollect = infoToCollect;
            this.calculationMethod = calculationMethod;
            this.infoSource = infoSource;
            this.infoHolder = infoHolder;
            this.info_collector = info_collector;
            this.infoCollector = infoCollector;
            this.periodicity = periodicity;
            this.collectionTime = collectionTime;
            this.infoInterpreter = infoInterpreter;
            this.toolsToUse = toolsToUse;
            this.wayToCollect = wayToCollect;
    }
}

export class IndicatorState {
    idindicatorState: number;
    multiAnnualProgram: MultiAnnualProgram;
    indicatorValue: string;

    constructor(multiAnnualProgram: MultiAnnualProgram, indicatorValue: string) {
        this.multiAnnualProgram = multiAnnualProgram;
        this.indicatorValue = indicatorValue;
    }
}
