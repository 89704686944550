import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {reloadComponent} from '../../../../../_helpers';
import {NotificationFlashService} from '../../../../../services/notification-flash.service';
import {RestAPIService} from '../../../../../services/rest-api.service';
import {Project} from '../../../../../payloads/project.payload';
import {ImplementationProc, ImplementationProcDate} from '../../../../../payloads/implementation-process.payload';

@Component({
    selector: 'app-create-implementation-proc',
    templateUrl: './create-implementation-proc.component.html',
    styleUrls: ['./create-implementation-proc.component.scss']
})
export class CreateImplementationProcComponent implements OnInit {
    isLoaded: boolean = false;
    fileChecked: boolean = false;
    commentChecked: boolean = false;

    startDate: Date;
    endDate: Date;

    implementationProcs: ImplementationProc[] = [];
    implementationProcDates: ImplementationProcDate[] = [];

    implementationProcForm: UntypedFormGroup;

    implementationProcFormSubmitted = false;
    private _danger = new Subject<string>();

    staticAlertClosed = false;
    errorMessage: string;

    @Input() project: Project;

    @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;

    constructor(private formBuilder: UntypedFormBuilder, private notification: NotificationFlashService, private router: Router,
                private translate: TranslateService, private restAPIService: RestAPIService, private activeModal: NgbActiveModal) {
    }

    ngOnInit(): void {
        this._danger.subscribe((message) => (this.errorMessage = message));
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.startDate = new Date(this.project.startDate);
        this.endDate = new Date(this.project.endDate);

        if (this.project.implementationProcs.length > 0) {
            this.implementationProcs = [...this.project.implementationProcs];

            this.implementationProcs.sort((implementationProc1: ImplementationProc, implementationProc2: ImplementationProc) =>
                (implementationProc1.idimplementationProc > implementationProc2.idimplementationProc) ? 1 : -1
            );

            this.implementationProcs.forEach((implementationProc: ImplementationProc) => {
                this.implementationProcDates = [...implementationProc.implementationProcDates];

                this.implementationProcDates.sort((implementationProcDate1: ImplementationProcDate,
                                                   implementationProcDate2: ImplementationProcDate) =>
                    (implementationProcDate1.plannedDate > implementationProcDate2.plannedDate) ? 1 : -1
                );

                implementationProc.implementationProcDate = this.implementationProcDates[this.implementationProcDates.length - 1];
            });
        }

        this.initForm();

        this.isLoaded = true;
    }

    get implementationForm() {
        return this.implementationProcForm.controls;
    }

    initForm() {
        this.implementationProcForm = this.formBuilder.group({
            title: [null, [Validators.required]],
            plannedDate: [null, [Validators.required]],
            acceptFile: [null],
            acceptComment: [null]
        });
    }

    onFileChange(event: boolean) {
        this.fileChecked = event;
    }

    onCommentChange(event: boolean) {
        this.commentChecked = event;
    }

    addImplementationProc() {
        this.implementationProcFormSubmitted = true;
        if (this.implementationProcForm.invalid) {
            return;
        }

        const implementationProc = new ImplementationProc(
            this.implementationProcForm.value.title,
            new ImplementationProcDate(
                this.implementationProcForm.value.plannedDate,
            ),
            true,
            true
        );

        this.implementationProcs.push(implementationProc);

        this.implementationProcFormSubmitted = false;

        this.implementationProcForm.get('title').setValue('');
        this.implementationProcForm.get('plannedDate').setValue('');
        this.implementationProcForm.get('acceptFile').setValue(false);
        this.implementationProcForm.get('acceptComment').setValue(false);

        this.onSubmit();
    }

    deleteImplementationProc(index: number) {
        this.implementationProcs.splice(index, 1);
    }

    acceptInput(accept: boolean): string {
        if (accept) {
            return 'fa fa-check text-success';
        }
        return 'fa fa-time text-danger';
    }

    onSubmit() {
        this.errorMessage = null;

        if (this.implementationProcs.length === 0) {
            this.errorMessage = this.translate.instant('notifications.danger.add-implementation-process');
            return;
        }

        this.restAPIService.createImplementationProcess(this.project.idproject, this.implementationProcs).subscribe(() => {
            this.closeModal();

            reloadComponent(this.router);

            this.notification.success(this.translate.instant('notifications.success.implementation-process-created'));
        }, () => {
            this.notification.danger(this.translate.instant('notifications.danger.implementation-process-not-created'));
        });
    }

    closeModal() {
        this.activeModal.close();
    }

    formatDate(date: string) {
        return new Date(date).toLocaleDateString('fr-FR');
    }
}
