<!DOCTYPE html>
<section class="animated fadeIn" *ngIf="isLoaded">
    <div class="modal-header d-flex justify-content-between">
        <h3 class="modal-title roboto-bold-font">{{ "implementation-process" | translate }}</h3>
        <span class="close fa fa-times text-red" aria-label="Close" (click)="closeModal()"></span>
    </div>
    <div class="modal-body text-black container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 forms">
                <form [formGroup]="implementationProcForm">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ngb-alert #selfClosingAlert *ngIf="errorMessage" type="danger"
                                (closed)="errorMessage = ''">{{
                                errorMessage
                                }}</ngb-alert>

                                <section>
                                    <div class="row">
                                        <div class="col-xl-9 col-lg-9 col-md-8">
                                            <div class="form-group">
                                                <label class="control-label" for="title">{{ "inputs.entitle.label" | translate }}</label>
                                                <input type="text" formControlName="title" class="form-control"
                                                    [ngClass]="{ 'is-invalid': implementationProcFormSubmitted && implementationForm.title.errors }"
                                                    aria-label="Title" aria-describedby="basic-user" id="title">
                                                <div *ngIf="implementationProcFormSubmitted && implementationForm.title.errors" class="invalid-feedback">
                                                    <div *ngIf="implementationForm.title.errors.required">{{
                                                        "inputs.entitle.validations.required" |
                                                        translate }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-4">
                                            <div class="form-group">
                                                <label class="control-label" for="plannedDate">{{ "inputs.plannedDate.label" | translate }}</label>
                                                <input type="text" formControlName="plannedDate" class="form-control"
                                                       bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [daysDisabled]="[7,0]"
                                                       placeholder="{{ 'date-format' | translate }}" [minDate]="startDate" [maxDate]="endDate"
                                                id="plannedDate" [ngClass]="{ 'is-invalid': implementationProcFormSubmitted && implementationForm.plannedDate.errors }">
                                                <div *ngIf="implementationProcFormSubmitted && implementationForm.plannedDate.errors" class="invalid-feedback">
                                                    <div *ngIf="implementationForm.plannedDate.errors.required">{{
                                                        "inputs.plannedDate.validations.required" |
                                                        translate }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2" [hidden]="true">
                                            <div class="form-group">
                                                <label class="control-label pr-2 pt-4" for="acceptFile">{{ "accept-file" | translate }} <span class="roboto-bold-font">?</span></label>
                                                <input type="checkbox" formControlName="acceptFile" class="checkbox" id="acceptFile" (change)="onFileChange($event.target.checked)" [(ngModel)]="fileChecked">
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2" [hidden]="true">
                                            <div class="form-group">
                                                <label class="control-label pr-2 pt-4" for="acceptComment">{{ "accept-comment" | translate }} <span class="roboto-bold-font">?</span></label>
                                                <input type="checkbox" formControlName="acceptComment" class="checkbox" id="acceptComment" (change)="onCommentChange($event.target.checked)" [(ngModel)]="commentChecked">
                                            </div>
                                        </div>

                                        <div
                                            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end text-white">
                                            <a class="btn btn-pill bg-primary px-5" (click)="addImplementationProc()">{{ "save" | translate }}</a>
                                        </div>

                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" [hidden]="true">
                                            <div class="table-responsive">
                                                <table class="table table-hover table-striped">
                                                    <caption></caption>
                                                    <thead class="thead-dark">
                                                        <tr>
                                                            <th>{{ "implementation-process" | translate }}</th>
                                                            <th class="text-center">{{ "planned-date" | translate }}</th>
                                                            <th class="text-center">{{ "accept-file" | translate }}</th>
                                                            <th class="text-center">{{ "accept-comment" | translate }}</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let implementationProc of implementationProcs; let i = index;">
                                                            <td>{{ implementationProc.title }}</td>
                                                            <td class="text-center">{{ formatDate(implementationProc.implementationProcDate.plannedDate) }}</td>
                                                            <td class="text-center">
                                                                <em [ngClass]="acceptInput(implementationProc.acceptFile)"></em>
                                                            </td>
                                                            <td class="text-center">
                                                                <em [ngClass]="acceptInput(implementationProc.acceptComment)"></em>
                                                            </td>
                                                            <td class="text-center">
                                                                <a class="btn btn-pill bg-danger my-2 mx-1 text-white" (click)="deleteImplementationProc(i)" *ngIf="!implementationProc.idimplementationProc"><em class="fa fa-times"></em></a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" [hidden]="true">
                                        <div
                                            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end">
                                            <a class="btn btn-pill bg-primary px-5" (click)="onSubmit()">{{ "save" | translate }}</a>
                                        </div>
                                    </div>
                                </section>


                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
