import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {RestAPIService} from '../../../../../services/rest-api.service';
import {NotificationFlashService} from '../../../../../services/notification-flash.service';
import {Project} from '../../../../../payloads/project.payload';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {KoboCollect, KoboCollectData} from '../../../../../interfaces/KoboCollect';
import {Activity} from '../../../../../payloads/activity.payload';
import {ActivityOper, Difficulty, Gap} from '../../../../../payloads/show-activity.payload';
import {reloadComponent, viewTime} from '../../../../../_helpers';
import {Task} from '../../../../../payloads/task.payload';
import {Document} from '../../../../../payloads/document.payload';

@Component({
    selector: 'app-import-kobo-collect',
    templateUrl: './import-kobo-collect.component.html',
    styleUrls: ['./import-kobo-collect.component.scss'],
})


export class ImportKoboCollectComponent implements OnInit {
    @Input() project: Project;
    isLoaded = false;
    errorMessage: string;
    koboCollectForm: UntypedFormGroup;
    koboCollectFormSubmitted: boolean = false;
    activity: Activity;
    activities: Activity[] = [];
    koboCollects: KoboCollect[];
    koboCollectDatas: KoboCollectData[];
    tasks: Task[];
    executedTasks: number = 0;
    consumedAmounts: number = 0;
    fileBase64: string = null;
    isFileLoaded: boolean = true;

    get koboToolForm() {
        return this.koboCollectForm.controls;
    }

    constructor(private translate: TranslateService, private formBuilder: UntypedFormBuilder, private restAPIService: RestAPIService,
                private activeModal: NgbActiveModal, private notification: NotificationFlashService) {
    }

    @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;

    ngOnInit(): void {
        this.koboCollectForm = this.formBuilder.group({
            activity: [null, [Validators.required]],
            koboForm: [null, [Validators.required]],
            koboFormData: [null, [Validators.required]]
        });

        this.activities = this.project.activities
            .filter((activity: Activity) => activity.status !== 'TERMINATED')
            .sort((activity1: Activity, activity2: Activity) =>
                activity1.numb > activity2.numb ? 1 : -1
            );

        this.restAPIService.getKoboCollectForms().subscribe((koboCollectForms: KoboCollect[]) => {
            this.koboCollects = koboCollectForms;

            this.isLoaded = true;
        }, (error) => {
            this.isLoaded = true;
            console.log(error.errorMessage);
        });
    }

    closeModal() {
        this.activeModal.close();
    }

    onKoboCollectChange(index: any) {
        this.errorMessage = null;
        if (this.koboCollectForm.get('activity').invalid) {
            this.koboCollectFormSubmitted = true;
            return;
        }

        this.restAPIService.getKoboCollectFormDatas(this.koboCollects[index].id).subscribe((koboCollectFormDatas: KoboCollectData[]) => {
            this.koboCollectDatas = koboCollectFormDatas.filter((koboCollectData: KoboCollectData) =>
                koboCollectData.activity.toLowerCase() === this.activities[this.koboCollectForm.get('activity').value].numb.toLowerCase()
            ).sort((koboCollectData1: KoboCollectData, koboCollectData2: KoboCollectData) =>
                koboCollectData1._submission_time > koboCollectData2._submission_time ? 1 : -1
            );
        }, (error) => {
            console.log(error.errorMessage);
        });
    }

    dateFormat(date: string): string {
        return new Date(date).toLocaleDateString('fr-FR');
    }

    timeFormat(date: string): string {
        return viewTime(date, this.translate.currentLang);
    }

    setDisabled(i: number) {
        return (
            (this.koboCollectDatas[i]._validation_status.label === undefined) ||
            ['Not Approved', 'Approved'].includes(this.koboCollectDatas[i]._validation_status.label));
    }

    setBGColor(i: number) {
        let bgColor;
        switch (this.koboCollectDatas[i]._validation_status.label) {
            case undefined:
                bgColor = 'bg-primary';
                break;
            case 'Not Approved':
                bgColor = 'bg-danger';
                break;
            case 'On Hold':
                bgColor = 'bg-warning';
                break;
            default:
                bgColor = 'bg-success';
                break;
        }

        return bgColor;
    }

    onSubmit() {
        this.errorMessage = null;
        this.koboCollectFormSubmitted = true;
        if (this.koboCollectForm.invalid) {
            return;
        }

        const koboCollectData: KoboCollectData = this.koboCollectDatas[this.koboCollectForm.get('koboFormData').value];

        if (Number(this.activity.amountFcfa) < (this.consumedAmounts + Number(koboCollectData.consumptedBudget))) {
            this.errorMessage = this.translate.instant('notifications.danger.activity_amount_greater_than_total_consumed_amount');
            return;
        }
        if (this.tasks.length < (this.executedTasks + Number(koboCollectData.executedTasks))) {
            this.errorMessage = this.translate.instant('notifications.danger.activity_tasks_greater_than_total_executed_tasks');
            return;
        }

        let document: Document = null;
        if (koboCollectData._attachments.length > 0) {
            this.isFileLoaded = false;
            this.restAPIService.getKoboCollectFormDataDocuemnt(koboCollectData._attachments[0].download_url).subscribe(
                (base64: string) => {
                    this.fileBase64 = base64;
                    this.isFileLoaded = true;
                }, (error) => {
                    this.isFileLoaded = true;
                    console.log(error.errorMessage);
                }
            );

            if (this.isFileLoaded && this.fileBase64 != null) {
                document = new Document(
                    null,
                    koboCollectData.document,
                    koboCollectData._attachments[0].mimetype,
                    this.fileBase64
                );
            }
        }

        if (this.isFileLoaded && this.errorMessage === null) {
            const activityOper = new ActivityOper(
                koboCollectData.situation,
                koboCollectData.encounteredDiff,
                koboCollectData.learnedLesson,
                koboCollectData.deadlineJustif,
                Number(koboCollectData.executedTasks),
                koboCollectData.executionJustif,
                Number(koboCollectData.consumptedBudget),
                koboCollectData.consumptionJustif,
                koboCollectData.obtainedOutput,
                koboCollectData.outputJustif,
                koboCollectData.verifMeans,
                document,
                koboCollectData._geolocation[0] + ' ' + koboCollectData._geolocation[1],
                this.getDifficulties(koboCollectData),
                this.getGaps(koboCollectData),
                this.activities[this.koboCollectForm.get('activity').value].idactivity
            );

            this.restAPIService.activityOperation(activityOper).subscribe(
                () => {
                    reloadComponent();

                    this.closeModal();

                    if (this.executedTasks + Number(koboCollectData.executedTasks) === this.tasks.length) {
                        this.notification.success(
                            this.translate.instant(
                                'notifications.success.status-modified',
                                {status: this.translate.instant('closed')}
                            )
                        );
                    } else {
                        this.notification.success(
                            this.translate.instant(
                                'notifications.success.activity-modified'
                            )
                        );
                    }
                }, () => {
                    if (this.executedTasks + Number(koboCollectData.executedTasks) === this.tasks.length) {
                        this.notification.danger(
                            this.translate.instant(
                                'notifications.danger.status-not-modified',
                                {status: this.translate.instant('closed')}
                            )
                        );
                    } else {
                        this.notification.danger(
                            this.translate.instant(
                                'notifications.danger.activity-not-modified'
                            )
                        );
                    }
                }
            );
        }
    }

    onActivityChange(i: number) {
        this.activity = this.activities[i];
        this.tasks = this.activity.tasks;
        if (this.activity.activityOpers.length > 0) {
            let activityOpers = [...this.activity.activityOpers];
            activityOpers.sort(
                (activityOper1: ActivityOper, activityOper2: ActivityOper) =>
                    activityOper1.idactivityOper > activityOper2.idactivityOper
                        ? 1
                        : -1
            );

            activityOpers.forEach((activityOper: ActivityOper) => {
                this.consumedAmounts += Number(activityOper.consumptedBudget);
                this.executedTasks += Number(activityOper.executedTasks);
            });
        }

        if (this.koboCollectForm.get('koboForm').value) {
            this.onKoboCollectChange(this.koboCollectForm.get('koboForm').value);
        }
    }

    private getDifficulties(koboCollectData: KoboCollectData) {
        let difficulties: Difficulty[] = [];
        if (koboCollectData['group_tj3ce70_diff_1/group_tj3ce70_diff_1_diff_title']) {
            difficulties.push(new Difficulty(
                koboCollectData['group_tj3ce70_diff_1/group_tj3ce70_diff_1_diff_title'],
                koboCollectData['group_tj3ce70_diff_1/group_tj3ce70_diff_1_diff_action'],
                koboCollectData['group_tj3ce70_diff_1/group_tj3ce70_diff_1_diff_princresponsible'],
                koboCollectData['group_tj3ce70_diff_1/group_tj3ce70_diff_1_diff_delay'],
                koboCollectData['group_tj3ce70_diff_1/group_tj3ce70_diff_1_diff_verifmeans']
            ));
        }
        if (koboCollectData['group_tj3ce70_diff_2/group_tj3ce70_diff_2_diff_title']) {
            difficulties.push(new Difficulty(
                koboCollectData['group_tj3ce70_diff_2/group_tj3ce70_diff_2_diff_title'],
                koboCollectData['group_tj3ce70_diff_2/group_tj3ce70_diff_2_diff_action'],
                koboCollectData['group_tj3ce70_diff_2/group_tj3ce70_diff_2_diff_princresponsible'],
                koboCollectData['group_tj3ce70_diff_2/group_tj3ce70_diff_2_diff_delay'],
                koboCollectData['group_tj3ce70_diff_2/group_tj3ce70_diff_2_diff_verifmeans']
            ));
        }
        if (koboCollectData['group_tj3ce70_diff_3/group_tj3ce70_diff_3_diff_title']) {
            difficulties.push(new Difficulty(
                koboCollectData['group_tj3ce70_diff_3/group_tj3ce70_diff_3_diff_title'],
                koboCollectData['group_tj3ce70_diff_3/group_tj3ce70_diff_3_diff_action'],
                koboCollectData['group_tj3ce70_diff_3/group_tj3ce70_diff_3_diff_princresponsible'],
                koboCollectData['group_tj3ce70_diff_3/group_tj3ce70_diff_3_diff_delay'],
                koboCollectData['group_tj3ce70_diff_3/group_tj3ce70_diff_3_diff_verifmeans']
            ));
        }
        if (koboCollectData['group_tj3ce70_diff_4/group_tj3ce70_diff_4_diff_title']) {
            difficulties.push(new Difficulty(
                koboCollectData['group_tj3ce70_diff_4/group_tj3ce70_diff_4_diff_title'],
                koboCollectData['group_tj3ce70_diff_4/group_tj3ce70_diff_4_diff_action'],
                koboCollectData['group_tj3ce70_diff_4/group_tj3ce70_diff_4_diff_princresponsible'],
                koboCollectData['group_tj3ce70_diff_4/group_tj3ce70_diff_4_diff_delay'],
                koboCollectData['group_tj3ce70_diff_4/group_tj3ce70_diff_4_diff_verifmeans']
            ));
        }
        if (koboCollectData['group_tj3ce70_diff_5/group_tj3ce70_diff_5_diff_title']) {
            difficulties.push(new Difficulty(
                koboCollectData['group_tj3ce70_diff_5/group_tj3ce70_diff_5_diff_title'],
                koboCollectData['group_tj3ce70_diff_5/group_tj3ce70_diff_5_diff_action'],
                koboCollectData['group_tj3ce70_diff_5/group_tj3ce70_diff_5_diff_princresponsible'],
                koboCollectData['group_tj3ce70_diff_5/group_tj3ce70_diff_5_diff_delay'],
                koboCollectData['group_tj3ce70_diff_5/group_tj3ce70_diff_5_diff_verifmeans']
            ));
        }

        return difficulties;
    }

    private getGaps(koboCollectData: KoboCollectData) {
        let gaps: Gap[] = [];
        if (koboCollectData['group_wa5am91_gap_1/group_wa5am91_gap_1_diff_title']) {
            gaps.push(new Gap(
                koboCollectData['group_wa5am91_gap_1/group_wa5am91_gap_1_diff_title'],
                koboCollectData['group_wa5am91_gap_1/group_wa5am91_gap_1_diff_action'],
                koboCollectData['group_wa5am91_gap_1/group_wa5am91_gap_1_diff_princresponsible'],
                koboCollectData['group_wa5am91_gap_1/group_wa5am91_gap_1_diff_delay'],
                koboCollectData['group_wa5am91_gap_1/group_wa5am91_gap_1_diff_verifmeans']
            ));
        }
        if (koboCollectData['group_wa5am91_gap_2/group_wa5am91_gap_2_diff_title']) {
            gaps.push(new Gap(
                koboCollectData['group_wa5am91_gap_2/group_wa5am91_gap_2_diff_title'],
                koboCollectData['group_wa5am91_gap_2/group_wa5am91_gap_2_diff_action'],
                koboCollectData['group_wa5am91_gap_2/group_wa5am91_gap_2_diff_princresponsible'],
                koboCollectData['group_wa5am91_gap_2/group_wa5am91_gap_2_diff_delay'],
                koboCollectData['group_wa5am91_gap_2/group_wa5am91_gap_2_diff_verifmeans']
            ));
        }
        if (koboCollectData['group_wa5am91_gap_3/group_wa5am91_gap_3_diff_title']) {
            gaps.push(new Gap(
                koboCollectData['group_wa5am91_gap_3/group_wa5am91_gap_3_diff_title'],
                koboCollectData['group_wa5am91_gap_3/group_wa5am91_gap_3_diff_action'],
                koboCollectData['group_wa5am91_gap_3/group_wa5am91_gap_3_diff_princresponsible'],
                koboCollectData['group_wa5am91_gap_3/group_wa5am91_gap_3_diff_delay'],
                koboCollectData['group_wa5am91_gap_3/group_wa5am91_gap_3_diff_verifmeans']
            ));
        }
        if (koboCollectData['group_wa5am91_gap_4/group_wa5am91_gap_4_diff_title']) {
            gaps.push(new Gap(
                koboCollectData['group_wa5am91_gap_4/group_wa5am91_gap_4_diff_title'],
                koboCollectData['group_wa5am91_gap_4/group_wa5am91_gap_4_diff_action'],
                koboCollectData['group_wa5am91_gap_4/group_wa5am91_gap_4_diff_princresponsible'],
                koboCollectData['group_wa5am91_gap_4/group_wa5am91_gap_4_diff_delay'],
                koboCollectData['group_wa5am91_gap_4/group_wa5am91_gap_4_diff_verifmeans']
            ));
        }
        if (koboCollectData['group_wa5am91_gap_5/group_wa5am91_gap_5_diff_title']) {
            gaps.push(new Gap(
                koboCollectData['group_wa5am91_gap_5/group_wa5am91_gap_5_diff_title'],
                koboCollectData['group_wa5am91_gap_5/group_wa5am91_gap_5_diff_action'],
                koboCollectData['group_wa5am91_gap_5/group_wa5am91_gap_5_diff_princresponsible'],
                koboCollectData['group_wa5am91_gap_5/group_wa5am91_gap_5_diff_delay'],
                koboCollectData['group_wa5am91_gap_5/group_wa5am91_gap_5_diff_verifmeans']
            ));
        }

        return gaps;
    }

    getValidationLabel(i: number) {
        return (this.koboCollectDatas[i]._validation_status.label === undefined) ?
            this.translate.instant('On Verification') :
            this.translate.instant(this.koboCollectDatas[i]._validation_status.label);
    }
}
