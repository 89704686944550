import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {RestAPIService} from '../../../../../../services/rest-api.service';
import {Router} from '@angular/router';
import {UtilityService} from '../../../../../../services/utility.service';
import {debounceTime} from 'rxjs/operators';
import {addDays, contractNumber, formatNumber, reloadComponent} from '../../../../../../_helpers';
import {Document} from '../../../../../../payloads/document.payload';
import {Commissioner, Contract, ContractDeliverable, ContractPayment} from '../../../../../../payloads/contract.payload';
import {Market} from '../../../../../../payloads/market.payload';
import {Project} from '../../../../../../payloads/project.payload';
import {Cashout} from '../../../../../../payloads/task.payload';
import {NotificationFlashService} from '../../../../../../services/notification-flash.service';
import {ConfirmationDialogService} from '../../../../../shared-component/confirmation-dialog/confirmation-dialog.service';

@Component({
    selector: 'app-create-contract',
    templateUrl: './create-contract.component.html',
    styleUrls: ['./create-contract.component.scss']
})
export class CreateContractComponent implements OnInit {

    isLoaded: boolean = false;

    slide1: boolean = false;
    slide2: boolean = true;
    slide3: boolean = true;

    slide1Form: UntypedFormGroup;
    slide1_1Form: UntypedFormGroup;
    slide2Form: UntypedFormGroup;
    slide3Form: UntypedFormGroup;

    slide1FormSubmitted: boolean = false;
    slide1_1FormSubmitted: boolean = false;
    slide2FormSubmitted: boolean = false;
    slide3FormSubmitted: boolean = false;

    devises: string[];

    private _danger = new Subject<string>();

    staticAlertClosed = false;
    errorMessage: string;

    @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;
    document: Document = null;
    contractPayments: ContractPayment[] = [];
    deliverables: ContractDeliverable[] = [];
    commissioners: Commissioner[] = [];
    judicialForms: string[];
    contract: Contract = null;
    market: Market = null;
    project: Project = null;
    @Input() signedMarket: any;

    editedCashouts: Cashout[] = [];

    constructor(private formBuilder: UntypedFormBuilder, private translate: TranslateService, private restAPIService: RestAPIService,
                private router: Router, private utilityService: UtilityService, private activeModal: NgbActiveModal,
                private notification: NotificationFlashService, private confirmationDialog: ConfirmationDialogService) {
    }

    ngOnInit(): void {
        this._danger.subscribe((message) => (this.errorMessage = message));
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.market = this.signedMarket.market;
        this.project = this.signedMarket.project;
        this.contract = this.signedMarket.contract;
        this.devises = this.utilityService.fetchCurrencies();
        this.judicialForms = this.utilityService.fetchJudicialForm();

        this.initForm();

        this.isLoaded = true;
    }

    get csrForm() {
        return this.slide1Form.controls;
    }

    get modForm() {
        return this.slide1_1Form.controls;
    }

    get dForm() {
        return this.slide2Form.controls;
    }

    get pForm() {
        return this.slide3Form.controls;
    }

    private initForm() {
        if (this.contract === null) {
            this.slide1Form = this.formBuilder.group({
                numb: [contractNumber(this.market.numb, this.market.contracts.length + 1), [Validators.required]],
                title: [this.market.title, [Validators.required]],
                socialReason: [null, [Validators.required]],
                uin: [null, [Validators.required]],
                uinFile: [null],
                rccm: [null, [Validators.required]],
                judicialForm: [null, [Validators.required]],
                phone: [null],
                directorName: [null],
                companyNationality: [null],
                signatoryName: [null, [Validators.required]],
                signatoryFunction: [null, [Validators.required]],
                projectOwner: [null],
                marketServiceChief: [null],
                delProjectOwner: [null],
                beneficiaryAccountNumb: [null],
                bankingInstitution: [null],
                amount: [formatNumber(this.market.amount)],
                currency: [this.market.currency, [Validators.required]],
                amountFcfa: [formatNumber(this.market.amountFcfa)]
            });

            this.slide1_1Form = this.formBuilder.group({
                amount: [null, [Validators.required]],
                condition: [null, [Validators.required]]
            });

            this.slide2Form = this.formBuilder.group({
                name: [null, [Validators.required]],
                fonction: [null, [Validators.required]]
            });

            this.slide3Form = this.formBuilder.group({
                title: [null, [Validators.required]],
                description: [null],
                amount: [null],
                submissionLimit: [null, [Validators.required]],
                submissionDate: [null, [Validators.required]],
                validationLimit: [null, [Validators.required]],
                validationDate: [null, [Validators.required]]
            });

        } else {
            this.slide1Form = this.formBuilder.group({
                numb: [this.contract.numb, [Validators.required]],
                title: [this.contract.title, [Validators.required]],
                socialReason: [this.contract.socialReason, [Validators.required]],
                uin: [this.contract.uin, [Validators.required]],
                uinFile: [null],
                rccm: [this.contract.rccm, [Validators.required]],
                judicialForm: [this.contract.judicialForm, [Validators.required]],
                phone: [this.contract.phone],
                directorName: [this.contract.directorName],
                companyNationality: [this.contract.companyNationality],
                signatoryName: [this.contract.signatoryName, [Validators.required]],
                signatoryFunction: [this.contract.signatoryFunction, [Validators.required]],
                projectOwner: [this.contract.projectOwner],
                marketServiceChief: [this.contract.marketServiceChief],
                delProjectOwner: [this.contract.delProjectOwner],
                beneficiaryAccountNumb: [this.contract.beneficiaryAccountNumb],
                bankingInstitution: [this.contract.bankingInstitution],
                amount: [formatNumber(this.market.amount)],
                currency: [this.market.currency, [Validators.required]],
                amountFcfa: [formatNumber(this.market.amountFcfa)]
            });

            this.slide1_1Form = this.formBuilder.group({
                amount: [null, [Validators.required]],
                condition: [null, [Validators.required]]
            });

            this.slide2Form = this.formBuilder.group({
                name: [null, [Validators.required]],
                fonction: [null, [Validators.required]]
            });

            this.slide3Form = this.formBuilder.group({
                title: [null, [Validators.required]],
                description: [null],
                amount: [null],
                submissionLimit: [null, [Validators.required]],
                submissionDate: [null, [Validators.required]],
                validationLimit: [null, [Validators.required]],
                validationDate: [null, [Validators.required]]
            });

            this.document = this.contract.document;
            this.contractPayments = this.contract.contractPayments;
            this.commissioners = this.contract.commissioners;
            this.deliverables = this.contract.deliverables;
        }
    }

    onEditedCashoutsChanged(newEditedCashouts: Cashout[]): void {
        this.editedCashouts = newEditedCashouts;
    }

    changeSlide(nextSlide: number) {
        this.errorMessage = null;

        if (nextSlide === 1) {
            this.slide1 = false;
            this.slide2 = true;
            this.slide3 = true;
        }

        if (nextSlide === 2) {
            this.slide1FormSubmitted = true;
            if (this.slide1Form.invalid) {
                return;
            }

            this.slide1 = true;
            this.slide2 = false;
            this.slide3 = true;
        }

        if (nextSlide === 3) {

            this.slide2FormSubmitted = true;

            if (this.commissioners.length !== 0) {

                this.slide1 = true;
                this.slide2 = true;
                this.slide3 = false;
            }
        }

    }

    onFileSelect(event: any) {
        const file: File = event.target.files[0];

        if (file) {
            // File Preview
            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = (read: any) => {
                this.document = new Document(
                    0, file.name,
                    file.type, read.target.result
                );
            };
        }
    }

    addContractPayment() {
        this.slide1_1FormSubmitted = true;
        if (this.slide1_1Form.invalid) {
            return;
        }

        const contractPayment = new ContractPayment(
            this.slide1_1Form.value.amount,
            this.slide1_1Form.value.condition
        );

        this.contractPayments.push(contractPayment);

        this.slide1_1FormSubmitted = false;

        this.slide1_1Form.get('amount').setValue('');
        this.slide1_1Form.get('condition').setValue('');

    }

    deleteContractPayment(i: number) {
        this.contractPayments.splice(i, 1);
    }

    addCommissioner() {
        this.slide2FormSubmitted = true;
        if (this.slide2Form.invalid) {
            return;
        }

        const commissioner = new Commissioner(
            this.slide2Form.value.name,
            this.slide2Form.value.fonction
        );

        this.commissioners.push(commissioner);

        this.slide2FormSubmitted = false;

        this.slide2Form.get('name').setValue('');
        this.slide2Form.get('fonction').setValue('');

    }

    deleteCommissioner(i: number) {
        this.commissioners.splice(i, 1);
    }

    addDeliverable() {
        this.slide3FormSubmitted = true;
        if (this.slide3Form.invalid) {
            return;
        }

        const deliverable = new ContractDeliverable(
            this.slide3Form.value.title,
            this.slide3Form.value.description,
            this.slide3Form.value.amount,
            this.slide3Form.value.submissionLimit,
            this.slide3Form.value.submissionDate,
            this.slide3Form.value.validationLimit,
            this.slide3Form.value.validationDate
        );

        this.deliverables.push(deliverable);

        this.slide3FormSubmitted = false;

        this.slide3Form.get('title').setValue('');
        this.slide3Form.get('description').setValue('');
        this.slide3Form.get('amount').setValue('');
        this.slide3Form.get('submissionLimit').setValue('');
        this.slide3Form.get('submissionDate').setValue('');
        this.slide3Form.get('validationLimit').setValue('');
        this.slide3Form.get('validationDate').setValue('');
    }

    deleteDeliverable(i: number) {
        this.deliverables.splice(i, 1);
    }

    onSubmit() {
        this.contract = new Contract(
            this.slide1Form.value.numb,
            this.slide1Form.value.title,
            this.slide1Form.value.socialReason,
            this.slide1Form.value.uin,
            this.slide1Form.value.rccm,
            this.slide1Form.value.judicialForm,
            this.slide1Form.value.phone,
            this.slide1Form.value.directorName,
            this.slide1Form.value.companyNationality,
            this.slide1Form.value.signatoryName,
            this.slide1Form.value.signatoryFunction,
            this.slide1Form.value.projectOwner,
            this.slide1Form.value.marketServiceChief,
            this.slide1Form.value.delProjectOwner,
            this.slide1Form.value.beneficiaryAccountNumb,
            this.slide1Form.value.bankingInstitution,
            this.contractPayments,
            this.commissioners,
            this.deliverables,
            this.document,
            this.editedCashouts
        );

        this.confirmationDialog.confirm(this.translate.instant('contract'), this.translate.instant('confirmations.contents.create-contract'))
            .then((confirmation) => {
                    if (confirmation) {
                        this.restAPIService.createContract(this.market.idmarket, this.translate.currentLang, this.contract).subscribe(
                            () => {
                                this.notification.success(this.translate.instant('notifications.success.contract-created'));

                                this.closeModal(this.contract);

                                reloadComponent(this.router);

                            }, (error) => {
                                if (error.status === 400) {
                                    this.notification.danger(
                                        this.translate.instant('notifications.error.bad-request')
                                    );
                                } else {
                                    this.notification.danger(
                                        this.translate.instant('notifications.success.contract-not-created')
                                    );
                                }
                            }
                        );
                    }
                }
            );
    }

    formatDate(date: string): string {
        return new Date(date).toLocaleDateString('fr-FR');
    }

    formatMoney(amount: number) {
        return formatNumber(amount);
    }

    closeModal(contract: Contract) {
        this.activeModal.close(contract);
    }

    submissionDate(days: any) {
        this.slide3Form.get('submissionDate').setValue(addDays(this.signedMarket.signatureDate, days));
    }

    validationDate(days: any) {
        this.slide3Form.get('validationDate').setValue(addDays(this.signedMarket.signatureDate, days));
    }

    checkPaymentAmount(amount: any) {
        let paymentsAmount: number = 0;
        this.contractPayments.forEach((payment: ContractPayment) => {
            paymentsAmount += payment.amount;
        });

        const remainingAmount = this.market.amountFcfa - paymentsAmount;

        if (amount > remainingAmount) {
            this.errorMessage = this.translate.instant(
                'notifications.danger.payments-amount-not-more-than-contract-amount',
                {amount: this.formatMoney(remainingAmount)},
            );

            this.slide1_1Form.get('amount').setValue(Math.floor(amount / 10));
        } else {
            this.errorMessage = null;
        }

        this.slide1_1Form.get('amount').setValue(this.slide1_1Form.value.amount);
    }

    checkDeliverableAmount(amount: any) {
        let deliverablesAmount: number = 0;
        this.deliverables.forEach((deliverable: ContractDeliverable) => {
            deliverablesAmount += deliverable.amount;
        });

        const remainingAmount = this.market.amountFcfa - deliverablesAmount;

        if (amount > remainingAmount) {
            this.errorMessage = this.translate.instant(
                'notifications.danger.deliverables-amount-not-more-than-contract-amount',
                {amount: this.formatMoney(remainingAmount)},
            );

            this.slide3Form.get('amount').setValue(Math.floor(amount / 10));
        } else {
            this.errorMessage = null;
        }

        this.slide3Form.get('amount').setValue(this.slide3Form.value.amount);
    }

    onCashoutsUpdated(updatedCashouts: Cashout[]) {
        this.editedCashouts = updatedCashouts;
    }
}
