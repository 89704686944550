import {Injectable} from '@angular/core';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

import {ConfirmationDialogComponent} from './confirmation-dialog.component';

@Injectable()
export class ConfirmationDialogService {

    constructor(private modalService: NgbModal, private translate: TranslateService) {
    }

    public confirm(title: string, message: string, keyboard: boolean = true,
                   btnOkText: string = this.translate.instant('yes'),
                   btnCancelText: string = this.translate.instant('no'),
                   dialogSize: 'xl' | 'lg' | 'md' | 'sm' | 'xs' = 'md'): Promise<boolean> {
        const modalRef = this.modalService.open(ConfirmationDialogComponent, {size: dialogSize, backdrop: false});
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.btnOkText = btnOkText;
        modalRef.componentInstance.btnCancelText = btnCancelText;
        modalRef.componentInstance.keyboard = false;

        return modalRef.result;
    }

}
