import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {NgbActiveModal, NgbAlert, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Document} from '../../../../../../payloads/document.payload';
import {Commissioner, Contract, ContractDeliverable, ContractPayment} from '../../../../../../payloads/contract.payload';
import {Market} from '../../../../../../payloads/market.payload';
import {TranslateService} from '@ngx-translate/core';
import {RestAPIService} from '../../../../../../services/rest-api.service';
import {Router} from '@angular/router';
import {UtilityService} from '../../../../../../services/utility.service';
import {debounceTime} from 'rxjs/operators';
import {addDays, formatNumber} from '../../../../../../_helpers';
import {FileViewerComponent} from '../../../../../shared-component/file-viewer/file-viewer.component';
import {Project} from '../../../../../../payloads/project.payload';

@Component({
    selector: 'app-view-contract',
    templateUrl: './view-contract.component.html',
    styleUrls: ['./view-contract.component.scss']
})
export class ViewContractComponent implements OnInit {

    isLoaded: boolean = false;

    slide1: boolean = false;
    slide2: boolean = true;
    slide3: boolean = true;
    slide4: boolean = true;
    slide1Form: UntypedFormGroup;
    slide1_1Form: UntypedFormGroup;
    slide2Form: UntypedFormGroup;
    slide3Form: UntypedFormGroup;
    slide4Form: UntypedFormGroup;


    slide1FormSubmitted: boolean = false;
    slide1_1FormSubmitted: boolean = false;
    slide2FormSubmitted: boolean = false;
    slide3FormSubmitted: boolean = false;
    slide4FormSubmitted: boolean = false;


    devises: string[];
    staticAlertClosed = false;
    errorMessage: string;
    @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;
    document: Document = null;
    contractPayments: ContractPayment[] = [];
    deliverables: ContractDeliverable[] = [];
    commissioners: Commissioner[] = [];
    judicialForms: string[];
    contract: Contract = null;
    market: Market = null;
    project: Project = null;
    @Input() signedMarket: any;
    private _danger = new Subject<string>();

    constructor(private formBuilder: UntypedFormBuilder, private translate: TranslateService, private restAPIService: RestAPIService,
                private router: Router, private utilityService: UtilityService, private activeModal: NgbActiveModal,
                private modalService: NgbModal) {
    }

    get csrForm() {
        return this.slide1Form.controls;
    }

    get modForm() {
        return this.slide1_1Form.controls;
    }

    get dForm() {
        return this.slide2Form.controls;
    }

    get pForm() {
        return this.slide3Form.controls;
    }

    ngOnInit(): void {
        this._danger.subscribe((message) => (this.errorMessage = message));
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.market = this.signedMarket.market;
        this.project = this.signedMarket.project;
        this.contract = this.signedMarket.contract;

        this.document = this.contract.document;

        this.contractPayments = [...this.contract.contractPayments];
        this.contractPayments.sort((contractPayment1: ContractPayment, contractPayment2: ContractPayment) =>
            contractPayment1.idcontractPayment > contractPayment2.idcontractPayment ? 1 : -1);

        this.commissioners = [...this.contract.commissioners];
        this.commissioners.sort((commissioner1: Commissioner, commissioner2: Commissioner) =>
            commissioner1.idcommissioner > commissioner2.idcommissioner ? 1 : -1);

        this.deliverables = [...this.contract.deliverables];
        this.deliverables.sort((contractDeliverable1: ContractDeliverable, contractDeliverable2: ContractDeliverable) =>
            contractDeliverable1.idcontractDeliverable > contractDeliverable2.idcontractDeliverable ? 1 : -1);

        this.devises = this.utilityService.fetchCurrencies();
        this.judicialForms = this.utilityService.fetchJudicialForm();

        this.initForm();

        this.isLoaded = true;
    }

    changeSlide(nextSlide: number) {
        this.errorMessage = null;

        if (nextSlide === 1) {
            this.slide1 = false;
            this.slide2 = true;
            this.slide3 = true;
            this.slide4 = true;
        }

        if (nextSlide === 2) {
            this.slide1FormSubmitted = true;
            if (this.slide1Form.invalid) {
                return;
            }

            this.slide1 = true;
            this.slide2 = false;
            this.slide3 = true;
            this.slide4 = true;
        }

        if (nextSlide === 3) {
            this.slide1 = true;
            this.slide2 = true;
            this.slide3 = false;
            this.slide4 = true;
        }

        if (nextSlide === 4) {
            this.slide1 = true;
            this.slide2 = true;
            this.slide3 = true;
            this.slide4 = false;
        }
    }

    onAmountFcfaChange() {
        const amountFcfa = this.slide4Form.get('amountFcfa').value;
        const exchangeRate = this.market.exchangeRate;

        // Vérifiez si amountFcfa et exchangeRate sont des nombres valides
        if (!isNaN(amountFcfa) && !isNaN(exchangeRate)) {
            const amountInCurrency = amountFcfa / exchangeRate;
            this.slide4Form.get('amount').setValue(amountInCurrency);
        }
    }

    onFileSelect(event: any) {
        const file: File = event.target.files[0];

        if (file) {
            // File Preview
            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = (read: any) => {
                this.document = new Document(
                    0, file.name,
                    file.type, read.target.result
                )
            };
        }
    }

    addContractPayment() {
        this.slide1_1FormSubmitted = true;
        if (this.slide1_1Form.invalid) {
            return;
        }

        const contractPayment = new ContractPayment(
            this.slide1_1Form.value.amount,
            this.slide1_1Form.value.condition
        );

        this.contractPayments.push(contractPayment);

        this.slide1_1FormSubmitted = false;

        this.slide1_1Form.get('amount').setValue('');
        this.slide1_1Form.get('condition').setValue('');

    }

    deleteContractPayment(i: number) {
        this.contractPayments.splice(i, 1);
    }

    addCommissioner() {
        this.slide2FormSubmitted = true;
        if (this.slide2Form.invalid) {
            return;
        }

        const commissioner = new Commissioner(
            this.slide2Form.value.name,
            this.slide2Form.value.fonction
        );

        this.commissioners.push(commissioner);

        this.slide2FormSubmitted = false;

        this.slide2Form.get('name').setValue('');
        this.slide2Form.get('fonction').setValue('');

    }

    deleteCommissioner(i: number) {
        this.commissioners.splice(i, 1);
    }

    addDeliverable() {
        this.slide3FormSubmitted = true;
        if (this.slide3Form.invalid) {
            return;
        }

        const deliverable = new ContractDeliverable(
            this.slide3Form.value.title,
            this.slide3Form.value.description,
            this.slide3Form.value.amount,
            this.slide3Form.value.submissionLimit,
            this.slide3Form.value.submissionDate,
            this.slide3Form.value.validationLimit,
            this.slide3Form.value.validationDate
        );

        this.deliverables.push(deliverable);

        this.slide3FormSubmitted = false;

        this.slide3Form.get('title').setValue('');
        this.slide3Form.get('description').setValue('');
        this.slide3Form.get('amount').setValue('');
        this.slide3Form.get('submissionLimit').setValue('');
        this.slide3Form.get('submissionDate').setValue('');
        this.slide3Form.get('validationLimit').setValue('');
        this.slide3Form.get('validationDate').setValue('');
    }

    deleteDeliverable(i: number) {
        this.deliverables.splice(i, 1);
    }

    onSubmit() {
        this.closeModal(this.contract);
    }

    formatDate(date: string): string {
        return new Date(date).toLocaleDateString('fr-FR');
    }

    formatMoney(amount: number) {
        return formatNumber(amount);
    }

    closeModal(contract: Contract) {
        this.activeModal.close(contract);
    }

    submissionDate(days: any) {
        this.slide3Form.get('submissionDate').setValue(addDays(this.signedMarket.signatureDate, days));
    }

    validationDate(days: any) {
        this.slide3Form.get('validationDate').setValue(addDays(this.signedMarket.signatureDate, days));
    }

    checkPaymentAmount(amount: any) {
        let paymentsAmount: number = 0;
        this.contractPayments.forEach((payment: ContractPayment) => {
            paymentsAmount += payment.amount;
        });

        const remainingAmount = this.market.amountFcfa - paymentsAmount;

        if (amount > remainingAmount) {
            this.errorMessage = this.translate.instant(
                'notifications.danger.payments-amount-not-more-than-contract-amount',
                {amount: this.formatMoney(remainingAmount)},
            );

            this.slide1_1Form.get('amount').setValue(Math.floor(amount / 10));
        } else {
            this.errorMessage = null;
        }

        this.slide1_1Form.get('amount').setValue(this.slide1_1Form.value.amount);
    }

    checkDeliverableAmount(amount: any) {
        let deliverablesAmount: number = 0;
        this.deliverables.forEach((deliverable: ContractDeliverable) => {
            deliverablesAmount += deliverable.amount;
        });

        const remainingAmount = this.market.amountFcfa - deliverablesAmount;

        if (amount > remainingAmount) {
            this.errorMessage = this.translate.instant(
                'notifications.danger.deliverables-amount-not-more-than-contract-amount',
                {amount: this.formatMoney(remainingAmount)},
            );

            this.slide3Form.get('amount').setValue(Math.floor(amount / 10));
        } else {
            this.errorMessage = null;
        }

        this.slide3Form.get('amount').setValue(this.slide3Form.value.amount);
    }

    view(idDocument: number) {
        this.modalService.open(FileViewerComponent, {
            windowClass: 'gr-modal-full',
            scrollable: false,
            backdrop: false,
            centered: true
        }).componentInstance.idDocument = idDocument;
    }

    private initForm() {
        this.slide1Form = this.formBuilder.group({
            numb: [this.contract.numb],
            title: [this.contract.title],
            socialReason: [this.contract.socialReason],
            uin: [this.contract.uin],
            uinFile: [null],
            rccm: [this.contract.rccm],
            judicialForm: [this.contract.judicialForm],
            phone: [this.contract.phone],
            directorName: [this.contract.directorName],
            companyNationality: [this.contract.companyNationality],
            signatoryName: [this.contract.signatoryName],
            signatoryFunction: [this.contract.signatoryFunction],
            projectOwner: [this.contract.projectOwner],
            marketServiceChief: [this.contract.marketServiceChief],
            delProjectOwner: [this.contract.delProjectOwner],
            beneficiaryAccountNumb: [this.contract.beneficiaryAccountNumb],
            bankingInstitution: [this.contract.bankingInstitution],
            amount: [formatNumber(this.market.amount)],
            currency: [this.market.currency],
            amountFcfa: [formatNumber(this.market.amountFcfa)]
        });

        this.slide1_1Form = this.formBuilder.group({
            amount: [null],
            condition: [null]
        });

        this.slide2Form = this.formBuilder.group({
            name: [null],
            fonction: [null]
        });

        this.slide3Form = this.formBuilder.group({
            title: [null],
            description: [null],
            amount: [null],
            submissionLimit: [null],
            submissionDate: [null],
            validationLimit: [null],
            validationDate: [null]
        });

        this.slide4Form = this.formBuilder.group({
            startDate: [null, [Validators.required]],
            endDate: [null, [Validators.required]],
            amount: [null, [Validators.required]],
            amountFcfa: [null, [Validators.required]],
            description: [null],
        });
    }
}
