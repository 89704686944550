/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {firstValueFrom, Observable, of} from 'rxjs';
import {Activity, ActivityANODate} from '../payloads/activity.payload';
import {BasicInfo} from '../payloads/basic-information.payload';
import {ActivityBudget, TaskBudget} from '../payloads/budgetary.payload';
import {Calendar} from '../payloads/calendar.payload';
import {Canceled} from '../payloads/canceled.payload';
import {Claim} from '../payloads/complain-manage.payload';
import {MarketExecutionDate, MarketFollowUp} from '../payloads/complete-execution.payload';
import {Document} from '../payloads/document.payload';
import {FinancialData} from '../payloads/finance-data.payload';
import {CompleteFinanceMobil, FinanceMobil} from '../payloads/finance-mobilisation.payload';
import {CompleteImplementationProc, ImplementationProc, ImplementationState} from '../payloads/implementation-process.payload';
import {Indicator} from '../payloads/indicator.payload';
import {LogicalFramework} from '../payloads/logical-framework.payload';
import {Market, Procurement} from '../payloads/market.payload';
import {CompleteMaturationEval, MaturationEval} from '../payloads/maturation-evaluation.payload';
import {Composante, InstDisposition, Project, SubComponent} from '../payloads/project.payload';
import {ContReview, Review} from '../payloads/review.payload';
import {Role} from '../payloads/role.payload';
import {ActivityOper} from '../payloads/show-activity.payload';
import {CompleteSignatureProc, SignatureProc} from '../payloads/signature-process.payload';
import {StrategicPlan} from '../payloads/strategic-plan.payload';
import {Cashout, Task} from '../payloads/task.payload';
import {User} from '../payloads/user.payload';
import {ProjectReport} from '../payloads/utility.payload';
import {JwtResponse} from './security/jwt-response';
import {ServerUrl} from './server-url.service';
import {Closed} from '../payloads/closed.payload';
import {Contract} from '../payloads/contract.payload';
import {KoboCollect} from '../interfaces/KoboCollect';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class RestAPIService {

    constructor(private http: HttpClient, private serverUrl: ServerUrl) {
    }

    //  Project
    createProject(project: Project): Observable<Project> {
        return this.http.post<Project>(this.serverUrl.projectBaseURL + 'create-project', project, httpOptions);
    }

    getKoboCollectForms(): Observable<KoboCollect[]> {
        return this.http.get<KoboCollect[]>(this.serverUrl.kobolToolBoxBaseURL, httpOptions);
    }

    getKoboCollectFormDatas(formID: number): Observable<any> {
        return this.http.get<any>(this.serverUrl.kobolToolBoxBaseURL + '/' + formID, httpOptions);
    }

    getKoboCollectFormDataDocuemnt(downLoadURL: string): Observable<string> {
        return this.http.get<string>(this.serverUrl.kobolToolBoxBaseURL + '/attachments?downloadURL=' + downLoadURL, httpOptions);
    }

    fetchProjects(): Observable<any> {
        return this.http.get(this.serverUrl.projectBaseURL, httpOptions);
    }

    fetchProjectsByStatus(status: string[]): Observable<any> {
        return this.http.get(this.serverUrl.projectBaseURL + 'status/' + status, httpOptions);
    }

    fetchProject(idproject: number): Observable<any> {
        return this.http.get(this.serverUrl.projectBaseURL + idproject, httpOptions);
    }

    updateProjectBasicInfo(idproject: number, basicInfo: BasicInfo): Observable<BasicInfo> {
        return this.http.put<BasicInfo>(this.serverUrl.projectBaseURL + idproject + '/basic-information', basicInfo, httpOptions);
    }

    deleteProjectTeam(idprojTeam: number): Observable<string> {
        return this.http.delete<string>(this.serverUrl.projectBaseURL + 'project-team/' + idprojTeam, httpOptions);
    }

    deletePortProjectTeam(idportProjTeam: number): Observable<string> {
        return this.http.delete<string>(this.serverUrl.projectBaseURL + 'port-project-team/' + idportProjTeam, httpOptions);
    }

    updateProjectInstDisposition(idproject: number, instDisposition: InstDisposition): Observable<InstDisposition> {
        return this.http.put<InstDisposition>(this.serverUrl.projectBaseURL + idproject + '/institution-disposition', instDisposition,
            httpOptions);
    }

    updateProjectCalendar(idproject: number, calendar: Calendar): Observable<Calendar> {
        return this.http.put<Calendar>(this.serverUrl.projectBaseURL + idproject + '/calendar', calendar, httpOptions);
    }

    updateProjectLogicalFramework(idproject: number, logicalFramework: LogicalFramework): Observable<LogicalFramework> {
        return this.http.put<LogicalFramework>(this.serverUrl.projectBaseURL + idproject + '/logical-framework', logicalFramework,
            httpOptions);
    }

    updateProjectFinancialData(idproject: number, financialData: FinancialData): Observable<FinancialData> {
        return this.http.put<FinancialData>(this.serverUrl.projectBaseURL + idproject + '/financial-data', financialData, httpOptions);
    }

    validateProject(idproject: number, validate: string) {
        return this.http.put(this.serverUrl.projectBaseURL + idproject + '/validate-project/' + validate, httpOptions);
    }

    //  Activity
    createActivity(activity: Activity): Observable<Activity> {
        return this.http.post<Activity>(this.serverUrl.createActivityURL, activity, httpOptions);
    }

    updateActivity(idactivity: number, activity: Activity): Observable<any> {
        return this.http.put(this.serverUrl.activityBaseURL + idactivity + '/update-activity', activity, httpOptions);
    }

    activityANO(idactivity: number, activityANODate: ActivityANODate): Observable<any> {
        return this.http.put(this.serverUrl.activityBaseURL + idactivity + '/activity-ano', activityANODate, httpOptions);
    }

    validateActivity(idactivity: number, validate: string) {
        return this.http.put(this.serverUrl.activityBaseURL + idactivity + '/validate-activity/' + validate, httpOptions);
    }

    activityOperation(activityOper: ActivityOper): Observable<ActivityOper> {
        return this.http.put<ActivityOper>(this.serverUrl.activityBaseURL + 'activity-operation', activityOper, httpOptions);
    }

    activityBudget(activityBudget: ActivityBudget[]): Observable<ActivityBudget> {
        return this.http.put<ActivityBudget>(this.serverUrl.activityBaseURL + 'activity-budget', activityBudget, httpOptions);
    }

    //  Task
    createTask(idactivity: number, tasks: Task[]): Observable<Task> {
        return this.http.post<Task>(this.serverUrl.taskBaseURL + idactivity + '/create-task', tasks, httpOptions);
    }

    deleteTask(idtask: number): Observable<boolean> {
        return this.http.delete<boolean>(this.serverUrl.taskBaseURL + idtask, httpOptions);
    }

    completeTask(idtask: number, task: Task): Observable<any> {
        return this.http.put(this.serverUrl.taskBaseURL + idtask + '/complete-task', task, httpOptions);
    }

    taskBudget(taskBudget: TaskBudget[]): Observable<TaskBudget> {
        return this.http.put<TaskBudget>(this.serverUrl.taskBaseURL + 'task-budget', taskBudget, httpOptions);
    }

    fetchTask(idtask: number): Observable<any> {
        return this.http.get(this.serverUrl.taskBaseURL + idtask, httpOptions);
    }

    //  Market
    createMarket(market: Market): Observable<Market> {
        return this.http.post<Market>(this.serverUrl.createMarketURL, market, httpOptions);
    }

    updateMarket(idmarket: number, market: Market): Observable<any> {
        return this.http.put(this.serverUrl.marketBaseURL + idmarket + '/update-market', market, httpOptions);
    }

    validateMarket(idmarket: number, validate: string) {
        return this.http.put(this.serverUrl.marketBaseURL + idmarket + '/validate-market/' + validate, httpOptions);
    }

    marketFollowUp(idmarket: number, marketFollowUp: MarketFollowUp, changeStatus: number = 0): Observable<any> {
        return this.http.put(this.serverUrl.marketBaseURL + idmarket + '/market-follow-up/' + changeStatus, marketFollowUp, httpOptions);
    }

    getMarketFromTask(idtask: number) {
        return this.http.get(this.serverUrl.getMarketURL + idtask, httpOptions);
    }

    //  Procurement
    updateProcurement(procurements: Procurement[]): Observable<Procurement[]> {
        return this.http.put<Procurement[]>(this.serverUrl.procurementBaseURL + 'update-procurement', procurements, httpOptions);
    }

    completeProcurement(idmarket: number, completed: any): Observable<Document> {
        return this.http.put<Document>(this.serverUrl.procurementBaseURL + idmarket + '/complete-procurement/', completed, httpOptions);
    }

    //  Finance Mobilisation
    updateFinanceMobilisation(financeMobils: FinanceMobil[]): Observable<FinanceMobil[]> {
        return this.http.put<FinanceMobil[]>(this.serverUrl.financeMobilisationBaseURL + 'update-finance-mobilisation',
            financeMobils, httpOptions);
    }

    completeFinanceMobilisation(idproject: number, completeFinanceMobils: CompleteFinanceMobil[], changeStatus: boolean = false):
        Observable<CompleteFinanceMobil[]> {
        return this.http.put<CompleteFinanceMobil[]>(this.serverUrl.financeMobilisationBaseURL + idproject + '/complete-finance-mobilisation/' +
            changeStatus, completeFinanceMobils, httpOptions);
    }

    //  Maturation Evaluation
    updateMaturationEvaluation(maturationEvals: MaturationEval[]): Observable<MaturationEval[]> {
        return this.http.put<MaturationEval[]>(this.serverUrl.maturationEvaluationURL + 'update-maturation-evaluation',
            maturationEvals, httpOptions);
    }

    completeMaturationEvaluation(idproject: number, completeMaturationEvals: CompleteMaturationEval[], changeStatus: boolean = false):
        Observable<CompleteMaturationEval[]> {
        return this.http.put<CompleteMaturationEval[]>(this.serverUrl.maturationEvaluationURL + idproject + '/complete-maturation-evaluation/' +
            changeStatus, completeMaturationEvals, httpOptions);
    }

    //  Signature Process
    updateSignatureProcess(signatureProcs: SignatureProc[]): Observable<SignatureProc[]> {
        return this.http.put<SignatureProc[]>(this.serverUrl.signatureProcessURL + 'update-signature-process',
            signatureProcs, httpOptions);
    }

    completeSignatureProcess(idproject: number, completeSignatureProcs: CompleteSignatureProc[], changeStatus: boolean = false):
        Observable<CompleteSignatureProc[]> {
        return this.http.put<CompleteSignatureProc[]>(this.serverUrl.signatureProcessURL + idproject + '/complete-signature-process/' +
            changeStatus, completeSignatureProcs, httpOptions);
    }

    //  Implementation Process
    createImplementationProcess(idproject: number, implementationProcs: ImplementationProc[]): Observable<ImplementationProc[]> {
        return this.http.post<ImplementationProc[]>(this.serverUrl.implementationProcessURL + idproject + '/create-implementation-process',
            implementationProcs, httpOptions);
    }

    updateImplementationProcess(implementationProcs: ImplementationProc[]):
        Observable<ImplementationProc[]> {
        return this.http.put<ImplementationProc[]>(this.serverUrl.implementationProcessURL + 'update-implementation-process',
            implementationProcs, httpOptions);
    }

    completeImplementationProcess(idproject: number, completeImplementationProcs: CompleteImplementationProc[],
        changeStatus: boolean = false): Observable<CompleteImplementationProc[]> {
        return this.http.put<CompleteImplementationProc[]>(this.serverUrl.implementationProcessURL + idproject +
            '/complete-implementation-process/' + changeStatus, completeImplementationProcs, httpOptions);
    }

    // Review
    createReview(idProject: number, review: Review): Observable<Review> {
        return this.http.post<Review>(this.serverUrl.createReviewURL + idProject, review, httpOptions);
    }

    // Continue Review
    continueReview(idreview: number, contReview: ContReview): Observable<ContReview> {
        return this.http.post<ContReview>(this.serverUrl.createContReviewURL + idreview, contReview, httpOptions);
    }


    // Claim
    createClaim(claim: Claim): Observable<Claim> {
        return this.http.post<Claim>(this.serverUrl.createClaimURL, claim, httpOptions);
    }

    // Canceled
    cancelProject(idproject: number, canceled: Canceled): Observable<Canceled> {
        return this.http.put<Canceled>(this.serverUrl.projectBaseURL + idproject + '/canceled', canceled, httpOptions);
    }

    // Closed
    closeProject(idproject: number, closed: Closed): Observable<Closed> {
        return this.http.put<Closed>(this.serverUrl.projectBaseURL + idproject + '/closed', closed, httpOptions);
    }

    resetMarketExecutionDate(idmarketExecution: number, marketExecutionDate: MarketExecutionDate): Observable<MarketExecutionDate> {
        return this.http.put<MarketExecutionDate>(this.serverUrl.marketExecutionBaseURL + idmarketExecution + '/reset-planned-date',
            marketExecutionDate, httpOptions);
    }

    //  Strategic Planification
    createStrategicPlanifiction(idproject: number, strategicPlan: StrategicPlan): Observable<StrategicPlan> {
        return this.http.post<StrategicPlan>(this.serverUrl.projectBaseURL + idproject + '/strategic-planification', strategicPlan,
            httpOptions);
    }

    // Connection Logs
    fetchConnectionLogs(): Observable<any> {
        return this.http.get(this.serverUrl.connectionLogBase, httpOptions);
    }


    //  User
    createUser(user: any): Observable<User> {
        return this.http.post<User>(this.serverUrl.createUser, user, httpOptions);
    }

    fetchUsers(): Observable<any> {
        return this.http.get(this.serverUrl.userBase, httpOptions);
    }

    fetchUser(idUser: number): Observable<any> {
        return this.http.get(this.serverUrl.userBase + idUser, httpOptions);
    }

    updateUser(idUser: number, user: any) {
        return this.http.put<User>(this.serverUrl.userBase + idUser, user, httpOptions);
    }

    updatePassword(idUser: number, userPassword) {
        return this.http.put(this.serverUrl.userBase + idUser + '/update-password', userPassword, httpOptions);
    }

    changeStatus(idUser: number, status: boolean) {
        return this.http.put<User>(this.serverUrl.userBase + idUser + '/active/' + status, httpOptions);
    }

    signIn(signinInfos: any): Observable<JwtResponse> {
        return this.http.post<JwtResponse>(this.serverUrl.signinUrl, signinInfos, httpOptions);
    }

    signUp(signupInfos: any): Observable<any> {
        return this.http.post<any>(this.serverUrl.signupUrl, signupInfos, httpOptions);
    }

    signOut(idUser: any): Observable<any> {
        return this.http.put<any>(this.serverUrl.signoutUrl + idUser, httpOptions);
    }

    forgotPassword(forgotInfos: any): Observable<any> {
        return this.http.put<any>(this.serverUrl.forgotPasswordUrl, forgotInfos, httpOptions);
    }

    resetUserPassword(resetInfos: any): Observable<any> {
        return this.http.put<any>(this.serverUrl.resetPasswordUrl, resetInfos, httpOptions);
    }

    // Role
    createRole(role: Role): Observable<Role> {
        return this.http.post<Role>(this.serverUrl.createRole, role, httpOptions);
    }

    fetchRoles(): Observable<any> {
        return this.http.get(this.serverUrl.roleBase, httpOptions);
    }

    updateRole(idRole: number, role: Role) {
        return this.http.put<Role>(this.serverUrl.roleBase + idRole, role, { responseType: 'json' });
    }

    deleteRole(idRole: number) {
        return this.http.delete<Role>(this.serverUrl.roleBase + idRole, httpOptions);
    }

    // Update project
    deleteComponentFromProject(componentId: number, projectId: number) {
        return this.http.delete<any>(this.serverUrl.deleteComponentFromProject + 'project/' + projectId + '/component/' + componentId, httpOptions);
    }

    deleteSubComponentFromProject(subComponentId: number, projectId: number) {
        return this.http.delete<any>(this.serverUrl.deleteComponentFromProject + 'project/' + projectId + '/subcomponent/' + subComponentId, httpOptions);
    }

    // Document Management
    downloadDocument(idDocument: number): Observable<Document> {
        return this.http.get<Document>(this.serverUrl.projectBaseURL + 'documents/download/' + idDocument, httpOptions);
    }

    // Document Management
    async downloadDocumentAsync(idDocument: number): Promise<Document> {
        const url = `${this.serverUrl.projectBaseURL}documents/download/${idDocument}`;

        try {
            const response = await firstValueFrom(this.http.get<Document>(url, httpOptions));
            return response;
        } catch (error) {
            console.error('Error downloading document:', error);
            throw error;
        }
    }



    //  Generate Project File
    generateProjectFile(idProject: number, lang: string, projectReport: ProjectReport): Observable<Document> {
        return this.http.post<Document>(this.serverUrl.projectBaseURL + idProject + '/project-file/' + lang,
            projectReport, httpOptions);
    }

    //  Indicator
    createIndicator(idProject: number, indicator: Indicator): Observable<any> {
        return this.http.post<any>(this.serverUrl.projectBaseURL + idProject + '/create-indicator', indicator, httpOptions);
    }

    updateIndicator(idIndicator: number, indicator: Indicator): Observable<any> {
        return this.http.put<any>(this.serverUrl.projectBaseURL + 'update-indicator/' + idIndicator, indicator, httpOptions);
    }

    //  Implementation State
    createImplementationState(idproject: number, implementationState: ImplementationState): Observable<StrategicPlan> {
        return this.http.post<StrategicPlan>(this.serverUrl.projectBaseURL + idproject + '/implementation-state', implementationState,
            httpOptions);
    }

    deleteCashout(idCashout: number) {
        return this.http.delete<Cashout>(this.serverUrl.cashoutBaseURL + idCashout + '/delete-cashout', httpOptions);
    }

    createCashout(idtask: number, idmarket: number, cashout: any): Observable<any> {
        if (idmarket === null) {
            return this.http.post<any>(`${this.serverUrl.cashoutBaseURL}${idtask}/market/create-cashout`, cashout, httpOptions);
        } else {
            return this.http.post<any>(`${this.serverUrl.cashoutBaseURL}${idtask}/market/${idmarket}/create-cashout`, cashout, httpOptions);
        }
    }

    // Contract
    createContract(idmarket: number, lang: string, contract: Contract): Observable<Document> {
        return this.http.post<any>(this.serverUrl.marketBaseURL + idmarket + '/contract/' + lang, contract, httpOptions);
    }

    fetchNotifications(idProject: number, idUser: number): Observable<any> {
        return this.http.get<any>(`${this.serverUrl.notificationBaseURL}project/${idProject}/user/${idUser}`);
    }

    markNotificationAsRead(id_notif: number, idUser: number): Observable<any> {
        return this.http.put(`${this.serverUrl.notificationBaseURL}${id_notif}/viewed-by/${idUser}`, {});
    }

    markAllNotificationsAsRead(idUser: number, iDs: number[]): Observable<any> {
        return this.http.put(`${this.serverUrl.notificationBaseURL}view-all-by/${idUser}`, iDs);
    }

    getIPAddress(): any {
        return this.http.get<any>('https://ipv4.jsonip.com/');
    }

    fetchActivitiesByComponent(idComponent: number, year: number = 0): Observable<Activity[]> {
        return this.http.get<Activity[]>(`${this.serverUrl.activityBaseURL}component/${idComponent}/year/${year}`);
    }

    fetchActivitiesBySubComponent(idSubComponent: number, year: number = 0): Observable<Activity[]> {
        return this.http.get<Activity[]>(`${this.serverUrl.activityBaseURL}sub-component/${idSubComponent}/year/${year}`);
    }

    fetchSubComponentsByComponent(component: Composante): Observable<SubComponent[]> {
        return of(component.subComponents);
    }
}
