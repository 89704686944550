<!DOCTYPE html>
<section class="animated fadeIn container-fluid">
    <div class="row py-4">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <ngb-alert #selfClosingAlert *ngIf="errorMessage" type="danger" (closed)="errorMessage = ''">
                {{errorMessage}}
            </ngb-alert>
            <form [formGroup]="diagrammeBatonForm" *ngIf="graphType !== 'line'">
                <div class="row py-2">
                    <h4 class="roboto-bold-font">{{ getTitle()}}</h4>
                    <div class="col-lg-12">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4">
                                <div class="form-group">
                                    <label class="control-label" for="itemx">{{ "inputs.itemx.label" | translate }}</label>
                                    <ng-select  (change)="onXElementChange($event)" formControlName="itemx" placeholder="{{ 'inputs.itemx.placeholders' | translate }}"
                                               [searchable]="true" [clearable]="true" id="itemx" 
                                               [ngClass]="{ 'is-invalid': diagrammeBatonFormSubmitted && diagrammeBaton.itemx.errors }">
                                        <ng-option *ngFor="let element of xElements" [value]="element">{{ element.element | translate }}</ng-option>
                                    </ng-select>
                                    <div *ngIf="diagrammeBatonFormSubmitted && diagrammeBaton.itemx.errors" class="invalid-feedback">
                                        <div *ngIf="diagrammeBaton.itemx.errors.required">{{
                                            "inputs.itemx.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4">
                                <div class="form-group">
                                    <label class="control-label" for="itemy">{{ "inputs.itemy.label" | translate }} </label>
                                    <ng-select formControlName="itemy" placeholder="{{ 'inputs.itemy.placeholders' | translate }}"
                                               [searchable]="true" [clearable]="true" id="itemy" [multiple]="multi"
                                               [ngClass]="{ 'is-invalid': diagrammeBatonFormSubmitted && diagrammeBaton.itemy.errors }">
                                        <ng-option *ngFor="let element of yElements" [value]="element">{{ element.element | translate }}</ng-option>
                                    </ng-select>
                                    <div *ngIf="diagrammeBatonFormSubmitted && diagrammeBaton.itemy.errors" class="invalid-feedback">
                                        <div *ngIf="diagrammeBaton.itemy.errors.required">{{
                                            "inputs.itemy.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-between">
                                <a class="btn btn-pill bg-danger px-5" (click)="hideDiagram()">{{ "cancel" | translate }}</a>
                                <a class="btn btn-pill bg-green px-5" (click)="processData()" *ngIf="!showTable">{{ "display" | translate }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2"></div>
                </div>
            </form>
        </div>
    </div>
    <ng-container *ngIf="showTable">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                    <div class="table-responsive">
                        <table id="ListePortfolio" class="table table-hover table-striped" datatable [dtOptions]="dtOptions">
                            <caption></caption>
                            <thead class="roboto-bold-font bg-green thead-dark">
                            <tr>
                                <th *ngFor="let analyseProperty of projects[projects.length - 1].analyseProperties">
                                    {{ analyseProperty.attribute }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let project of projects">
                                <td *ngFor="let analyseProperty of project.analyseProperties" [innerHTML]="sanitizeValue(analyseProperty.value)"></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
    </ng-container>
    
    <ng-container *ngIf="showTable || showDiagOnly">
        <form  [formGroup]="diagrammeBatonForm">
            <div class="row">
                <div class="col-xl-4 col-lg-4" *ngIf="graphType.includes('bar')">
                    <div class="form-group">
                        <label class="control-label" for="direction-v">{{ "direction" | translate }} </label>
                        <div class="row ml-3">
                            <div class="col-5">
                                <input  class="form-check-input" (change)="drawDiagram()" id="direction-v" type="radio" formControlName="direction" name="direction" value="vertical">
                                <label class="control-label" for="direction-v">{{ "vertical" | translate }} </label>
                            </div>
                            <div class="col-5">
                                <input  class="form-check-input" (change)="drawDiagram()" id="direction-h" type="radio" formControlName="direction" name="direction" value="horizontal">
                                <label class="control-label" for="direction-h">{{ "horizontal" | translate }} </label>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </form>
        <div class="row bg-white">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <div class="flex justify-content-center">
                    <ng-container [ngSwitch]="graphType">
                      <p-chart *ngSwitchCase="'bar'" type="bar" [data]="data" [options]="options"/>
                      <p-chart *ngSwitchCase="'multi-bar'" type="bar" [data]="multiDatasets" [options]="options"/>
                      <p-chart *ngSwitchCase="'pie'" type="pie" [data]="pieData" [options]="pieOptions" />
                      <p-chart *ngSwitchCase="'line'" type="line" [data]="lineData" [options]="lineOptions" />
                      <p *ngSwitchDefault></p>
                  </ng-container>
                  
                </div>
            </div>
        </div>
    </ng-container>
</section>


