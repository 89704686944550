import {Document} from './document.payload';

export class MaturationEval {
    idmaturationEval: number;
    title: string;
    maturationEvalDate: MaturationEvalDate;
    maturationEvalDates: MaturationEvalDate[];
    acceptFile: boolean;
    acceptComment: boolean;
    executedDate: string;
    document: Document = null;
    comment: string;
    project: number;
    createdAt: string;
    updatedAt: string;
    plannedDate: any;

    constructor(title: string, maturationEvalDate: MaturationEvalDate, acceptFile: boolean = true,  acceptComment: boolean = true) {
        this.title = title;
        this.maturationEvalDate = maturationEvalDate;
        this.acceptFile = acceptFile;
        this.acceptComment = acceptComment;
    }
}

export class MaturationEvalDate {
    idmaturationEvalDate: number;
    plannedDate: string;
    maturationEval: number;
    createdAt: string;
    updatedAt: string;

    constructor(plannedDate: string) {
        this.plannedDate = plannedDate;
    }
}

export class CompleteMaturationEval {
    idmaturationEval: number;
    executedDate: string;
    document: Document = null;
    comment: string;

    constructor(idmaturationEval: number, executedDate: string, document: Document, comment: string) {
        this.idmaturationEval = idmaturationEval;
        this.executedDate = executedDate;
        this.document = document;
        this.comment = comment;
    }
}
