import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {reloadComponent} from '../../../../../_helpers';
import {NotificationFlashService} from '../../../../../services/notification-flash.service';
import {RestAPIService} from '../../../../../services/rest-api.service';
import {FinanceMobil, FinanceMobilDate} from '../../../../../payloads/finance-mobilisation.payload';
import {Project} from '../../../../../payloads/project.payload';
import {UtilityService} from '../../../../../services/utility.service';
import {ConfirmationDialogService} from '../../../../shared-component/confirmation-dialog/confirmation-dialog.service';

@Component({
    selector: 'app-reset-fin-mobil-date',
    templateUrl: './reset-fin-mobil-date.component.html',
    styleUrls: ['./reset-fin-mobil-date.component.scss']
})
export class ResetFinMobilDateComponent implements OnInit {
    isLoaded: boolean = false;
    hideSave: boolean = false;
    private _danger = new Subject<string>();

    staticAlertClosed = false;
    errorMessage: string;

    @Input() project: Project;

    @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;
    financeMobils: FinanceMobil[];
    colspan: number;

    constructor(private formBuilder: UntypedFormBuilder, private activeModal: NgbActiveModal, private restAPIService: RestAPIService,
                private notification: NotificationFlashService, private translate: TranslateService, private router: Router,
                private utilityService: UtilityService, private confirmationDialog: ConfirmationDialogService) {
    }

    ngOnInit(): void {
        this._danger.subscribe((message) => (this.errorMessage = message));
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.financeMobils = [...this.project.financeMobils];
        this.financeMobils.sort(
            (financeMobil1: FinanceMobil, financeMobil2: FinanceMobil) =>
                financeMobil1.idfinanceMobil > financeMobil2.idfinanceMobil ? 1 : -1
        );

        const dates: number[] = [];
        let hide = 0;
        this.financeMobils.forEach((financeMobil: FinanceMobil) => {
            financeMobil.financeMobilDates.sort((financeMobilDate1: FinanceMobilDate, financeMobilDate2: FinanceMobilDate) =>
                financeMobilDate1.idfinanceMobilDate > financeMobilDate2.idfinanceMobilDate ? 1 : -1
            );

            if (financeMobil.executedDate) {
                ++hide;
            }

            dates.push(financeMobil.financeMobilDates.length);
        });

        dates.sort((date1: number, date2: number) => date1 < date2 ? 1 : -1);

        this.colspan = dates[0];

        if (this.utilityService.fetchArchiveStatus().includes(this.project.status) || hide === this.financeMobils.length) {
            this.hideSave = true;
        }

        this.isLoaded = true;
    }

    onSubmit() {
        this.confirmationDialog.confirm(this.translate.instant('reprogram'),
            this.translate.instant('confirmations.contents.reprogrammed-dates-validation'))
            .then((confirmation) => {
                    if (confirmation) {
                        this.restAPIService.updateFinanceMobilisation(this.financeMobils).subscribe(() => {
                                this.closeModal();

                                reloadComponent(this.router);

                                this.notification.success(this.translate.instant('notifications.success.planned-date-reset'));
                            }, () => {
                                this.notification.danger(this.translate.instant('notifications.danger.planned-date-not-reset'));
                            }
                        );
                    }
                }
            );
    }

    closeModal() {
        this.activeModal.close();
    }

    dateFormat(date: string): string {
        return new Date(date).toLocaleDateString('fr-FR');
    }

    changePlannedDate(date: any, i: number) {
        this.financeMobils[i].plannedDate = date;
        this.financeMobils[i].financeMobilDate = new FinanceMobilDate(date);
    }

    formatBSDate(date: string): Date {
        if (!date) {
            return null;
        }
        return new Date(date);
    }
}
