<!DOCTYPE html>
<section class="animated fadeIn" *ngIf="isLoaded">
    <div class="modal-header d-flex justify-content-between">
        <h3 class="modal-title roboto-bold-font">{{ "tasks" | translate }}</h3>
        <span class="close fa fa-times text-red" aria-label="Close" (click)="closeModal()"></span>
    </div>
    <div class="modal-body text-black container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <ngb-alert #selfClosingAlert *ngIf="errorMessage" type="danger"
                           (closed)="errorMessage = ''">{{
                    errorMessage
                    }}</ngb-alert>

                <form [formGroup]="createTaskForm">
                    <div class="row">
                        <div class="col-xl-4 col-lg-4">
                            <div class="form-group row">
                                <label for="yes" class="form-check-label col-xl-7 col-lg-7 col-md-7 col-sm-7 col-xs-12">{{ "inputs.conference.label" | translate }}
                                    <span class="roboto-bold-font text-red">*</span></label>
                                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-12 text-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" id="yes" [value]="true"
                                               formControlName="conference" [ngClass]="{ 'is-invalid': createTaskFormSubmitted && createTask.conference.errors}">
                                        <label class="form-check-label" for="yes">{{ "yes" | translate }}</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" id="no" [value]="false"
                                               formControlName="conference" [ngClass]="{ 'is-invalid': createTaskFormSubmitted && createTask.conference.errors}">
                                        <label class="form-check-label" for="no">{{ "no" | translate }}</label>
                                    </div>
                                </div>
                                <div *ngIf="createTaskFormSubmitted && createTask.conference.errors" class="invalid-feedback">
                                    <div *ngIf="createTask.conference.errors.required">{{ "inputs.conference.validations.required" | translate }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-8 col-lg-8"></div>

                        <div class="col-xl-3 col-lg-3">
                            <div class="form-group">
                                <label class="control-label"
                                       for="task_numb">{{ "inputs.reference-number.label" | translate }}
                                    <span class="roboto-bold-font text-red">*</span></label>
                                <input type="text" formControlName="numb"
                                       class="form-control text-right roboto-bold-font"
                                       [ngClass]="{ 'is-invalid': createTaskFormSubmitted && createTask.numb.errors }"
                                       aria-label="Number" aria-describedby="basic-user" id="task_numb">
                                <div *ngIf="createTaskFormSubmitted && createTask.numb.errors" class="invalid-feedback">
                                    <div *ngIf="createTask.numb.errors.required">{{
                                        "inputs.reference-number.validations.required" | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6">
                            <div class="form-group">
                                <label class="control-label" for="task_title">{{ "inputs.entitle.label" | translate
                                    }}
                                    <span class="roboto-bold-font text-red">*</span></label>
                                <input type="text" formControlName="title" class="form-control"
                                       [ngClass]="{ 'is-invalid': createTaskFormSubmitted && createTask.title.errors }"
                                       aria-label="Title" aria-describedby="basic-user" id="task_title">
                                <div *ngIf="createTaskFormSubmitted && createTask.title.errors"
                                     class="invalid-feedback">
                                    <div *ngIf="createTask.title.errors.required">{{
                                        "inputs.entitle.validations.required" |
                                            translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6">
                            <div class="form-group">
                                <label class="control-label" for="task_amount_fcfa">{{
                                    "inputs.amount_in_fcfa.label" |
                                        translate
                                    }}</label>
                                <div class="input-group mb-3">
                                    <input type="number" formControlName="amountFcfa"
                                           class="form-control text-right roboto-bold-font"
                                           [ngClass]="{ 'is-invalid': createTaskFormSubmitted && createTask.amountFcfa.errors }"
                                           aria-label="Amount" aria-describedby="basic-user"
                                           id="task_amount_fcfa" min="0" (input)="checkTaskAmount($event.target.value)">
                                    <div class="input-group-append"><span class="input-group-text"
                                                                          id="basic-user">FCFA</span></div>
                                    <div *ngIf="createTaskFormSubmitted && createTask.amountFcfa.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="createTask.amountFcfa.errors.required">{{
                                            "inputs.amount.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-4 col-lg-4 col-md-6">
                            <div class="form-group">
                                <label class="control-label" for="deliverable">{{ "inputs.deliverable.label" | translate
                                    }}</label>
                                <input type="text" formControlName="deliverable" class="form-control"
                                       aria-label="Title" aria-describedby="basic-user" id="deliverable"
                                       [ngClass]="{ 'is-invalid': createTaskFormSubmitted && createTask.deliverable.errors }">
                                <div *ngIf="createTaskFormSubmitted && createTask.deliverable.errors"
                                     class="invalid-feedback">
                                    <div *ngIf="createTask.deliverable.errors.required">{{
                                        "inputs.deliverable.validations.required" |
                                            translate }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4">
                            <div class="form-group">
                                <label class="control-label" for="verifMeans">{{ "inputs.verifMeans.label" | translate
                                    }}</label>
                                <input type="text" formControlName="verifMeans" class="form-control"
                                       aria-label="Verification Means" aria-describedby="basic-user"
                                       id="verifMeans"
                                       [ngClass]="{ 'is-invalid': createTaskFormSubmitted && createTask.verifMeans.errors }">
                                <div *ngIf="createTaskFormSubmitted && createTask.verifMeans.errors"
                                     class="invalid-feedback">
                                    <div *ngIf="createTask.verifMeans.errors.required">{{
                                        "inputs.verifMeans.validations.required" |
                                            translate }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4">
                            <div class="form-group">
                                <label class="control-label" for="princResponsible">{{
                                    "inputs.principal_responsible.label" |
                                        translate }}</label>
                                <input type="text" formControlName="princResponsible" class="form-control"
                                       aria-label="Pricipale Responsible" aria-describedby="basic-user"
                                       id="princResponsible"
                                       [ngClass]="{ 'is-invalid': createTaskFormSubmitted && createTask.princResponsible.errors }">
                                <div *ngIf="createTaskFormSubmitted && createTask.princResponsible.errors"
                                     class="invalid-feedback">
                                    <div *ngIf="createTask.princResponsible.errors.required">{{
                                        "inputs.principal_responsible.validations.required" |
                                            translate }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <label class="control-label"
                                       for="task_start_date">{{ "inputs.startDate.label" | translate }}</label>
                                <input type="text" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                                       [daysDisabled]="[7,0]"
                                       placeholder="{{ 'date-format' | translate }}" formControlName="startDate"
                                       class="form-control"
                                       [minDate]="task_start_date" [maxDate]="task_end_date" id="task_start_date"
                                       (ngModelChange)="minTaskDate($event)" placement="top"
                                       aria-label="Date Début" aria-describedby="basic-user"
                                       [ngClass]="{ 'is-invalid': createTaskFormSubmitted && createTask.startDate.errors }">
                                <div *ngIf="createTaskFormSubmitted && createTask.startDate.errors"
                                     class="invalid-feedback">
                                    <div *ngIf="createTask.startDate.errors.required">{{
                                        "inputs.startDate.validations.required" |
                                            translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <label class="control-label"
                                       for="task_end_date">{{ "inputs.endDate.label" | translate }}</label>
                                <input type="text" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                                       [daysDisabled]="[7,0]"
                                       placeholder="{{ 'date-format' | translate }}" formControlName="endDate"
                                       class="form-control"
                                       [minDate]="task_end_start_date" [maxDate]="task_end_date" id="task_end_date"
                                       aria-label="Date Début" aria-describedby="basic-user" placement="top"
                                       [ngClass]="{ 'is-invalid': createTaskFormSubmitted && createTask.endDate.errors }">
                                <div *ngIf="createTaskFormSubmitted && createTask.endDate.errors"
                                     class="invalid-feedback">
                                    <div *ngIf="createTask.endDate.errors.required">{{
                                        "inputs.endDate.validations.required" |
                                            translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- espace de geolocalisation -->
                        <div class="col-xl-12">
                            <button
                                *ngIf="!showGeolocationTask"
                                class="btn btn-pill bg-primary px-5"
                                (click)="showGeolocationTaskSection()"
                            >
                                Géolocaliser
                            </button>
                            <button
                                *ngIf="showGeolocationTask"
                                (click)="hideTaskSections()"
                                class="btn btn-pill bg-danger px-5"
                            >
                                Annuler
                            </button>

                            <div *ngIf="showGeolocationTask">
                                <h4 class="py-2 roboto-bold-font">
                                    {{ "save_location" | translate }}
                                </h4>
                                <div class="row">
                                    <div class="col-md-6 col-12 p-3">
                                        <label for="">Latitude</label>
                                        <input
                                            type="number"
                                            formControlName="T_latitude"
                                            class="form-control"
                                            placeholder="Latitude"
                                            (ngModelChange)="
                                        validerTaskCoordonnees()
                                    "
                                        />
                                        <div
                                            *ngIf="
                                        createTaskForm.hasError(
                                            'horsLimite'
                                        )
                                    "
                                            class="text-danger"
                                        >
                                            Les coordonnées doivent être dans la
                                            zone du Cameroun.
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12 p-3">
                                        <label for="">Longitude</label>
                                        <input
                                            type="number"
                                            formControlName="T_longitude"
                                            class="form-control"
                                            placeholder="Longitude"
                                            (ngModelChange)="
                                        validerCoordonnees()
                                    "
                                        />
                                        <div
                                            *ngIf="
                                        createTaskForm.hasError(
                                            'horsLimite'
                                        )
                                    "
                                            class="text-danger"
                                        >
                                            Les coordonnées doivent être dans la
                                            zone du Cameroun.
                                        </div>
                                    </div>
                                    <app-map-box
                                        [lat]="latitude_task"
                                        [lng]="longitude_task"
                                        class="map"
                                    ></app-map-box>
                                </div>
                            </div>
                        </div>
                        <!-- end geolocalisation-->

                        <div
                            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end text-white">
                            <a class="btn btn-pill bg-green my-2 mx-1" (click)="addTask()"><em
                                class="fa fa-plus"></em></a>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="table-responsive">
                                <table class="table table-hover table-striped">
                                    <caption></caption>
                                    <thead class="thead-dark">
                                    <tr>
                                        <th scope="col"
                                            rowspan="2">{{ "inputs.reference-number.label" | translate }}</th>
                                        <th scope="col" rowspan="2">{{ "inputs.entitle.label" | translate }}</th>
                                        <th scope="col" class="nowrap"
                                            rowspan="2">{{ "inputs.amount_in_fcfa.label" | translate }}</th>
                                        <th scope="col" rowspan="2">{{ "inputs.deliverable.label" | translate }}</th>
                                        <th scope="col" rowspan="2" class="nowrap">{{ "inputs.verifMeans.label" | translate }}</th>
                                        <th scope="col" rowspan="2">{{ "inputs.responsible.label" | translate }}</th>
                                        <th scope="col" colspan="2">{{ "calendar" | translate }}</th>
                                        <th scope="col" rowspan="2"></th>
                                    </tr>
                                    <tr>
                                        <th scope="col"
                                            class="text-center">{{ "inputs.startDate.label" | translate }}</th>
                                        <th scope="col"
                                            class="text-center">{{ "inputs.endDate.label" | translate }}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let task of activityTasks; let i = index;">
                                        <td class="text-center">{{ task.numb }}</td>
                                        <td *ngIf="!task.idtask">{{ task.title }}</td>
                                        <td class="nowrap" *ngIf="task.idtask">
                                            <input type="text" class="form-control task-input" value="{{ task.title }}"
                                                   (input)="changeTaskTitle($event.target.value, i)">
                                        </td>
                                        <td *ngIf="!task.idtask"
                                            class="roboto-bold-font text-right nowrap">{{ moneyFormat(task.amountFcfa) }}</td>
                                        <td *ngIf="task.idtask">
                                            <input type="number" class="form-control roboto-bold-font text-right"
                                                   value="{{ task.amountFcfa }}" (input)="changeTaskAmount($event, i)">
                                        </td>
                                        <td *ngIf="!task.idtask">{{ task.deliverable }}</td>
                                        <td class="nowrap" *ngIf="task.idtask">
                                            <input type="text" class="form-control" value="{{ task.deliverable }}"
                                                   (input)="changeTaskDeliverable($event.target.value, i)">
                                        </td>
                                        <td *ngIf="!task.idtask">{{ task.verifMeans }}</td>
                                        <td class="nowrap" *ngIf="task.idtask">
                                            <input type="text" class="form-control" value="{{ task.verifMeans }}"
                                                   (input)="changeTaskVerifMeans($event.target.value, i)">
                                        </td>
                                        <td *ngIf="!task.idtask">{{ task.princResponsible }}</td>
                                        <td class="nowrap" *ngIf="task.idtask">
                                            <input type="text" class="form-control" value="{{ task.princResponsible }}"
                                                   (input)="changeTaskResponsible($event.target.value, i)">
                                        </td>
                                        <td *ngIf="!task.idtask"
                                            class="text-center">{{ formatDate(task.startDate) }}</td>
                                        <td *ngIf="task.idtask">
                                            <input type="text" bsDatepicker
                                                   [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" placement="top"
                                                   placeholder="{{ 'date-format' | translate }}"
                                                   [minDate]="task_start_date" [maxDate]="task_end_date"
                                                   class="form-control task-input" [daysDisabled]="[7,0]"
                                                   [bsValue]="formatBSDate(task.startDate)"
                                                   (bsValueChange)="changeTaskStartDate($event, i)">
                                        </td>
                                        <td *ngIf="!task.idtask" class="text-center">{{ formatDate(task.endDate) }}</td>
                                        <td *ngIf="task.idtask">
                                            <input type="text" bsDatepicker
                                                   [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" placement="top"
                                                   placeholder="{{ 'date-format' | translate }}"
                                                   [minDate]="task_end_start_date" [maxDate]="task_end_date"
                                                   class="form-control task-input" [daysDisabled]="[7,0]"
                                                   [bsValue]="formatBSDate(task.endDate)"
                                                   (bsValueChange)="changeTaskEndDate($event, i)">
                                        </td>
                                        <td class="text-center">
                                            <a class="btn btn-pill bg-danger my-2 mx-1 text-white"
                                               (click)="deleteTask(i)"><em class="fa fa-times"></em></a>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="row py-2">
                    <div
                        class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end">
                        <button type="submit" (click)="onSubmit()"
                                class="btn btn-pill bg-primary px-5">{{ "save" | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
