import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Review} from '../../../../../../payloads/review.payload';
import {DatatableLanguage} from '../../../../../shared-component/datatable-langs';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {getPrivilege} from '../../../../../../_helpers';
import {CreateProgReviewComponent} from '../../../../shared-modal/review/create-prog-review/create-prog-review.component';
import {ContinProgReviewComponent} from '../../../../shared-modal/review/contin-prog-review/contin-prog-review.component';
import {Privilege} from '../../../../../../payloads/privilege.payload';
import {Project} from '../../../../../../payloads/project.payload';
import {DefaultLayoutComponent} from '../../../../../shared-component/default-layout/default-layout.component';
import {Router} from '@angular/router';
import {RestAPIService} from '../../../../../../services/rest-api.service';
import {projectNavItems} from '../../../sidebars/project';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewArchiveComponent implements OnInit, AfterViewInit {
    idProject = Number(sessionStorage.getItem('project'));
    isLoaded: boolean = false;
    isValidated: boolean = false;
    privilege: Privilege = getPrivilege();

    project: Project = null;
    reviews: Review[] = [];

    dtOptions: any = {};
    dtLang: any = DatatableLanguage.fr;

    constructor(private defaultLayout: DefaultLayoutComponent, private translate: TranslateService, private router: Router,
                private modalService: NgbModal, private restAPIService: RestAPIService) { }

    ngAfterViewInit(): void {
        setTimeout((): void => {
            this.defaultLayout.navItems = projectNavItems(this.translate);
        }, 0);
    }

    ngOnInit(): void {
        if (this.idProject === 0) {
            this.router.navigate(['/archives/projects']);
        }

        this.restAPIService.fetchProject(this.idProject).subscribe((project: Project) => {
            this.project = project;

            this.defaultLayout.navItems = projectNavItems(this.translate);
            this.defaultLayout.reloadBreadCrumb(this.project.abbr);

            this.reviews = [...this.project.reviews];
            this.reviews.sort((review1: Review,  review2: Review) =>
                (review1.createdAt > review2.createdAt) ? 1 : -1
            );

            if (this.translate.currentLang !== 'fr') {
                this.dtLang = DatatableLanguage.en;
            }

            this.dtOptions = {
                pageLength: 10,
                processing: true,
                language: this.dtLang
            };

            this.isLoaded = true;
        });
    }

    createReview() {
        this.modalService.open(CreateProgReviewComponent, {
            backdropClass: 'light-yellowish-backdrop',
            size: 'xl',
            centered: false,
            scrollable: false,
            backdrop: false
        }).componentInstance.project = this.project;
    }

    continuoReview(review: Review) {
        this.modalService.open(ContinProgReviewComponent, {
            backdropClass: 'light-yellowish-backdrop',
            size: 'xl',
            centered: false,
            scrollable: false,
            backdrop: false
        }).componentInstance.review = review;
    }

    dateFormat(date: string): string {
        return new Date(date).toLocaleDateString('fr-FR');
    }
}
