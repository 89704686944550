import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {RestAPIService} from '../../../services/rest-api.service';
import {splitBase64String} from '../../../_helpers';
import {Document} from '../../../payloads/document.payload';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit {
    public isLoaded: boolean = false;
    public document: Document = null;

  @ViewChild('pdfViewerAutoLoad') pdfViewerAutoLoad;
  @Input() idDocument: number;

  constructor(private activeModal: NgbActiveModal, private restAPIService: RestAPIService) { }

  ngOnInit(): void {
    this.restAPIService.downloadDocument(this.idDocument).subscribe((document) => {
        this.document = splitBase64String(document.base64);

        const byteCharacters = atob(this.document.base64);
        const len = byteCharacters.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; ++i) {
          bytes[i] = byteCharacters.charCodeAt(i);
        }

        this.pdfViewerAutoLoad.pdfSrc = bytes;
        this.pdfViewerAutoLoad.refresh();
      });

      this.isLoaded = true;
  }

  closeModal() {
    this.activeModal.close();
  }
}
