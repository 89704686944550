import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {User} from '../../../../../payloads/user.payload';
import {Role} from '../../../../../payloads/role.payload';
import {Document} from '../../../../../payloads/document.payload';
import {Subject} from 'rxjs';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {NotificationFlashService} from '../../../../../services/notification-flash.service';
import {TranslateService} from '@ngx-translate/core';
import {RestAPIService} from '../../../../../services/rest-api.service';
import {debounceTime} from 'rxjs/operators';
import {reloadComponent} from '../../../../../_helpers';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss']
})
export class UpdateUserComponent implements OnInit {
    isLoaded = false;
    userForm: UntypedFormGroup;
    roles: Role[] = [];
    userFormSubmitted = false;
    document: Document = null;
    imgSrc: string = '../../../../../../assets/img/default-avatar.jpeg';
    langs: any[] = [
        {
            abbr: 'fr',
            name: 'french'
        },
        {
            abbr: 'en',
            name: 'english'
        }
    ]

    @Input() public user: User;
    staticAlertClosed = false;
    errorMessage: string;
    @ViewChild('staticAlert', { static: false }) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert;
    private _danger = new Subject<string>();

    constructor(private formBuilder: UntypedFormBuilder, private router: Router, private notification: NotificationFlashService,
                private translate: TranslateService, private restAPIService: RestAPIService, private activeModal: NgbActiveModal) { }

    get updateForm() { return this.userForm.controls; }

    ngOnInit(): void {
        this._danger.subscribe(message => this.errorMessage = message);
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.restAPIService.fetchRoles().subscribe((userRoles: Role[]) => {
            userRoles.forEach((role: Role) => {
                if (!role.forProject) {
                    this.roles.push(role);
                } else if (role.forProject && !role.privilege.canReadActivity && !role.privilege.canReadMarket && !role.privilege.canReadFollow) {
                    this.roles.push(role);
                }
            });

            this.roles.sort((role1: Role, role2: Role) =>
                role1.name > role2.name ? 1 : -1
            );

            if (this.user.document !== null) {
                this.restAPIService.downloadDocument(this.user.document.iddocument).subscribe((document: Document) => {
                        this.imgSrc = document.base64;
                    }
                );
            }

            this.isLoaded = true;

            this.initForm();
        });
    }

    initForm() {
        this.userForm = this.formBuilder.group({
            file: [null],
            name: [this.user.name, [Validators.required]],
            surname: [this.user.surname],
            dob: [new Date(this.user.dob)],
            pob: [this.user.pob],
            email: [this.user.email, [Validators.required, Validators.email]],
            phone: [this.user.phone],
            username: [this.user.username, [Validators.required]],
            idrole: [this.user.role.idrole, [Validators.required]],
            lang: [this.user.lang, [Validators.required]],
        });
    }

    onFileSelect(event: any) {
        const file: File = event.target.files[0];

        if (file) {
            // File Preview
            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = (read: any) => {
                this.imgSrc = reader.result as string;
                this.document = new Document(null, file.name, file.type, read.target.result);
            };
        }
    }

    onSubmit() {
        this.userFormSubmitted = true;
        if (this.userForm.invalid) {
            return;
        }

        const user = new User().update(
            this.userForm.value.name?.trim(),
            this.userForm.value.surname?.trim(),
            this.userForm.value.dob,
            this.userForm.value.pob,
            this.userForm.value.email?.trim(),
            this.userForm.value.phone,
            this.userForm.value.username?.trim(),
            this.userForm.value.lang?.trim(),
            this.userForm.value.idrole,
            this.document
        );

        this.restAPIService.updateUser(this.user.iduser, user).subscribe(() => {
            this.notification.success(
                this.translate.instant('notifications.success.user-modified'),
            );

            this.closeModal();

            reloadComponent(this.router);
        }, (error) => {
            console.error(error);

            this.errorMessage = this.translate.instant(
                'notifications.danger.user-not-modified',
            );
        });
    }

    closeModal() {
        this.activeModal.close();
    }
}
