import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {RestAPIService} from '../../../../../../services/rest-api.service';
import {NotificationFlashService} from '../../../../../../services/notification-flash.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {debounceTime} from 'rxjs/operators';
import {reloadComponent} from '../../../../../../_helpers';
import {MarketExecution, MarketExecutionDate} from '../../../../../../payloads/complete-execution.payload';
import {ConfirmationDialogService} from '../../../../../shared-component/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-reset-market-exec-date',
  templateUrl: './reset-market-exec-date.component.html',
  styleUrls: ['./reset-market-exec-date.component.scss']
})
export class ResetMarketExecDateComponent implements OnInit {
    isLoaded: boolean = false;

    resetMarketExecutionDateForm: UntypedFormGroup;

    marketExecution: MarketExecution;

    startDate: Date;

    idProject = Number(sessionStorage.getItem('project'));

    resetMarketExecutionDateFormSubmitted = false;
    private _danger = new Subject<string>();

    staticAlertClosed = false;
    errorMessage: string;

    @Input() reset;

    @ViewChild('staticAlert', { static: false }) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert;

    constructor(private formBuilder: UntypedFormBuilder, private activeModal: NgbActiveModal, private restAPIService: RestAPIService,
                private notification: NotificationFlashService, private translate: TranslateService, private router: Router,
                private confirmationDialog: ConfirmationDialogService) { }

    ngOnInit(): void {
        this._danger.subscribe((message) => (this.errorMessage = message));
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        if (this.idProject === 0) {
            this.router.navigate(['/preparation-maturation']);
        }

        this.marketExecution = this.reset.marketExecution;

        const marketExecutionDates: MarketExecutionDate[] = [...this.marketExecution.marketExecutionDates];

        marketExecutionDates.sort(
            (
                marketExecutionDate1: MarketExecutionDate,
                marketExecutionDate2: MarketExecutionDate
            ) =>
                marketExecutionDate1.plannedDate >
                marketExecutionDate2.plannedDate
                    ? 1
                    : -1
        );

        this.marketExecution.plannedDate = marketExecutionDates[marketExecutionDates.length - 1].plannedDate;

        this.startDate = new Date(marketExecutionDates[0].plannedDate);
        if (!marketExecutionDates[0].plannedDate) {
            this.startDate = new Date(this.reset.marketStart);
        }

        this.resetMarketExecutionDateForm = this.formBuilder.group({
            plannedDate: [new Date(this.marketExecution.plannedDate), [Validators.required]],
        });

        this.isLoaded = true;
    }

    get executionForm() { return this.resetMarketExecutionDateForm.controls; }

    onSubmit() {
        this.resetMarketExecutionDateFormSubmitted = true;
        if (this.resetMarketExecutionDateForm.invalid) {
            return;
        }

        const marketExecutionDate = new MarketExecutionDate(
            this.resetMarketExecutionDateForm.value.plannedDate,
        );

        this.confirmationDialog.confirm(this.translate.instant('reprogram'),
            this.translate.instant('confirmations.contents.reprogrammed-dates-validation'))
            .then((confirmation) => {
                    if (confirmation) {
                        this.restAPIService.resetMarketExecutionDate(this.reset.marketExecution.idmarketExecution, marketExecutionDate).subscribe(
                            () => {
                                this.closeModal();

                                reloadComponent(this.router);

                                this.notification.success(this.translate.instant('notifications.success.planned-date-reset'));
                            }, () => {
                                this.notification.danger(this.translate.instant('notifications.danger.planned-date-not-reset'));
                            }
                        );
                    }
                }
            );
    }

    closeModal() {
        this.activeModal.close();
    }
}
