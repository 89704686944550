import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Project, ProjTeam} from '../../../payloads/project.payload';
import {User} from '../../../payloads/user.payload';
import {TokenStorageService} from '../../../services/security/token-storage.service';
import {NotificationFlashService} from '../../../services/notification-flash.service';
import {RestAPIService} from '../../../services/rest-api.service';
import {UtilityService} from '../../../services/utility.service';
import {removePrivilege, removeRole, setPrivilege, setProject, setRole} from '../../../_helpers';

@Component({
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
    user: User;
    projects: Project[] = [];

    constructor(private tokenStorage: TokenStorageService, private router: Router, private notification: NotificationFlashService,
                private utilityService: UtilityService, private translate: TranslateService, private restAPIService: RestAPIService) {
    }

    ngOnInit(): void {
        this.user = this.tokenStorage.getUser();

        removePrivilege();
        removeRole();
    }

    preparationMaturation() {
        this.restAPIService.fetchProjectsByStatus(this.utilityService.fetchPreparationStatus()).subscribe((projets: Project[]) => {
            if (projets !== null) {
                if (this.user.role.forProject) {
                    projets.forEach((projet: Project) => {
                        const projTeams: ProjTeam[] = projet.projTeams;

                        projTeams.forEach((projTeam: ProjTeam) => {
                            if (projTeam.user.iduser === this.user.iduser) {
                                this.projects.push(projet);
                            }
                        });
                    });

                    if (this.projects.length === 1) {
                        setProject(this.projects[0]?.idproject);
                        this.projects[0]?.projTeams.forEach((projTeam: ProjTeam) => {
                            if (projTeam.user.iduser === this.user.iduser) {
                                setPrivilege(projTeam.role.privilege);
                                setRole(projTeam.role);
                            }
                        });
                        this.router.navigate(['/preparation-maturation/projects/project']);
                    } else if (this.projects.length > 1) {
                        this.router.navigate(['/preparation-maturation']);
                    } else {
                        this.notification.danger(this.translate.instant('notifications.danger.you-no-project-on-preparation'));
                    }
                } else {
                    this.router.navigate(['/preparation-maturation']);
                }
            } else {
                if (this.user.role.forProject) {
                    this.notification.danger(this.translate.instant('notifications.danger.no-project-on-preparation'));
                } else {
                    this.router.navigate(['/preparation-maturation']);
                }
            }
        });
    }

    projectPortFolio() {
        this.restAPIService.fetchProjectsByStatus(this.utilityService.fetchPortefeuilleStatus()).subscribe((projets: Project[]) => {
            if (projets !== null) {
                if (this.user.role.forProject) {
                    projets.forEach((projet: Project) => {
                        const projTeams: ProjTeam[] = projet.portProjTeams;

                        projTeams.forEach((projTeam: ProjTeam) => {
                            if (projTeam.user.iduser === this.user.iduser) {
                                this.projects.push(projet);
                            }
                        });
                    });

                    if (this.projects.length === 1) {
                        setProject(this.projects[0]?.idproject);
                        this.projects[0]?.portProjTeams.forEach((projTeam: ProjTeam) => {
                            if (projTeam.user.iduser === this.user.iduser) {
                                setPrivilege(projTeam.role.privilege);
                                setRole(projTeam.role);
                            }
                        });
                        this.router.navigate(['/project-portfolio/projects/project']);
                    } else if (this.projects.length > 1) {
                        this.router.navigate(['/project-portfolio']);
                    } else {
                        this.notification.danger(this.translate.instant('notifications.danger.you-no-project-on-portfolio'));
                    }
                } else {
                    this.router.navigate(['/project-portfolio']);
                }
            } else {
                this.notification.danger(this.translate.instant('notifications.danger.no-project-on-portfolio'));
            }
        });
    }

    archives() {
        this.restAPIService.fetchProjectsByStatus(this.utilityService.fetchArchiveStatus()).subscribe((projets: Project[]) => {
            if (projets !== null) {
                if (this.user.role.forProject) {
                    projets.forEach((projet: Project) => {
                        const projTeams: ProjTeam[] = projet.projTeams;
                        projTeams.forEach((projTeam: ProjTeam) => {
                            if (projTeam.user.iduser === this.user.iduser) {
                                this.projects.push(projet);
                            }
                        });

                        const portProjTeams: ProjTeam[] = projet.portProjTeams;
                        portProjTeams.forEach((projTeam: ProjTeam) => {
                            if (projTeam.user.iduser === this.user.iduser) {
                                this.projects.push(projet);
                            }
                        });
                    });

                    if (this.projects.length === 1) {
                        setProject(this.projects[0]?.idproject);
                        this.projects[0]?.portProjTeams.forEach((projTeam: ProjTeam) => {
                            if (projTeam.user.iduser === this.user.iduser) {
                                setPrivilege(projTeam.role.privilege);
                                setRole(projTeam.role);
                            }
                        });
                        this.router.navigate(['/archives/projects/project']);
                    } else if (this.projects.length > 1) {
                        this.router.navigate(['/archives']);
                    } else {
                        this.notification.danger(this.translate.instant('notifications.danger.you-no-project-on-archives'));
                    }
                } else {
                    this.router.navigate(['/archives']);
                }
            } else {
                this.notification.danger(this.translate.instant('notifications.danger.no-project-on-archives'));
            }
        });
    }
}
