import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Project} from '../../../../../payloads/project.payload';
import {Market, ProcDate, Procurement} from '../../../../../payloads/market.payload';
import {Activity} from '../../../../../payloads/activity.payload';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {RestAPIService} from '../../../../../services/rest-api.service';
import {UtilityService} from '../../../../../services/utility.service';
import {Router} from '@angular/router';
import {NotificationFlashService} from '../../../../../services/notification-flash.service';
import {debounceTime} from 'rxjs/operators';
import {dbDate, formatNumber, reloadComponent} from '../../../../../_helpers';
import {Cashout, Task} from '../../../../../payloads/task.payload';
import {ConfirmationDialogService} from '../../../../shared-component/confirmation-dialog/confirmation-dialog.service';

@Component({
    selector: 'app-update-market',
    templateUrl: './update-market.component.html',
    styleUrls: ['./update-market.component.scss']
})
export class UpdateMarketComponent implements OnInit {
    isLoaded: boolean = false;
    project: Project;
    market: Market;
    cashouts: Cashout[] = [];

    markets: Market[] = [];
    procurements: Procurement[] = [];
    activities: Activity[] = [];

    slide1Form: UntypedFormGroup;
    slide2Form: UntypedFormGroup;
    slide3Form: UntypedFormGroup;

    slide1FormSubmitted: boolean = false;
    slide2FormSubmitted: boolean = false;
    slide3FormSubmitted: boolean = false;

    devises: string[];
    marketTypes: any[];
    methods: string[];

    private _danger = new Subject<string>();

    staticAlertClosed = false;
    errorMessage: string;

    slide1: boolean = false;
    slide2: boolean = true;
    slide3: boolean = true;

    startDate: Date;
    endDate: Date;

    min_year: number;
    max_year: number;
    @Input() update: any;

    @ViewChild('staticAlert', { static: false }) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert;
    tasks: Task[];
    hideSave: boolean = false;
    cashOutTask: Task;

    constructor(private formBuilder: UntypedFormBuilder, private confirmationDialog: ConfirmationDialogService, private translate: TranslateService, private restAPIService: RestAPIService,
                private utilityService: UtilityService, private activeModal: NgbActiveModal, private router: Router,
                private notification: NotificationFlashService) {}

    ngOnInit(): void {
        this._danger.subscribe((message) => (this.errorMessage = message));
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.project = this.update.project;
        this.market = this.update.market;

        this.startDate = new Date(this.project.startDate);
        this.endDate = new Date(this.project.endDate);

        this.min_year = new Date(this.project.startDate).getFullYear();
        this.max_year = new Date(this.project.endDate).getFullYear();

        this.markets = this.project.markets;

        this.devises = this.utilityService.fetchCurrencies();
        this.activities = this.project.activities;
        this.activities.sort((activity1: Activity, activity2: Activity) =>
            activity1.idactivity > activity2.idactivity ? 1 : -1
        );
        for (let i = 0; i < this.activities.length; i++) {
            const activity: Activity = this.activities[i];
            if (Number(activity.validated) < 4) {
                this.activities.splice(i, 1);
            }
        }
        this.tasks = [];
        this.onActivityChange(this.market.activity.idactivity);
        this.onTaskChange(this.market.task.idtask);
        this.marketTypes = this.utilityService.fetchMarketTypes();
        this.methods = this.onMethodChange(this.marketTypes.findIndex((marketType: any) => marketType.type === this.market.marketType));

        this.procurements = this.market.procurements;
        this.procurements.sort((procurement1: Procurement, procurement2: Procurement) =>
            procurement1.idprocurement > procurement2.idprocurement ? 1 : -1
        );

        this.procurements.forEach((procurement: Procurement) => {
            const procDates: ProcDate[] = procurement.procDates.sort((procDate1: ProcDate, procDate2: ProcDate) =>
                procDate1.idprocDate > procDate2.idprocDate ? 1 : -1
            );

            procurement.plannedDate = procDates[procDates.length - 1].plannedDate;
        });

        if (this.project.closed !== null || this.project.canceled !== null) {
            this.hideSave = true;
        }

        this.initForm();

        this.isLoaded = true;
    }

    get csrForm() {
        return this.slide1Form.controls;
    }
    get dForm() {
        return this.slide2Form.controls;
    }

    initForm() {
        this.slide1Form = this.formBuilder.group({
            numb: [this.market.numb, [Validators.required]],
            title: [this.market.title, [Validators.required]],
            description: [this.market.description],
            amount: [this.market.amount, [Validators.required]],
            currency: [this.project.currency, [Validators.required]],
            amountFcfa: [this.market.amountFcfa, [Validators.required]],
            exchangeRate: [this.project.exchangeRate],
            activity: [this.market.activity.idactivity, [Validators.required]],
            task: [this.market.task.idtask, [Validators.required]],
            fcpRate: [this.returnNull(this.market.fcpRate), [Validators.max(100)]],
            fcpYear: [this.returnNull(this.market.fcpYear), [Validators.max(this.max_year)]],
            fcpBudgetLine: [this.market.fcpBudgetLine],
            lessorRate: [this.returnNull(this.market.lessorRate), [Validators.max(100)]],
            lessorYear: [this.returnNull(this.market.lessorYear), [Validators.max(this.max_year)]],
            lessorBudgetLine: [this.market.lessorBudgetLine],
        });

        this.slide2Form = this.formBuilder.group({
            deadLine: [this.market.deadLine, [Validators.required]],
            marketType: [this.marketTypes.findIndex((marketType: any) => marketType.type === this.market.marketType), [Validators.required]],
            method: [this.market.method]
        });

        this.slide3Form = this.formBuilder.group({
            startDate: [null, [Validators.required]],
            endDate: [null, [Validators.required]],
            amount: [null, [Validators.required]],
            amountFcfa: [null, [Validators.required]],
            description: [null],
          });
    }

    onActivityChange(value: any) {
        for (const activity of this.activities) {
            if (activity.idactivity === value) {
                this.tasks = activity.tasks;
                this.tasks.sort((task1: Task,  task2: Task) =>
                    (task1.numb > task2.numb) ? 1 : -1
                );
            }
        }
    }

    onTaskChange(value: any) {
        for (const task of this.tasks) {
            if (task.idtask === value) {
                this.startDate = new Date(task.startDate);
                this.endDate = new Date(task.endDate);
                this.cashOutTask = task;
            }
        }
    }

    changeSlide(nextSlide: number) {
        this.errorMessage = null;

        if (nextSlide === 1) {
          this.slide1 = false;
          this.slide2 = true;
          this.slide3 = true;
        }

        if (nextSlide === 2) {
            this.slide1FormSubmitted = true;
            if (this.slide1Form.invalid) {
                return;
            }

            this.slide1 = true;
            this.slide2 = false;
            this.slide3 = true;
        }

        if (nextSlide === 3) {
            this.slide2FormSubmitted = true;
            if (this.slide2Form.invalid) {
                return;
            }

            this.slide1 = true;
            this.slide2 = true;
            this.slide3 = false;
        }
    }

    checkAmount(amount: any) {
        let taskAmount = 0;
        if (this.slide1Form.value.task !== null) {
            this.tasks.forEach((task: Task) => {
                if (task.idtask === this.slide1Form.value.task) {
                    taskAmount = task.amountFcfa;
                }
            });
        } else {
            this.slide1FormSubmitted = true;
        }

        if (amount > taskAmount) {
            this.errorMessage = this.translate.instant(
                'notifications.danger.markets-amount-not-more-than-task-amount',
                { amount: this.moneyFormat(taskAmount) },
            );

            this.slide1Form.get('amountFcfa').setValue(Math.floor(amount / 10));
        } else {
            this.errorMessage = null;
        }

        this.slide1Form.get('amount').setValue((this.slide1Form.value.amountFcfa / this.slide1Form.value.exchangeRate).toFixed(1));
    }

    checkRate(input: any) {
        const rate: number = 100 - input.value;

        if (input.id === 'fcpRate') {
            this.slide1Form.get('lessorRate').setValue(rate);
        }

        if (input.id === 'lessorRate') {
            this.slide1Form.get('fcpRate').setValue(rate);
        }
    }

    onMethodChange(index: any) {
        this.methods = this.marketTypes[Number(index)].methods;
        if (this.marketTypes[Number(index)].type === this.market.marketType) {
            this.procurements = this.market.procurements;
        } else {
            this.procurements = this.marketTypes[Number(index)].procurements;
        }
        return this.methods;
    }

    onAmountFcfaChange() {
        const amountFcfa = this.slide3Form.get('amountFcfa').value;
        const exchangeRate = this.slide1Form.get('exchangeRate').value;

        // Vérifiez si amountFcfa et exchangeRate sont des nombres valides
        if (!isNaN(amountFcfa) && !isNaN(exchangeRate)) {
          const amountInCurrency = amountFcfa / exchangeRate;
          this.slide3Form.get('amount').setValue(amountInCurrency);
        }
      }


    onSubmit() {
        this.errorMessage = null;
        this.slide2FormSubmitted = true;
        if (this.slide2Form.invalid) {
            return;
        }

        const market = new Market(
            this.slide1Form.value.numb,
            this.slide1Form.value.title,
            this.slide1Form.value.description,
            this.slide1Form.value.amount,
            this.slide1Form.value.currency,
            this.slide1Form.value.exchangeRate,
            this.slide1Form.value.amountFcfa,
            this.slide1Form.value.activity,
            this.slide1Form.value.task,
            this.slide2Form.value.deadLine,
            this.marketTypes[Number(this.slide2Form.value.marketType)].type,
            this.slide2Form.value.method,
            this.slide1Form.value.fcpRate,
            this.slide1Form.value.fcpYear,
            this.slide1Form.value.fcpBudgetLine,
            this.slide1Form.value.lessorRate,
            this.slide1Form.value.lessorYear,
            this.slide1Form.value.lessorBudgetLine,
            this.procurements,
            this.project.idproject,
            this.cashouts
        );

        this.restAPIService.updateMarket(this.market.idmarket, market).subscribe(() => {
            this.notification.success(
                this.translate.instant('notifications.success.market-modified'),
            );

            reloadComponent(this.router);

            this.closeModal();
        }, (error) => {
            console.error(error);

            this.errorMessage = this.translate.instant('notifications.danger.market-not-modified');
        });
    }

    closeModal() {
        this.activeModal.close();
    }

    moneyFormat(amount: number) {
        return formatNumber(amount);
    }

    dateFormat(date: string): string {
        return new Date(date).toLocaleDateString('fr-FR');
    }

    dbDateFormat(date: string): string {
        return dbDate(date);
    }

    returnNull(value: any) {
        if (Number(value) === 0) {
            return null;
        } else {
            return value;
        }
    }

    changeProcurementPlannedDate(date: any, i: number) {
        this.procurements[i].plannedDate = date;
        this.procurements[i].procDate = new ProcDate(date);
    }

    onCashoutsUpdated(updatedCashouts: Cashout[]) {
        this.cashouts = updatedCashouts;
    }

    formatBSDate(date: string): Date {
        if (!date) {
            return null;
        }
        return new Date(date);
    }

    setDisabled(task: Task) {
        let existMarket = false;
        this.markets.forEach((market: Market) => {
            if ((this.slide1Form.value.task !== task.idtask) && (task.idtask === market.task.idtask)) {
                existMarket = true;
            }
        });
        return task.conference || existMarket;
    }
}
