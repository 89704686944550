import {Document} from './document.payload';

export class Claim {
    idclaim: number;
    claimDate: string;
    object: string;
    treatment: string;
    document: Document = null;
    response: string;
    project: number;
    createdAt: string;
    updatedAt: string;

    constructor(claimDate: string, object: string, treatment: string, document: Document, response: string, project: number) {
        this.claimDate = claimDate;
        this.object = object;
        this.treatment = treatment;
        this.document = document;
        this.response = response;
        this.project = project;
    }
}
