import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {reloadComponent} from '../../../../../_helpers';
import {NotificationFlashService} from '../../../../../services/notification-flash.service';
import {RestAPIService} from '../../../../../services/rest-api.service';
import {Project} from '../../../../../payloads/project.payload';
import {Review} from '../../../../../payloads/review.payload';
import {TokenStorageService} from '../../../../../services/security/token-storage.service';
import {User} from '../../../../../payloads/user.payload';

@Component({
    selector: 'app-create-prog-review',
    templateUrl: './create-prog-review.component.html',
    styleUrls: ['./create-prog-review.component.scss']
})
export class CreateProgReviewComponent implements OnInit {
    isLoaded = false;
    idProject = Number(sessionStorage.getItem('project'));
    user: User = null;
    reviewForm: UntypedFormGroup;
    sender: string;
    reviewFormSubmitted = false;
    private _danger = new Subject<string>();
    @Input() project: Project;

    staticAlertClosed = false;
    errorMessage: string;

    @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;

    constructor(private formBuilder: UntypedFormBuilder, private router: Router, private notification: NotificationFlashService,
                private translate: TranslateService, private restAPIService: RestAPIService, private activeModal: NgbActiveModal,
                private tokenStorage: TokenStorageService) {
    }

    ngOnInit(): void {
        this._danger.subscribe(message => this.errorMessage = message);
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.user = this.tokenStorage.getUser();
        this.sender = this.user.name + ' ' + this.user.surname;

        this.initForm();

        this.isLoaded = true;
    }

    get createForm() {
        return this.reviewForm.controls;
    }

    initForm() {
        this.reviewForm = this.formBuilder.group({
            date: [{value: new Date(), disabled: true}, [Validators.required]],
            sender: [{value: this.user.name + ' ' + this.user.surname, disabled: true}, [Validators.required]],
            object: [null, [Validators.required]],
            message: [null, [Validators.required]]
        });
    }

    onSubmit() {
        this.reviewFormSubmitted = true;
        if (this.reviewForm.invalid) {
            return;
        }

        const review = new Review(
            this.user.iduser,
            this.reviewForm.value.object,
            this.reviewForm.value.message,
            this.idProject,
            new Date().toISOString()
        );

        this.restAPIService.createReview(this.project.idproject, review).subscribe(() => {
                this.closeModal();

                reloadComponent(this.router);

                this.notification.success(this.translate.instant('notifications.success.review-created'));
            }, (error) => {
                console.error(error);

                this.errorMessage = this.translate.instant('notifications.danger.review-not-created');
            },
        );
    }

    closeModal() {
        this.activeModal.close();
    }
}
