<!DOCTYPE html>
<section class="animated fadeIn" *ngIf="isLoaded">
    <div class="modal-header d-flex justify-content-between">
        <h3 class="modal-title roboto-bold-font">{{ "profile" | translate }}</h3>
        <span class="close fa fa-times text-red" aria-label="Close" (click)="closeModal()"></span>
    </div>
    <div class="modal-body text-black container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 forms">
                <mat-tab-group mat-align-tabs="center">
                    <mat-tab label="{{ 'general_information' | translate }}">
                        <app-user-infos></app-user-infos>
                    </mat-tab>
                    <mat-tab label="{{ 'update_password' | translate }}">
                        <app-user-password></app-user-password>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</section>
