import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {TokenStorageService} from '../../../../../../services/security/token-storage.service';
import {NotificationFlashService} from '../../../../../../services/notification-flash.service';
import {ConfirmationDialogService} from '../../../../../shared-component/confirmation-dialog/confirmation-dialog.service';
import {MustMatch} from '../../../../../../_helpers';
import {RestAPIService} from '../../../../../../services/rest-api.service';
import {User} from '../../../../../../payloads/user.payload';
import {debounceTime} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-user-password',
    templateUrl: './user-password.component.html',
    styleUrls: ['./user-password.component.css']
})

export class UserPasswordComponent implements OnInit {
    userPasswordSubmitted = false;
    userPasswordForm: UntypedFormGroup;
    isLoaded: boolean = false;
    staticAlertClosed = false;
    errorMessage: string;
    @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;
    private _danger = new Subject<string>();

    constructor(private formBuilder: UntypedFormBuilder, private tokenStorage: TokenStorageService, private router: Router,
                private notification: NotificationFlashService, private confirmationDialog: ConfirmationDialogService,
                private translate: TranslateService, private restAPIService: RestAPIService, private activeModal: NgbActiveModal) {
    }

    get passwordForm() {
        return this.userPasswordForm.controls;
    }

    ngOnInit() {
        this._danger.subscribe(message => this.errorMessage = message);
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.isLoaded = true;

        this.initForm();
    }

    initForm() {
        this.userPasswordForm = this.formBuilder.group({
            password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(100)]],
            new_password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(100)]],
            confirm_new_password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(100)]],
        }, {
            validator: MustMatch('new_password', 'confirm_new_password')
        });
    }

    onSubmit() {
        this.userPasswordSubmitted = true;
        if (this.userPasswordForm.invalid) {
            return;
        }

        const userPassword = new User().updatePassword(
            this.userPasswordForm.value.password,
            this.userPasswordForm.value.new_password
        );

        this.confirmationDialog.confirm(this.translate.instant('update_password'), this.translate.instant('confirmations.contents.update-password'))
            .then((confirmation) => {
                if (confirmation) {
                    this.restAPIService.updatePassword(Number(this.tokenStorage.getUser().iduser), userPassword).subscribe(
                        () => {
                            this.closeModal();
                            this.tokenStorage.signOut();
                            this.router.navigate(['/sign-in']);
                        },
                        (error) => {
                            console.error(error);

                            this.errorMessage = this.translate.instant(
                                'notifications.danger.incorrect-actual-password',
                            );
                        },
                    );
                }
            });
    }

    closeModal() {
        this.activeModal.close();
    }
}
