import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {Router} from '@angular/router';
import {NotificationFlashService} from '../../../../../services/notification-flash.service';
import {RestAPIService} from '../../../../../services/rest-api.service';
import {Subject} from 'rxjs';
import {Claim} from '../../../../../payloads/complain-manage.payload';
import {Project} from '../../../../../payloads/project.payload';
import {dbDate, reloadComponent} from '../../../../../_helpers';

@Component({
  selector: 'app-create-complain',
  templateUrl: './create-complain.component.html',
  styleUrls: ['./create-complain.component.scss']
})
export class CreateComplainComponent implements OnInit {
  isLoaded = false;

  public idProject = Number(sessionStorage.getItem('project'));

  public project: Project;
  public claim: Claim;

  claimForm: UntypedFormGroup;

  startDate: Date;

  claimFormSubmitted = false;
  private _danger = new Subject<string>();

  staticAlertClosed = false;
  errorMessage: string;

  @ViewChild('staticAlert', { static: false }) staticAlert: NgbAlert;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert;

  constructor(private formBuilder: UntypedFormBuilder,
    private router: Router,
    private notification: NotificationFlashService,
    private translate: TranslateService,
    private restAPIService: RestAPIService,
    private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this._danger.subscribe(message => this.errorMessage = message);
    this._danger.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });

    if (this.idProject === 0) {
      this.router.navigate(['/preparation-maturation']);
    }

    this.restAPIService.fetchProject(this.idProject).subscribe(
      project => {
        this.project = project;

        this.startDate = new Date(this.project.startDate);

        this.initForm();

        this.isLoaded = true;
      }
    );
  }

  get createForm() { return this.claimForm.controls; }

  initForm() {
    this.claimForm = this.formBuilder.group({
      date: [null, [Validators.required]],
      object: [null],
      treatment: [null, [Validators.required]],
      document: [null],
      response: [null, [Validators.required]]
    });
  }

  onSubmit() {
    this.claimFormSubmitted = true;
    if (this.claimForm.invalid) {
      return;
    }

    this.claim = new Claim(
      dbDate(this.claimForm.value.date),
      this.claimForm.value.object,
      this.claimForm.value.treatment,
      this.claimForm.value.file,
      this.claimForm.value.response,
      this.project.idproject
    );

    this.restAPIService.createClaim(this.claim).subscribe(
      () => {
        this.notification.success(
          this.translate.instant('notifications.success.claim-created'),
        );

        this.closeModal();

        reloadComponent(this.router);
      },
      (error) => {
        console.error(error);

        this.errorMessage = this.translate.instant(
          'notifications.danger.claim-not-created',
        );
      },
    );
  }

  closeModal() {
    this.activeModal.close();
  }
}
