import { INavData } from '@coreui/angular';
import { TranslateService } from '@ngx-translate/core';

export const updateProjectNavItems = (translate: TranslateService): INavData[] => {
    return [
        // {
        //     name: translate.instant('implementation-state'),
        //     url: '/project-portfolio/projects/project/update/update-project/implementation-state',
        //     icon: 'fa fa-repeat',
        // },
        {
            name: translate.instant('basic-information'),
            url: '/project-portfolio/projects/project/update/update-project/basic-information',
            icon: 'fa fa-repeat',
        },
        {
            name: translate.instant('finance-data'),
            url: '/project-portfolio/projects/project/update/update-project/finance-data',
            icon: 'fa fa-money',
        },
        {
            name: translate.instant('status'),
            url: '/project-portfolio/projects/project/update/update-project/status',
            icon: 'fa fa-clock-o',
            children: [
                {
                    name: translate.instant('negotiated'),
                    url: '/project-portfolio/projects/project/update/update-project/status/negotiated',
                    icon: 'fa fa-money',
                },
                {
                    name: translate.instant('obtained-maturation'),
                    url: '/project-portfolio/projects/project/update/update-project/status/obtained-maturation',
                    icon: 'fa fa-stack-exchange',
                },
                {
                    name: translate.instant('signed'),
                    url: '/project-portfolio/projects/project/update/update-project/status/signed',
                    icon: 'fa fa-sign-language',
                },
                {
                    name: translate.instant('implemented'),
                    url: '/project-portfolio/projects/project/update/update-project/status/implemented',
                    icon: 'fa fa-sign-language',
                },
                {
                    name: translate.instant('closed'),
                    url: '/project-portfolio/projects/project/update/update-project/status/closed',
                    icon: 'fa fa-check',
                },
                {
                    name: translate.instant('canceled'),
                    url: '/project-portfolio/projects/project/update/update-project/status/canceled',
                    icon: 'fa fa-times',
                }
            ]
        },
        {
            name: translate.instant('logical-framework'),
            url: '/project-portfolio/projects/project/update/update-project/logical-framework',
            icon: 'fa fa-square',
        },
        {
            name: translate.instant('institutional-disposition'),
            url: '/project-portfolio/projects/project/update/update-project/institutional-disposition',
            icon: 'fa fa-group',
        },
        {
            name: translate.instant('calendar'),
            url: '/project-portfolio/projects/project/update/update-project/calendar',
            icon: 'fa fa-calendar',
        }
    ];
};
