import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {NotificationFlashService} from '../../../services/notification-flash.service';
import {TranslateService} from '@ngx-translate/core';
import {Role} from '../../../payloads/role.payload';
import {RestAPIService} from '../../../services/rest-api.service';
import {User} from '../../../payloads/user.payload';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit {
    registerForm: UntypedFormGroup;
    isLoaded: boolean = false;

    public roles: Role[] = [];

    registerFormSubmitted = false;
    staticAlertClosed = false;
    errorMessage: string;
    @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;
    private _danger = new Subject<string>();

    constructor(private router: Router,
                private acRoute: ActivatedRoute,
                private notification: NotificationFlashService,
                private formBuilder: UntypedFormBuilder,
                private translate: TranslateService,
                private restAPIService: RestAPIService) {
    }

    get register() {
        return this.registerForm.controls;
    }

    ngOnInit() {
        this._danger.subscribe(message => this.errorMessage = message);
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.restAPIService.fetchRoles().subscribe((roles) => {
            this.roles = roles;

            this.isLoaded = true;

            this.initForm();
        });
    }

    initForm() {
        this.registerForm = this.formBuilder.group({
            name: [null, [Validators.required]],
            username: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
            email: [null, [Validators.required, Validators.email]],
            role: [null, [Validators.required]]
        });
    }

    onSubmit() {
        this.registerFormSubmitted = true;
        if (this.registerForm.invalid) {
            return;
        }

        const signupInfo: {
            name: string; email: string;
            username: string; idrole: number;
        } = new User().signUp(
            this.registerForm.value.name?.trim(),
            this.registerForm.value.email?.trim(),
            this.registerForm.value.username?.trim(),
            this.registerForm.value.role
        );

        this.restAPIService.signUp(signupInfo).subscribe(
            () => {
                this.redirectTo('');

                this.notification.success(this.translate.instant('notifications.success.user_created'));
            }, error => {
                if (error.status === 404) {
                    this.notification.danger(this.translate.instant('notifications.danger.user_created'));
                } else if (error.status === 400) {
                    const messages = String(error.error.message).split(' ');

                    if (messages[0] === 'Cet') {
                        this.errorMessage = this.translate.instant('notifications.danger.email_already_exist');
                    } else if (messages[0] === 'Ce') {
                        this.errorMessage = this.translate.instant('notifications.danger.username_already_exist');
                    } else {
                        this.errorMessage = this.translate.instant('notifications.danger.email_username_already_exist');
                    }
                } else {
                    this.notification.danger(this.translate.instant('notifications.danger.user_created'));
                }
            }
        );
    }

    redirectTo(path: string) {
        this.router.navigate(['/' + path], {relativeTo: this.acRoute});
    }

}
