import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {projectReportsNavItems} from "../../../../sidebars/project-reports";
import {Project, ProjTeam} from "../../../../../../../payloads/project.payload";
import {ArchivesDoc, ClaimDoc, TeamLeadDoc} from "../../../../../../../payloads/utility.payload";
import {DefaultLayoutComponent} from "../../../../../../shared-component/default-layout/default-layout.component";
import {RestAPIService} from "../../../../../../../services/rest-api.service";
import {FileViewerComponent} from "../../../../../../shared-component/file-viewer/file-viewer.component";
import {Claim} from "../../../../../../../payloads/complain-manage.payload";
import {Document} from "../../../../../../../payloads/document.payload";

@Component({
  selector: 'app-archives-portfolio-reports',
  templateUrl: './archives-portfolio-reports.component.html',
  styleUrls: ['./archives-portfolio-reports.component.scss']
})
export class ArchivesProjectPortfolioReportsComponent implements OnInit, AfterViewInit {
  isLoaded: boolean = false;

  public idProject = Number(sessionStorage.getItem('project'));
  public project: Project;

  public archivesDocs: ArchivesDoc[] = [];
  public projTeams: ProjTeam[] = [];
  public claims: Claim[] = [];

  constructor(private defaultLayout: DefaultLayoutComponent,
    private translate: TranslateService,
    private router: Router,
    private modalService: NgbModal,
    private restAPIService: RestAPIService) { }

  ngAfterViewInit(): void {
    setTimeout((): void => {
      this.defaultLayout.navItems = projectReportsNavItems(this.translate);
    }, 0);
  }

  ngOnInit(): void {
    if (this.idProject === 0) {
      this.router.navigate(['/project-portfolio/projects']);
    }

    this.restAPIService.fetchProject(this.idProject).subscribe(
      project => {
        this.project = project;

        this.defaultLayout.navItems = projectReportsNavItems(this.translate);
        this.defaultLayout.reloadBreadCrumb(this.project.abbr);

        const teamLeadDocuments: Document[] = [];
        const claimDocuments: Document[] = [];

        if (this.project.projTeams.length > 0) {
          this.projTeams = [...this.project.projTeams];

          const leads: ProjTeam[] = this.projTeams.filter((team: ProjTeam) => team.document !== null);
          if (leads.length > 0) {
            leads.sort((lead1: ProjTeam, lead2: ProjTeam) => (lead1.idprojTeam > lead2.idprojTeam) ? 1 : -1);

            leads.forEach((lead: ProjTeam) => {
              teamLeadDocuments.push(lead.document);
            });
          }
        }

        if (this.project.claims.length > 0) {
          this.claims = [...this.project.claims];

          this.claims.sort((claim1: Claim, claim2: Claim) => (claim1.idclaim > claim2.idclaim) ? 1 : -1);
          this.claims.forEach((claim: Claim) => {
            if (claim.document !== null) {
              claimDocuments.push(claim.document);
            }
          });
        }

        this.archivesDocs.push(
          new ArchivesDoc(
            this.project.numb,
            this.project.title,
            new TeamLeadDoc(this.translate.instant('team-lead-documents'), teamLeadDocuments),
            new ClaimDoc(this.translate.instant('claims-complain'), claimDocuments)
          )
        );

        this.isLoaded = true;
      }
    );
  }

  view(idDocument: number) {
    this.modalService.open(FileViewerComponent,
      {
        windowClass: 'gr-modal-full',
        scrollable: false,
        backdrop: false,
        centered: true
      }).componentInstance.idDocument = idDocument;
  }

}
