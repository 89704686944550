<!DOCTYPE html>
<div class="row home-body mx-1">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <app-header></app-header>

    <div class="row pt-5">
      <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
      <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10 fadeInDown">
        <h1 class="header-text roboto-bold-font" [innerHTML]="'title' | translate"></h1>
        <p>{{ "sub_title" | translate }}</p>
        <button type="button" class="btn btn-pill bg-warning" routerLink="/about-us">{{ "about_us" | translate }}</button>
      </div>
      <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
    </div>
  </div>
</div>
