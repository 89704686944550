<!DOCTYPE html>
<app-header></app-header>
<!-- Showcase -->
<section class="bg-white text-dark text-center text-sm-start">
    <div class="container">
        <div class="d-sm-flex align-items-center justify-content-between">
            <div>
                <h1 [innerHTML]="'home.title' | translate"></h1>
                <p class="lead my-4 px-4">
                    {{"home.subtitle"| translate}}
                </p>
            </div>
            <img alt="" class="img-fluid w-50 d-none d-sm-block" ngSrc="/assets/img/project.png" height="1200"
                 width="1200" priority="true"/>
        </div>
    </div>
</section>

<section class="text-sm-start">
    <div class="container">
        <h4 class="py-2 roboto-bold-font"> {{ "filter-project" | translate }}</h4>

        <form [formGroup]="filterForm">
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-4">
                    <div class="form-group">
                        <label class="control-label" for="state">{{ "inputs.projectStatus.label" | translate }}</label>
                        <ng-select [clearable]="true" [multiple]="true" [searchable]="true"
                                   formControlName="state" id="state" (change)="onFilterChange()">
                            <ng-option *ngFor="let state of states"
                                       [value]="state">{{ replaceState(state) | translate }}</ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4">
                    <div class="form-group">
                        <label class="control-label"
                               for="financingAgency">{{ "inputs.financingAgency.label" | translate }}</label>
                        <ng-select [clearable]="true" [multiple]="true" [searchable]="true"
                                   formControlName="financingAgency" id="financingAgency" (change)="onFilterChange()">
                            <ng-option *ngFor="let financingAgency of financingAgencies"
                                       [value]="financingAgency">{{ financingAgency }}</ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                    <div class="form-group">
                        <label class="control-label" for="year">{{ "project-year.label" | translate }}</label>
                        <ng-select [clearable]="true" [multiple]="false" [searchable]="true"
                                   formControlName="year" id="year" (change)="onFilterChange()">
                            <ng-option *ngFor="let year of allYears" [value]="year">{{ year }}</ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-6">
                    <div class="form-group mt-4">
                        <button (click)="onReset()" class="btn btn-reset mx-2"
                                type="button">{{ "reset" | translate }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="container">
        <div *ngIf="isLoading; else contentToShow" class="text-center p-5 p-lg-0 pt-lg-5">
            <div class="spinner-border" role="status">
                <span class="sr-only">{{ "loading" | translate }}</span>
            </div>
            <p>{{ "loading-project" | translate }}</p>
        </div>
        <ng-template #contentToShow>
            <div class="row">
                <div *ngFor="let project of filteredProjects | paginate : {
                itemsPerPage: projectsPerPage,
                currentPage: currentPage
                }" class="col-sm-4 my-2">
                    <div class="card h-100">
                        <div class="card-body">
                            <h5 class="card-title">{{ project.abbr }}</h5>
                            <h6 class="card-subtitle mb-2 text-muted">{{ project.title }}</h6>
                            <p class="card-text"> {{ project.financingAgency }} </p>
                            <span class="badge badge-warning">{{ replaceState(project.status) | translate }}</span>
                        </div>
                        <div class="card-footer text-white bg-success font-weight-bold">
                            {{ "inputs.startDate.label" | translate }} : {{ dateFormat(project.startDate) }} <br/>
                            {{ "inputs.endDate.label" | translate }} : {{ dateFormat(project.endDate) }}
                        </div>
                    </div>
                </div>
            </div>

            <nav aria-label="Page navigation">
                <ul class="pagination justify-content-center pt-3">
                    <pagination-controls [directionLinks]="true" [responsive]="true"
                                         [previousLabel]="'previous' | translate"
                                         [nextLabel]="'next' | translate"
                                         (pageChange)="onPageChange($event)"></pagination-controls>
                </ul>
            </nav>
        </ng-template>
    </div>

</section>

<app-footer></app-footer>
