import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import * as L from 'leaflet';
import {LatLng, latLng, Map, MapOptions, marker, ZoomAnimEvent} from 'leaflet';
import {GeoSearchControl, OpenStreetMapProvider} from 'leaflet-geosearch';
import {MapBoxService} from './map-box.service';

@Component({
    selector: 'app-map-box',
    templateUrl: './map-box.component.html',
    styleUrls: ['./map-box.component.scss'],
})
export class MapBoxComponent implements OnChanges {
    map!: Map;
    public zoom!: number;
    @Input() options: MapOptions;
    @Input() lat = 3.866667;
    @Input() lng = 11.516667;
    leafletCenter!: [number, number];
    leafletZoom: number | string;
    private marker!: any;

    constructor(private mapBoxService: MapBoxService) {
        this.options = mapBoxService.mapOptions;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.lat || changes.lng) {
            this.updateMarkerPosition();
        }
        if (this.lat && this.lng) {
            this.leafletCenter = [Number(this.lat), Number(this.lng)];
        } else {
            this.leafletCenter = [3.866667, 11.516667];
        }

        if (this.map) {
            this.map.panTo(this.leafletCenter);
        }
    }

    updateMarkerPosition() {
        if (this.map && this.marker) {
            this.marker.setLatLng([this.lat, this.lng]);
            this.map.panTo([this.lat, this.lng]);
        }
    }

    onMapReady(map: Map) {
        this.map = map;

        if (this.lat && this.lng) {
            this.leafletCenter = [Number(this.lat), Number(this.lng)];
        } else {
            this.leafletCenter = [3.866667, 11.516667];
        }

        this.marker = marker([Number(this.lat), Number(this.lng)], {
            icon: this.mapBoxService.markerIcon,
            draggable: true,
        }).addTo(this.map);

        this.marker.on('dragend', (event: any) => {
            const newPosition: LatLng = (event.target as any).getLatLng();
            this.lat = newPosition.lat;
            this.lng = newPosition.lng;
            this.mapBoxService.updateMarkerPosition(latLng(this.lat, this.lng));
        });

        const searchControl: any = new (GeoSearchControl as any)({
            provider: new OpenStreetMapProvider(),
            style: 'bar',
            showMarker: false,
            showPopup: false,
            autoClose: true,
        });

        map.addControl(searchControl);

        this.leafletCenter = [Number(this.lat), Number(this.lng)];
        map.on('click', (e: L.LeafletMouseEvent) => this.onMapClick(e));
    }

    onMapZoomEnd(e: ZoomAnimEvent) {
        this.zoom = e.target.getZoom();
    }

    onMapClick(event: L.LeafletMouseEvent) {
        const clickedPosition: LatLng = event.latlng;
        this.lat = clickedPosition.lat;
        this.lng = clickedPosition.lng;
        this.mapBoxService.updateMarkerPosition(latLng({lat: this.lat, lng: this.lng}));
    }
}
