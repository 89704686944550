<!DOCTYPE html>
<section class="animated fadeIn" *ngIf="isLoaded">
    <div class="row py-2 container-fluid">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <form [formGroup]="userPasswordForm">
              <ngb-alert #selfClosingAlert *ngIf="errorMessage" type="danger" (closed)="errorMessage = ''">{{
                  errorMessage
                  }}</ngb-alert>

            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-2 col-sm-1"></div>
                <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10">
                    <div class="form-group">
                        <label class="control-label" for="password">{{ "inputs.actual_password.label" | translate }}</label>
                        <input type="password" formControlName="password" class="form-control" aria-label="Actual Password"
                               aria-describedby="basic-user" id="password"
                               [ngClass]="{ 'is-invalid': userPasswordSubmitted && passwordForm.password.errors }">
                        <div *ngIf="userPasswordSubmitted && passwordForm.password.errors" class="invalid-feedback">
                            <div *ngIf="passwordForm.password.errors.required">{{ "inputs.actual_password.validations.required" | translate }}</div>
                            <div *ngIf="passwordForm.password.errors.minlength">{{ "inputs.actual_password.validations.min_length" | translate: {'size': '6'} }}</div>
                            <div *ngIf="passwordForm.password.errors.maxlength">{{ "inputs.actual_password.validations.max_length" | translate: {'size': '100'} }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-2 col-sm-1"></div>
            </div>

              <div class="row">
                  <div class="col-xl-3 col-lg-3 col-md-2 col-sm-1"></div>
                  <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10">
                      <div class="form-group">
                          <label class="control-label" for="new_password">{{ "inputs.new_password.label" | translate }}</label>
                          <input type="password" formControlName="new_password" class="form-control" aria-label="Actual Password"
                                 aria-describedby="basic-user" id="new_password"
                                 [ngClass]="{ 'is-invalid': userPasswordSubmitted && passwordForm.new_password.errors }">
                          <div *ngIf="userPasswordSubmitted && passwordForm.new_password.errors" class="invalid-feedback">
                              <div *ngIf="passwordForm.new_password.errors.required">{{ "inputs.new_password.validations.required" | translate }}</div>
                              <div *ngIf="passwordForm.new_password.errors.minlength">{{ "inputs.new_password.validations.min_length" | translate: {'size': '6'} }}</div>
                              <div *ngIf="passwordForm.new_password.errors.maxlength">{{ "inputs.new_password.validations.max_length" | translate: {'size': '100'} }}</div>
                          </div>
                      </div>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-2 col-sm-1"></div>
              </div>

              <div class="row">
                  <div class="col-xl-3 col-lg-3 col-md-2 col-sm-1"></div>
                  <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10">
                      <div class="form-group">
                          <label class="control-label" for="new_password">{{ "inputs.confirm_new_password.label" | translate }}</label>
                          <input type="password" formControlName="confirm_new_password" class="form-control" aria-label="Actual Password"
                                 aria-describedby="basic-user" id="confirm_new_password"
                                 [ngClass]="{ 'is-invalid': userPasswordSubmitted && passwordForm.confirm_new_password.errors }">
                          <div *ngIf="userPasswordSubmitted && passwordForm.confirm_new_password.errors" class="invalid-feedback">
                              <div *ngIf="passwordForm.confirm_new_password.errors.required">{{ "inputs.confirm_new_password.validations.required" | translate }}</div>
                              <div *ngIf="passwordForm.confirm_new_password.errors.minlength">{{ "inputs.confirm_new_password.validations.min_length" | translate: {'size': '6'} }}</div>
                              <div *ngIf="passwordForm.confirm_new_password.errors.maxlength">{{ "inputs.confirm_new_password.validations.max_length" | translate: {'size': '100'} }}</div>
                              <div *ngIf="passwordForm.confirm_new_password.errors.mustMatch">{{ "inputs.confirm_new_password.validations.match" | translate }}</div>
                          </div>
                      </div>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-2 col-sm-1"></div>
              </div>

              <div class="row">
                  <div class="col-xl-3 col-lg-3 col-md-2 col-sm-1"></div>
                  <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 d-flex justify-content-end">
                      <button type="submit" (click)="onSubmit()" class="btn btn-pill bg-primary px-5">{{ "modify" | translate }}</button>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-2 col-sm-1"></div>
              </div>
          </form>
        </div>
    </div>
</section>
