<!DOCTYPE html>
<section *ngIf="isLoaded" class="animated fadeIn">
    <div class="modal-header d-flex justify-content-between">
        <h3 class="modal-title roboto-bold-font">{{ "follow-up-procurement" | translate }}</h3>
        <span (click)="closeModal()" aria-label="Close" class="close fa fa-times text-red"></span>
    </div>
    <div class="modal-body text-black container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 forms">
                <ngb-alert #selfClosingAlert (closed)="errorMessage = ''" *ngIf="errorMessage"
                           type="danger">{{ errorMessage }}</ngb-alert>
                <form #completeProcurementForm="ngForm" enctype="multipart/form-data">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="table-responsive">
                                <table class="table table-hover table-striped">
                                    <caption></caption>
                                    <thead class="thead-dark">
                                    <tr>
                                        <th class="nowrap">{{ "entitled" | translate }}</th>
                                        <th class="nowrap">{{ "planned-date" | translate }}</th>
                                        <th class="nowrap">{{ "executed-date" | translate }}</th>
                                        <th>{{ "docs" | translate }}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let procurement of procurements; let i = index;">
                                        <td class="nowrap">{{ procurement.title }}</td>
                                        <td class="nowrap text-center">{{ dateFormat(procurement.plannedDate) }}</td>
                                        <td class="bg-white">
                                            <input [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [daysDisabled]="[7,0]"
                                                   [bsValue]="formatBSDate(procurement.executedDate)"
                                                   [disabled]="setDisabled(i)" [maxDate]="todayDate"
                                                   [minDate]="formatBSDate(procurement.plannedDate)"
                                                   [readonly]="procurement.executedDate" bsDatepicker
                                                   class="form-control" id="executedDate_{{ procurement.idprocurement }}"
                                                   name="executedDate_{{ procurement.idprocurement }}" ngModel
                                                   placeholder="{{ 'date-format' | translate }}" required type="text">
                                        </td>
                                        <td class="text-center bg-white text-black">
                                            <span *ngIf="procurement.document !== null">
                                                <em (click)="view(procurement.document.iddocument)"
                                                    *ngIf="procurement.document.iddocument"
                                                    class="bi-sm bi-file-earmark-pdf pointer"></em>
                                                <input (change)="onFileSelect(procurement.idprocurement, $event)"
                                                       *ngIf="!procurement.document.iddocument" class="text-black"
                                                       [disabled]="setDisabled(i)" [readonly]="procurement.document"
                                                       accept=".pdf" id="file_{{ procurement.idprocurement }}"
                                                       name="file_{{ procurement.idprocurement }}" ngModel type="file">
                                            </span>
                                            <input (change)="onFileSelect(procurement.idprocurement, $event)"
                                                   *ngIf="procurement.document === null" [disabled]="setDisabled(i)"
                                                   [readonly]="procurement.document" accept=".pdf" class="text-black"
                                                   id="file_{{ procurement.idprocurement }}"
                                                   name="file_{{ procurement.idprocurement }}" ngModel type="file">
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="row py-2">
                        <div
                            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-white d-flex justify-content-between">
                            <button (click)="resetProcDate()" [hidden]="hideSave"
                                    class="btn btn-pill bg-warning px-5 py-2"
                                    type="button">{{ "reprogram" | translate }}</button>

                            <button (click)="createContract()" [hidden]="contractCreate"
                                    class="btn btn-pill bg-green px-5 py-2 text-center"
                                    type="button">{{ "contract" | translate }}</button>
                            <button (click)="viewContract(contract)" [hidden]="contractView"
                                    class="btn btn-pill bg-green px-5 py-2 text-center"
                                    type="button">{{ "contract" | translate }}</button>

                            <button (click)="onSubmit(completeProcurementForm.value)" [hidden]="hideSave"
                                    class="btn btn-pill bg-primary px-5 py-2"
                                    type="button">{{ "save" | translate }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
