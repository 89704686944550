<!DOCTYPE html>
<section class="animated fadeIn" *ngIf="isLoaded">
    <div class="modal-header d-flex justify-content-between">
        <h3 class="modal-title roboto-bold-font">{{ "execution-monitoring" | translate }} : {{ market.title }}</h3>
        <span class="close fa fa-times text-red" aria-label="Close" (click)="closeModal()"></span>
    </div>
    <div class="modal-body text-black container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 forms">
                <ngb-alert #selfClosingAlert *ngIf="errorMessage" type="danger"
                           (closed)="errorMessage = ''">{{ errorMessage }}</ngb-alert>

                <section [hidden]="slide1">
                    <h4 class="py-2 roboto-bold-font">{{ "service-orders" | translate }}</h4>
                    <form [formGroup]="slide1Form">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-8">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="title">{{ "inputs.entitle.label" | translate}}</label>
                                    <input type="text" formControlName="title" class="form-control"
                                           aria-label="Title" aria-describedby="basic-user" id="title"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && basicForm.title.errors }">
                                    <div *ngIf="slide1FormSubmitted && basicForm.title.errors" class="invalid-feedback">
                                        <div *ngIf="basicForm.title.errors.required">{{
                                            "inputs.entitle.validations.required" | translate }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-4">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="orderType">{{ "inputs.orderType.label" | translate}}</label>
                                    <ng-select formControlName="orderType" id="orderType" placeholder="Order Type"
                                               [ngClass]="{ 'is-invalid': slide1FormSubmitted && basicForm.orderType.errors }"
                                               [searchable]="true" [clearable]="false">
                                        <ng-option *ngFor="let orderType of orderTypes"
                                                   [value]="orderType">{{ orderType }}</ng-option>
                                    </ng-select>
                                    <div *ngIf="slide1FormSubmitted && basicForm.orderType.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="basicForm.orderType.errors.required">{{
                                            "inputs.orderType.validations.required" | translate }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-12">
                                <div class="form-group">
                                    <label class="control-label" for="file">{{ "inputs.file.label" | translate}}</label>
                                    <br>
                                    <input type="file" formControlName="file" aria-label="File"
                                           aria-describedby="basic-user"
                                           (change)="onServiceOrderFileSelect($event)" id="file" accept=".pdf"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && basicForm.file.errors }">
                                    <div *ngIf="slide1FormSubmitted && basicForm.file.errors" class="invalid-feedback">
                                        <div *ngIf="basicForm.file.errors.required">{{
                                            "inputs.file.validations.required" | translate }}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-6 col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="signatureDate">{{ "inputs.signatureDate.label" | translate}}</label>
                                    <input [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [daysDisabled]="[7,0]" type="text"
                                           bsDatepicker class="form-control" placeholder="{{ 'date-format' | translate }}" formControlName="signatureDate"
                                           [minDate]="formatBSDate(market.startDate)" [maxDate]="todayDate"
                                           aria-label="Signature Date" aria-describedby="basic-user" id="signatureDate"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && basicForm.signatureDate.errors }">
                                    <div *ngIf="slide1FormSubmitted && basicForm.signatureDate.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="basicForm.signatureDate.errors.required">{{
                                            "inputs.signatureDate.validations.required" |
                                                translate }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="receptionDate">{{ "inputs.receptionDate.label" | translate}}</label>
                                    <input [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [daysDisabled]="[7,0]" type="text"
                                           bsDatepicker class="form-control" placeholder="{{ 'date-format' | translate }}" formControlName="receptionDate"
                                           aria-label="Reception Date" aria-describedby="basic-user" id="receptionDate"
                                           [minDate]="formatBSDate(market.startDate)" [maxDate]="todayDate"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && basicForm.receptionDate.errors }">
                                    <div *ngIf="slide1FormSubmitted && basicForm.receptionDate.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="basicForm.receptionDate.errors.required">{{
                                            "inputs.receptionDate.validations.required" |
                                                translate }}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-12 col-lg-12 d-flex justify-content-end text-white">
                                <a class="btn btn-pill bg-green my-2 mx-1" (click)="addServiceOrder()"
                                   disabled="{{ execution.view }}"><em class="fa fa-plus"></em></a>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="table-responsive">
                                    <table class="table table-hover table-striped">
                                        <caption></caption>
                                        <thead class="thead-dark">
                                        <tr>
                                            <th>{{ "inputs.entitle.label" | translate }}</th>
                                            <th>{{ "inputs.orderType.label" | translate }}</th>
                                            <th>{{ "inputs.signatureDate.label" | translate }}</th>
                                            <th>{{ "inputs.receptionDate.label" | translate }}</th>
                                            <th>{{ "docs" | translate }}</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let serviceOrder of serviceOrders; let i = index;">
                                            <td>{{ serviceOrder.title }}</td>
                                            <td>{{ serviceOrder.orderType }}</td>
                                            <td class="text-center">{{ dateFormat(serviceOrder.signatureDate) }}</td>
                                            <td class="text-center">{{ dateFormat(serviceOrder.receptionDate) }}</td>
                                            <td class="text-center">
                                                <span *ngIf="serviceOrder.document !== null">
                                                    <em *ngIf="serviceOrder.document.iddocument"
                                                        class="bi-sm bi-file-earmark-pdf pointer"
                                                        (click)="view(serviceOrder.document.iddocument)"></em>
                                                    <em *ngIf="!serviceOrder.document.iddocument"
                                                        class="bi-sm bi-file-earmark-pdf no-pointer"></em>
                                                </span>
                                            </td>
                                            <td class="text-center">
                                                <a class="btn btn-pill bg-danger my-2 mx-1 text-white"
                                                   *ngIf="!serviceOrder.idserviceOrder"
                                                   (click)="deleteServiceOrder(i)"><em class="fa fa-times"></em></a>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </form>

                    <hr>

                    <h4 class="py-2 roboto-bold-font">{{ "endorsements" | translate }}</h4>
                    <form [formGroup]="slide2Form">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-5">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="numb">{{ "inputs.numb.label" | translate }}</label>
                                    <input type="text" formControlName="numb"
                                           class="form-control text-right roboto-bold-font"
                                           [ngClass]="{ 'is-invalid': slide2FormSubmitted && endorseForm.numb.errors }"
                                           aria-label="Number" aria-describedby="basic-user" id="numb">
                                    <div *ngIf="slide2FormSubmitted && endorseForm.numb.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="endorseForm.numb.errors.required">{{
                                            "inputs.numb.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-8 col-lg-8 col-md-7">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="object">{{ "inputs.object.label" | translate }}</label>
                                    <input type="text" formControlName="object" class="form-control"
                                           [ngClass]="{ 'is-invalid': slide2FormSubmitted && endorseForm.object.errors }"
                                           aria-label="Object" aria-describedby="basic-user" id="object">
                                    <div *ngIf="slide2FormSubmitted && endorseForm.object.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="endorseForm.object.errors.required">{{
                                            "inputs.object.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-5">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="control-label"
                                                   for="amount">{{ "inputs.amount.label" | translate }}</label>
                                            <input type="number" formControlName="amount" min="0"
                                                   (input)="checkAmount($event.target.value)"
                                                   class="form-control text-right roboto-bold-font" id="amount"
                                                   [ngClass]="{ 'is-invalid': slide2FormSubmitted && endorseForm.amount.errors }"
                                                   aria-describedby="basic-user" aria-label="Amount">
                                            <div *ngIf="slide2FormSubmitted && endorseForm.amount.errors"
                                                 class="invalid-feedback">
                                                <div
                                                    *ngIf="endorseForm.amount.errors.required">{{ "inputs.amount.validations.required" | translate }}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-12 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="control-label"
                                                   for="date">{{ "inputs.date.label" | translate}}</label>
                                            <input [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [daysDisabled]="[7,0]"
                                                   type="text" bsDatepicker class="form-control" placeholder="{{ 'date-format' | translate }}"
                                                   [minDate]="formatBSDate(market.startDate)" [maxDate]="todayDate"
                                                   formControlName="date" aria-label="Date" aria-describedby="basic-user" id="date"
                                                   [ngClass]="{ 'is-invalid': slide2FormSubmitted && endorseForm.date.errors }">
                                            <div *ngIf="slide2FormSubmitted && endorseForm.date.errors"
                                                 class="invalid-feedback">
                                                <div *ngIf="endorseForm.date.errors.required">{{
                                                    "inputs.date.validations.required" |
                                                        translate }}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-12 col-lg-12">
                                        <div class="form-group">
                                            <label class="control-label"
                                                   for="endorseFile">{{ "inputs.file.label" | translate}}</label>
                                            <br>
                                            <input type="file" formControlName="file" aria-label="File"
                                                   aria-describedby="basic-user"
                                                   (change)="onEndorsementFileSelect($event)" id="endorseFile"
                                                   accept=".pdf"
                                                   [ngClass]="{ 'is-invalid': slide2FormSubmitted && endorseForm.file.errors }">
                                            <div *ngIf="slide2FormSubmitted && endorseForm.file.errors"
                                                 class="invalid-feedback">
                                                <div *ngIf="endorseForm.file.errors.required">{{
                                                    "inputs.file.validations.required" | translate }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-8 col-lg-8 col-md-7">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <label class="control-label"
                                                   for="description">{{ "inputs.description.label" | translate }}</label>
                                            <textarea formControlName="description" class="form-control"
                                                      aria-label="Description" rows="8"
                                                      [ngClass]="{ 'is-invalid': slide2FormSubmitted && endorseForm.description.errors }"
                                                      aria-describedby="basic-user" id="description"></textarea>
                                            <div *ngIf="slide2FormSubmitted && endorseForm.description.errors"
                                                 class="invalid-feedback">
                                                <div *ngIf="endorseForm.description.errors.required">{{
                                                    "inputs.description.validations.required" |
                                                        translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-12 col-lg-12 d-flex justify-content-end text-white">
                                <a class="btn btn-pill bg-green my-2 mx-1" (click)="addEndorsement()"
                                   disabled="{{ execution.view }}"><em class="fa fa-plus"></em></a>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="table-responsive">
                                    <table class="table table-hover table-striped">
                                        <caption></caption>
                                        <thead class="thead-dark">
                                        <tr>
                                            <th>{{ "inputs.numb.label" | translate }}</th>
                                            <th>{{ "inputs.object.label" | translate }}</th>
                                            <th>{{ "inputs.description.label" | translate }}</th>
                                            <th class="nowrap">{{ "inputs.amount.label" | translate }}</th>
                                            <th>{{ "inputs.date.label" | translate }}</th>
                                            <th>{{ "docs" | translate }}</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let endorsement of endorsements; let i = index;">
                                            <td>{{ endorsement.numb }}</td>
                                            <td>{{ endorsement.object }}</td>
                                            <td>{{ endorsement.description }}</td>
                                            <td class="roboto-bold-font text-right nowrap">{{ moneyFormat(endorsement.amount) }}</td>
                                            <td class="text-center">{{ dateFormat(endorsement.date) }}</td>
                                            <td class="text-center">
                                                <span *ngIf="endorsement.document !== null">
                                                    <em *ngIf="endorsement.document.iddocument"
                                                        class="bi-sm bi-file-earmark-pdf pointer"
                                                        (click)="view(endorsement.document.iddocument)"></em>
                                                    <em *ngIf="!endorsement.document.iddocument"
                                                        class="bi-sm bi-file-earmark-pdf no-pointer"></em>
                                                </span>
                                            </td>
                                            <td class="text-center">
                                                <a class="btn btn-pill bg-danger my-2 mx-1 text-white"
                                                   *ngIf="!endorsement.idendorsement"
                                                   (click)="deleteEndorsement(i)"><em class="fa fa-times"></em></a>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end">
                            <button class="btn btn-pill bg-green px-5"
                                    (click)="taskChangeSlide(2)">{{ "next" | translate }}</button>
                        </div>
                    </div>
                </section>

                <section [hidden]="slide2">
                    <h4 class="py-2 roboto-bold-font">{{ "deliverables" | translate }}</h4>
                    <form #completeContractDeliverableForm="ngForm" enctype="multipart/form-data">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="table-responsive">
                                    <table class="table table-hover table-striped">
                                        <caption></caption>
                                        <thead class="thead-dark">
                                        <tr>
                                            <th>{{ "inputs.entitle.label" | translate }}</th>
                                            <th>{{ "inputs.receptionDate.label" | translate }}</th>
                                            <th>{{ "inputs.validationDate.label" | translate }}</th>
                                            <th>{{ "inputs.observation.label" | translate }}</th>
                                            <th>{{ "docs" | translate }}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let deliverable of deliverables; let i = index;">
                                            <td>{{ deliverable.title }}</td>
                                            <td>
                                                <input [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                                                       [daysDisabled]="[7,0]"
                                                       type="text" bsDatepicker class="form-control"
                                                       placeholder="{{ 'date-format' | translate }}"
                                                       [minDate]="formatBSDate(market.startDate)" [maxDate]="todayDate"
                                                       name="submittedDate_{{ deliverable.idcontractDeliverable }}"
                                                       id="submittedDate_{{ deliverable.idcontractDeliverable }}"
                                                       [readonly]="deliverable.submittedDate"
                                                       [disabled]="setDeliverableDisabled(i)"
                                                       ngModel="{{ formatBSDate(deliverable.submittedDate) }}">
                                            </td>
                                            <td>
                                                <input [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                                                       [daysDisabled]="[7,0]"
                                                       type="text" bsDatepicker class="form-control"
                                                       placeholder="{{ 'date-format' | translate }}"
                                                       [minDate]="formatBSDate(market.startDate)" [maxDate]="todayDate"
                                                       name="validatedDate_{{ deliverable.idcontractDeliverable }}"
                                                       id="validatedDate_{{ deliverable.idcontractDeliverable }}"
                                                       [readonly]="deliverable.validatedDate"
                                                       [disabled]="setDeliverableDisabled(i)"
                                                       ngModel="{{ formatBSDate(deliverable.validatedDate) }}">
                                            </td>
                                            <td>
                                                <textarea class="form-control"
                                                          name="observation_{{ deliverable.idcontractDeliverable }}"
                                                          id="observation_{{ deliverable.idcontractDeliverable }}"
                                                          [readonly]="deliverable.observation"
                                                          ngModel="{{ deliverable.observation }}"
                                                          [disabled]="setDeliverableDisabled(i)"></textarea>
                                            </td>
                                            <td class="text-center">
                                                <span *ngIf="deliverable.document !== null">
                                                    <em *ngIf="deliverable.document.iddocument"
                                                        class="bi-sm bi-file-earmark-pdf pointer"
                                                        (click)="view(deliverable.document.iddocument)"></em>
                                                    <input type="file"
                                                           name="deliverableFile_{{ deliverable.idcontractDeliverable }}"
                                                           id="deliverableFile_{{ deliverable.idcontractDeliverable }}"
                                                           accept=".pdf"
                                                           (change)="onDeliverableFileSelect(deliverable.idcontractDeliverable, $event)"
                                                           *ngIf="!deliverable.document.iddocument"
                                                           [disabled]="setDeliverableDisabled(i)">
                                                </span>
                                                <span *ngIf="deliverable.document === null">
                                                    <input type="file"
                                                           name="deliverableFile_{{ deliverable.idcontractDeliverable }}"
                                                           id="deliverableFile_{{ deliverable.idcontractDeliverable }}"
                                                           accept=".pdf"
                                                           (change)="onDeliverableFileSelect(deliverable.idcontractDeliverable, $event)"
                                                           [disabled]="setDeliverableDisabled(i)">
                                                </span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </form>

                    <hr>

                    <h4 class="py-2 roboto-bold-font">{{ "payments" | translate }}</h4>
                    <form #completeContractPaymentForm="ngForm" enctype="multipart/form-data">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="table-responsive">
                                    <table class="table table-hover table-striped">
                                        <caption></caption>
                                        <thead class="thead-dark">
                                        <tr>
                                            <th>{{ "inputs.amountAssociatedStatement.label" | translate }}</th>
                                            <th>{{ "inputs.paymentDate.label" | translate }}</th>
                                            <th>{{ "inputs.observation.label" | translate }}</th>
                                            <th>{{ "docs" | translate }}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let payment of payments; let i = index;">
                                            <td class="text-right roboto-bold-font">{{ moneyFormat(payment.amount) }}</td>
                                            <td>
                                                <input [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                                                       [daysDisabled]="[7,0]"
                                                       type="text" bsDatepicker class="form-control"
                                                       placeholder="{{ 'date-format' | translate }}"
                                                       [minDate]="formatBSDate(market.startDate)" [maxDate]="todayDate"
                                                       name="paymentDate_{{ payment.idcontractPayment }}"
                                                       id="paymentDate_{{ payment.idcontractPayment }}"
                                                       [readonly]="payment.paymentDate"
                                                       ngModel="{{ formatBSDate(payment.paymentDate) }}"
                                                       [disabled]="setPaymentDisabled(i)">
                                            </td>
                                            <td>
                                                <textarea class="form-control"
                                                          name="observation_{{ payment.idcontractPayment }}"
                                                          id="observation_{{ payment.idcontractPayment }}"
                                                          [readonly]="payment.observation"
                                                          ngModel="{{ payment.observation }}"
                                                          [disabled]="setPaymentDisabled(i)"></textarea>
                                            </td>
                                            <td class="text-center">
                                                <span *ngIf="payment.document !== null">
                                                    <em *ngIf="payment.document.iddocument"
                                                        class="bi-sm bi-file-earmark-pdf pointer"
                                                        (click)="view(payment.document.iddocument)"></em>
                                                    <input type="file"
                                                           name="paymentFile_{{ payment.idcontractPayment }}"
                                                           id="paymentFile_{{ payment.idcontractPayment }}"
                                                           accept=".pdf"
                                                           (change)="onPaymentFileSelect(payment.idcontractPayment, $event)"
                                                           *ngIf="!payment.document.iddocument"
                                                           [disabled]="setPaymentDisabled(i)">
                                                </span>
                                                <span *ngIf="payment.document === null">
                                                    <input type="file"
                                                           name="paymentFile_{{ payment.idcontractPayment }}"
                                                           id="paymentFile_{{ payment.idcontractPayment }}"
                                                           accept=".pdf"
                                                           (change)="onPaymentFileSelect(payment.idcontractPayment, $event)"
                                                           [disabled]="setPaymentDisabled(i)">
                                                </span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div class="row py-2">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-between">
                            <a class="btn btn-pill bg-warning px-5" (click)="taskChangeSlide(1)">
                                {{ "previous" | translate }}</a>
                            <button type="button"
                                    (click)="onSubmit(completeContractDeliverableForm.value, completeContractPaymentForm.value)"
                                    [hidden]="execution.view"
                                    class="btn btn-pill bg-primary px-5">{{ "save" | translate }}</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>
