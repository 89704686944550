import {Component, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {GeoSearchControl, OpenStreetMapProvider} from 'leaflet-geosearch';
import {environment} from '../../../../environments/environment';
import * as L from 'leaflet';
import {icon, latLng, Map, MapOptions, marker, popup, tileLayer, ZoomAnimEvent} from 'leaflet';
import {TranslateService} from '@ngx-translate/core';
import {Coordinate} from '../../../interfaces/Coordinate';


@Component({
    selector: 'app-map-box-all',
    templateUrl: './map-box-all.component.html',
    styleUrls: ['./map-box-all.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MapBoxAllComponent implements OnChanges {
    map!: Map;

    @Input() options: MapOptions = {
        layers: [
            tileLayer(
                `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${environment.mapbox.accessToken}`,
                {
                    tileSize: 512,
                    zoomOffset: -1,
                    attribution: '© <a href="https://www.mapbox.com/contribute/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                }
            )
        ],
        zoom: 6,
        center: latLng(0, 0),
    };

    style = 'mapbox://styles/mapbox/streets-v12';
    @Input() lat = 3.866667;
    @Input() lng = 11.516667;
    @Input() zoom = 8;
    @Input() center = true;
    @Input() markers: any[];

    constructor(private translate: TranslateService) {}

    private streetLayer = tileLayer(
        `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${environment.mapbox.accessToken}`,
        {
            tileSize: 512,
            zoomOffset: -1,
            attribution: '© <a href="https://www.mapbox.com/contribute/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        }
    );

    private satelliteLayer = tileLayer(
        `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=${environment.mapbox.accessToken}`, {
            tileSize: 512,
            zoomOffset: -1,
            attribution: '© <a href="https://www.mapbox.com/contribute/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }
    );

    private markerActiviteIcon = this.createMarkerIcon('assets/icons/activity.png');
    private markerTaskIcon = this.createMarkerIcon('assets/icons/task.png');
    private markerProjectIcon = this.createMarkerIcon('assets/icons/project.png');

    leafletCenter: [number, number] = [this.lat, this.lng];
    leafletZoom: number|string;

    switchToStreetView() {
        this.map.eachLayer(layer => {
            if (layer instanceof tileLayer) {
                this.map.removeLayer(layer);
            }
        });
        this.streetLayer.addTo(this.map);
    }

    switchToSatelliteView() {
        this.map.eachLayer(layer => {
            if (layer instanceof tileLayer) {
                this.map.removeLayer(layer);
            }
        });
        this.satelliteLayer.addTo(this.map);
    }

    addMarkersToMap(markers) {
        if (this.map) {
            this.map.eachLayer(layer => {
                if (layer instanceof L.Marker) {
                    this.map.removeLayer(layer);
                }
            });
        }

        const validMarkers = markers.filter(marker =>
            marker.longitude !== undefined &&
            marker.latitude !== undefined &&
            marker.indicator !== undefined &&
            marker.title !== undefined
        );

        this.markers = validMarkers;

        for (const mapMarker of validMarkers) {
            switch (mapMarker.indicator) {
                case 'project':
                    this.addProjectMarker(mapMarker);
                    break;
                case 'activity':
                    this.addActivityMarker(mapMarker);
                    break;
                case 'task':
                    this.addTaskMarker(mapMarker);
                    break;
            }
        }
    }

    addProjectMarker(marker: Coordinate) {
        if (marker.latitude && marker.longitude) {
            this.addMarker(
                Number(marker.latitude),
                Number(marker.longitude),
                this.markerProjectIcon,
                "<h5>" + this.translate.instant('project') + ": " + marker.projet.abbr + "</h5> <br> " +
                "<h5>" + marker.title + "</h5>"
            );
        }
    }

    addActivityMarker(marker: Coordinate) {
        if (marker.longitude && marker.latitude) {
            this.addMarker(
                Number(marker.latitude),
                Number(marker.longitude),
                this.markerActiviteIcon,
                "<h5>" + this.translate.instant('project') + ": " + marker.projet.abbr + "</h5> <br> " +
                "<h5>" + this.translate.instant('activity') + ": " + marker.activity.numb + "</h5> <br> " +
                "<h5>" + marker.title + "</h5>"
            );
        }
    }

    addTaskMarker(marker: Coordinate) {
        if (marker.longitude && marker.latitude) {
            this.addMarker(
                Number(marker.latitude),
                Number(marker.longitude),
                this.markerTaskIcon,
                "<h5>" + this.translate.instant('project') + ": " + marker.projet.abbr + "</h5> <br> " +
                "<h5>" + this.translate.instant('activity') + ": " + marker.activity.numb + "</h5> <br> " +
                "<h5>" + this.translate.instant('task') + ": " + marker.task.numb + "</h5> <br> " +
                "<h5>" + marker.title + "</h5>"
            );
        }
    }

    addMarker(latitude: number, longitude: number, icon: any, popupContent: string) {
        const markerPopup = popup().setContent(popupContent);
        const newMarker = marker([latitude, longitude], {
            icon: icon,
        }).bindPopup(markerPopup);
        this.markers.push(newMarker);
        if (this.map) {
            this.map.addLayer(newMarker);
        }
    }

    onMapReady(map: Map) {
        this.map = map;
        this.addMarkersToMap(this.markers);
        if (this.center) {
            this.leafletCenter = [this.lat, this.lng];
        }

        const latitudeCameroun = 5.9631;
        const longitudeCameroun = 12.709;

        const projectTitle = this.translate.instant('project');
        const activityTitle = this.translate.instant('activity');
        const taskTitle = this.translate.instant('task');

        this.map.setView([latitudeCameroun, longitudeCameroun], this.zoom);

        const searchControl: any = new (GeoSearchControl as any)({
            provider: new OpenStreetMapProvider(),
            style: 'bar',
            showMarker: false,
            showPopup: false,
            autoClose: true,
        });

        map.addControl(searchControl);

        const legendControl = L.Control.extend({
            onAdd: function () {
                const div = L.DomUtil.create('div', 'info legend');
                div.innerHTML = "<h4>Légende</h4>" +
                    "<img src='assets/icons/project.png' alt='project'>" + projectTitle + "<br>" +
                    "<img src='assets/icons/activity.png' alt='activity'>" + activityTitle + "<br>" +
                    "<img src='assets/icons/task.png' alt='task'>" + taskTitle + "<br>";
                return div;
            }
        });

        const legend = new legendControl({position: 'bottomright'});
        legend.addTo(map);
    }

    onMapZoomEnd(e: ZoomAnimEvent) {
        this.zoom = e.target.getZoom();
    }

    createMarkerIcon(iconUrl: string) {
        return icon({
            iconUrl: `${iconUrl}`,
            iconSize: [31, 46],
            iconAnchor: [15.5, 42],
            popupAnchor: [0, -45],
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.markers) {
            this.addMarkersToMap(changes.markers.currentValue);
        }
    }

}
