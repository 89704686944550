<section class="animated fadeIn" *ngIf="isLoaded">
    <div class="modal-header d-flex justify-content-between">
        <h3 class="modal-title roboto-bold-font">{{ "sync-activity-kobotoolbox" | translate }}</h3>
        <span class="close fa fa-times text-red" aria-label="Close" (click)="closeModal()"></span>
    </div>
    <div class="modal-body text-black container-fluid" [formGroup]="koboCollectForm">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <ngb-alert [dismissible]="true" *ngIf="errorMessage" type="danger" (closed)="errorMessage = ''">{{ errorMessage }}</ngb-alert>

                <div class="row">
                    <div class="col-xl-12 col-lg-12">
                        <div class="form-group">
                            <label class="control-label"
                                   for="activity">{{"inputs.activity.label" | translate }}
                                <span class="roboto-bold-font text-red">*</span></label>
                            <ng-select formControlName="activity" id="activity" [searchable]="true" [clearable]="true"
                                       placeholder="{{ 'inputs.activity.placeholders' | translate }}" (change)="onActivityChange($event)"
                                       [ngClass]="{ 'is-invalid': koboCollectFormSubmitted && koboToolForm.activity.errors }">
                                <ng-option *ngFor="let activity of activities; let i = index" [value]="i">
                                    {{ activity.numb }} : {{ activity.title }}
                                </ng-option>
                            </ng-select>
                            <div *ngIf="koboCollectFormSubmitted && koboToolForm.activity.errors"
                                 class="invalid-feedback">
                                <div *ngIf="koboToolForm.activity.errors.required">{{
                                    "inputs.activity.validations.required" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6">
                        <div class="form-group">
                            <label class="control-label" for="koboForm">{{ "inputs.kobol_collect_forms.label" | translate }}
                                <span class="roboto-bold-font text-red">*</span>
                            </label>
                            <ng-select formControlName="koboForm" id="koboForm" [searchable]="true" [clearable]="true"
                                       placeholder="{{ 'inputs.kobol_collect_forms.placeholders' | translate }}"
                                       [ngClass]="{ 'is-invalid': koboCollectFormSubmitted && koboToolForm.koboForm.errors }"
                                       (change)="onKoboCollectChange($event)">
                                <ng-option *ngFor="let koboCollect of koboCollects; let i = index" [value]="i">
                                    {{ koboCollect.title }}
                                </ng-option>
                            </ng-select>
                            <div *ngIf="koboCollectFormSubmitted && koboToolForm.koboForm.errors"
                                 class="invalid-feedback">
                                <div *ngIf="koboToolForm.koboForm.errors.required">{{
                                    "inputs.kobol_collect_forms.validations.required" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6">
                        <div class="form-group">
                            <label class="control-label" for="koboFormData">{{ "inputs.kobol_collect_form_datas.label" | translate }}
                                <span class="roboto-bold-font text-red">*</span>
                            </label>
                            <ng-select formControlName="koboFormData" id="koboFormData" [searchable]="true" [clearable]="true"
                                       placeholder="{{ 'inputs.kobol_collect_form_datas.placeholders' | translate }}"
                                       [ngClass]="{ 'is-invalid': koboCollectFormSubmitted && koboToolForm.koboFormData.errors }">
                                <ng-option *ngFor="let koboCollectData of koboCollectDatas; let i = index" [value]="i" [disabled]="setDisabled(i)">
                                    <div class="clearfix">
                                        <span class="float-left">
                                            {{ dateFormat(koboCollectData._submission_time) }}
                                            {{ timeFormat(koboCollectData._submission_time) }}
                                        </span>
                                        <em class="float-right text-light px-1 w-30 text-center validation-label"
                                            [class]="setBGColor(i)">{{ getValidationLabel(i) }}</em>
                                    </div>
                                </ng-option>
                            </ng-select>
                            <div *ngIf="koboCollectFormSubmitted && koboToolForm.koboFormData.errors"
                                 class="invalid-feedback">
                                <div *ngIf="koboToolForm.koboFormData.errors.required">{{
                                    "inputs.kobol_collect_form_datas.validations.required" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row py-2">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end">
                            <button (click)="onSubmit()" class="btn btn-pill bg-primary px-5"
                                    type="submit">{{ "import" | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
