import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {projectReportsNavItems} from "../../../../sidebars/project-reports";
import {Project} from "../../../../../../../payloads/project.payload";
import {ActivityOper} from "../../../../../../../payloads/show-activity.payload";
import {ActivityDoc} from "../../../../../../../payloads/utility.payload";
import {DefaultLayoutComponent} from "../../../../../../shared-component/default-layout/default-layout.component";
import {Activity} from "../../../../../../../payloads/activity.payload";
import {RestAPIService} from "../../../../../../../services/rest-api.service";
import {FileViewerComponent} from "../../../../../../shared-component/file-viewer/file-viewer.component";
import {Document} from "../../../../../../../payloads/document.payload";

@Component({
  selector: 'app-activities-portfolio-reports',
  templateUrl: './activities-portfolio-reports.component.html',
  styleUrls: ['./activities-portfolio-reports.component.scss']
})
export class ActivitiesProjectPortfolioReportsComponent implements OnInit, AfterViewInit {
  isLoaded: boolean = false;

  public idProject = Number(sessionStorage.getItem('project'));

  public project: Project;

  public activities: Activity[] = [];
  public activityOpers: ActivityOper[] = [];

  public activityDocs: ActivityDoc[] = [];

  constructor(private defaultLayout: DefaultLayoutComponent,
    private translate: TranslateService,
    private modalService: NgbModal,
    private router: Router,
    private restAPIService: RestAPIService) { }

  ngAfterViewInit(): void {
    setTimeout((): void => {
      this.defaultLayout.navItems = projectReportsNavItems(this.translate);
    }, 0);
  }

  ngOnInit(): void {
    if (this.idProject === 0) {
      this.router.navigate(['/project-portfolio/projects']);
    }

    this.restAPIService.fetchProject(this.idProject).subscribe((project: Project) => {
        this.project = project;

        this.defaultLayout.navItems = projectReportsNavItems(this.translate);
        this.defaultLayout.reloadBreadCrumb(this.project.abbr);

        if (this.project.activities.length > 0) {
          this.activities = [...this.project.activities];
          this.activities.sort((activity1: Activity, activity2: Activity) =>
              activity1.idactivity > activity2.idactivity ? 1 : -1,
          );

          this.activities.forEach((activity: Activity) => {
              if (activity.activityOpers.length > 0) {
                  this.activityOpers = [...activity.activityOpers];
                  this.activityOpers.sort((activityOper1: ActivityOper, activityOper2: ActivityOper) =>
                      (activityOper1.idactivityOper > activityOper2.idactivityOper) ? 1 : -1
                  );

                  const documents: Document[] = [];
                  this.activityOpers.forEach((activityOper: ActivityOper) => {
                      if (activityOper.document !== null) {
                          documents.push(activityOper.document);
                      }
                  });

                  if (documents.length > 0) {
                    this.activityDocs.push(
                      new ActivityDoc(activity.numb, activity.title, documents)
                    );
                  }
              }
          });
      }

    this.isLoaded = true;
      }
    );
  }

  view(idDocument: number) {
    this.modalService.open(FileViewerComponent,
      {
        windowClass: 'gr-modal-full',
        scrollable: false,
        backdrop: false,
        centered: true
      }).componentInstance.idDocument = idDocument;
  }
}
