<!DOCTYPE html>
<section class="animated fadeIn" *ngIf="isLoaded">
    <div class="modal-header d-flex justify-content-between">
        <h3 class="modal-title roboto-bold-font">{{ "inputs.indicator.label" | translate }}</h3>
        <span class="close fa fa-times text-red" aria-label="Close" (click)="closeModal()"></span>
    </div>
    <div class="modal-body text-black container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <ngb-alert #selfClosingAlert *ngIf="errorMessage" type="danger"
                           (closed)="errorMessage = ''">{{
                    errorMessage
                    }}</ngb-alert>

                <section>
                    <form [formGroup]="indicatorForm">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label class="control-label" for="name">{{ "inputs.entitle.label" | translate }}
                                        <span class="roboto-bold-font text-red">*</span></label>
                                    <input type="text" formControlName="name" class="form-control"
                                           [ngClass]="{ 'is-invalid': indicatorFormSubmitted && createIndicator.name.errors }"
                                           aria-label="name" aria-describedby="basic-user" id="name">
                                    <div *ngIf="indicatorFormSubmitted && createIndicator.name.errors" class="invalid-feedback">
                                        <div *ngIf="createIndicator.name.errors.required">{{
                                            "inputs.entitle.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <label class="control-label" for="unit_mesure">{{ "inputs.unit_mesure.label" | translate }}</label>
                                    <span class="roboto-bold-font text-red">*</span>
                                    <ng-select formControlName="unit_mesure" id="unit_mesure" [searchable]="true" [clearable]="false"
                                               [ngClass]="{ 'is-invalid': indicatorFormSubmitted && createIndicator.unit_mesure.errors }"
                                               placeholder="{{ 'inputs.unit_mesure.select-placeholders' | translate }}">
                                        <ng-option *ngFor="let unit_mesure of measuringUnits" [value]="unit_mesure">{{ unit_mesure | translate }}</ng-option>
                                    </ng-select>
                                    <div *ngIf="indicatorFormSubmitted && createIndicator.unit_mesure.errors" class="invalid-feedback">
                                        <div *ngIf="createIndicator.unit_mesure.errors.required">{{
                                            "inputs.unit_mesure.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <label class="control-label" for="calcul_method">{{ "inputs.calcul_method.label" | translate }}</label>
                                    <input type="text" formControlName="calcul_method" class="form-control"
                                           [ngClass]="{ 'is-invalid': indicatorFormSubmitted && createIndicator.calcul_method.errors }"
                                           aria-label="Number" aria-describedby="basic-user" id="calcul_method">
                                    <div *ngIf="indicatorFormSubmitted && createIndicator.calcul_method.errors" class="invalid-feedback">
                                        <div *ngIf="createIndicator.calcul_method.errors.required">{{
                                            "inputs.calcul_method.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <h5 class="roboto-bold-font">{{ "inputs.reference_situation.label" | translate }}</h5>
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="form-group">
                                            <label class="control-label" for="reference_year">{{ "inputs.year.label" | translate }}</label>
                                            <input type="number" formControlName="reference_year" class="form-control"
                                                    [ngClass]="{ 'is-invalid': indicatorFormSubmitted && createIndicator.reference_year.errors }"
                                                    aria-label="reference_year" aria-describedby="basic-user" id="reference_year">
                                            <div *ngIf="indicatorFormSubmitted && createIndicator.reference_year.errors" class="invalid-feedback">
                                                <div *ngIf="createIndicator.reference_year.errors.required">{{
                                                    "inputs.year.validations.required" |
                                                        translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="form-group">
                                            <label class="control-label" for="reference_value">{{ "inputs.value.label" | translate }}</label>
                                            <input type="text" formControlName="reference_value" class="form-control"
                                                    [ngClass]="{ 'is-invalid': indicatorFormSubmitted && createIndicator.reference_value.errors }"
                                                    aria-label="reference_value" aria-describedby="basic-user" id="reference_value">
                                            <div *ngIf="indicatorFormSubmitted && createIndicator.reference_value.errors" class="invalid-feedback">
                                                <div *ngIf="createIndicator.reference_value.errors.required">{{
                                                    "inputs.value.validations.required" |
                                                        translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <h5 class="roboto-bold-font">{{ "inputs.target.label" | translate }}</h5>
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="form-group">
                                            <label class="control-label" for="cible_year">{{ "inputs.year.label" | translate }}</label>
                                            <input type="number" formControlName="cible_year" class="form-control"
                                                    [ngClass]="{ 'is-invalid': indicatorFormSubmitted && createIndicator.cible_year.errors }"
                                                    aria-label="cible_year" aria-describedby="basic-user" id="cible_year">
                                            <div *ngIf="indicatorFormSubmitted && createIndicator.cible_year.errors" class="invalid-feedback">
                                                <div *ngIf="createIndicator.cible_year.errors.required">{{
                                                    "inputs.year.validations.required" |
                                                        translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="form-group">
                                            <label class="control-label" for="cible_value">{{ "inputs.value.label" | translate }}</label>
                                            <input type="text" formControlName="cible_value" class="form-control"
                                                    [ngClass]="{ 'is-invalid': indicatorFormSubmitted && createIndicator.cible_value.errors }"
                                                    aria-label="cible_value" aria-describedby="basic-user" id="cible_value">
                                            <div *ngIf="indicatorFormSubmitted && createIndicator.cible_value.errors" class="invalid-feedback">
                                                <div *ngIf="createIndicator.cible_value.errors.required">{{
                                                    "inputs.value.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <label class="control-label" for="infos_source">{{ "inputs.infos_source.label" | translate }}</label>
                                    <input type="text" formControlName="infos_source" class="form-control"
                                           [ngClass]="{ 'is-invalid': indicatorFormSubmitted && createIndicator.infos_source.errors }"
                                           aria-label="infos_source" aria-describedby="basic-user" id="infos_source">
                                    <div *ngIf="indicatorFormSubmitted && createIndicator.infos_source.errors" class="invalid-feedback">
                                        <div *ngIf="createIndicator.infos_source.errors.required">{{
                                            "inputs.infos_source.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <label class="control-label" for="info_collector">{{ "inputs.info_collector.label" | translate }}</label>
                                    <input type="text" formControlName="info_collector" class="form-control"
                                           [ngClass]="{ 'is-invalid': indicatorFormSubmitted && createIndicator.info_collector.errors }"
                                           aria-label="info_collector" aria-describedby="basic-user" id="info_collector">
                                    <div *ngIf="indicatorFormSubmitted && createIndicator.info_collector.errors" class="invalid-feedback">
                                        <div *ngIf="createIndicator.info_collector.errors.required">{{
                                            "inputs.info_collector.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <label class="control-label" for="collection_time">{{ "inputs.collection_time.label" | translate }}</label>
                                    <input type="time" formControlName="collection_time" class="form-control"
                                           [ngClass]="{ 'is-invalid': indicatorFormSubmitted && createIndicator.collection_time.errors }"
                                           aria-label="collection_time" aria-describedby="basic-user" id="collection_time">
                                    <div *ngIf="indicatorFormSubmitted && createIndicator.collection_time.errors" class="invalid-feedback">
                                        <div *ngIf="createIndicator.collection_time.errors.required">{{
                                            "inputs.collection_time.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <label class="control-label" for="info_holder">{{ "inputs.info_holder.label" | translate }}</label>
                                    <input type="text" formControlName="info_holder" class="form-control"
                                           [ngClass]="{ 'is-invalid': indicatorFormSubmitted && createIndicator.info_holder.errors }"
                                           aria-label="info_holder" aria-describedby="basic-user" id="info_holder">
                                    <div *ngIf="indicatorFormSubmitted && createIndicator.info_holder.errors" class="invalid-feedback">
                                        <div *ngIf="createIndicator.info_holder.errors.required">{{
                                            "inputs.info_holder.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <label class="control-label" for="info_interpreter">{{ "inputs.info_interpreter.label" | translate }}</label>
                                    <input type="text" formControlName="info_interpreter" class="form-control"
                                           [ngClass]="{ 'is-invalid': indicatorFormSubmitted && createIndicator.info_interpreter.errors }"
                                           aria-label="info_interpreter" aria-describedby="basic-user" id="info_interpreter">
                                    <div *ngIf="indicatorFormSubmitted && createIndicator.info_interpreter.errors" class="invalid-feedback">
                                        <div *ngIf="createIndicator.info_interpreter.errors.required">{{
                                            "inputs.info_interpreter.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <label class="control-label" for="periodicity">{{ "inputs.periodicity.label" | translate }}</label>
                                        <ng-select formControlName="periodicity" id="periodicity" [searchable]="true" [clearable]="false"
                                                    [ngClass]="{ 'is-invalid': indicatorFormSubmitted && createIndicator.periodicity.errors }"
                                                    placeholder="{{ 'inputs.periodicity.placeholders' | translate }}">
                                            <ng-option *ngFor="let periodicity of periodicities" [value]="periodicity">{{ periodicity | translate }}</ng-option>
                                        </ng-select>
                                    <div *ngIf="indicatorFormSubmitted && createIndicator.periodicity.errors" class="invalid-feedback">
                                        <div *ngIf="createIndicator.periodicity.errors.required">{{
                                            "inputs.periodicity.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <div class="form-group">
                                    <label class="control-label" for="infos_to_collect">{{ "inputs.infos_to_collect.label" | translate }}</label>
                                    <textarea type="text" formControlName="infos_to_collect" class="form-control" rows="10"
                                            aria-label="infos_to_collect" aria-describedby="basic-user" id="infos_to_collect"
                                            [ngClass]="{ 'is-invalid': indicatorFormSubmitted && createIndicator.infos_to_collect.errors }"></textarea>
                                    <div *ngIf="indicatorFormSubmitted && createIndicator.infos_to_collect.errors" class="invalid-feedback">
                                        <div *ngIf="createIndicator.infos_to_collect.errors.required">{{
                                            "inputs.infos_to_collect.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <div class="form-group">
                                    <label class="control-label" for="tools_to_use">{{ "inputs.tools_to_use.label" | translate }}</label>
                                    <textarea type="text" formControlName="tools_to_use" class="form-control" rows="10"
                                            aria-label="tools_to_use" aria-describedby="basic-user" id="tools_to_use"
                                            [ngClass]="{ 'is-invalid': indicatorFormSubmitted && createIndicator.tools_to_use.errors }"></textarea>
                                    <div *ngIf="indicatorFormSubmitted && createIndicator.tools_to_use.errors" class="invalid-feedback">
                                        <div *ngIf="createIndicator.tools_to_use.errors.required">{{
                                            "inputs.tools_to_use.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label class="control-label" for="way_to_collect">{{ "inputs.way_to_collect.label" | translate }}</label>
                                    <textarea type="text" formControlName="way_to_collect" class="form-control" rows="10"
                                        aria-label="way_to_collect" aria-describedby="basic-user" id="way_to_collect"
                                        [ngClass]="{ 'is-invalid': indicatorFormSubmitted && createIndicator.way_to_collect.errors }"></textarea>
                                    <div *ngIf="indicatorFormSubmitted && createIndicator.way_to_collect.errors" class="invalid-feedback">
                                        <div *ngIf="createIndicator.way_to_collect.errors.required">{{
                                            "inputs.way_to_collect.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row py-2">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end">
                                <button type="submit" (click)="onSubmit()"
                                        class="btn btn-pill bg-primary px-5">{{ "save" | translate }}</button>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </div>
    </div>
</section>
