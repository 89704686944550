import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {getLang, setLang} from '../../../_helpers';

@Component({
    selector: 'app-toggle-lang',
    templateUrl: './toggle-lang.component.html',
    styleUrls: ['./toggle-lang.component.scss']
})
export class ToggleLangComponent implements OnInit {
    selectedLanguage !: string;
    titlee: string;

    constructor(private readonly translate: TranslateService, public location: Location) {
        translate.onLangChange.subscribe((langChange) => {
            this.selectedLanguage = langChange.lang;
            setLang(langChange.lang);
        });
        const current = this.translate.currentLang;
        const lang = getLang();
        if (current) {
            this.selectedLanguage = current;
        } else if (lang === 'fr' || lang === 'en') {
            this.selectedLanguage = lang;
        } else {
            this.selectedLanguage = this.translate.defaultLang;
        }
        this.translate.use(this.selectedLanguage);
    }

    ngOnInit(): void {
        this.titlee = this.location.prepareExternalUrl(this.location.path()).slice(2);
    }

    onChangeLang(lang: string): void {
        this.translate.use(lang);
    }

    textColor(): any {
        let result: string = '';
        const links: string[] = ['sign-in', 'forgot-password', 'reset-password'];
        if (links.includes(this.titlee.split('/')[0])) {
            result += ' nav-link-white';
        } else {
            result += ' nav-link';
        }
        return result;
    }
}
