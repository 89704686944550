import {Document} from './document.payload';
import {Cashout} from './task.payload';

export class Contract {
    idcontract: number;
    numb: string;
    title: string;
    socialReason: string;
    uin: string;
    document: Document = null;
    rccm: string;
    judicialForm: string;
    phone: string;
    directorName: string;
    companyNationality: string;
    signatoryName: string;
    signatoryFunction: string;
    projectOwner: string;
    marketServiceChief: string;
    delProjectOwner: string;
    contractPayments: ContractPayment[];
    beneficiaryAccountNumb: string;
    bankingInstitution: string;
    deliverables: ContractDeliverable[];
    commissioners: Commissioner[];
    serviceOrders: ServiceOrder[];
    endorsements: Endorsement[];
    cashouts: Cashout[];
    cashoutComfirms: Cashout[];
    status: string;
    createdAt: string;
    updatedAt: string;

    constructor(numb: string, title: string, socialReason: string, uin: string, rccm: string, judicialForm: string,
                phone: string, directorName: string, companyNationality: string, signatoryName: string, signatoryFunction: string,
                projectOwner: string, marketServiceChief: string, delProjectOwner: string, beneficiaryAccountNumb: string,
                bankingInstitution: string, contractPayments: ContractPayment[], commissioners: Commissioner[],
                deliverables: ContractDeliverable[], document: Document = null, cashouts: Cashout[]) {
        this.numb = numb;
        this.title = title;
        this.socialReason = socialReason;
        this.uin = uin;
        this.rccm = rccm;
        this.judicialForm = judicialForm;
        this.phone = phone;
        this.directorName = directorName;
        this.companyNationality = companyNationality;
        this.signatoryName = signatoryName;
        this.signatoryFunction = signatoryFunction;
        this.projectOwner = projectOwner;
        this.marketServiceChief = marketServiceChief;
        this.delProjectOwner = delProjectOwner;
        this.contractPayments = contractPayments;
        this.beneficiaryAccountNumb = beneficiaryAccountNumb;
        this.bankingInstitution = bankingInstitution;
        this.commissioners = commissioners;
        this.deliverables = deliverables;
        this.document = document;
        this.cashouts = cashouts;
    }
}

export  class ContractPayment {
    idcontractPayment: number;
    amount: number;
    condition: string;
    paymentDate: string;
    document: Document = null;
    createdAt: string;
    updatedAt: string;
    observation: string;

    constructor(amount: number, condition: string) {
        this.amount = amount;
        this.condition = condition;
    }
}

export class CompletePayment {
    idcontractPayment: number;
    paymentDate: string;
    observation: string;
    document: Document = null;

    constructor(idcontractPayment: number, paymentDate: string, observation: string, document: Document) {
        this.idcontractPayment = idcontractPayment;
        this.paymentDate = paymentDate;
        this.observation = observation;
        this.document = document;
    }
}

export  class Commissioner {
    idcommissioner: number;
    name: string;
    fonction: string;
    createdAt: string;
    updatedAt: string;

    constructor(name: string, fonction: string) {
        this.name = name;
        this.fonction = fonction;
    }
}

export  class ContractDeliverable {
    idcontractDeliverable: number;
    title: string;
    description: string;
    amount: number;
    submissionLimit: number;
    submissionDate: string;
    validationLimit: number;
    validationDate: string;
    submittedDate: string;
    validatedDate: string;
    observation: string;
    document: Document = null;
    createdAt: string;
    updatedAt: string;

    constructor(title: string, description: string, amount: number, submissionLimit: number,  submissionDate: string,
                validationLimit: number, validationDate: string) {
        this.title = title;
        this.description = description;
        this.amount = amount;
        this.submissionLimit = submissionLimit;
        this.submissionDate = submissionDate;
        this.validationLimit = validationLimit;
        this.validationDate = validationDate;
    }
}

export class CompleteDeliverable {
    idcontractDeliverable: number;
    submittedDate: string;
    validatedDate: string;
    observation: string;
    document: Document = null;

    constructor(idcontractDeliverable: number, submittedDate: string, validatedDate: string, observation: string,
                document: Document = null) {
        this.idcontractDeliverable = idcontractDeliverable;
        this.submittedDate = submittedDate;
        this.validatedDate = validatedDate;
        this.observation = observation;
        this.document = document;
    }
}

export class ServiceOrder {
    idserviceOrder: number;
    title: string;
    orderType: string;
    signatureDate: string;
    receptionDate: string;
    document: Document = null;
    createdAt: string;
    updatedAt: string;

    constructor(title: string, orderType: string, signatureDate: string, receptionDate: string, document: Document = null) {
        this.title = title;
        this.orderType = orderType;
        this.signatureDate = signatureDate;
        this.receptionDate = receptionDate;
        this.document = document;
    }
}

export class Endorsement {
    idendorsement: number;
    numb: number;
    object: string;
    description: string;
    amount: number;
    date: string;
    document: Document = null;

    constructor(numb: number, object: string, description: string, amount: number, date: string, document: Document = null) {
        this.numb = numb;
        this.object = object;
        this.description = description;
        this.amount = amount;
        this.date = date;
        this.document = document;
    }
}

export class ContractFollowUp {
    serviceOrders: ServiceOrder[];
    endorsements: Endorsement[];
    deliverables: CompleteDeliverable[];
    payments: CompletePayment[];

    constructor(serviceOrders: ServiceOrder[], endorsements: Endorsement[], deliverables: CompleteDeliverable[],
                payments: CompletePayment[]) {
        this.serviceOrders = serviceOrders;
        this.endorsements = endorsements;
        this.deliverables = deliverables;
        this.payments = payments;
    }
}

export class CanceledContract {
    idcanceledContract: number;
    executedDate: string;
    document: Document = null;
    comment: string;
    project: number;
    createdAt: string;
    updatedAt: string;

    constructor(executedDate: string, document: Document, comment: string) {
        this.executedDate = executedDate;
        this.document = document;
        this.comment = comment;
    }
}
