<!DOCTYPE html>
<section class="sign-in-background">
  <app-header></app-header>

  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-5 col-xs-1"></div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-10">
        <div class="card animated fadeInDown">
          <div class="card-body">
            <div class="roboto-bold-font">
              <h1 class="h1">{{ "forgot_password" | translate }}</h1>
            </div>

            <div class="pt-4">
              <ngb-alert #selfClosingAlert *ngIf="errorMessage" type="danger" (closed)="errorMessage = ''">{{ errorMessage }}</ngb-alert>
              <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-email">&#64;</span>
                    </div>
                    <input type="email" formControlName="email" class="form-control"
                      [ngClass]="{ 'is-invalid': forgotPasswordSubmitted && forgot.email.errors }" placeholder="{{ 'inputs.email.label' | translate }}"
                      aria-label="Email" aria-describedby="basic-email" required>
                    <div *ngIf="forgotPasswordSubmitted && forgot.email.errors" class="invalid-feedback">
                      <div *ngIf="forgot.email.errors.required">{{ "inputs.email.validations.required" | translate }}</div>
                      <div *ngIf="forgot.email.errors.email">{{ "inputs.email.validations.valid" | translate }}</div>
                    </div>
                  </div>
                </div>

                <div class="row pt-2">
                  <button type="submit" class="btn btn-pill btn-block bg-warning">{{ "send" | translate }}</button>
                </div>

                <div class="row pt-2 d-flex justify-content-between">
                    <p>{{ "remember_your_password" | translate }}</p>
                    <strong [routerLink]="['/sign-in']" class="strong btn-link pull-right roboto-bold-font">{{ "sign_in" | translate }}</strong>
                </div>
              </form>
            </div>
            <div class="row pt-4">
              <p class="roboto-bold-font">{{ "unauthorized_use_is_prohibited" | translate }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
    </div>
  </div>
</section>
