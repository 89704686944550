import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {Document} from '../../../../../payloads/document.payload';
import {Role} from '../../../../../payloads/role.payload';
import {User} from '../../../../../payloads/user.payload';
import {NotificationFlashService} from '../../../../../services/notification-flash.service';
import {RestAPIService} from '../../../../../services/rest-api.service';
import {reloadComponent} from '../../../../../_helpers';

@Component({
    selector: 'app-create-user',
    templateUrl: './create-user.component.html',
    styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
    isLoaded = false;
    userForm: UntypedFormGroup;
    user: User;
    roles: Role[] = [];
    userFormSubmitted = false;
    document: Document = null;
    imgSrc: string = '../../../../../../assets/img/default-avatar.jpeg';
    langs: any[] = [
        {
            abbr: 'fr',
            name: 'french'
        },
        {
            abbr: 'en',
            name: 'english'
        }
    ];

    @Input() public isCoordinator: boolean = false;
    staticAlertClosed = false;
    errorMessage: string;
    @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;
    private _danger = new Subject<string>();

    constructor(private formBuilder: UntypedFormBuilder, private router: Router, private notification: NotificationFlashService,
                private translate: TranslateService, private restAPIService: RestAPIService, private activeModal: NgbActiveModal) {
    }

    get createForm() {
        return this.userForm.controls;
    }

    ngOnInit(): void {
        this._danger.subscribe(message => this.errorMessage = message);
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.restAPIService.fetchRoles().subscribe((userRoles: Role[]) => {
            userRoles.forEach((role: Role) => {
                if (!role.forProject) {
                    this.roles.push(role);
                } else if (role.forProject && !role.privilege.canReadActivity && !role.privilege.canReadMarket && !role.privilege.canReadFollow) {
                    this.roles.push(role);
                }

                this.roles.sort((role1: Role, role2: Role) =>
                    role1.name > role2.name ? 1 : -1
                );
            });

            this.isLoaded = true;

            this.initForm();
        });
    }

    initForm() {
        if (this.isCoordinator) {
            this.userForm = this.formBuilder.group({
                file: [null],
                name: [null, [Validators.required]],
                surname: [null],
                dob: [null],
                pob: [null],
                email: [null, [Validators.required, Validators.email]],
                phone: [null],
                username: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
                idrole: [null],
                lang: ['fr', [Validators.required]]
            });
        } else {
            this.userForm = this.formBuilder.group({
                file: [null],
                name: [null, [Validators.required]],
                surname: [null],
                dob: [null],
                pob: [null],
                email: [null, [Validators.required, Validators.email]],
                phone: [null],
                username: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
                idrole: [null, [Validators.required]],
                lang: ['fr', [Validators.required]]
            });
        }
    }

    onFileSelect(event: any) {
        const file: File = event.target.files[0];

        if (file) {
            // File Preview
            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = (read: any) => {
                this.imgSrc = reader.result as string;
                this.document = new Document(null, file.name, file.type, read.target.result);
            };
        }
    }

    onSubmit() {
        this.userFormSubmitted = true;
        if (this.userForm.invalid) {
            return;
        }

        const user = new User().create(
            this.userForm.value.name?.trim(),
            this.userForm.value.surname?.trim(),
            this.userForm.value.dob,
            this.userForm.value.pob,
            this.userForm.value.email?.trim(),
            this.userForm.value.phone,
            this.userForm.value.username?.trim(),
            this.userForm.value.lang?.trim(),
            this.userForm.value.idrole,
            this.document
        );

        this.restAPIService.createUser(user).subscribe(() => {
            this.notification.success(
                this.translate.instant('notifications.success.user-created'),
            );

            this.closeModal();

            reloadComponent(this.router);
        }, (error) => {
            console.error(error);

            this.errorMessage = this.translate.instant(
                'notifications.danger.user-not-created',
            );
        });
    }

    closeModal() {
        this.activeModal.close();
    }
}
