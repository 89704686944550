import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Role} from '../../../../../payloads/role.payload';
import {Subject} from 'rxjs';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {NotificationFlashService} from '../../../../../services/notification-flash.service';
import {TranslateService} from '@ngx-translate/core';
import {RestAPIService} from '../../../../../services/rest-api.service';
import {debounceTime} from 'rxjs/operators';
import {Privilege} from '../../../../../payloads/privilege.payload';
import {UtilityService} from '../../../../../services/utility.service';
import {reloadComponent} from '../../../../../_helpers';

@Component({
    selector: 'app-update-role',
    templateUrl: './update-role.component.html',
    styleUrls: ['./update-role.component.scss']
})
export class UpdateRoleComponent implements OnInit {
    isLoaded = false;
    validateLevels: string[] = [];
    canValidate: boolean = false;
    canReEvaluate: boolean = false;
    canReValidate: boolean = false;

    roleForm: UntypedFormGroup;
    roleFormSubmitted = false;
    @Input() public updateRole;
    role: Role;
    roles: Role[];
    staticAlertClosed = false;
    errorMessage: string;
    @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;
    private _danger = new Subject<string>();

    constructor(private formBuilder: UntypedFormBuilder, private router: Router, private notification: NotificationFlashService,
                private translate: TranslateService, private restAPIService: RestAPIService, private activeModal: NgbActiveModal,
                private utilityService: UtilityService) {
    }

    get updateForm() {
        return this.roleForm.controls;
    }

    ngOnInit(): void {
        this._danger.subscribe((message) => (this.errorMessage = message));
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.role = this.updateRole.role;
        this.validateLevels = this.utilityService.fecthValidateLevels();
        if (this.updateRole.rolesToEvaluate !== null) {
            this.roles = this.updateRole.rolesToEvaluate;
            this.roles.sort((role1: Role, role2: Role) =>
                role1.name > role2.name ? 1 : -1
            );
        }

        this.onValidateChange(this.role.privilege.canValidate);
        this.onReEvaluateChange(this.role.privilege.canReEvaluate);
        this.onReValidateChange(this.role.privilege.canReValidate);

        this.initForm();

        this.isLoaded = true;
    }

    initForm() {
        let reEvaluateRoles = null;
        if (this.role.privilege.reEvaluateRoles !== null) {
            reEvaluateRoles = this.role.privilege.reEvaluateRoles.split(',').map(Number);
        }
        let reValidateRoles = null;
        if (this.role.privilege.reValidateRoles !== null) {
            reValidateRoles = this.role.privilege.reValidateRoles.split(',').map(Number);
        }
        this.roleForm = this.formBuilder.group({
            name: [this.role.name, [Validators.required]],
            code: [this.role.code, [Validators.required]],
            forProject: [this.role.forProject],
            canCreateActivity: [this.role.privilege.canCreateActivity],
            canReadActivity: [this.role.privilege.canReadActivity],
            canUpdateActivity: [this.role.privilege.canUpdateActivity],
            canDeleteActivity: [this.role.privilege.canDeleteActivity],
            canCreateMarket: [this.role.privilege.canCreateMarket],
            canReadMarket: [this.role.privilege.canReadMarket],
            canUpdateMarket: [this.role.privilege.canUpdateMarket],
            canDeleteMarket: [this.role.privilege.canDeleteMarket],
            canCreateFollow: [this.role.privilege.canCreateFollow],
            canReadFollow: [this.role.privilege.canReadFollow],
            canUpdateFollow: [this.role.privilege.canUpdateFollow],
            canDeleteFollow: [this.role.privilege.canDeleteFollow],
            canValidate: [this.role.privilege.canValidate],
            validateLevel: [this.role.privilege.validateLevel],
            canReEvaluate: [this.role.privilege.canReEvaluate],
            reEvaluateRoles: [reEvaluateRoles],
            canReValidate: [this.role.privilege.canReValidate],
            reValidateRoles: [reValidateRoles]
        });
    }

    onValidateChange(event: boolean) {
        this.canValidate = !event;
    }

    onSubmit() {
        this.roleFormSubmitted = true;
        if (this.roleForm.invalid) {
            return;
        }

        let reEvaluateRoles = null;
        if (this.roleForm.value.canReEvaluate && (this.roleForm.value.reEvaluateRoles !== null ||
            this.roleForm.value.reEvaluateRoles !== '' || this.roleForm.value.reEvaluateRoles !== '[]' ||
            this.roleForm.value.reEvaluateRoles !== undefined) && this.roleForm.value.reEvaluateRoles.length > 0) {
            reEvaluateRoles = this.roleForm.value.reEvaluateRoles.toString();
        }

        let reValidateRoles = null;
        if (this.roleForm.value.canReValidate && (this.roleForm.value.reValidateRoles !== null ||
            this.roleForm.value.reValidateRoles !== '' || this.roleForm.value.reValidateRoles !== '[]' ||
            this.roleForm.value.reValidateRoles !== undefined) && this.roleForm.value.reValidateRoles.length > 0) {
            reValidateRoles = this.roleForm.value.reValidateRoles.toString();
        }

        const role: Role = new Role(
            this.roleForm.value.name,
            this.roleForm.value.code,
            new Privilege(
                this.roleForm.value.canCreateActivity,
                this.roleForm.value.canReadActivity,
                this.roleForm.value.canUpdateActivity,
                this.roleForm.value.canDeleteActivity,
                this.roleForm.value.canCreateMarket,
                this.roleForm.value.canReadMarket,
                this.roleForm.value.canUpdateMarket,
                this.roleForm.value.canDeleteMarket,
                this.roleForm.value.canCreateFollow,
                this.roleForm.value.canReadFollow,
                this.roleForm.value.canUpdateFollow,
                this.roleForm.value.canDeleteFollow,
                this.roleForm.value.canValidate,
                this.roleForm.value.canReEvaluate,
                reEvaluateRoles,
                this.roleForm.value.canReValidate,
                reValidateRoles,
                this.roleForm.value.validateLevel
            ),
            this.roleForm.value.forProject
        );

        this.restAPIService.updateRole(this.role.idrole, role).subscribe(
            () => {
                this.notification.success(
                    this.translate.instant('notifications.success.role-modified')
                );

                this.closeModal();

                reloadComponent(this.router);
            },
            (error) => {
                console.error(error);

                this.errorMessage = this.translate.instant(
                    'notifications.danger.role-not-modified'
                );
            }
        );
    }

    closeModal() {
        this.activeModal.close();
    }

    onReEvaluateChange(event: boolean) {
        this.canReEvaluate = !event;
    }

    onReValidateChange(event: boolean) {
        this.canReValidate = !event;
    }
}
