<!DOCTYPE html>
<section class="animated fadeIn" *ngIf="isLoaded">
    <div class="modal-header d-flex justify-content-between">
        <h3 class="modal-title roboto-bold-font">{{ "market" | translate }} : {{ market.numb }}</h3>
        <span class="close fa fa-times text-red" aria-label="Close" (click)="closeModal()"></span>
    </div>
    <div class="modal-body text-black container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <ngb-alert #selfClosingAlert *ngIf="errorMessage" type="danger"
                           (closed)="errorMessage = ''">{{
                    errorMessage
                    }}</ngb-alert>

                <section>
                    <h3 class="roboto-bold-font">{{ "procurement-programming" | translate }}</h3>
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="table-responsive">
                                <table class="table table-hover table-striped">
                                    <caption></caption>
                                    <thead class="thead-dark">
                                    <tr>
                                        <th class="nowrap">{{ "follow-up-procurement" | translate }}</th>
                                        <th class="text-center" [colSpan]="colspan">{{ "planned-date" | translate }}</th>
                                        <th class="text-center nowrap">{{ "new-planned-date" | translate }}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let procurement of procurements; let i = index;">
                                        <td class="nowrap">{{ procurement.title }}</td>
                                        <td class="text-center" *ngFor="let procurementDate of procurement.procDates; let i = index;">
                                            {{ dateFormat(procurementDate.plannedDate) }}
                                        </td>
                                        <td class="text-center nowrap">
                                            <input [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [daysDisabled]="[7,0]"
                                                   type="text" bsDatepicker class="form-control" placeholder="{{ 'date-format' | translate }}"
                                                   [minDate]="formatBSDate(procurement.plannedDate)"
                                                   [maxDate]="formatBSDate(market.endDate)" [disabled]="procurement.executedDate"
                                                   (bsValueChange)="changeProcurementPlannedDate($event, i)">
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="row py-2">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end">
                            <button type="submit" (click)="onSubmit()" [hidden]="hideSave"
                                    class="btn btn-pill bg-primary px-5 text-right">{{ "save" | translate }}</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>
