import {Document} from './document.payload';

export class FinanceMobil {
    idfinanceMobil: number;
    title: string;
    financeMobilDate: FinanceMobilDate;
    financeMobilDates: FinanceMobilDate[];
    acceptFile: boolean;
    acceptComment: boolean;
    executedDate: string;
    document: Document = null;
    comment: string;
    project: number;
    createdAt: string;
    updatedAt: string;

    plannedDate: any;

    constructor(title: string, financeMobilDate: FinanceMobilDate, acceptComment: boolean = true, acceptFile: boolean = true) {
        this.title = title;
        this.financeMobilDate = financeMobilDate;
        this.acceptComment = acceptComment;
        this.acceptFile = acceptFile;
    }
}

export class FinanceMobilDate {
    idfinanceMobilDate: number;
    plannedDate: string;
    financeMobil: number;
    createdAt: string;
    updatedAt: string;

    constructor(plannedDate: string) {
        this.plannedDate = plannedDate;
    }
}

export class CompleteFinanceMobil {
    idfinanceMobil: number;
    executedDate: string;
    document: Document = null;
    comment: string;

    constructor(idfinanceMobil: number, executedDate: string, document: Document, comment: string) {
        this.idfinanceMobil = idfinanceMobil;
        this.executedDate = executedDate;
        this.document = document;
        this.comment = comment;
    }
}
