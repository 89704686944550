<!DOCTYPE html>
<section class="animated fadeIn" *ngIf="isLoaded">
    <div class="modal-header d-flex justify-content-between">
        <h3 class="modal-title roboto-bold-font">{{ "market" | translate }} : {{ market.numb }}</h3>
        <span class="close fa fa-times text-red" aria-label="Close" (click)="closeModal()"></span>
    </div>
    <div class="modal-body text-black container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <ngb-alert #selfClosingAlert *ngIf="errorMessage" type="danger"
                           (closed)="errorMessage = ''">{{errorMessage}}</ngb-alert>

                <section [hidden]="slide1">
                    <form [formGroup]="slide1Form">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-5 col-sm-5">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="numb">{{ "inputs.reference-number.label" | translate }}
                                        <span class="roboto-bold-font text-red">*</span></label>
                                    <input type="text" formControlName="numb"
                                           class="form-control text-right roboto-bold-font"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.numb.errors }"
                                           aria-label="Number" aria-describedby="basic-user" id="numb">
                                    <div *ngIf="slide1FormSubmitted && csrForm.numb.errors" class="invalid-feedback">
                                        <div *ngIf="csrForm.numb.errors.required">{{
                                            "inputs.reference-number.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-8 col-lg-8 col-md-7 col-sm-7">
                                <div class="form-group">
                                    <label class="control-label" for="title">{{ "inputs.entitle.label" | translate }}
                                        <span class="roboto-bold-font text-red">*</span></label>
                                    <input type="text" formControlName="title" class="form-control"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.title.errors }"
                                           aria-label="Title" aria-describedby="basic-user" id="title">
                                    <div *ngIf="slide1FormSubmitted && csrForm.title.errors" class="invalid-feedback">
                                        <div *ngIf="csrForm.title.errors.required">{{
                                            "inputs.entitle.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="description">{{ "inputs.description.label" | translate }}</label>
                                    <textarea formControlName="description" class="form-control"
                                              aria-label="Description" rows="5"
                                              [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.description.errors }"
                                              aria-describedby="basic-user" id="description"></textarea>
                                    <div *ngIf="slide1FormSubmitted && csrForm.description.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="csrForm.description.errors.required">{{
                                            "inputs.description.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xl-6 col-lg-6">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="activity">{{"inputs.related_activity.label" | translate }}
                                        <span class="roboto-bold-font text-red">*</span></label>
                                    <ng-select formControlName="activity" id="activity" [searchable]="true"
                                               [clearable]="true" (change)="onActivityChange($event)"
                                               [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.activity.errors }"
                                               placeholder="{{ 'inputs.related_activity.placeholders' | translate }}">
                                        <ng-option *ngFor="let activity of activities"
                                                   [value]="activity.idactivity">{{ activity.numb }}
                                            : {{ activity.title }}</ng-option>
                                    </ng-select>
                                    <div *ngIf="slide1FormSubmitted && csrForm.activity.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="csrForm.activity.errors.required">{{
                                            "inputs.related_activity.validations.required" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="task">{{ "inputs.related_task.label" | translate }}
                                        <span class="roboto-bold-font text-red">*</span></label>
                                    <ng-select formControlName="task" id="task" [searchable]="true" [clearable]="true"
                                               [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.task.errors }"
                                               placeholder="{{ 'inputs.related_task.placeholders' | translate }}"
                                               (change)="onTaskChange($event)">
                                        <ng-option *ngFor="let task of tasks" [disabled]="setDisabled(task)"
                                                   [value]="task.idtask">{{ task.numb }} : {{ task.title }}</ng-option>
                                    </ng-select>
                                    <div *ngIf="slide1FormSubmitted && csrForm.task.errors" class="invalid-feedback">
                                        <div *ngIf="csrForm.task.errors.required">{{
                                            "inputs.related_task.validations.required" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h4 class="py-2 roboto-bold-font">{{ "market_over_all_amount" | translate }}</h4>
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="amount">{{ "inputs.amount_in_currency.label" | translate }}
                                        <span class="roboto-bold-font text-red">*</span></label>
                                    <div class="input-group mb-3">
                                        <input type="number" formControlName="amount" min="0"
                                               class="form-control text-right roboto-bold-font" id="amount"
                                               readonly="true"
                                               [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.amount.errors }"
                                               aria-describedby="basic-user" aria-label="Amount">
                                        <div class="input-group-append">
                                            <ng-select formControlName="currency" id="currency"
                                                       placeholder="Dévise" readonly="true"
                                                       [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.currency.errors }"
                                                       [searchable]="true" [clearable]="false">
                                                <ng-option *ngFor="let devise of devises"
                                                           [value]="devise">{{ devise }}</ng-option>
                                            </ng-select>
                                            <div *ngIf="slide1FormSubmitted && csrForm.currency.errors"
                                                 class="invalid-feedback">
                                                <div
                                                    *ngIf="csrForm.currency.errors.required">{{ "inputs.currency.validations.required" | translate }}</div>
                                            </div>
                                        </div>
                                        <div *ngIf="slide1FormSubmitted && csrForm.amount.errors"
                                             class="invalid-feedback">
                                            <div
                                                *ngIf="csrForm.amount.errors.required">{{ "inputs.amount.validations.required" | translate }}</div>
                                        </div>
                                        <div *ngIf="errorMessage" class="invalid-feedback">
                                            <div>{{ errorMessage }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="form-group">
                                    <label class="control-label" for="exchangeRate">{{
                                        "inputs.exchangeRate.label" | translate }} ({{ "at_signature" | translate }}
                                        )</label>
                                    <input type="number" min="0" formControlName="exchangeRate" readonly="true"
                                           class="form-control" aria-label="Exchange Rate" aria-describedby="basic-user"
                                           id="exchangeRate">
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="amount">{{ "inputs.amount_in_fcfa.label" | translate }}
                                        <span class="roboto-bold-font text-red">*</span></label>
                                    <div class="input-group mb-3">
                                        <input type="number" formControlName="amountFcfa"
                                               class="form-control text-right roboto-bold-font"
                                               [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.amountFcfa.errors }"
                                               min="0"
                                               aria-label="Amount" aria-describedby="basic-user" id="amountFcfa"
                                               (input)="checkAmount($event.target.value)">
                                        <div class="input-group-append"><span class="input-group-text" id="basic-user1">FCFA</span>
                                        </div>
                                        <div *ngIf="slide1FormSubmitted && csrForm.amount.errors"
                                             class="invalid-feedback">
                                            <div *ngIf="csrForm.amountFcfa.errors.required">{{
                                                "inputs.amount.validations.required" |
                                                    translate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h5 class="py-2 roboto-bold-font">{{ "financing-source" | translate }}</h5>
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="form-group">
                                    <label class="control-label" for="fcpRate">{{ "inputs.fcpRate.label" |
                                        translate }}</label>
                                    <div class="input-group mb-3">
                                        <input type="number" max="100" formControlName="fcpRate"
                                               class="form-control" aria-label="FCP Rate"
                                               (input)="checkRate($event.target)"
                                               [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.fcpRate.errors }"
                                               min="0"
                                               aria-describedby="basic-user" id="fcpRate">
                                        <div class="input-group-append">
                                            <span class="input-group-text" id="basic-user2"><em class="fa fa-percent"
                                                                                                aria-hidden="true"></em></span>
                                        </div>
                                        <div *ngIf="slide1FormSubmitted && csrForm.fcpRate.errors"
                                             class="invalid-feedback">
                                            <div
                                                *ngIf="csrForm.fcpRate.errors.required">{{ "inputs.fcpRate.validations.required" | translate }}</div>
                                            <div
                                                *ngIf="csrForm.fcpRate.errors.min">{{ "inputs.fcpRate.validations.min" | translate: {'size': '0'} }}</div>
                                            <div
                                                *ngIf="csrForm.fcpRate.errors.max">{{ "inputs.fcpRate.validations.max" | translate: {'size': '100'} }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="form-group">
                                    <label class="control-label" for="fcpYear">{{ "inputs.fcpYear.label" |
                                        translate }}</label>
                                    <input type="number" max="{{ max_year }}" formControlName="fcpYear"
                                           class="form-control"
                                           aria-label="FCP Year" aria-describedby="basic-user" id="fcpYear"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.fcpYear.errors }">
                                    <div *ngIf="slide1FormSubmitted && csrForm.fcpYear.errors" class="invalid-feedback">
                                        <div
                                            *ngIf="csrForm.fcpYear.errors.required">{{ "inputs.fcpYear.validations.required" | translate }}</div>
                                        <div
                                            *ngIf="csrForm.fcpYear.errors.min">{{ "inputs.fcpYear.validations.min" | translate: {'size': min_year} }}</div>
                                        <div
                                            *ngIf="csrForm.fcpYear.errors.max">{{ "inputs.fcpYear.validations.max" | translate: {'size': max_year} }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4">
                                <div class="form-group">
                                    <label class="control-label" for="fcpBudgetLine">{{
                                        "inputs.fcpBudgetLine.label" | translate }}</label>
                                    <input type="text" formControlName="fcpBudgetLine" class="form-control"
                                           aria-label="FCP Budgetary Line" aria-describedby="basic-user"
                                           id="fcpBudgetLine" min="0"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.fcpBudgetLine.errors }">
                                    <div *ngIf="slide1FormSubmitted && csrForm.fcpBudgetLine.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="csrForm.fcpBudgetLine.errors.required">{{
                                            "inputs.fcpBudgetLine.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="form-group">
                                    <label class="control-label" for="lessorRate">{{ "inputs.lessorRate.label" |
                                        translate }}</label>
                                    <div class="input-group mb-3">
                                        <input type="number" max="100" formControlName="lessorRate"
                                               class="form-control" aria-label="Lessor Rate"
                                               (input)="checkRate($event.target)"
                                               [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.lessorRate.errors }"
                                               min="0"
                                               aria-describedby="basic-user" id="lessorRate">
                                        <div class="input-group-append">
                                            <span class="input-group-text" id="basic-user"><em class="fa fa-percent"
                                                                                               aria-hidden="true"></em></span>
                                        </div>
                                        <div *ngIf="slide1FormSubmitted && csrForm.lessorRate.errors"
                                             class="invalid-feedback">
                                            <div
                                                *ngIf="csrForm.lessorRate.errors.required">{{ "inputs.lessorRate.validations.required" | translate }}</div>
                                            <div
                                                *ngIf="csrForm.lessorRate.errors.min">{{ "inputs.lessorRate.validations.min" | translate: {'size': '0'} }}</div>
                                            <div
                                                *ngIf="csrForm.lessorRate.errors.max">{{ "inputs.lessorRate.validations.max" | translate: {'size': '100'} }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="form-group">
                                    <label class="control-label" for="lessorYear">{{ "inputs.lessorYear.label" |
                                        translate }}</label>
                                    <input type="number" max="{{ max_year }}" formControlName="lessorYear"
                                           class="form-control"
                                           aria-label="Lessor Year" aria-describedby="basic-user" id="lessorYear"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.lessorYear.errors }">
                                    <div *ngIf="slide1FormSubmitted && csrForm.lessorYear.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="csrForm.lessorYear.errors.required">{{
                                            "inputs.lessorYear.validations.required" |
                                                translate }}
                                        </div>
                                        <div
                                            *ngIf="csrForm.lessorYear.errors.min">{{ "inputs.lessorYear.validations.min" | translate: {'size': min_year} }}</div>
                                        <div
                                            *ngIf="csrForm.lessorYear.errors.max">{{ "inputs.lessorYear.validations.max" | translate: {'size': max_year} }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4">
                                <div class="form-group">
                                    <label class="control-label" for="lessorBudgetLine">{{
                                        "inputs.lessorBudgetLine.label" | translate }}</label>
                                    <input type="text" formControlName="lessorBudgetLine" class="form-control"
                                           aria-label="FCP Budgetary Line" aria-describedby="basic-user"
                                           id="lessorBudgetLine" min="0"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.lessorBudgetLine.errors }">
                                    <div *ngIf="slide1FormSubmitted && csrForm.lessorBudgetLine.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="csrForm.lessorBudgetLine.errors.required">{{
                                            "inputs.lessorBudgetLine.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div class="row">
                        <div
                            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end">
                            <a class="btn btn-pill bg-green px-5" (click)="changeSlide(2)">{{ "next" | translate }}</a>
                        </div>
                    </div>
                </section>

                <section [hidden]="slide2">
                    <form [formGroup]="slide2Form">
                        <div class="row">
                            <div class="col-xl-3 col-lg-3 col-md-3">
                                <div class="form-group">
                                    <label class="control-label" for="deadLine">{{ "inputs.deadLine.label" | translate}}
                                        <span class="roboto-bold-font text-red">*</span></label>
                                    <div class="input-group mb-3">
                                        <input type="number" formControlName="deadLine" class="form-control"
                                               aria-label="Deadline" aria-describedby="basic-user"
                                               id="deadLine"
                                               [ngClass]="{ 'is-invalid': slide2FormSubmitted && dForm.deadLine.errors }">
                                        <div class="input-group-append">
                                            <span class="input-group-text"
                                                  id="submission">{{ "days" | translate }}</span>
                                        </div>
                                        <div *ngIf="slide2FormSubmitted && dForm.deadLine.errors"
                                             class="invalid-feedback">
                                            <div *ngIf="dForm.deadLine.errors.required">{{
                                                "inputs.deadLine.validations.required" | translate }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-5 col-lg-5 col-md-5">
                                <div class="form-group">
                                    <label class="control-label" for="marketType">{{
                                        "inputs.market-type.label" | translate }}
                                        <span class="roboto-bold-font text-red">*</span></label>
                                    <ng-select formControlName="marketType" id="marketType"
                                               (change)="onMethodChange($event)"
                                               [searchable]="true" [clearable]="false"
                                               [ngClass]="{ 'is-invalid': slide2FormSubmitted && dForm.marketType.errors }"
                                               placeholder="{{ 'inputs.market-type.placeholders' | translate }}">
                                        <ng-option *ngFor="let marketType of marketTypes; let i = index"
                                                   [value]="i">{{ marketType.type | translate }}</ng-option>
                                    </ng-select>
                                    <div *ngIf="slide2FormSubmitted && dForm.marketType.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="dForm.marketType.errors.required">{{
                                            "inputs.market-type.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label class="control-label" for="method">{{
                                        "inputs.procurement-method.label" | translate }}</label>
                                    <ng-select formControlName="method" id="method" [searchable]="true"
                                               [clearable]="false"
                                               [ngClass]="{ 'is-invalid': slide2FormSubmitted && dForm.method.errors }"
                                               placeholder="{{ 'inputs.procurement-method.placeholders' | translate }}">
                                        <ng-option *ngFor="let method of methods" [value]="method">{{
                                            method | translate }}</ng-option>
                                    </ng-select>
                                    <div *ngIf="slide2FormSubmitted && dForm.method.errors" class="invalid-feedback">
                                        <div *ngIf="dForm.method.errors.required">{{
                                            "inputs.procurement-method.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <h4 class="roboto-bold-font pt-2">{{ "procurement-programming" | translate }}</h4>
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="table-responsive">
                                <table class="table table-hover table-striped">
                                    <caption></caption>
                                    <thead class="thead-dark">
                                    <tr>
                                        <th class="nowrap">{{ "inputs.entitle.label" | translate }}</th>
                                        <th class="text-center">{{ "inputs.plannedDate.label" | translate }}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let procurement of procurements; let i = index;">
                                        <td class="nowrap">{{ procurement.title }}</td>
                                        <td class="text-center">
                                            <input type="text" bsDatepicker
                                                   [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                                                   [daysDisabled]="[7,0]" placeholder="{{ 'date-format' | translate }}"
                                                   [minDate]="startDate"
                                                   [maxDate]="endDate" class="form-control"
                                                   [bsValue]="formatBSDate(procurement.plannedDate)"
                                                   (bsValueChange)="changeProcurementPlannedDate($event, i)">
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="row py-2">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-between">
                            <a class="btn btn-pill bg-warning px-5"
                               (click)="changeSlide(1)">{{ "previous" | translate }}</a>
                            <a class="btn btn-pill bg-green px-5" (click)="changeSlide(3)">{{ "next" | translate }}</a>
                        </div>
                    </div>
                </section>

                <section [hidden]="slide3">
                    <app-cashout (cashoutsUpdated)="onCashoutsUpdated($event)" [task]="cashOutTask"
                                 [exchangeRate]="slide1Form.get('exchangeRate').value" [market]="market"
                                 [marketAmountFCFA]="slide1Form.get('amountFcfa').value" [project]="project">
                    </app-cashout>

                    <div class="row py-2">
                        <div
                            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-between">
                            <a (click)="changeSlide(2)"
                               class="btn btn-pill bg-warning px-5">{{ "previous" | translate }}</a>
                            <button (click)="onSubmit()" class="btn btn-pill bg-primary px-5"
                                    type="submit">{{ "save" | translate }}</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>
