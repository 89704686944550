<!DOCTYPE html>
<section class="animated fadeIn" *ngIf="isLoaded">
    <div class="modal-header d-flex justify-content-between">
        <h3 class="modal-title roboto-bold-font">{{ "activities" | translate }} : {{ activity.numb }}</h3>
        <span class="close fa fa-times text-red" aria-label="Close" (click)="closeModal()"></span>
    </div>
    <div class="modal-body text-black container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 forms">
                <ngb-alert [dismissible] = "true" *ngIf="errorMessage" type="danger" (closed)="errorMessage = ''">{{ errorMessage }}</ngb-alert>

                <section [hidden]="slide1">
                    <form [formGroup]="slide1Form">
                        <div class="row">
                            <div class="col-xl-3 col-lg-3">
                                <div class="form-group">
                                    <label class="control-label" for="numb">{{ "inputs.numb.label" | translate }}
                                        <span class="roboto-bold-font text-red">*</span></label>
                                    <input type="text" formControlName="numb" disabled="true"
                                        class="form-control text-right roboto-bold-font"
                                        [ngClass]="{ 'is-invalid': slide1FormSubmitted && irForm.numb.errors }"
                                        aria-label="Number" aria-describedby="basic-user" id="numb">
                                    <div *ngIf="slide1FormSubmitted && irForm.numb.errors" class="invalid-feedback">
                                        <div *ngIf="irForm.numb.errors.required">{{
                                            "inputs.numb.validations.required" |
                                            translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-7 col-lg-7">
                                <div class="form-group">
                                    <label class="control-label" for="title">{{ "inputs.entitle.label" | translate }}
                                        <span class="roboto-bold-font text-red">*</span></label>
                                    <input type="text" formControlName="title" class="form-control" disabled="true"
                                        [ngClass]="{ 'is-invalid': slide1FormSubmitted && irForm.title.errors }"
                                        aria-label="Title" aria-describedby="basic-user" id="title1">
                                    <div *ngIf="slide1FormSubmitted && irForm.title.errors" class="invalid-feedback">
                                        <div *ngIf="irForm.title.errors.required">{{
                                            "inputs.entitle.validations.required" |
                                            translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-2">
                                <div class="form-group">
                                    <label class="control-label" for="date">{{ "inputs.date.label" | translate }}</label>
                                    <input type="text" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [daysDisabled]="[7,0]"
                                           placeholder="{{ 'date-format' | translate }}" formControlName="date" id="date" disabled="true"
                                           class="form-control" aria-label="Date" aria-describedby="basic-user"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && irForm.date.errors }">
                                    <div *ngIf="slide1FormSubmitted && irForm.date.errors" class="invalid-feedback">
                                        <div *ngIf="irForm.date.errors.required">{{
                                            "inputs.date.validations.required" |
                                            translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <label class="control-label" for="objective">{{ "inputs.objective.label" | translate }}
                                    <span class="roboto-bold-font text-red">*</span>
                                </label>
                                <textarea formControlName="objective" class="form-control" aria-label="Objective" rows="5"
                                    [ngClass]="{ 'is-invalid': slide1FormSubmitted && irForm.objective.errors }" disabled="true"
                                    aria-describedby="basic-user" id="objective"></textarea>
                                <div *ngIf="slide1FormSubmitted && irForm.objective.errors" class="invalid-feedback">
                                    <div *ngIf="irForm.objective.errors.required">{{
                                        "inputs.objective.validations.required" |
                                        translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <label class="control-label" for="situation">{{ "inputs.situation.label" | translate }}</label>
                                <textarea formControlName="situation" class="form-control" aria-label="Situation" rows="5"
                                    [ngClass]="{ 'is-invalid': slide1FormSubmitted && irForm.situation.errors }"
                                    aria-describedby="basic-user" id="situation"></textarea>
                                <div *ngIf="slide1FormSubmitted && irForm.situation.errors" class="invalid-feedback">
                                    <div *ngIf="irForm.situation.errors.required">{{
                                        "inputs.situation.validations.required" |
                                        translate }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="py-2"></div>

                        <div class="row">
                            <div class="col-xl-6 col-lg-6">
                                <div class="form-group">
                                    <label class="control-label" for="princResponsible">{{
                                        "inputs.principal_responsible.label" |
                                        translate }}<span class="roboto-bold-font text-red">*</span></label>
                                    <input type="text" formControlName="princResponsible" class="form-control"
                                        aria-label="Pricipale Responsible" aria-describedby="basic-user" disabled="true"
                                        id="princResponsible1" [ngClass]="{ 'is-invalid': slide1FormSubmitted && irForm.princResponsible.errors }">
                                    <div *ngIf="slide1FormSubmitted && irForm.princResponsible.errors" class="invalid-feedback">
                                        <div *ngIf="irForm.princResponsible.errors.required">{{
                                            "inputs.principal_responsible.validations.required" |
                                            translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6">
                                <div class="form-group">
                                    <label class="control-label" for="partResponsible">{{
                                        "inputs.implementation_partner.label"
                                        | translate }}</label>
                                    <input type="text" formControlName="partResponsible" class="form-control"
                                        aria-label="Implementation Partner" aria-describedby="basic-user" disabled="true"
                                        id="partResponsible" [ngClass]="{ 'is-invalid': slide1FormSubmitted && irForm.partResponsible.errors }">
                                    <div *ngIf="slide1FormSubmitted && irForm.partResponsible.errors" class="invalid-feedback">
                                        <div *ngIf="irForm.partResponsible.errors.required">{{
                                            "inputs.implementation_partner.validations.required" |
                                            translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <label class="control-label" for="encounteredDiff">{{ "inputs.encounteredDiff.label" | translate }}</label>
                                <textarea formControlName="encounteredDiff" class="form-control" aria-label="Rate Difference Justification"
                                    [ngClass]="{ 'is-invalid': slide1FormSubmitted && irForm.encounteredDiff.errors }" rows="5"
                                    aria-describedby="basic-user" id="encounteredDiff"></textarea>
                                <div *ngIf="slide1FormSubmitted && irForm.encounteredDiff.errors" class="invalid-feedback">
                                    <div *ngIf="irForm.encounteredDiff.errors.required">{{
                                        "inputs.encounteredDiff.validations.required" |
                                        translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <label class="control-label" for="learnedLesson">{{ "inputs.learnedLesson.label" | translate }}</label>
                                <textarea formControlName="learnedLesson" class="form-control" aria-label="Learned Lesson" rows="5"
                                    [ngClass]="{ 'is-invalid': slide1FormSubmitted && irForm.learnedLesson.errors }"
                                    aria-describedby="basic-user" id="learnedLesson"></textarea>
                                <div *ngIf="slide1FormSubmitted && irForm.learnedLesson.errors" class="invalid-feedback">
                                    <div *ngIf="irForm.learnedLesson.errors.required">{{
                                        "inputs.learnedLesson.validations.required" |
                                        translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- espace de geolocalisation -->
                        <div class="pt-2">
                            <button
                                class="btn btn-pill bg-primary px-5"
                                *ngIf="!showGeolocation"
                                (click)="showGeolocationSection()"
                            >
                                Géolocaliser
                            </button>
                            <button
                                class="btn btn-pill bg-danger px-5"
                                *ngIf="showGeolocation"
                                (click)="hideSections()"
                            >
                                Annuler
                            </button>

                            <div *ngIf="showGeolocation">
                                <h4 class="py-2 roboto-bold-font">
                                    {{ "save_location" | translate }}
                                </h4>
                                <div class="row">
                                    <div class="col-md-6 col-12 p-3">
                                        <label for="latitude">Latitude</label>
                                        <input
                                            id="latitude"
                                            type="number"
                                            formControlName="L_latitude"
                                            class="form-control"
                                            placeholder="Latitude"
                                        />
                                    </div>
                                    <div class="col-md-6 col-12 p-3">
                                        <label for="longitude">Longitude</label>
                                        <input
                                            id="longitude"
                                            type="number"
                                            formControlName="L_longitude"
                                            class="form-control"
                                            placeholder="Longitude"
                                        />
                                    </div>
                                    <app-map-box
                                        [lat]="latitude"
                                        [lng]="longitude"
                                        class="map"
                                    ></app-map-box>
                                </div>
                            </div>
                        </div>

                        <!-- end geolocalisation-->
                        <div class="row py-2">
                            <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end">
                                <a class="btn btn-pill bg-green px-5" (click)="changeSlide(2)">{{ "next" | translate }}</a>
                            </div>
                        </div>
                    </form>
                </section>

                <section [hidden]="slide2">
                    <form [formGroup]="slide2Form">
                        <h4 class="py-2 roboto-bold-font">{{ "time-consumption-level" | translate }}</h4>
                        <div class="row">
                            <div class="col-xl-8 col-lg-8">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-4">
                                        <div class="form-group">
                                            <label class="control-label" for="startDate">{{ "inputs.startDate.label" | translate }}
                                                <span class="roboto-bold-font text-red">*</span></label>
                                            <input type="text" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [daysDisabled]="[7,0]"
                                                   placeholder="{{ 'date-format' | translate }}" formControlName="startDate" id="startDate" disabled="disabled"
                                                class="form-control" aria-label="Start Date" aria-describedby="basic-user" readonly="readonly"
                                                [ngClass]="{ 'is-invalid': slide2FormSubmitted && nnForm.startDate.errors }">
                                            <div *ngIf="slide2FormSubmitted && nnForm.startDate.errors" class="invalid-feedback">
                                                <div *ngIf="nnForm.startDate.errors.required">{{
                                                    "inputs.startDate.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4">
                                        <div class="form-group">
                                            <label class="control-label" for="endDate">{{ "inputs.endDate.label" | translate }}
                                                <span class="roboto-bold-font text-red">*</span></label>
                                            <input type="text" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [daysDisabled]="[7,0]"
                                                   placeholder="{{ 'date-format' | translate }}" formControlName="endDate" id="endDate" disabled="disabled"
                                                class="form-control" aria-label="Start Date" aria-describedby="basic-user" readonly="readonly"
                                                [ngClass]="{ 'is-invalid': slide2FormSubmitted && nnForm.endDate.errors }">
                                            <div *ngIf="slide2FormSubmitted && nnForm.endDate.errors" class="invalid-feedback">
                                                <div *ngIf="nnForm.endDate.errors.required">{{
                                                    "inputs.endDate.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4">
                                        <div class="form-group">
                                            <label class="control-label" for="deadlineRate">{{ "inputs.deadlineRate.label" | translate }}
                                                <span class="roboto-bold-font text-red">*</span></label>
                                            <input type="number" formControlName="deadlineRate" id="deadlineRate" disabled="true"
                                                class="form-control" aria-label="Dead Line Rate" aria-describedby="basic-user" [min]="0"
                                                [ngClass]="{ 'is-invalid': slide2FormSubmitted && nnForm.deadlineRate.errors }">
                                            <div *ngIf="slide2FormSubmitted && nnForm.deadlineRate.errors" class="invalid-feedback">
                                                <div *ngIf="nnForm.deadlineRate.errors.required">{{
                                                    "inputs.deadlineRate.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label" for="physicRate">{{ "inputs.physicRate.label" | translate }}
                                                <span class="roboto-bold-font text-red">*</span></label>
                                            <input type="number" formControlName="physicRate" id="physicRate" disabled="true"
                                                class="form-control" aria-label="Physic Rate" aria-describedby="basic-user" [min]="0"
                                                [ngClass]="{ 'is-invalid': slide2FormSubmitted && nnForm.physicRate.errors }">
                                            <div *ngIf="slide2FormSubmitted && nnForm.physicRate.errors" class="invalid-feedback">
                                                <div *ngIf="nnForm.physicRate.errors.required">{{
                                                    "inputs.physicRate.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label" for="budgetRate">{{ "inputs.budgetRate.label" | translate }}
                                                <span class="roboto-bold-font text-red">*</span></label>
                                            <input type="number" formControlName="budgetRate" id="budgetRate" disabled="true"
                                                class="form-control" aria-label="Budget Rate" aria-describedby="basic-user" [min]="0"
                                                [ngClass]="{ 'is-invalid': slide2FormSubmitted && nnForm.budgetRate.errors }">
                                            <div *ngIf="slide2FormSubmitted && nnForm.budgetRate.errors" class="invalid-feedback">
                                                <div *ngIf="nnForm.budgetRate.errors.required">{{
                                                    "inputs.budgetRate.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12">
                                        <div class="form-group">
                                            <label class="control-label" for="deadlineJustif">{{ "inputs.rateDiffJustif.label" | translate }}</label>
                                            <textarea formControlName="deadlineJustif" class="form-control" aria-label="Rate Difference Justification"
                                                [ngClass]="{ 'is-invalid': slide2FormSubmitted && nnForm.deadlineJustif.errors }" rows="5"
                                                aria-describedby="basic-user" id="deadlineJustif"></textarea>
                                            <div *ngIf="slide2FormSubmitted && nnForm.deadlineJustif.errors" class="invalid-feedback">
                                                <div *ngIf="nnForm.deadlineJustif.errors.required">{{
                                                    "inputs.rateDiffJustif.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h4 class="py-2 roboto-bold-font">{{ "physical-execution-level" | translate }}</h4>
                        <div class="row">
                            <div class="col-xl-8 col-lg-8">
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label" for="plannedTask">{{ "inputs.plannedTask.label" | translate }}
                                                <span class="roboto-bold-font text-red">*</span></label>
                                            <input type="number" formControlName="plannedTask" id="plannedTask" disabled="true"
                                                class="form-control" aria-label="Planned Task" aria-describedby="basic-user" [min]="0"
                                                [ngClass]="{ 'is-invalid': slide2FormSubmitted && nnForm.plannedTask.errors }">
                                            <div *ngIf="slide2FormSubmitted && nnForm.plannedTask.errors" class="invalid-feedback">
                                                <div *ngIf="nnForm.plannedTask.errors.required">{{
                                                    "inputs.plannedTask.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label" for="executedTask">{{ "inputs.executedTask.label" | translate }}
                                                <span class="roboto-bold-font text-red">*</span></label>
                                            <input type="number" formControlName="executedTask" id="executedTask" (input)="executionRate($event)"
                                                class="form-control" aria-label="Executed Task" aria-describedby="basic-user" [min]="0"
                                                [ngClass]="{ 'is-invalid': slide2FormSubmitted && nnForm.executedTask.errors }" [max]="tasks.length">
                                            <div *ngIf="slide2FormSubmitted && nnForm.executedTask.errors" class="invalid-feedback">
                                                <div *ngIf="nnForm.executedTask.errors.required">{{
                                                    "inputs.executedTask.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label" for="executionRate">{{ "inputs.executionRate.label" | translate }}
                                                <span class="roboto-bold-font text-red">*</span></label>
                                            <input type="number" formControlName="executionRate" id="executionRate" disabled="true"
                                                class="form-control" aria-label="Execution Rate" aria-describedby="basic-user" [min]="0"
                                                [ngClass]="{ 'is-invalid': slide2FormSubmitted && nnForm.executionRate.errors }">
                                            <div *ngIf="slide2FormSubmitted && nnForm.executionRate.errors" class="invalid-feedback">
                                                <div *ngIf="nnForm.executionRate.errors.required">{{
                                                    "inputs.executionRate.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label" for="executionDelay">{{ "inputs.difference.label" | translate }}
                                                <span class="roboto-bold-font text-red">*</span></label>
                                            <input type="number" formControlName="executionDelay" id="executionDelay" disabled="true"
                                                class="form-control" aria-label="Execution Difference" aria-describedby="basic-user" [min]="0"
                                                [ngClass]="{ 'is-invalid': slide2FormSubmitted && nnForm.executionDelay.errors }">
                                            <div *ngIf="slide2FormSubmitted && nnForm.executionDelay.errors" class="invalid-feedback">
                                                <div *ngIf="nnForm.executionDelay.errors.required">{{
                                                    "inputs.difference.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12">
                                        <div class="form-group">
                                            <label class="control-label" for="executionJustif">{{ "inputs.rateDiffJustif.label" | translate }}</label>
                                            <textarea formControlName="executionJustif" class="form-control" aria-label="Rate Difference Justification"
                                                [ngClass]="{ 'is-invalid': slide2FormSubmitted && nnForm.executionJustif.errors }" rows="5"
                                                aria-describedby="basic-user" id="executionJustif"></textarea>
                                            <div *ngIf="slide2FormSubmitted && nnForm.executionJustif.errors" class="invalid-feedback">
                                                <div *ngIf="nnForm.executionJustif.errors.required">{{
                                                    "inputs.rateDiffJustif.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row py-2">
                            <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-between">
                                <a class="btn btn-pill bg-warning px-5" (click)="changeSlide(1)">{{ "previous" | translate }}</a>
                                <a class="btn btn-pill bg-green px-5" (click)="changeSlide(3)">{{ "next" | translate }}</a>
                            </div>
                        </div>
                    </form>
                </section>

                <section [hidden]="slide3">
                    <form [formGroup]="slide3Form">
                        <h4 class="py-2 roboto-bold-font">{{ "financial-execution-level" | translate }}</h4>
                        <div class="row">
                            <div class="col-xl-8 col-lg-8">
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label" for="affectedBudget">{{ "inputs.affectedBudget.label" | translate }}
                                                <span class="roboto-bold-font text-red">*</span></label>
                                            <input type="text" formControlName="affectedBudget" id="affectedBudget" disabled="true"
                                                class="form-control text-right roboto-bold-font" aria-label="Affected Budget" aria-describedby="basic-user"
                                                [ngClass]="{ 'is-invalid': slide3FormSubmitted && nForm.affectedBudget.errors }">
                                            <div *ngIf="slide3FormSubmitted && nForm.affectedBudget.errors" class="invalid-feedback">
                                                <div *ngIf="nForm.affectedBudget.errors.required">{{
                                                    "inputs.affectedBudget.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label" for="consumptedBudget">{{ "inputs.consumptedBudget.label" | translate }}
                                                <span class="roboto-bold-font text-red">*</span></label>
                                            <input type="number" formControlName="consumptedBudget" id="consumptedBudget"
                                                class="form-control text-right roboto-bold-font" aria-label="Consumped Budget" aria-describedby="basic-user" [min]="0"
                                                [ngClass]="{ 'is-invalid': slide3FormSubmitted && nForm.consumptedBudget.errors }" (input)="consumptionRate($event)">
                                            <div *ngIf="slide3FormSubmitted && nForm.consumptedBudget.errors" class="invalid-feedback">
                                                <div *ngIf="nForm.consumptedBudget.errors.required">{{
                                                    "inputs.consumptedBudget.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label" for="consumptionRate">{{ "inputs.consumptionRate.label" | translate }}
                                                <span class="roboto-bold-font text-red">*</span></label>
                                            <input type="number" formControlName="consumptionRate" id="consumptionRate" disabled="true"
                                                class="form-control" aria-label="Consumption Rate" aria-describedby="basic-user" [min]="0"
                                                [ngClass]="{ 'is-invalid': slide3FormSubmitted && nForm.consumptionRate.errors }">
                                            <div *ngIf="slide3FormSubmitted && nForm.consumptionRate.errors" class="invalid-feedback">
                                                <div *ngIf="nForm.consumptionRate.errors.required">{{
                                                    "inputs.consumptionRate.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label" for="consumptionDelay">{{ "inputs.difference.label" | translate }}
                                                <span class="roboto-bold-font text-red">*</span></label>
                                            <input type="text" formControlName="consumptionDelay" id="consumptionDelay" disabled="true"
                                                class="form-control text-right roboto-bold-font" aria-label="Consumption Difference" aria-describedby="basic-user"
                                                [ngClass]="{ 'is-invalid': slide3FormSubmitted && nForm.consumptionDelay.errors }">
                                            <div *ngIf="slide3FormSubmitted && nForm.consumptionDelay.errors" class="invalid-feedback">
                                                <div *ngIf="nForm.consumptionDelay.errors.required">{{
                                                    "inputs.difference.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12">
                                        <div class="form-group">
                                            <label class="control-label" for="consumptionJustif">{{ "inputs.rateDiffJustif.label" | translate }}</label>
                                            <textarea formControlName="consumptionJustif" class="form-control" aria-label="Rate Difference Justification"
                                                [ngClass]="{ 'is-invalid': slide3FormSubmitted && nForm.consumptionJustif.errors }" rows="5"
                                                aria-describedby="basic-user" id="consumptionJustif"></textarea>
                                            <div *ngIf="slide3FormSubmitted && nForm.consumptionJustif.errors" class="invalid-feedback">
                                                <div *ngIf="nForm.consumptionJustif.errors.required">{{
                                                    "inputs.rateDiffJustif.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="py-2"></div>

                        <div class="row">
                            <div class="col-xl-8 col-lg-8">
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label" for="plannedOutput">{{ "inputs.plannedOutput.label" | translate }}
                                                <span class="roboto-bold-font text-red">*</span></label>
                                            <input type="text" formControlName="plannedOutput" id="plannedOutput" disabled="true"
                                                class="form-control" aria-label="Planned Output" aria-describedby="basic-user"
                                                [ngClass]="{ 'is-invalid': slide3FormSubmitted && nForm.plannedOutput.errors }">
                                            <div *ngIf="slide3FormSubmitted && nForm.plannedOutput.errors" class="invalid-feedback">
                                                <div *ngIf="nForm.plannedOutput.errors.required">{{
                                                    "inputs.plannedOutput.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label" for="obtainedOutput">{{ "inputs.obtainedOutput.label" | translate }}
                                                <span class="roboto-bold-font text-red">*</span></label>
                                            <input type="text" formControlName="obtainedOutput" id="obtainedOutput"
                                                class="form-control" aria-label="Obtained Output" aria-describedby="basic-user"
                                                [ngClass]="{ 'is-invalid': slide3FormSubmitted && nForm.obtainedOutput.errors }">
                                            <div *ngIf="slide3FormSubmitted && nForm.obtainedOutput.errors" class="invalid-feedback">
                                                <div *ngIf="nForm.obtainedOutput.errors.required">{{
                                                    "inputs.obtainedOutput.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label" for="verifMeans">{{ "inputs.verifMeans.label" | translate }}</label>
                                            <input type="text" formControlName="verifMeans" id="verifMeans1"
                                                class="form-control" aria-label="Verification Means" aria-describedby="basic-user"
                                                [ngClass]="{ 'is-invalid': slide3FormSubmitted && nForm.verifMeans.errors }">
                                            <div *ngIf="slide3FormSubmitted && nForm.verifMeans.errors" class="invalid-feedback">
                                                <div *ngIf="nForm.verifMeans.errors.required">{{
                                                    "inputs.verifMeans.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label" for="outputDelay">{{ "inputs.difference.label" | translate }}</label>
                                            <input type="text" formControlName="outputDelay" id="outputDelay"
                                                class="form-control" aria-label="Difference" aria-describedby="basic-user"
                                                [ngClass]="{ 'is-invalid': slide3FormSubmitted && nForm.outputDelay.errors }">
                                            <div *ngIf="slide3FormSubmitted && nForm.outputDelay.errors" class="invalid-feedback">
                                                <div *ngIf="nForm.outputDelay.errors.required">{{
                                                    "inputs.difference.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label" for="geoCoordinate">{{ "inputs.geoCoordinate.label" | translate }}</label>
                                            <textarea formControlName="geoCoordinate" class="form-control" aria-label="Localisation and Geographic Coordinate"
                                                [ngClass]="{ 'is-invalid': slide3FormSubmitted && nForm.geoCoordinate.errors }" rows="5"
                                                aria-describedby="basic-user" id="geoCoordinate"></textarea>
                                            <div *ngIf="slide3FormSubmitted && nForm.geoCoordinate.errors" class="invalid-feedback">
                                                <div *ngIf="nForm.geoCoordinate.errors.required">{{
                                                    "inputs.geoCoordinate.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label" for="outputJustif">{{ "inputs.rateDiffJustif.label" | translate }}</label>
                                            <textarea formControlName="outputJustif" class="form-control" aria-label="Rate Difference Justification"
                                                [ngClass]="{ 'is-invalid': slide3FormSubmitted && nForm.outputJustif.errors }" rows="5"
                                                aria-describedby="basic-user" id="outputJustif"></textarea>
                                            <div *ngIf="slide3FormSubmitted && nForm.outputJustif.errors" class="invalid-feedback">
                                                <div *ngIf="nForm.outputJustif.errors.required">{{
                                                    "inputs.rateDiffJustif.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12">
                                        <div class="form-group">
                                            <label class="control-label" for="file">{{ "inputs.file.label" | translate }}</label><br>
                                            <input type="file" (change)="onFileSelect($event)" formControlName="file"
                                               id="file" accept=".pdf" aria-label="file" aria-describedby="basic-user"
                                                [ngClass]="{ 'is-invalid': slide3FormSubmitted && nForm.file.errors }">
                                            <div *ngIf="slide3FormSubmitted && nForm.file.errors" class="invalid-feedback">
                                                <div *ngIf="nForm.file.errors.required">{{
                                                    "inputs.file.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-12 col-lg-12">
                                        <div class="form-group">
                                            <textarea formControlName="imagePreview" class="form-control" aria-label="imagePreview"
                                                rows="10" aria-describedby="basic-user" id="imagePreview"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row py-2">
                            <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-between">
                                <a class="btn btn-pill bg-warning px-5" (click)="changeSlide(2)">{{ "previous" | translate }}</a>
                                <a class="btn btn-pill bg-green px-5" (click)="changeSlide(4)">{{ "next" | translate }}</a>
                            </div>
                        </div>
                    </form>
                </section>

                <section [hidden]="slide4">
                    <h4 class="py-2 roboto-bold-font">{{ "recommendations" | translate }}</h4>
                    <form [formGroup]="slide4DiffForm">
                        <h5 class="py-2 roboto-bold-font">{{ "difficulties" | translate }}</h5>
                        <div class="row">
                            <div class="col-xl-4 col-lg-4">
                                <div class="form-group">
                                    <label class="control-label" for="title">{{ "inputs.entitle.label" | translate }}</label>
                                    <input type="text" formControlName="title" id="title"
                                        class="form-control" aria-label="Title" aria-describedby="basic-user"
                                        [ngClass]="{ 'is-invalid': slide4DiffFormSubmitted && diffForm.title.errors }">
                                    <div *ngIf="slide4DiffFormSubmitted && diffForm.title.errors" class="invalid-feedback">
                                        <div *ngIf="diffForm.title.errors.required">{{
                                            "inputs.entitle.validations.required" |
                                            translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4">
                                <div class="form-group">
                                    <label class="control-label" for="action">{{ "inputs.action-to-take.label" | translate }}</label>
                                    <input type="text" formControlName="action" id="action"
                                        class="form-control" aria-label="Action" aria-describedby="basic-user"
                                        [ngClass]="{ 'is-invalid': slide4DiffFormSubmitted && diffForm.action.errors }">
                                    <div *ngIf="slide4DiffFormSubmitted && diffForm.action.errors" class="invalid-feedback">
                                        <div *ngIf="diffForm.action.errors.required">{{
                                            "inputs.action-to-take.validations.required" |
                                            translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4">
                                <div class="form-group">
                                    <label class="control-label" for="princResponsible">{{ "inputs.responsible.label" | translate }}</label>
                                    <input type="text" formControlName="princResponsible" id="princResponsible"
                                        class="form-control" aria-label="Responsible" aria-describedby="basic-user"
                                        [ngClass]="{ 'is-invalid': slide4DiffFormSubmitted && diffForm.princResponsible.errors }">
                                    <div *ngIf="slide4DiffFormSubmitted && diffForm.princResponsible.errors" class="invalid-feedback">
                                        <div *ngIf="diffForm.princResponsible.errors.required">{{
                                            "inputs.responsible.validations.required" |
                                            translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4">
                                <div class="form-group">
                                    <label class="control-label" for="delay3">{{ "inputs.delay.label" | translate }}</label>
                                    <input type="text" formControlName="delay" id="delay3"
                                        class="form-control" aria-label="Delay" aria-describedby="basic-user"
                                        [ngClass]="{ 'is-invalid': slide4DiffFormSubmitted && diffForm.delay.errors }">
                                    <div *ngIf="slide4DiffFormSubmitted && diffForm.delay.errors" class="invalid-feedback">
                                        <div *ngIf="diffForm.delay.errors.required">{{
                                            "inputs.delay.validations.required" |
                                            translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4">
                                <div class="form-group">
                                    <label class="control-label" for="verifMeans3">{{ "inputs.verifMeans.label" | translate }}</label>
                                    <input type="text" formControlName="verifMeans" id="verifMeans3"
                                        class="form-control" aria-label="Verification Means" aria-describedby="basic-user"
                                        [ngClass]="{ 'is-invalid': slide4DiffFormSubmitted && diffForm.verifMeans.errors }">
                                    <div *ngIf="slide4DiffFormSubmitted && diffForm.verifMeans.errors" class="invalid-feedback">
                                        <div *ngIf="diffForm.verifMeans.errors.required">{{
                                            "inputs.verifMeans.validations.required" |
                                            translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end text-white">
                                <a class="btn btn-pill bg-green my-2 mx-1" (click)="addDifficulties()"><em
                                        class="fa fa-plus"></em></a>
                            </div>

                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="table-responsive">
                                    <table class="table table-hover table-striped">
                                        <caption></caption>
                                        <thead class="thead-dark">
                                            <tr>
                                                <th>{{ "difficulties" | translate }}</th>
                                                <th>{{ "inputs.action-to-take.label" | translate }}</th>
                                                <th>{{ "inputs.responsible.label" | translate }}</th>
                                                <th>{{ "inputs.delay.label" | translate }}</th>
                                                <th>{{ "inputs.verifMeans.label" | translate }}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let difficulty of difficulties; let i = index;">
                                                <td>{{ difficulty.title }}</td>
                                                <td>{{ difficulty.action }}</td>
                                                <td>{{ difficulty.princResponsible }}</td>
                                                <td>{{ difficulty.delay }}</td>
                                                <td>{{ difficulty.verifMeans }}</td>
                                                <td class="text-center">
                                                    <a *ngIf="!difficulty.iddifficulty" class="btn btn-pill bg-danger my-2 mx-1 text-white"
                                                        (click)="deleteDifficulty(i)"><em
                                                            class="fa fa-times"></em></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </form>

                    <form [formGroup]="slide4DelayForm">
                        <h5 class="py-2 roboto-bold-font">{{ "physical-financial-deadline-difference" | translate }}</h5>
                        <div class="row">
                            <div class="col-xl-4 col-lg-4">
                                <div class="form-group">
                                    <label class="control-label" for="title4">{{ "inputs.entitle.label" | translate }}</label>
                                    <input type="text" formControlName="title" id="title4"
                                        class="form-control" aria-label="Title" aria-describedby="basic-user"
                                        [ngClass]="{ 'is-invalid': slide4DelayFormSubmitted && delayForm.title.errors }">
                                    <div *ngIf="slide4DelayFormSubmitted && delayForm.title.errors" class="invalid-feedback">
                                        <div *ngIf="delayForm.title.errors.required">{{
                                            "inputs.entitle.validations.required" |
                                            translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4">
                                <div class="form-group">
                                    <label class="control-label" for="action4">{{ "inputs.action-to-take.label" | translate }}</label>
                                    <input type="text" formControlName="action" id="action4"
                                        class="form-control" aria-label="Action" aria-describedby="basic-user"
                                        [ngClass]="{ 'is-invalid': slide4DelayFormSubmitted && delayForm.action.errors }">
                                    <div *ngIf="slide4DelayFormSubmitted && delayForm.action.errors" class="invalid-feedback">
                                        <div *ngIf="delayForm.action.errors.required">{{
                                            "inputs.action-to-take.validations.required" |
                                            translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4">
                                <div class="form-group">
                                    <label class="control-label" for="princResponsible4">{{ "inputs.responsible.label" | translate }}</label>
                                    <input type="text" formControlName="princResponsible" id="princResponsible4"
                                        class="form-control" aria-label="Responsible" aria-describedby="basic-user"
                                        [ngClass]="{ 'is-invalid': slide4DelayFormSubmitted && delayForm.princResponsible.errors }">
                                    <div *ngIf="slide4DelayFormSubmitted && delayForm.princResponsible.errors" class="invalid-feedback">
                                        <div *ngIf="delayForm.princResponsible.errors.required">{{
                                            "inputs.responsible.validations.required" |
                                            translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4">
                                <div class="form-group">
                                    <label class="control-label" for="delay">{{ "inputs.delay.label" | translate }}</label>
                                    <input type="text" formControlName="delay" id="delay"
                                        class="form-control" aria-label="Delay" aria-describedby="basic-user"
                                        [ngClass]="{ 'is-invalid': slide4DelayFormSubmitted && delayForm.delay.errors }">
                                    <div *ngIf="slide4DelayFormSubmitted && delayForm.delay.errors" class="invalid-feedback">
                                        <div *ngIf="delayForm.delay.errors.required">{{
                                            "inputs.delay.validations.required" |
                                            translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4">
                                <div class="form-group">
                                    <label class="control-label" for="verifMeans">{{ "inputs.verifMeans.label" | translate }}</label>
                                    <input type="text" formControlName="verifMeans" id="verifMeans"
                                        class="form-control" aria-label="Verification Means" aria-describedby="basic-user"
                                        [ngClass]="{ 'is-invalid': slide4DelayFormSubmitted && delayForm.verifMeans.errors }">
                                    <div *ngIf="slide4DelayFormSubmitted && delayForm.verifMeans.errors" class="invalid-feedback">
                                        <div *ngIf="delayForm.verifMeans.errors.required">{{
                                            "inputs.verifMeans.validations.required" |
                                            translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end text-white">
                                <a class="btn btn-pill bg-green my-2 mx-1" (click)="addGap()"><em
                                        class="fa fa-plus"></em></a>
                            </div>

                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="table-responsive">
                                    <table class="table table-hover table-striped">
                                        <caption></caption>
                                        <thead class="thead-dark">
                                            <tr>
                                                <th>{{ "physical-financial-deadline-difference" | translate }}</th>
                                                <th>{{ "inputs.action-to-take.label" | translate }}</th>
                                                <th>{{ "inputs.responsible.label" | translate }}</th>
                                                <th>{{ "inputs.delay.label" | translate }}</th>
                                                <th>{{ "inputs.verifMeans.label" | translate }}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let gap of gaps; let i = index;">
                                                <td>{{ gap.title }}</td>
                                                <td>{{ gap.action }}</td>
                                                <td>{{ gap.princResponsible }}</td>
                                                <td>{{ gap.delay }}</td>
                                                <td>{{ gap.verifMeans }}</td>
                                                <td class="text-center">
                                                    <a *ngIf="!gap.idgap" class="btn btn-pill bg-danger my-2 mx-1 text-white"
                                                        (click)="deleteGap(i)"><em
                                                            class="fa fa-times"></em></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div class="row py-2">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-between">
                            <a class="btn btn-pill bg-warning px-5" (click)="changeSlide(3)">{{ "previous" | translate }}</a>
                            <button type="submit" (click)="onSubmit()" [hidden]="hideSave"
                                    class="btn btn-pill bg-primary px-5">{{ "save" | translate }}</button>
                            <a class="btn btn-pill bg-green px-5" (click)="changeSlide(5)" [hidden]="true">{{ "next" | translate }}</a>
                        </div>
                    </div>
                </section>

                <section [hidden]="true">
                    <h4 class="py-2 roboto-bold-font">{{ "perspectives" | translate }}</h4>

                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end py-2"
                             *ngIf="privilege.canCreateActivity">
                            <button class="btn btn-pill bg-green px-5 pull-right" (click)="addTask()">{{ "create-task" | translate }}</button>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="table-responsive">
                                <table class="table table-hover table-striped">
                                    <caption></caption>
                                    <thead class="thead-dark">
                                        <tr>
                                            <th>{{ "tasks" | translate }}</th>
                                            <th class="text-center">{{ "planned-start-date" | translate }}</th>
                                            <th class="text-center">{{ "effective-start-date" | translate }}</th>
                                            <th class="text-center">{{ "new-start-date" | translate }}</th>
                                            <th class="text-center">{{ "planned-end-date" | translate }}</th>
                                            <th class="text-center">{{ "new-end-date" | translate }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let task of tasks;">
                                            <td>{{ task.title }}</td>
                                            <td class="text-center">{{ dateFormat(task.startDate) }}</td>
                                            <td class="text-center">{{ dateFormat(task.startDate) }}</td>
                                            <td class="text-center">{{ dateFormat(task.startDate) }}</td>
                                            <td class="text-center">{{ dateFormat(task.endDate) }}</td>
                                            <td class="text-center">{{ dateFormat(task.endDate) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="row py-2">
                        <div
                            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-between">
                            <a class="btn btn-pill bg-warning px-5"
                                (click)="changeSlide(4)">{{ "previous" | translate }}</a>
                            <button type="submit" (click)="onSubmit()" [hidden]="hideSave"
                                class="btn btn-pill bg-primary px-5">{{ "save" | translate }}</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>
