import {HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, Provider} from '@angular/core';
import {TokenStorageService} from './token-storage.service';
import {catchError, finalize, Observable, throwError} from 'rxjs';
import {getUserID} from '../../_helpers';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    title: string;
    links: string[] = ['sign-in', 'forgot-password', 'reset-password'];
    private requestCount = 0;

    constructor(private location: Location, private tokenStorageService: TokenStorageService, private spinner: NgxSpinnerService,
                private router: Router) {
    }

    intercept(httpRequest: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {
        this.title = this.location.prepareExternalUrl(this.location.path()).slice(2);
        this.showSpinner();

        const token = this.tokenStorageService.getToken();
        const userID = this.tokenStorageService.getUser()?.iduser ?? getUserID();

        let request = httpRequest;
        if (token && userID) {
            request = httpRequest.clone({
                setHeaders: {
                    'Authorization': `Bearer ${token}`,
                    'Client-Id': userID.toString()
                }
            });
        } else {
            if (!this.links.includes(this.title.split('/')[0])) {
                this.tokenStorageService.signOut();
                this.router.navigate(['/sign-in']);
            }
        }

        return httpHandler.handle(request)
            .pipe(catchError((error) => {
                    this.spinner.hide();
                   if(error instanceof HttpErrorResponse){
                    if (error.status == 401) {
                        this.tokenStorageService.signOut();
                        this.router.navigate(['/sign-in']);
                    }
                   }
                    return throwError(() => error);
                }), finalize(() => this.hideSpinner())
            );
    }

    private showSpinner(): void {
        if (this.requestCount === 0) {
            this.spinner.show();
        }
        this.requestCount++;
    }

    private hideSpinner(): void {
        this.requestCount--;
        if (this.requestCount === 0) {
            this.spinner.hide();
        }
    }
}

export const httpInterceptorProviders: Provider = {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true};
