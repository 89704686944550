// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: true,
    securityUrl: 'http://api.sunshine.mx:8001/security/',
    preparationMaturationURL: 'http://api.sunshine.mx:8001/preparation-maturation/',
    securityKey: 'SISEPCAM-FINEX@SUNSHINEAdvisory@2022',
    mapbox: {
        accessToken:
            "pk.eyJ1IjoibGluazIzNyIsImEiOiJjbGs0MHBxYWkwNnVqM2tyMHowNWlrMGRoIn0.GxHbEAyPLO95NXVhbWxSwg",
    },
    geoapifykey: "98d1ca19a9a34aa3ad551354577bae88"
};
