export class ActivityTask {
    idtask: number = null;
    numb: string;
    title: string;
    amountFcfa: number;
    princResponsible: string;
    startDate: string;
    endDate: string;
    deliverable: string;
    verifMeans: string;
    deliverables: Deliverable[] = [];
    operations: Operation[];
    status: string;
    createdAt: string;
    updatedAt: string;
    longitude: any;
    latitude: any;
    conference: boolean;
    delivers: any;

    constructor(numb: string, title: string, amountFcfa: number, deliverable: string, verifMeans: string, princResponsible: string,
                startDate: string, endDate: string, latitude: any, longitude: any, conference: boolean = false, status: string = 'NOT_STARTED') {
        this.numb = numb;
        this.title = title;
        this.amountFcfa = amountFcfa;
        this.deliverable = deliverable;
        this.verifMeans = verifMeans;
        this.princResponsible = princResponsible;
        this.startDate = startDate;
        this.endDate = endDate;
        this.status = status;
        this.longitude = longitude;
        this.latitude = latitude;
        this.conference = conference;
    }
}
export interface ActivityTaskFromExcel {
    numb: string;
    is_atelier: "OUI" | "NON";
    amountFcfa: number;
    deliverable: string;
    verifMeans: string;
    responsable: string;
    startDate: number;
    endDate: number;
    title: string;
    longitude: number;
    latitude: number;
}
export class Cashout {
    idcashout: number;
    startDate: any;
    toggle: number = 0;
    amount: number;
    amountFcfa: number;
    description: string;
    numberOfDeliverables: number;
    deliverablesList: DeliverableCashout[];

    constructor(startDate: string, amount: number, amountFcfa: number, description: string, numberOfDeliverables: number,
                deliverablesList: DeliverableCashout[] = []) {
        this.amount = amount;
        this.startDate = startDate;
        this.numberOfDeliverables = numberOfDeliverables;
        this.amountFcfa = amountFcfa;
        this.description = description;
        this.deliverablesList = deliverablesList;
    }
}

export class Task {
    idtask: number;
    numb: string;
    title: string;
    amountFcfa: number;
    princResponsible: string;
    partResponsible: string;
    contextJustif: string;
    objective: string;
    awaitedResult: string;
    riskHypothese: string;
    startDate: string;
    endDate: string;
    deliverables: Deliverable[];
    operations: Operation[];
    cashouts: Cashout[];
    status: string;
    createdAt: string;
    updatedAt: string;
    deliverable: string;
    verifMeans: string;
    inPreparation: boolean;
    longitude: any;
    latitude: any;
    conference: boolean;

    constructor(numb: string, title: string, amountFcfa: number, princResponsible: string, partResponsible: string, contextJustif: string,
                objective: string, awaitedResult: string, riskHypothese: string, startDate: string, endDate: string, deliverables: Deliverable[],
                operations: Operation[], latitude: any, longitude: any, cashouts: Cashout[], conference: boolean = false, status: string = 'Non Démarré') {
        this.numb = numb;
        this.title = title;
        this.amountFcfa = amountFcfa;
        this.princResponsible = princResponsible;
        this.partResponsible = partResponsible;
        this.contextJustif = contextJustif;
        this.objective = objective;
        this.awaitedResult = awaitedResult;
        this.riskHypothese = riskHypothese;
        this.startDate = startDate;
        this.endDate = endDate;
        this.deliverables = deliverables;
        this.operations = operations;
        this.cashouts = cashouts;
        this.status = status;
        this.longitude = longitude;
        this.latitude = latitude;
        this.conference = conference;
    }
}

export class Deliverable {
    iddeliverable: number;
    title: string;
    verifMeans: string;
    createdAt: string;
    updatedAt: string;

    constructor(title: string, verifMeans: string, iddeliverable: number = null) {
        this.title = title;
        this.verifMeans = verifMeans;
        this.iddeliverable = iddeliverable;
    }
}

export class DeliverableCashout {
    iddeliverablecashout: number;
    deliverableAmount: number;
    deliverableDate: any;
    deliverableLabel: string;
    deliverableRate: number;

    constructor(deliverableDate: string, deliverableLabel: string, deliverableRate: number) {
        this.deliverableLabel = deliverableLabel;
        this.deliverableDate = deliverableDate;
        this.deliverableRate = deliverableRate;
    }
}

export class Operation {
    idoperation: number;
    title: string;
    budget: number;
    physicUnit: string;
    unitPrice: number;
    startDate: string;
    endDate: string;
    status: string;
    createdAt: string;
    updatedAt: string;

    constructor(title: string, budget: number, physicUnit: string, unitPrice: number, startDate: string, endDate: string, status: string = 'Non Démarrer') {
        this.title = title;
        this.budget = budget;
        this.physicUnit = physicUnit;
        this.unitPrice = unitPrice;
        this.startDate = startDate;
        this.endDate = endDate;
        this.status = status;
    }
}
