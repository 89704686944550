<!DOCTYPE html>
<section *ngIf="isLoaded" class="animated fadeIn">
    <div class="modal-header d-flex justify-content-between">
        <h3 class="modal-title roboto-bold-font">{{ activity.numb }} : {{ "ANO_dates" | translate }}</h3>
        <span (click)="closeModal()" aria-label="Close" class="close fa fa-times text-red"></span>
    </div>
    <div class="modal-body text-black container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <ngb-alert (closed)="errorMessage = ''" *ngIf="errorMessage" [dismissible]="true" type="danger">{{
                        errorMessage
                    }}
                </ngb-alert>

                <form [formGroup]="slide1Form">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <label class="control-label" for="anoSubmissionDate">{{
                                        "inputs.anoSubmissionDate.label" | translate
                                    }}<span class="roboto-bold-font text-red">*</span></label>
                                <input (bsValueChange)="minANODate($event)"
                                       [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [daysDisabled]="[7,0]"
                                       [maxDate]="endDate" [minDate]="startDate"
                                       [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.anoSubmissionDate.errors }"
                                       aria-describedby="basic-user" aria-label="Date Soumission" bsDatepicker
                                       class="form-control" formControlName="anoSubmissionDate"
                                       id="anoSubmissionDate" placeholder="{{ 'date-format' | translate }}"
                                       placement="bottom" type="text">
                                <div *ngIf="slide1FormSubmitted && csrForm.anoSubmissionDate.errors"
                                     class="invalid-feedback">
                                    <div *ngIf="csrForm.anoSubmissionDate.errors.required">{{
                                            "inputs.anoSubmissionDate.validations.required" |
                                                translate
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <label class="control-label" for="anoObtentionDate">{{
                                        "inputs.anoObtentionDate.label" | translate
                                    }}<span class="roboto-bold-font text-red">*</span></label>
                                <input [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [daysDisabled]="[7,0]"
                                       [maxDate]="endDate" [minDate]="end_anoSubmissionDate"
                                       [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.anoObtentionDate.errors }"
                                       aria-describedby="basic-user" aria-label="Date Obtention"
                                       bsDatepicker class="form-control" formControlName="anoObtentionDate"
                                       id="anoObtentionDate" placeholder="{{ 'date-format' | translate }}"
                                       placement="bottom" type="text">
                                <div *ngIf="slide1FormSubmitted && csrForm.anoObtentionDate.errors"
                                     class="invalid-feedback">
                                    <div *ngIf="csrForm.anoObtentionDate.errors.required">{{
                                            "inputs.anoObtentionDate.validations.required" |
                                                translate
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="row py-2">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end">
                        <button (click)="onSubmit()" [hidden]="hideSave" class="btn btn-pill bg-primary px-5"
                                type="submit">{{ "save" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
