import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {reloadComponent} from '../../../../../_helpers';
import {NotificationFlashService} from '../../../../../services/notification-flash.service';
import {RestAPIService} from '../../../../../services/rest-api.service';
import {SignatureProc, SignatureProcDate} from '../../../../../payloads/signature-process.payload';
import {Project} from '../../../../../payloads/project.payload';
import {UtilityService} from '../../../../../services/utility.service';
import {ConfirmationDialogService} from '../../../../shared-component/confirmation-dialog/confirmation-dialog.service';

@Component({
    selector: 'app-reset-sign-proc-date',
    templateUrl: './reset-sign-proc-date.component.html',
    styleUrls: ['./reset-sign-proc-date.component.scss']
})
export class ResetSignProcDateComponent implements OnInit {
    isLoaded: boolean = false;
    hideSave: boolean = false;
    private _danger = new Subject<string>();

    staticAlertClosed = false;
    errorMessage: string;

    @Input() project: Project;

    @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;
    signatureProcs: SignatureProc[];
    colspan: number;

    constructor(private formBuilder: UntypedFormBuilder, private activeModal: NgbActiveModal, private restAPIService: RestAPIService,
                private notification: NotificationFlashService, private translate: TranslateService, private router: Router,
                private utilityService: UtilityService, private confirmationDialog: ConfirmationDialogService) {
    }

    ngOnInit(): void {
        this._danger.subscribe((message) => (this.errorMessage = message));
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.signatureProcs = [...this.project.signatureProcs];
        this.signatureProcs.sort(
            (signatureProc1: SignatureProc, signatureProc2: SignatureProc) =>
                signatureProc1.idsignatureProc > signatureProc2.idsignatureProc ? 1 : -1
        );

        const dates: number[] = [];
        let hide = 0;
        this.signatureProcs.forEach((signatureProc: SignatureProc) => {
            signatureProc.signatureProcDates.sort((signatureProcDate1: SignatureProcDate, signatureProcDate2: SignatureProcDate) =>
                signatureProcDate1.idsignatureProcDate > signatureProcDate2.idsignatureProcDate ? 1 : -1
            );

            dates.push(signatureProc.signatureProcDates.length);

            if (signatureProc.executedDate) {
                ++hide;
            }
        });

        dates.sort((date1: number, date2: number) => date1 < date2 ? 1 : -1);

        this.colspan = dates[0];

        if (this.utilityService.fetchArchiveStatus().includes(this.project.status) || hide === this.signatureProcs.length) {
            this.hideSave = true;
        }

        this.isLoaded = true;
    }

    onSubmit() {
        this.confirmationDialog.confirm(this.translate.instant('reprogram'),
            this.translate.instant('confirmations.contents.reprogrammed-dates-validation'))
            .then((confirmation) => {
                    if (confirmation) {
                        this.restAPIService.updateSignatureProcess(this.signatureProcs).subscribe(() => {
                            this.closeModal();

                            reloadComponent(this.router);

                            this.notification.success(this.translate.instant('notifications.success.planned-date-reset'));
                        }, () => {
                            this.notification.danger(this.translate.instant('notifications.danger.planned-date-not-reset'));
                        });
                    }
                }
            );
    }

    closeModal() {
        this.activeModal.close();
    }

    dateFormat(date: string): string {
        return new Date(date).toLocaleDateString('fr-FR');
    }

    changePlannedDate(date: any, i: number) {
        this.signatureProcs[i].plannedDate = date;
        this.signatureProcs[i].signatureProcDate = new SignatureProcDate(date);
    }

    formatBSDate(date: string): Date {
        if (!date) {
            return null;
        }
        return new Date(date);
    }
}
