import {CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';



import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {DataTablesModule} from 'angular-datatables';
import {PdfJsViewerModule} from 'ng2-pdfjs-viewer';


// Import 3rd party components
import {DatePipe, HashLocationStrategy, LocationStrategy} from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {httpInterceptorProviders} from './services/security/auth-interceptor';
import {ServerUrl} from './services/server-url.service';
import {DashboardComponent} from './views/dashboard/menu-principal/dashboard.component';
import {UserPasswordComponent} from './views/dashboard/setting/user/user-profile/user-password/user-password.component';
import {UserProfileComponent} from './views/dashboard/setting/user/user-profile/user-profile.component';
import {AboutUsComponent} from './views/public/about-us/about-us.component';
import {ContactUsComponent} from './views/public/contact-us/contact-us.component';
import {HomeComponent} from './views/public/home/home.component';


import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppComponent} from './app.component';


import {BadgeModule, BreadcrumbModule, FooterModule, HeaderModule, SidebarModule} from '@coreui/angular';
import {P404Component} from './views/error/404/404.component';
import {P500Component} from './views/error/500/500.component'; // Import routing module
import {NgSelectModule} from '@ng-select/ng-select';
import {AppRoutingModule} from './app.routing'; // tslint:disable-next-line:max-line-length
import {DataLabelService, MapsModule, MapsTooltipService, SelectionService} from '@syncfusion/ej2-angular-maps';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {MultiSelectModule} from 'primeng/multiselect';
import {TableModule} from 'primeng/table';

import {CreateRoleComponent} from './views/dashboard/setting/role/create-role/create-role.component';
import {UpdateRoleComponent} from './views/dashboard/setting/role/update-role/update-role.component';
import {CreateUserComponent} from './views/dashboard/setting/user/create-user/create-user.component';
import {UpdateUserComponent} from './views/dashboard/setting/user/update-user/update-user.component';
import {UserInfosComponent} from './views/dashboard/setting/user/user-profile/user-infos/user-infos.component';

import {ChartModule} from 'primeng/chart';
import {ReviewArchiveComponent} from './views/dashboard/archive/projects/project/review/review.component';
import {
    ImplementationStateComponent
} from './views/dashboard/project-portfolio/projects/project/update-project/implementation-state/implementation-state.component';
import {DialogModule} from 'primeng/dialog';
import {BsModalService, ModalModule} from 'ngx-bootstrap/modal';
import {SharedModalModule} from './views/dashboard/shared-modal/shared-modal.module';
import {SharedComponentModule} from './views/shared-component/shared-component.module';
import {ReviewProjectPortFolioComponent} from './views/dashboard/project-portfolio/projects/project/review/review.component';
import {
    MarketsProjectPortfolioReportsComponent
} from './views/dashboard/project-portfolio/projects/project/documents/markets-portfolio-reports/markets-portfolio-reports.component';
import {
    FollowUpProjectPortfolioReportsComponent
} from './views/dashboard/project-portfolio/projects/project/documents/follow-up-portfolio-reports/follow-up-portfolio-reports.component';
import {
    ArchivesProjectPortfolioReportsComponent
} from './views/dashboard/project-portfolio/projects/project/documents/archives-portfolio-reports/archives-portfolio-reports.component';
import {
    ActivitiesProjectPortfolioReportsComponent
} from './views/dashboard/project-portfolio/projects/project/documents/activities-portfolio-reports/activities-portfolio-reports.component';
import {SecurityModule} from './views/security/security.module';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';


@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
    overrides = {
        pinch: {enable: false},
        rotate: {enable: false}
    };
}

@Injectable()
export class CustomRouteReuse extends RouteReuseStrategy {
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {

    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        return {};
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return false;
    }
}

@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        BadgeModule,
        BreadcrumbModule,
        FooterModule,
        HeaderModule,
        SidebarModule,
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        FormsModule,
        HttpClientModule,
        
        
        NgbModule,
        MatTabsModule,
        MatSnackBarModule,
        MatCheckboxModule,
        NgSelectModule,
        TranslateModule.forRoot({
            defaultLanguage: 'fr',
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        }),
        DataTablesModule,
        MapsModule,
        PdfJsViewerModule,

        TableModule,
        MultiSelectModule,
        InputTextModule,
        ButtonModule,
        ChartModule,
        LeafletModule,
        DialogModule,
        ModalModule,
        SharedModalModule,
        SharedComponentModule,
        SecurityModule,
        NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
        BsDatepickerModule
    ],
    declarations: [
        ActivitiesProjectPortfolioReportsComponent,
        ArchivesProjectPortfolioReportsComponent,
        FollowUpProjectPortfolioReportsComponent,
        MarketsProjectPortfolioReportsComponent,
        ReviewProjectPortFolioComponent,
        AppComponent,
        P404Component,
        P500Component,
        HomeComponent,
        // ForgotPasswordComponent,
        // LoginComponent,
        // RegisterComponent,
        // ResetPasswordComponent,
        AboutUsComponent,
        ContactUsComponent,
        DashboardComponent,
        UserProfileComponent,
        UserPasswordComponent,
        CreateRoleComponent,
        UpdateRoleComponent,
        CreateUserComponent,
        UpdateUserComponent,
        UserInfosComponent,
        ReviewArchiveComponent,
        ImplementationStateComponent
    ],
    bootstrap: [AppComponent],
    providers: [
        // IconSetService,
        httpInterceptorProviders,
        DatePipe,
        {provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig},
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: RouteReuseStrategy, useClass: CustomRouteReuse},
        ServerUrl,
        MapsTooltipService,
        DataLabelService,
        SelectionService,
        BsModalService,
        Title
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}

export function httpTranslateLoader(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}
