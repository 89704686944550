import { INavData } from '@coreui/angular';
import { TranslateService } from '@ngx-translate/core';

export const preparationMaturationNavItems = (translate: TranslateService): INavData[] => {
  return [
    {
      name: translate.instant('dashboard'),
      url: '/preparation-maturation/dashboard',
      icon: 'fa fa-tachometer',
    },
    {
      name: translate.instant('projects'),
      url: '/preparation-maturation/projects',
      icon: 'fa fa-product-hunt'
    },
    {
      name: translate.instant('creation'),
      url: '/preparation-maturation/create-project',
      icon: 'fa fa-plus'
    },
    {
      name: translate.instant('reports_archives'),
      url: '/preparation-maturation/reports-archive',
      icon: 'fa fa-archive',
      children: [
        {
          name: translate.instant('reports'),
          url: '/preparation-maturation/reports-archive/reports',
          icon: 'fa fa-file'
        },
        {
          name: translate.instant('archives'),
          url: '/preparation-maturation/reports-archive/archives',
          icon: 'fa fa-archive'
        }
      ]
    },
    {
      name: translate.instant('analyses'),
      url: '/preparation-maturation/analyse',
      icon: 'fa fa-pie-chart'
    },
    {
      name: translate.instant('maps'),
      url: '/preparation-maturation/map',
      icon: 'fa fa-map'
    }
  ];
};
