import { Injectable } from '@angular/core';
import { Icon, latLng, MapOptions, LatLng, tileLayer, icon } from 'leaflet';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MapBoxService {
  private markerPositionSubject = new Subject<LatLng>();
  markerPosition$ = this.markerPositionSubject.asObservable();

  mapOptions: MapOptions = {
    layers: [
      tileLayer(
        'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=' +
        environment.mapbox.accessToken,
        {
          tileSize: 512,
          zoomOffset: -1,
          attribution:
            '© <a href="https://www.mapbox.com/contribute/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        }
      ),
    ],
    zoom: 14,
    center: latLng(3.866667, 11.516667),
  };

  style = 'mapbox://styles/mapbox/streets-v12';
  markerIcon = icon({
    iconSize: [12, 21],
    iconAnchor: [6, 21],
    iconUrl: 'https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png',
    shadowSize: [12, 21],
    shadowAnchor: [6, 21],
    shadowUrl:
      'https://unpkg.com/leaflet@1.6.0/dist/images/marker-shadow.png',
  }) as Icon;

  constructor() { }

  updateMarkerPosition(position: LatLng) {
    this.markerPositionSubject.next(position);
  }
}