import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

// Import Containers
import {DefaultLayoutComponent} from './views/shared-component/default-layout/default-layout.component';

import {P404Component} from './views/error/404/404.component';
import {P500Component} from './views/error/500/500.component';
import {HomeComponent} from './views/public/home/home.component';
import {AboutUsComponent} from './views/public/about-us/about-us.component';
import {ContactUsComponent} from './views/public/contact-us/contact-us.component';
import {AuthGuard} from './services/security/auth.guard';
import {FormsModule} from '@angular/forms';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {ForgotPasswordComponent} from './views/security/forgot-password/forgot-password.component';
import {LoginComponent} from './views/security/login/login.component';
import {ResetPasswordComponent} from './views/security/reset-password/reset-password.component';
import {RegisterComponent} from './views/security/register/register.component';
import {ConfirmationDialogService} from './views/shared-component/confirmation-dialog/confirmation-dialog.service';
import {DashboardComponent} from './views/dashboard/menu-principal/dashboard.component';
import {RestAPIService} from './services/rest-api.service';
import {ProjectsComponent} from './views/public/projects/projects.component';


export const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        data: {
            title: 'home'
        }
    },
    {
        path: 'about-us',
        component: AboutUsComponent,
        data: {
            title: 'about_us'
        }
    },
    {
        path: 'projects',
        component: ProjectsComponent,
        data: {
            title: 'projects'
        }
    },
    {
        path: 'contact-us',
        component: ContactUsComponent,
        data: {
            title: 'contact_us'
        }
    },
    {
        path: 'sign-in',
        component: LoginComponent,
        data: {
            title: 'sign-in'
        }
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: {
            title: 'forgot_password'
        }
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
        data: {
            title: 'update_password'
        }
    },
    {
        path: 'registration',
        component: RegisterComponent,
        data: {
            title: 'registration'
        }
    },
    {
        path: 'dashboard', canActivate: [AuthGuard],
        children: [
            {
                path: '', component: DashboardComponent,
                data: {
                    title: 'dashboard'
                }
            }
        ]
    },

    //  ::  PREPARATION MATURATION  ::
    {
        path: 'preparation-maturation', component: DefaultLayoutComponent, canActivate: [AuthGuard],
        loadChildren: () => import('./views/dashboard/preparation-maturation/preparation-maturation.module').then(m => m.PreparationMaturationModule)
    },
    //  ::  PROJECT PORTFOLIO  ::
    {
        path: 'project-portfolio', component: DefaultLayoutComponent, canActivate: [AuthGuard],
        loadChildren: () => import('./views/dashboard/project-portfolio/project-portfolio.module').then(m => m.ProjectPortfolioModule)
    },

    //  ::  ARCHIVES  ::
    {
        path: 'archives', component: DefaultLayoutComponent, canActivate: [AuthGuard],
        loadChildren: () => import('./views/dashboard/archive/archive.module').then(m => m.ArchiveModule)
    },

    //  ::  SETTING  ::
    {
        path: 'setting', component: DefaultLayoutComponent, canActivate: [AuthGuard],
        loadChildren: () => import('./views/dashboard/setting/setting.module').then(m => m.SettingModule)

    },

    {
        path: '404', component: P404Component,
        data: {
            title: 'Page 404'
        }
    },
    {
        path: '500', component: P500Component,
        data: {
            title: 'Page 500'
        }
    },
    { path: '**', component: P404Component }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'}), FormsModule, NgbModalModule],
    providers: [RestAPIService, AuthGuard, ConfirmationDialogService],
    exports: [RouterModule]
})

export class AppRoutingModule { }
