<!DOCTYPE html>
<section class="sign-in-background">
    <app-header></app-header>

    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-7 col-lg-7 col-md-7 col-sm-5 col-xs-1"></div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-10">
                <div class="card animated fadeInDown">
                    <div class="card-body">
                        <div class="roboto-bold-font">
                            <h1 class="h1">{{ "sign_in" | translate }}</h1>
                        </div>

                        <div class="pt-4">
                            <ngb-alert #selfClosingAlert (closed)="errorMessage = ''" *ngIf="errorMessage"
                                       type="danger">{{ errorMessage }}
                            </ngb-alert>
                            <form (ngSubmit)="onSubmit()" [formGroup]="loginForm">
                                <div class="row">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-user"><em aria-hidden="true"
                                                                                               class="fa fa-user"></em></span>
                                        </div>
                                        <input [ngClass]="{ 'is-invalid': loginFormSubmitted && login.username.errors }" aria-describedby="basic-user" aria-label="Noms d'utilisateur"
                                               class="form-control"
                                               formControlName="username"
                                               placeholder="{{ 'inputs.username.label' | translate }}"
                                               required type="text">
                                        <div *ngIf="loginFormSubmitted && login.username.errors"
                                             class="invalid-feedback">
                                            <div
                                                *ngIf="login.username.errors.required">{{ "inputs.username.validations.required" | translate }}
                                            </div>
                                            <div
                                                *ngIf="login.username.errors.minlength">{{ "inputs.username.validations.min_length" | translate: {'size': '3'} }}
                                            </div>
                                            <div
                                                *ngIf="login.username.errors.maxlength">{{ "inputs.username.validations.max_length" | translate: {'size': '60'} }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row pt-3">
                                    <div class="input-group mb-4">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-pass"><em aria-hidden="true"
                                                                                               class="fa fa-key"></em></span>
                                        </div>
                                        <input [ngClass]="{ 'is-invalid': loginFormSubmitted && login.password.errors }" aria-describedby="basic-pass" aria-label="Mot de passe"
                                               autocomplete="on"
                                               class="form-control"
                                               formControlName="password"
                                               placeholder="{{ 'inputs.password.label' | translate }}" required
                                               type="password">
                                        <div *ngIf="loginFormSubmitted && login.password.errors"
                                             class="invalid-feedback">
                                            <div
                                                *ngIf="login.password.errors.required">{{ "inputs.password.validations.required" | translate }}
                                            </div>
                                            <div
                                                *ngIf="login.password.errors.minlength">{{ "inputs.password.validations.min_length" | translate: {'size': '6'} }}
                                            </div>
                                            <div
                                                *ngIf="login.password.errors.maxlength">{{ "inputs.password.validations.max_length" | translate: {'size': '100'} }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <a class="btn-link pointer text-right roboto-bold-font"
                                       routerLink="/forgot-password">{{ "forgot_password" | translate }}</a>
                                </div>

                                <div class="row pt-1">
                                    <button class="btn btn-pill btn-block bg-warning"
                                            type="submit">{{ "sign_in" | translate }}
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div class="row pt-4">
                            <p class="roboto-bold-font">{{ "unauthorized_use_is_prohibited" | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
        </div>
    </div>
</section>
