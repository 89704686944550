<!-- cashout.component.html -->

<h4 class="roboto-bold-font pt-2">{{ "cash-disbursement-forecast" | translate }}</h4>

<div *ngIf="!view; else elseBlock" class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="table-responsive">
            <table class="table table-hover table-striped">
                <caption></caption>
                <thead class="thead-dark">
                <tr>
                    <th class="text-center">{{ "inputs.startDate.label" | translate }}</th>
                    <th class="text-center">{{ "inputs.description.label" | translate }}</th>
                    <th class="nowrap">{{ "inputs.amount_in_currency.label" | translate }} ({{project.currency}})</th>
                    <th class="text-center">{{ "inputs.amount_in_fcfa.label" | translate }}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody *ngFor="let cashout of cashouts; let i = index;">
                <ng-container>
                    <tr>
                        <td class="nowrap text-center">
                            <input [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [daysDisabled]="[7,0]"
                                   [bsValue]="formatBSDate(cashout.startDate)" [minDate]="startDate" [maxDate]="endDate"
                                   (bsValueChange)="onCashoutDateChanged($event, i)" required type="text" bsDatepicker
                                   class="form-control" placeholder="{{ 'date-format' | translate }}">
                        </td>
                        <td class="nowrap text-center">
                            <input (ngModelChange)="onCashoutDescriptionChanged($event, i)"
                                   [(ngModel)]="cashout.description" type="text" class="form-control">
                        </td>
                        <td class="nowrap text-right font-weight-bold">{{ moneyFormat(cashout.amount) }}</td>
                        <td class="nowrap text-center">
                            <input (input)="onAmountFcfaChange(i)" (ngModelChange)="onCashoutAmountChanged($event, i)"
                                   [(ngModel)]="cashout.amountFcfa" class="form-control" type="number">
                        </td>

                        <td class="text-center d-flex align-items-center">
                            <a (click)="deleteCashout(i, cashout.idcashout)"
                               class="btn btn-pill bg-danger my-1 mx-1 text-white">
                                <em class="fa fa-times"></em>
                            </a>
                            <a (click)="showDeliverable(cashout.idcashout)" *ngIf="cashout.toggle !== 1"
                               class="py-3 mx-2 text-green" title="{{ 'click-to-show-tasks' | translate }}">
                                <em class="fa fa-plus text-green"></em></a>
                            <a (click)="hideDeliverable(cashout.idcashout)" *ngIf="cashout.toggle === 1"
                               class="py-3 mx-2 text-red" title="{{ 'click-to-show-tasks' | translate }}">
                                <em class="fa fa-minus text-red"></em></a>
                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="cashout.toggle === 1">
                    <tr>
                        <td colspan="10">
                            <table class="table table-hover table-striped tasks" datatable>
                                <caption></caption>
                                <thead class="thead-dark">
                                <tr>
                                    <th class="text-center">{{ "inputs.deliverable_date.label" | translate }}</th>
                                    <th class="nowrap text-center">{{ "inputs.deliverable_label.label" | translate }}</th>
                                    <th class="nowrap text-center">{{ "inputs.decayRate.label" | translate }}</th>
                                    <th class="nowrap text-center">{{ "inputs.amount_in_fcfa.label" | translate }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let deliverable of cashout.deliverablesList let j = index">
                                    <td class="text-center">
                                        <input [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [daysDisabled]="[7,0]"
                                               [maxDate]="endDate"
                                               [bsValue]="formatBSDate(deliverable.deliverableDate)"
                                               [minDate]="startDate"
                                               (bsValueChange)="onDeliverableDateChange($event, j, i)"
                                               type="text" bsDatepicker class="form-control"
                                               placeholder="{{ 'date-format' | translate }}">
                                    </td>
                                    <td class="text-center nowrap">
                                        <input [(ngModel)]="deliverable.deliverableLabel" class="form-control"
                                               type="text">
                                    </td>
                                    <td class="text-center">
                                        <input (ngModelChange)="onDeliverableRateChange(j, i)"
                                               [(ngModel)]="deliverable.deliverableRate"
                                               type="number" class="form-control">
                                    </td>
                                    <td class="text-center font-weight-bold">
                                        {{ deliverableAmount(deliverable.deliverableRate, cashout.amountFcfa) }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ng-template #elseBlock>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="table-responsive">
                <table class="table table-hover table-striped">
                    <caption></caption>
                    <thead class="thead-dark">
                    <tr>
                        <th class="text-center">{{ "inputs.startDate.label" | translate }}</th>
                        <th class="text-center">{{ "inputs.description.label" | translate }}</th>
                        <th class="nowrap">{{ "inputs.amount_in_currency.label" | translate }} ({{project.currency}})
                        </th>
                        <th class="text-center">{{ "inputs.amount_in_fcfa.label" | translate }}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody *ngFor="let cashout of cashouts let i = index;">
                    <ng-container>
                        <tr>
                            <td class="nowrap text-center">{{ dateFormat(cashout.startDate) }}</td>
                            <td>{{ cashout.description }}</td>
                            <td class="nowrap text-center font-weight-bold">{{ cashout.amount }}</td>
                            <td class="nowrap text-center font-weight-bold">{{ moneyFormat(cashout.amountFcfa) }}</td>
                            <td class="text-center d-flex align-items-center">
                                <a (click)="showDeliverable(cashout.idcashout)" *ngIf="cashout.toggle !== 1"
                                   class="py-3 mx-2 text-green" title="{{ 'click-to-show-tasks' | translate }}">
                                    <em class="fa fa-plus text-green"></em></a>
                                <a (click)="hideDeliverable(cashout.idcashout)" *ngIf="cashout.toggle === 1"
                                   class="py-3 mx-2 text-red" title="{{ 'click-to-show-tasks' | translate }}">
                                    <em class="fa fa-minus text-red"></em></a>
                            </td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="cashout.toggle === 1">
                        <tr>
                            <td colspan="10">
                                <table class="table table-hover table-striped tasks">
                                    <caption></caption>
                                    <thead class="thead-dark">
                                    <tr>
                                        <th class="text-center">{{ "inputs.deliverable_date.label" | translate }}</th>
                                        <th class="nowrap text-center">{{ "inputs.deliverable_label.label" | translate }}</th>
                                        <th class="nowrap text-center">{{ "inputs.decayRate.label" | translate }}</th>
                                        <th class="nowrap text-center">{{ "inputs.amount_in_fcfa.label" | translate }}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let deliverable of cashout.deliverablesList let j = index">
                                        <td class="text-center">{{ dateFormat(deliverable.deliverableDate) }}</td>
                                        <td class="text-center">{{ deliverable.deliverableLabel }}</td>
                                        <td class="text-center">{{ deliverable.deliverableRate }}</td>
                                        <td class="text-center font-weight-bold">{{ deliverableAmount(deliverable.deliverableRate, cashout.amountFcfa)}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>
