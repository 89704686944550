<!DOCTYPE html>
<section class="animated fadeIn" *ngIf="isLoaded">
    <div class="modal-header d-flex justify-content-between">
        <h3 class="modal-title roboto-bold-font">{{ "claims-complain" | translate }}</h3>
        <span class="close fa fa-times text-red" aria-label="Close" (click)="closeModal()"></span>
    </div>
    <div class="modal-body text-black container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 forms">
                <form [formGroup]="claimForm">
                    <ngb-alert #selfClosingAlert *ngIf="errorMessage" type="danger" (closed)="errorMessage = ''">{{
                        errorMessage
                        }}</ngb-alert>

                    <div class="row">
                        <div class="col-xl-6 col-lg-6">
                            <div class="form-group">
                                <label class="control-label" for="date">{{ "inputs.date.label" | translate }}
                                    <span class="roboto-bold-font text-red">*</span>
                                </label>
                                <input type="text" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                                       [daysDisabled]="[7,0]" placeholder="{{ 'date-format' | translate }}" [minDate]="startDate" id="date"
                                       formControlName="date" class="form-control" aria-label="Date"
                                       aria-describedby="basic-user"
                                       [ngClass]="{ 'is-invalid': claimFormSubmitted && createForm.date.errors }">
                                <div *ngIf="claimFormSubmitted && createForm.date.errors" class="invalid-feedback">
                                    <div *ngIf="createForm.date.errors.required">{{
                                        "inputs.date.validations.required" |
                                            translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6">
                            <div class="form-group">
                                <label class="control-label"
                                       for="object">{{ "inputs.object.label" | translate }}</label>
                                <input type="text" formControlName="object" class="form-control" aria-label="Object"
                                       aria-describedby="basic-user" id="object"
                                       [ngClass]="{ 'is-invalid': claimFormSubmitted && createForm.object.errors }">
                                <div *ngIf="claimFormSubmitted && createForm.object.errors" class="invalid-feedback">
                                    <div *ngIf="createForm.object.errors.required">{{
                                        "inputs.object.validations.required" |
                                            translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6">
                            <div class="form-group">
                                <label class="control-label" for="treatment">{{ "inputs.treatment.label" | translate }}
                                    <span class="roboto-bold-font text-red">*</span>
                                </label>
                                <input type="text" formControlName="treatment" class="form-control"
                                       aria-label="Sender" aria-describedby="basic-user" id="treatment"
                                       [ngClass]="{ 'is-invalid': claimFormSubmitted && createForm.treatment.errors }">
                                <div *ngIf="claimFormSubmitted && createForm.treatment.errors" class="invalid-feedback">
                                    <div *ngIf="createForm.treatment.errors.required">{{
                                        "inputs.treatment.validations.required" |
                                            translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6">
                            <div class="form-group">
                                <label class="control-label"
                                       for="document">{{ "inputs.document.label" | translate }}</label>
                                <input type="file" (change)="onFileSelect($event)" formControlName="document"
                                       aria-label="Receiver" aria-describedby="basic-user" id="document" accept=".pdf"
                                       [ngClass]="{ 'is-invalid': claimFormSubmitted && createForm.document.errors }">
                                <div *ngIf="claimFormSubmitted && createForm.document.errors" class="invalid-feedback">
                                    <div *ngIf="createForm.document.errors.required">{{
                                        "inputs.document.validations.required" |
                                            translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-12 col-lg-12">
                            <div class="form-group">
                                <label class="control-label"
                                       for="response">{{ "inputs.response.label" | translate }}</label>
                                <textarea type="text" rows="5" formControlName="response" class="form-control"
                                          aria-label="Message" aria-describedby="basic-user" id="response"
                                          [ngClass]="{ 'is-invalid': claimFormSubmitted && createForm.response.errors }"></textarea>
                                <div *ngIf="claimFormSubmitted && createForm.response.errors" class="invalid-feedback">
                                    <div *ngIf="createForm.response.errors.required">{{
                                        "inputs.response.validations.required" |
                                            translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end">
                            <button type="submit" (click)="onSubmit()"
                                    class="btn btn-pill bg-primary px-5">{{ "save" | translate }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
