<div class="modal-header" style="background-color: #f9f0cd">
  <h4 class="modal-title">{{ title }}</h4>
</div>
<div class="modal-body">
  <p>{{ message }}</p>
</div>
<div class="modal-footer d-flex justify-content-between">
  <button type="button" class="btn btn-danger bg-danger" (click)="decline()"><span><em class="fa fa-close"
        aria-hidden="true"></em></span> {{ btnCancelText }}</button>
  <button type="button" class="btn btn-success bg-green" ngbAutofocus (click)="accept()"><span><em class="fa fa-check"
        aria-hidden="true"></em></span> {{ btnOkText }}</button>
</div>
