import {Component, OnInit} from '@angular/core';
import {SharedComponentModule} from '../../shared-component/shared-component.module';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {NgForOf, NgIf, NgOptimizedImage} from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormBuilder, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {UiSwitchModule} from 'ngx-ui-switch';
import {UtilityService} from '../../../services/utility.service';
import {Project} from '../../../payloads/project.payload';
import {RestAPIService} from '../../../services/rest-api.service';
import {NgxPaginationModule} from 'ngx-pagination';

@Component({
    selector: 'app-projects',
    standalone: true,
    imports: [
        SharedComponentModule,
        TranslateModule,
        NgForOf,
        NgIf,
        NgSelectModule,
        ReactiveFormsModule,
        UiSwitchModule,
        NgxPaginationModule,
        NgOptimizedImage
    ],
    templateUrl: './projects.component.html',
    styleUrl: './projects.component.css'
})
export class ProjectsComponent implements OnInit {
    isLoading: boolean = false;
    projects: Project[] = [];
    filteredProjects: Project[] = [];
    allYears: number[] = [];
    states: string[];
    financingAgencies: string[];

    currentPage = 1;
    projectsPerPage = 6;

    filterForm: FormGroup;

    constructor(private utilityService: UtilityService, private restAPIService: RestAPIService, private formBuilder: FormBuilder,
                private translate: TranslateService) {}

    ngOnInit(): void {
        this.isLoading = true;
        this.states = this.utilityService.fetchFilterStates();
        this.financingAgencies = this.utilityService.fetchFinancingAgencies();

        this.filterForm = this.formBuilder.group({
            state: [null],
            financingAgency: [null],
            year: [null]
        });

        this.fetchProjects();
    }

    fetchProjects(): void {
        this.restAPIService.fetchProjectsByStatus(this.states).subscribe(
            (projects: Project[]) => {
                if (projects.length > 0) {
                    this.projects = projects;
                    this.filteredProjects = this.projects;
                    const uniqueYears = Array.from(new Set(
                        this.projects.flatMap(project => [
                            new Date(project.startDate).getFullYear(),
                            new Date(project.endDate).getFullYear()
                        ])
                    ));
                    const minYear = Math.min(...uniqueYears);
                    const maxYear = Math.max(...uniqueYears);
                    this.allYears = Array.from({length: maxYear - minYear + 1}, (_, index) => minYear + index);
                }
                this.isLoading = false;
            },
            () => {
                this.isLoading = false;
            }
        );
    }

    onPageChange(page: number): void {
        this.currentPage = page;
    }

    onReset(): void {
        this.filterForm.reset();
        this.currentPage = 1;
        this.filteredProjects = [...this.projects];
    }

    dateFormat(date: string): string {
        return new Date(date).toLocaleDateString('fr-FR');
    }

    onFilterChange(): void {
        const state = this.filterForm.get('state').value;
        const financeAgency = this.filterForm.get('financingAgency').value;
        const year = this.filterForm.get('year').value;

        this.isLoading = true;

        const states: string[] = (state !== null && state !== '') ? state.toString().split(',') : [];
        const financeAgencies: string[] = (financeAgency !== null && financeAgency !== '') ? financeAgency.toString().split(',') : [];
        const newYear: number = (year !== null && year !== '') ? Number(year) : null;

        if (states.length === 0 && financeAgencies.length === 0 && newYear === null) {
            this.onReset();
        } else {
            this.filteredProjects = this.projects.filter((project: Project) => {
                return this.filterByStatus(states, project.status) ||
                    this.filterByFinancingAgency(financeAgencies, project.financingAgency) ||
                    this.filterByYear(newYear, project.startDate, project.endDate);
            });
        }

        this.isLoading = false;
    }

    filterByStatus(states: string[], status: string) {
        return states.length > 0 ? states.includes(status) : false;
    }

    filterByFinancingAgency(financeAgencies: string[], financingAgency: string): boolean {
        const financingAgencies = financingAgency !== null ?
            this.streamFinancingAgencies(financingAgency).split(',') : [];

        return financeAgencies.length > 0 ? this.hasSameString(financeAgencies, financingAgencies) : false;
    }

    filterByYear(year: number, startDate: string, endDate: string): boolean {
        const startYear = Number(new Date(startDate).getFullYear());
        const endYear = Number(new Date(endDate).getFullYear());

        return year >= this.allYears[0] ? (year >= startYear && year <= endYear) : null;
    }

    streamFinancingAgencies(financingAgency: string): string {
        return financingAgency !== null ? financingAgency.replace(/\[|\]/g, '').trim() : '';
    }

    hasSameString(arr1: string[], arr2: string[]): boolean {
        for (const item of arr1) {
            if (arr2.includes(item)) {
                return true;
            }
        }
        return false;
    }

    replaceState(state: string) {
        return state === 'IMPLEMENTED' ? 'IMPLEMENTATION' : state;
    }
}
