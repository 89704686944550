import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {NotificationFlashService} from '../../../services/notification-flash.service';
import {ServerUrl} from '../../../services/server-url.service';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../../payloads/user.payload';
import {RestAPIService} from '../../../services/rest-api.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm!: UntypedFormGroup;

  forgotPasswordSubmitted = false;
  private _danger = new Subject<string>();

  staticAlertClosed = false;
  errorMessage!: string;

  @ViewChild('staticAlert', {static: false}) staticAlert!: NgbAlert;
  @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert!: NgbAlert;


  constructor(private restAPIService: RestAPIService,
              private notification: NotificationFlashService,
              private formBuilder: UntypedFormBuilder,
              private router: Router,
              private acRoute: ActivatedRoute,
              private serverUrl: ServerUrl,
              private translate: TranslateService) { }

  ngOnInit() {
    this._danger.subscribe(message => this.errorMessage = message);
    this._danger.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });

    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get forgot() { return this.forgotPasswordForm.controls; }

  onSubmit() {
    this.forgotPasswordSubmitted = true;
    if (this.forgotPasswordForm.invalid) {
        return;
    }

    const forgotPassword: { email: string } = new User().forgotPassword(
      this.forgotPasswordForm.value.email?.trim(),
    );

    this.restAPIService.forgotPassword(forgotPassword).subscribe(
      () => {
        this.redirectTo('sign-in');

        this.notification.success(this.translate.instant('notifications.success.forgot_password_request'));
      }, error => {
        if (error.status === 404) {
          this.errorMessage = this.translate.instant('notifications.danger.unknown_email');
        } else {
          this.redirectTo('500');
        }
      }
    );
  }

  redirectTo(path: string) {
    this.router.navigate(['/' + path], {relativeTo: this.acRoute});
  }
}
