<div
    class="map-container"
    [leafletZoom]="leafletZoom"
    [leafletCenter]="leafletCenter"
    leaflet
    [leafletOptions]="options"
    (leafletMapReady)="onMapReady($event)"
    (leafletMapZoomEnd)="onMapZoomEnd($event)"
>
<div class="map-controls">
    <button class="btn btn-sm btn-primary" (click)="switchToStreetView()">Vue Street</button>
    <button class="btn btn-sm btn-primary" (click)="switchToSatelliteView()">Vue Satellite</button>
  </div>
</div>
