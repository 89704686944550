import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: "root"
})
export class ServerUrl {
    // Performance Evaluation
    putEvaluationMode: string = environment.preparationMaturationURL + 'performance-evaluations/'
    performanceEvalURL: string = environment.preparationMaturationURL + 'performance-evaluations/';
    createEvaluationMode: string = this.performanceEvalURL + 'create';
    getEvaluationMode: string = this.performanceEvalURL + 'project/';

    //  Produit Service
    produitService: string = environment.preparationMaturationURL + 'product-services/';
    againstProduitService: string = environment.preparationMaturationURL + 'against-product-services/';
    validProduitService: string = environment.preparationMaturationURL + 'valid-product-services/';
    createProduitService: string = this.produitService + 'create';
    createAgainstProduitService: string = this.againstProduitService + 'create';
    createValidProduitService: string = this.validProduitService + 'create';

    //  SECURITY

    //  Authentification
    signinUrl: string = environment.securityUrl + 'auth/sign-in';
    signupUrl: string = environment.securityUrl + 'auth/sign-up';
    signoutUrl: string = environment.securityUrl + 'auth/sign-out/';

    //  Forgot Password
    forgotPasswordUrl: string = environment.securityUrl + 'forgot-password/request';
    resetPasswordUrl: string = environment.securityUrl + 'forgot-password/reset';

    //  PREPARATION/MATURATION & PROJECT PORTFOLIO

    //  Projects
    projectBaseURL: string = environment.preparationMaturationURL + 'projects/';

    //  Activities
    activityBaseURL: string = environment.preparationMaturationURL + 'activities/';
    createActivityURL = this.activityBaseURL + 'create-activity';

    //  Tasks
    taskBaseURL: string = environment.preparationMaturationURL + 'tasks/';

    // Cashouts
    cashoutBaseURL: string = environment.preparationMaturationURL + 'cashouts/';

    //  Markets
    marketBaseURL: string = environment.preparationMaturationURL + 'markets/';
    createMarketURL = this.marketBaseURL + 'create-market';
    getMarketURL = this.marketBaseURL + 'task/';

    //  Procurement
    procurementBaseURL: string = environment.preparationMaturationURL + 'procurements/';

    //  Finance Mobilisation
    financeMobilisationBaseURL: string = environment.preparationMaturationURL + 'finance-mobilisations/';

    //  Maturation Evaluation
    maturationEvaluationURL: string = environment.preparationMaturationURL + 'maturation-evaluations/';

    //  Signature Process
    signatureProcessURL: string = environment.preparationMaturationURL + 'signature-processes/';

    //  Implementation Process
    implementationProcessURL: string = environment.preparationMaturationURL + 'implementation-processes/';

    //  Reviews
    reviewBaseURL: string = environment.preparationMaturationURL + 'reviews/';
    createReviewURL = this.reviewBaseURL + 'create-review/';

    //  Cont Reviews
    contReviewBaseURL: string = environment.preparationMaturationURL + 'cont-reviews/';
    createContReviewURL = this.contReviewBaseURL + 'review/';

    //  Claims
    claimBaseURL: string = environment.preparationMaturationURL + 'claims/';
    createClaimURL = this.claimBaseURL + 'create-claim';

    //  Market Execution
    marketExecutionBaseURL: string = environment.preparationMaturationURL + 'market-executions/';

    //  SECURITY

    // Roles
    roleBase: string = environment.securityUrl + 'roles/';
    createRole: string = this.roleBase + 'create-role';

    //  Users
    userBase: string = environment.securityUrl + 'users/';
    createUser: string = this.userBase + 'create-user';

    // Components
    deleteComponentFromProject: string = environment.securityUrl + 'component/';

    // Connection Logs
    connectionLogBase: string = environment.securityUrl + 'connection-logs/';

    // Notifications
    notificationBaseURL: string = environment.preparationMaturationURL + 'notifications/';

    kobolToolBoxBaseURL: string = this.activityBaseURL + 'follow-up/kobol-tool-box';
}
