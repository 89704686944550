import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

// Importez les composants pour les routes
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModalModule} from 'ngx-bootstrap/modal';
import {DialogModule} from 'primeng/dialog';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {ChartModule} from 'primeng/chart';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {MultiSelectModule} from 'primeng/multiselect';
import {TableModule} from 'primeng/table';
import {PdfJsViewerModule} from 'ng2-pdfjs-viewer';
import {MapsModule} from '@syncfusion/ej2-angular-maps';
import {DataTablesModule} from 'angular-datatables';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {BadgeModule, BreadcrumbModule, FooterModule, HeaderModule, SidebarModule} from '@coreui/angular';
import {SharedModalModule} from '../dashboard/shared-modal/shared-modal.module';
import {SharedComponentModule} from '../shared-component/shared-component.module';


@NgModule({
    declarations: [
        LoginComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        RegisterComponent,

    ],
    imports: [
        ReactiveFormsModule,
        BadgeModule,
        BreadcrumbModule,
        FooterModule,
        HeaderModule,
        SidebarModule,
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        FormsModule,
        HttpClientModule,
        NgbModule,
        MatTabsModule,
        MatSnackBarModule,
        MatCheckboxModule,
        NgSelectModule,
        TranslateModule,
        DataTablesModule,
        MapsModule,
        PdfJsViewerModule,
        TableModule,
        MultiSelectModule,
        InputTextModule,
        ButtonModule,
        ChartModule,
        LeafletModule,
        DialogModule,
        ModalModule,
        SharedModalModule,
        SharedComponentModule,
        CommonModule,
        RouterModule
    ],
})
export class SecurityModule { }
