import {ActivityOper} from './show-activity.payload';
import {ActivityTask, Task} from './task.payload';
import {AnalyseProperty} from './utility.payload';
import {Indicator} from './indicator.payload';

export class Activity {
    idactivity: number;
    numb: string;
    title: string;
    component: any;
    subComponent: any;
    princResponsible: string;
    partResponsible: string;
    contextJustif: string;
    objective: string;
    deliverable: string;
    verifMeans: string;
    riskHypothese: string;
    startDate: string;
    endDate: string;
    amount: number;
    currency: string;
    amountFcfa: number;
    exchangeRate: number;
    status: string;
    validated: number;
    activityOper: ActivityOper;
    activityOpers: ActivityOper[];
    activityTasks: ActivityTask[];
    tasks: Task[];
    project: number;
    createdAt: string;
    updatedAt: string;
    disbursementRate: string | number;
    executionRate: string | any;
    physicRate: string | any;
    financeRate: string | any;
    delayRate: string | any;
    obtainedOutput: string;
    delay: any;
    analyseProperties: AnalyseProperty[];
    deadlineRate: string | any;
    toggle: number;
    inPreparation: boolean;
    isValidated: boolean;
    fcpRate: number | any;
    fcpYear: string;
    fcpBudgetLine: string;
    lessorRate: number | any;
    lessorYear: string;
    lessorBudgetLine: string;
    output: string;
    indicator: Indicator;
    keyIndicator: number;
    baseline: string;
    targets: string;
    latitude: string;
    longitude: string;
    plannedTasks: number;
    executedTasks: number;
    allocatedBudget: number;
    disbursedBudget: number;
    anoSubmissionDate: string;
    anoObtentionDate: string;

    constructor(latitude: string, longitude: string, numb: string, title: string, component: any, subComponent: any, princResponsible: string, partResponsible: string,
                contextJustif: string, objective: string, deliverable: string, verifMeans: string, riskHypothese: string,
                startDate: string, endDate: string, amount: number, currency: string, amountFcfa: number, exchangeRate: number,
                fcpRate: number, fcpYear: string, fcpBudgetLine: string, lessorRate: number, lessorYear: string, lessorBudgetLine: string,
                output: string, keyIndicator: number, baseline: string, targets: string, activityTasks: ActivityTask[],
                project: number, status: string = 'NOT_STARTED') {
        this.latitude = latitude;
        this.longitude = longitude;
        this.numb = numb;
        this.title = title;
        this.component = component;
        this.subComponent = subComponent;
        this.princResponsible = princResponsible;
        this.partResponsible = partResponsible;
        this.contextJustif = contextJustif;
        this.objective = objective;
        this.deliverable = deliverable;
        this.verifMeans = verifMeans;
        this.riskHypothese = riskHypothese;
        this.startDate = startDate;
        this.endDate = endDate;
        this.amount = amount;
        this.currency = currency;
        this.amountFcfa = amountFcfa;
        this.exchangeRate = exchangeRate;
        this.status = status;
        this.fcpRate = fcpRate;
        this.fcpYear = fcpYear;
        this.fcpBudgetLine = fcpBudgetLine;
        this.lessorRate = lessorRate;
        this.lessorYear = lessorYear;
        this.lessorBudgetLine = lessorBudgetLine;
        this.output = output;
        this.keyIndicator = keyIndicator;
        this.baseline = baseline;
        this.targets = targets;
        this.activityTasks = activityTasks;
        this.project = project;
    }
}

export class ActivityANODate {
    anoSubmissionDate: string;
    anoObtentionDate: string;

    constructor(anoSubmissionDate: string, anoObtentionDate: string = null) {
        this.anoSubmissionDate = anoSubmissionDate;
        this.anoObtentionDate = anoObtentionDate;
    }
}
