<!DOCTYPE html>
<section class="animated fadeIn" *ngIf="isLoaded">
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngFor="let activityDoc of activityDocs">
            <h4 class="py-2 roboto-bold-font">{{ activityDoc.numb }} - {{ activityDoc.title }}</h4>
            <div class="row px-4 mx-4 py-4">
                <div class="row d-flex justify-content-center">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-6 d-flex justify-content-center pointer"
                        *ngFor="let document of activityDoc.documents" (click)="view(document.iddocument)">
                            <div class="row text-center">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"><em class="bi bi-file-earmark-pdf"></em></div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">{{ document.name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
