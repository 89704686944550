import {Injectable} from '@angular/core';
import {MaturationEval, MaturationEvalDate} from '../payloads/maturation-evaluation.payload';
import {FinanceMobil, FinanceMobilDate} from '../payloads/finance-mobilisation.payload';
import {SignatureProc, SignatureProcDate} from '../payloads/signature-process.payload';
import {ImplementationProc, ImplementationProcDate} from '../payloads/implementation-process.payload';
import {MarketExecution, MarketExecutionDate} from '../payloads/complete-execution.payload';
import {TokenStorageService} from './security/token-storage.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AnalyseElement, ProjectFile} from '../payloads/utility.payload';
import {ProcDate, Procurement} from '../payloads/market.payload';

@Injectable({
    providedIn: 'root'
})

export class UtilityService {

    constructor(private tokenStorage: TokenStorageService, private router: Router, private acRoute: ActivatedRoute,
                private translate: TranslateService) {}

    fetchRegions() {
        return [
            { label: 'OUEST' },
            { label: 'EST' },
            { label: 'LITTORAL' },
            { label: 'EXTRÊME-NORD' },
            { label: 'NORD' },
            { label: 'ADAMAOUA' },
            { label: 'NORD-OUEST' },
            { label: 'CENTRE' },
            { label: 'SUD-OUEST' },
            { label: 'SUD' }
        ];
    }

    fetchDivisions() {
        return [
            { label: 'WOURI', region: 'LITTORAL' },
            { label: 'MOUNGO', region: 'LITTORAL' },
            { label: 'BAMBOUTOS', region: 'OUEST' },
            { label: 'BUI', region: 'NORD-OUEST' },
            { label: 'BOYO', region: 'NORD-OUEST' },
            { label: 'DJA-ET- LOBO', region: 'SUD' },
            { label: 'DONGA MANTUNG', region: 'NORD-OUEST' },
            { label: 'HAUT-NKAM', region: 'OUEST' },
            { label: 'HAUTS-PLATEAUX', region: 'OUEST' },
            { label: 'KOUNG-KHI', region: 'OUEST' },
            { label: 'KOUPÈ-MANENGOUBA', region: 'SUD-OUEST' },
            { label: 'LEBIALEM', region: 'SUD-OUEST' },
            { label: 'MANYU', region: 'SUD-OUEST' },
            { label: 'MANYO-DANAY', region: 'EXTRÊME-NORD' },
            { label: 'MBAM-ET-KIM', region: 'CENTRE' },
            { label: 'MEME', region: 'SUD-OUEST' },
            { label: 'MENCHUM', region: 'NORD-OUEST' },
            { label: 'MEZAM', region: 'NORD-OUEST' },
            { label: 'MOMO', region: 'NORD-OUEST' },
            { label: 'NDIAN', region: 'SUD-OUEST' },
            { label: 'NGO-KETUNJIA', region: 'NORD-OUEST' },
            { label: 'NOUN', region: 'OUEST' },
            { label: 'NYONG-ET-MFOUMOU', region: 'CENTRE' },
            { label: 'SANAGA-MARITIME', region: 'LITTORAL' },
            { label: 'DJEREM', region: 'ADAMAOUA' },
            { label: 'FARO-ET-DÉO', region: 'ADAMAOUA' },
            { label: 'MAYO-BANYO', region: 'ADAMAOUA' },
            { label: 'MBÉRÉ', region: 'ADAMAOUA' },
            { label: 'VINA', region: 'ADAMAOUA' },
            { label: 'DIAMARE', region: 'EXTRÊME-NORD' },
            { label: 'LOGONE-ET-CHARI', region: 'EXTRÊME-NORD' },
            { label: 'MAYO-KANI', region: 'EXTRÊME-NORD' },
            { label: 'MAYO-SAVA', region: 'EXTRÊME-NORD' },
            { label: 'MAYO-TSANAGA', region: 'EXTRÊME-NORD' },
            { label: 'BENOUE', region: 'NORD' },
            { label: 'FARO', region: 'NORD' },
            { label: 'MAYO-LOUTI', region: 'NORD' },
            { label: 'MAYO-REY', region: 'NORD' },
            { label: 'HAUTE-SANAGA', region: 'CENTRE' },
            { label: 'LEKIÉ', region: 'CENTRE' },
            { label: 'MBAM-ET-INOUBOU', region: 'CENTRE' },
            { label: 'MÉFOU-ET-AFAMBA', region: 'CENTRE' },
            { label: 'MÉFOU-ET-AKONO', region: 'CENTRE' },
            { label: 'MFOUNDI', region: 'CENTRE' },
            { label: 'NYONG-ET-KÉLLÉ', region: 'CENTRE' },
            { label: 'NYONG-ET-SO O', region: 'CENTRE' },
            { label: 'BOUMBA-ET-NGOKO', region: 'EST' },
            { label: 'HAUT-NYONG', region: 'EST' },
            { label: 'KADEY', region: 'EST' },
            { label: 'LOM-ET-DJEREM', region: 'EST' },
            { label: 'MVILA', region: 'SUD' },
            { label: 'OCÉAN', region: 'SUD' },
            { label: 'VALLÉE-DU-NTEM', region: 'SUD' },
            { label: 'NKAM', region: 'LITTORAL' },
            { label: 'FAKO', region: 'SUD-OUEST' },
            { label: 'MENOUA', region: 'OUEST' },
            { label: 'MIFI', region: 'OUEST' },
            { label: 'NDÉ', region: 'OUEST' },
        ];
    }

    fetchSubDivisions() {
        return [
            { label: 'KUMBO', division: 'BUI' },
            { label: 'BABADJOU', division: 'BAMBOUTOS' },
            { label: 'MAMFE-CENTRAL', division: 'MANYU' },
            { label: 'TCHATI-BALI', division: 'MANYO-DANAY' },
            { label: 'NGAMBE-TIKAR', division: 'MBAM-ET-KIM' },
            { label: 'BATIBO', division: 'MOMO' },
            { label: 'DIBOMBARI', division: 'MOUNGO' },
            { label: 'BABESSI', division: 'NGO-KETUNJA' },
            { label: 'NGAOUNDAL', division: 'DJEREM' },
            { label: 'TIBATI', division: 'DJEREM' },
            { label: 'ABONG-MBANG', division: 'HAUT-NYONG' },
            { label: 'AFANLOUM', division: 'MÉFOU-ET-AFAMBA' },
            { label: 'AKO', division: 'DONGA MANTUNG' },
            { label: 'AKOM II', division: 'OCÉAN' },
            { label: 'AKWAYA', division: 'MANYU' },
            { label: 'ALOU', division: 'LEBIALEM' },
            { label: 'AMBAM', division: 'VALLÉE-DU-NTEM' },
            { label: 'NGIE', division: 'MOMO' },
            { label: 'ANGOSSAS', division: 'HAUT-NYONG' },
            { label: 'ATOK', division: 'HAUT-NYONG' },
            { label: 'AWAÉ', division: 'MÉFOU-ET-AFAMBA' },
            { label: 'BAFANG', division: 'HAUT-NKAM' },
            { label: 'BAKOU', division: 'HAUT-NKAM' },
            { label: 'BAHAM', division: 'HAUT-PLATEAUX' },
            { label: 'BALIKUMBAT', division: 'NGO-KETUNJA' },
            { label: 'BAMENDJOU', division: 'HAUT-PLATEAUX' },
            { label: 'BAMUSSO', division: 'NDIAN' },
            { label: 'BANA', division: 'HAUT-NKAM' },
            { label: 'BANDJA', division: 'HAUT-NKAM' },
            { label: 'DJEMBEM', division: 'KOUNG-KHI' },
            { label: 'BANGEM', division: 'KOUPÈ-MANENGOUBA' },
            { label: 'BANKA', division: 'HAUT-NKAM' },
            { label: 'BANGOU', division: 'HAUT-PLATEAUX' },
            { label: 'BANGOURAIN', division: 'NOUN' },
            { label: 'BANKIM', division: 'MANYO-BANYO' },
            { label: 'BANYO', division: 'MANYO-BANYO' },
            { label: 'GALIM-TIGNÈRE', division: 'FARO-ET-DÉO' },
            { label: 'MAYO-BALÉO', division: 'FARO-ET-DÉO' },
            { label: 'TIGNÈRE', division: 'FARO-ET-DÉO' },
            { label: 'KONTCHA', division: 'FARO-ET-DÉO' },
            { label: 'MAYO-DARLÉ', division: 'MANYO-BANYO' },
            { label: 'DIR', division: 'MBÉRÉ' },
            { label: 'DJOHONG', division: 'MBÉRÉ' },
            { label: 'MEIGANGA', division: 'MBÉRÉ' },
            { label: 'NGAOUI', division: 'MBÉRÉ' },
            { label: 'BELEL', division: 'VINA' },
            { label: 'MBE', division: 'VINA' },
            { label: 'NGANHA', division: 'VINA' },
            { label: 'NGAOUNDÉRÉ II', division: 'VINA' },
            { label: 'NGAOUNDÉRÉ I', division: 'VINA' },
            { label: 'NYAMBAKA', division: 'VINA' },
            { label: 'MARTAP', division: 'VINA' },
            { label: 'BOGO', division: 'DIAMARE' },
            { label: 'DARGALA', division: 'DIAMARE' },
            { label: 'GAWAZA', division: 'DIAMARE' },
            { label: 'MAROUA I', division: 'DIAMARE' },
            { label: 'MAROUA II', division: 'DIAMARE' },
            { label: 'MAROUA III', division: 'DIAMARE' },
            { label: 'MERI', division: 'DIAMARE' },
            { label: 'NDOUKOULA', division: 'DIAMARE' },
            { label: 'PETTÉ', division: 'DIAMARE' },
            { label: 'BLANGOUA', division: 'LOGONE-ET-CHARI' },
            { label: 'DARAK', division: 'LOGONE-ET-CHARI' },
            { label: 'FOTOKOL', division: 'LOGONE-ET-CHARI' },
            { label: 'GOULFEY', division: 'LOGONE-ET-CHARI' },
            { label: 'HILE-ALIFA', division: 'LOGONE-ET-CHARI' },
            { label: 'KOUSSÉRI', division: 'LOGONE-ET-CHARI' },
            { label: 'LOGONE-BIRNI', division: 'LOGONE-ET-CHARI' },
            { label: 'MAKARY', division: 'LOGONE-ET-CHARI' },
            { label: 'WAZA', division: 'LOGONE-ET-CHARI' },
            { label: 'ZINA', division: 'LOGONE-ET-CHARI' },
            { label: 'DATCHEKA', division: 'MANYO-DANAY' },
            { label: 'GOBO', division: 'LOGONE-ET-CHARI' },
            { label: 'GUEME', division: 'MANYO-DANAY' },
            { label: 'GUERE', division: 'MANYO-DANAY' },
            { label: 'KAI-KAI', division: 'MANYO-DANAY' },
            { label: 'KALFOU', division: 'MANYO-DANAY' },
            { label: 'KAY-HAY', division: 'MANYO-DANAY' },
            { label: 'MAGA', division: 'MANYO-DANAY' },
            { label: 'TCHATI-BALI', division: 'MANYO-DANAY' },
            { label: 'WINA', division: 'MANYO-DANAY' },
            { label: 'YAGOUA', division: 'MANYO-DANAY' },
            { label: 'DZIGUILAO', division: 'MAYO-KANI' },
            { label: 'GUIDIGUIS', division: 'MAYO-KANI' },
            { label: 'KAÉLÉ', division: 'MAYO-KANI' },
            { label: 'MINDIF', division: 'MAYO-KANI' },
            { label: 'MOULVOUDAYE', division: 'MAYO-KANI' },
            { label: 'MOUTOURWA', division: 'MAYO-KANI' },
            { label: 'TOULOUM', division: 'MAYO-KANI' },
            { label: 'KOLOFATA', division: 'MAYO-SAVA' },
            { label: 'MORA', division: 'MAYO-SAVA' },
            { label: 'TOKOMBÉRÉ', division: 'MAYO-SAVA' },
            { label: 'BOURRHA', division: 'MAYO-TSANAGA' },
            { label: 'HINA', division: 'MAYO-TSANAGA' },
            { label: 'KOZA', division: 'MAYO-TSANAGA' },
            { label: 'MOGODÉ', division: 'MAYO-TSANAGA' },
            { label: 'MOKOLO', division: 'MAYO-TSANAGA' },
            { label: 'MOZOGO', division: 'MAYO-TSANAGA' },
            { label: 'SOULEDÉ-ROUA', division: 'MAYO-TSANAGA' },
            { label: 'BARNDAKÉ (MAYO HOURNA)', division: 'BENOUE' },
            { label: 'BASHÉO (BASCHÉO)', division: 'BENOUE' },
            { label: 'NGAOUNDÉRÉ III', division: 'VINA' },
            { label: 'BIBEMI', division: 'BENOUE' },
            { label: 'DEMBO', division: 'BENOUE' },
            { label: 'GAROUA I', division: 'BENOUE' },
            { label: 'GAROUA II', division: 'BENOUE' },
            { label: 'GASHIGA (DEMSA)', division: 'BENOUE' },
            { label: 'LAGDO', division: 'BENOUE' },
            { label: 'NGONG (TCHEBOA)', division: 'BENOUE' },
            { label: 'PITOA', division: 'BENOUE' },
            { label: 'TOUROUA', division: 'BENOUE' },
            { label: 'GAROUA III', division: 'BENOUE' },
            { label: 'BEKA', division: 'FARO' },
            { label: 'POLI I', division: 'FARO' },
            { label: 'POLI II', division: 'FARO' },
            { label: 'FIGUIL', division: 'MAYO-LOUTI' },
            { label: 'GUIDER', division: 'MAYO-LOUTI' },
            { label: 'MAYO-OULO', division: 'MAYO-LOUTI' },
            { label: 'MANDINGRING', division: 'MAYO-REY' },
            { label: 'TCHOLLIRÉ', division: 'MAYO-REY' },
            { label: 'TOUBORO', division: 'MAYO-REY' },
            { label: 'REY-BOUBA', division: 'MAYO-REY' },
            { label: 'BIBEY', division: 'HAUTE-SANAGA' },
            { label: 'LEMBE-YEZOUM', division: 'HAUTE-SANAGA' },
            { label: 'MBANDJOCK', division: 'HAUTE-SANAGA' },
            { label: 'MINTA', division: 'HAUTE-SANAGA' },
            { label: 'NANGA-EBOKO', division: 'HAUTE-SANAGA' },
            { label: 'NKOTENG', division: 'HAUTE-SANAGA' },
            { label: 'NSEM', division: 'HAUTE-SANAGA' },
            { label: 'BATCHENGA', division: 'LEKIÉ' },
            { label: 'EBEBDA', division: 'LEKIÉ' },
            { label: 'ELIG-MFOMO', division: 'LEKIÉ' },
            { label: 'EVODOULA', division: 'LEKIÉ' },
            { label: 'LOBO', division: 'LEKIÉ' },
            { label: 'MONATÉLÉ', division: 'LEKIÉ' },
            { label: 'OBALA', division: 'LEKIÉ' },
            { label: 'OKOLA', division: 'LEKIÉ' },
            { label: 'SA\'A', division: 'LEKIÉ' },
            { label: 'BAFIA', division: 'MBAM-ET-INOUBOU' },
            { label: 'BOKITO', division: 'MBAM-ET-INOUBOU' },
            { label: 'DEUK', division: 'MBAM-ET-INOUBOU' },
            { label: 'KIIKI', division: 'MBAM-ET-INOUBOU' },
            { label: 'KON-YAMBETTA', division: 'MBAM-ET-INOUBOU' },
            { label: 'MAKÉNÉNÉ', division: 'MBAM-ET-INOUBOU' },
            { label: 'NDIKINIMÉKI', division: 'MBAM-ET-INOUBOU' },
            { label: 'NITOUKOU', division: 'MBAM-ET-INOUBOU' },
            { label: 'OMBESSA', division: 'MBAM-ET-INOUBOU' },
            { label: 'MBANGASSINA', division: 'MBAM-ET-KIM' },
            { label: 'NGORO', division: 'MBAM-ET-KIM' },
            { label: 'NTUI', division: 'MBAM-ET-KIM' },
            { label: 'YOKO', division: 'MBAM-ET-KIM' },
            { label: 'EDZENDOUAN', division: 'MÉFOU-ET-AFAMBA' },
            { label: 'ESSE', division: 'MÉFOU-ET-AFAMBA' },
            { label: 'MFOU', division: 'MÉFOU-ET-AFAMBA' },
            { label: 'NKOLAFAMBA', division: 'MÉFOU-ET-AFAMBA' },
            { label: 'OLANGUINA', division: 'MÉFOU-ET-AFAMBA' },
            { label: 'SOA', division: 'MÉFOU-ET-AFAMBA' },
            { label: 'AKONO', division: 'MÉFOU-ET-AKONO' },
            { label: 'BIKOK', division: 'MÉFOU-ET-AKONO' },
            { label: 'MBANKOMO', division: 'MÉFOU-ET-AKONO' },
            { label: 'NGOUMOU', division: 'MÉFOU-ET-AKONO' },
            { label: 'YAOUNDÉ I', division: 'MFOUNDI' },
            { label: 'YAOUNDÉ II', division: 'MFOUNDI' },
            { label: 'YAOUNDÉ III', division: 'MFOUNDI' },
            { label: 'YAOUNDÉ IV', division: 'MFOUNDI' },
            { label: 'YAOUNDÉ V', division: 'MFOUNDI' },
            { label: 'YAOUNDÉ VI', division: 'MFOUNDI' },
            { label: 'YAOUNDÉ VII', division: 'MFOUNDI' },
            { label: 'BIYOUHA', division: 'YONG-ET-KÉLLÉ' },
            { label: 'BONDJOCK', division: 'YONG-ET-KÉLLÉ' },
            { label: 'BOT-MAKAK', division: 'YONG-ET-KÉLLÉ' },
            { label: 'DIBANG', division: 'YONG-ET-KÉLLÉ' },
            { label: 'ESÉKA', division: 'YONG-ET-KÉLLÉ' },
            { label: 'MAKAK', division: 'YONG-ET-KÉLLÉ' },
            { label: 'MATOMB', division: 'YONG-ET-KÉLLÉ' },
            { label: 'MESSONDO', division: 'YONG-ET-KÉLLÉ' },
            { label: 'NGOG-MAPUBI', division: 'YONG-ET-KÉLLÉ' },
            { label: 'NGUI-BASSAL', division: 'YONG-ET-KÉLLÉ' },
            { label: 'AKONOLINGA', division: 'NYONG-ET-MFOUMOU' },
            { label: 'AYOS', division: 'NYONG-ET-MFOUMOU' },
            { label: 'ENDOM', division: 'NYONG-ET-MFOUMOU' },
            { label: 'KOBDOMBO', division: 'NYONG-ET-MFOUMOU' },
            { label: 'MENGANG', division: 'NYONG-ET-MFOUMOU' },
            { label: 'AKOEMAN', division: 'NYONG-ET-SO\'O' },
            { label: 'DZENG', division: 'NYONG-ET-SO\'O' },
            { label: 'MBALMAYO', division: 'NYONG-ET-SO\'O' },
            { label: 'MENGUEME', division: 'NYONG-ET-SO\'O' },
            { label: 'NGOMEDZAP', division: 'NYONG-ET-SO\'O' },
            { label: 'NKOLMETET', division: 'NYONG-ET-SO\'O' },
            { label: 'GARI-GOMBO', division: 'BOUMBA-ET-NGOKO' },
            { label: 'MOLOUNDOU', division: 'BOUMBA-ET-NGOKO' },
            { label: 'SALAPOUMBÉ', division: 'BOUMBA-ET-NGOKO' },
            { label: 'YOKADOUMA', division: 'BOUMBA-ET-NGOKO' },
            { label: 'DIMAKO', division: 'HAUT-NYONG' },
            { label: 'DOUMAINTANG', division: 'HAUT-NYONG' },
            { label: 'DOUMÉ', division: 'HAUT-NYONG' },
            { label: 'LOMIÉ', division: 'HAUT-NYONG' },
            { label: 'MBOMA', division: 'HAUT-NYONG' },
            { label: 'MESSAMENA', division: 'HAUT-NYONG' },
            { label: 'MESSOK', division: 'HAUT-NYONG' },
            { label: 'MINDOUROU', division: 'HAUT-NYONG' },
            { label: 'NGOYLA', division: 'HAUT-NYONG' },
            { label: 'NGUELEMENDOUKA', division: 'HAUT-NYONG' },
            { label: 'SOMALOMO', division: 'HAUT-NYONG' },
            { label: 'BATOURI', division: 'KADEY' },
            { label: 'KENTZOU', division: 'KADEY' },
            { label: 'KETTE', division: 'KADEY' },
            { label: 'MBANG', division: 'KADEY' },
            { label: 'NDELELE', division: 'KADEY' },
            { label: 'NGUELEBOK', division: 'KADEY' },
            { label: 'OULI', division: 'KADEY' },
            { label: 'MBOTORO', division: 'KADEY' },
            { label: 'BOMBE', division: 'KADEY' },
            { label: 'NDEM-NAM', division: 'KADEY' },
            { label: 'BÉLABO', division: 'LOM-ET-DJEREM' },
            { label: 'BERTOUA I', division: 'LOM-ET-DJEREM' },
            { label: 'BERTOUA II', division: 'LOM-ET-DJEREM' },
            { label: 'BÉTARÉ-OYA', division: 'LOM-ET-DJEREM' },
            { label: 'DIANG', division: 'LOM-ET-DJEREM' },
            { label: 'GAROUA-BOULAÏ', division: 'LOM-ET-DJEREM' },
            { label: 'MANDJOU', division: 'LOM-ET-DJEREM' },
            { label: 'NGOURA', division: 'KADEY' },
            { label: 'BENGBIS', division: 'DJA-ET-LOBO' },
            { label: 'DJOUM', division: 'DJA-ET-LOBO' },
            { label: 'MEYOMESSALA', division: 'DJA-ET-LOBO' },
            { label: 'MEYOMESSI', division: 'DJA-ET-LOBO' },
            { label: 'MINTOM', division: 'DJA-ET-LOBO' },
            { label: 'OVENG', division: 'DJA-ET-LOBO' },
            { label: 'SANGMÉLIMA I', division: 'DJA-ET-LOBO' },
            { label: 'ZOÉTÉLÉ', division: 'DJA-ET-LOBO' },
            { label: 'SANGMÉLIMA II', division: 'DJA-ET-LOBO' },
            { label: 'BIWONG-BANE', division: 'MVILLA' },
            { label: 'BIWONG-BULU', division: 'MVILLA' },
            { label: 'EBOLOWA I', division: 'MVILLA' },
            { label: 'EBOLOWA II', division: 'MVILLA' },
            { label: 'EFOULAN', division: 'MVILLA' },
            { label: 'MENGONG', division: 'MVILLA' },
            { label: 'MVANGANE', division: 'MVILLA' },
            { label: 'NGOULEMAKONG', division: 'MVILLA' },
            { label: 'BIPINDI', division: 'OCÉAN' },
            { label: 'CAMPO', division: 'OCÉAN' },
            { label: 'KRIBI I', division: 'OCÉAN' },
            { label: 'KRIBI II', division: 'OCÉAN' },
            { label: 'LOKUNDJE', division: 'OCÉAN' },
            { label: 'LOLODORF', division: 'OCÉAN' },
            { label: 'MVENGUE', division: 'OCÉAN' },
            { label: 'NIETE', division: 'OCÉAN' },
            { label: 'MA\'AN', division: 'VALLÉE-DU-NTEM' },
            { label: 'OLAMZE', division: 'VALLÉE-DU-NTEM' },
            { label: 'KIOSSI', division: 'VALLÉE-DU-NTEM' },
            { label: 'BARÉ', division: 'MOUNGO' },
            { label: 'BONALÉA', division: 'MOUNGO' },
            { label: 'EBONE', division: 'MOUNGO' },
            { label: 'LOUM', division: 'MOUNGO' },
            { label: 'MANJO', division: 'MOUNGO' },
            { label: 'MBANGA', division: 'MOUNGO' },
            { label: 'MELONG', division: 'MOUNGO' },
            { label: 'MOMBO', division: 'MOUNGO' },
            { label: 'NKONGSAMBA I', division: 'MOUNGO' },
            { label: 'NKONGSAMBA II', division: 'MOUNGO' },
            { label: 'NKONGSAMBA III', division: 'MOUNGO' },
            { label: 'PENJA', division: 'MOUNGO' },
            { label: 'NDOBIAN', division: 'NKAM' },
            { label: 'NKONDJOCK', division: 'NKAM' },
            { label: 'YABASSI', division: 'NKAM' },
            { label: 'YINGUI', division: 'NKAM' },
            { label: 'DIZANGUÉ', division: 'SANAGA-MARITIME' },
            { label: 'DIBAMBA', division: 'SANAGA-MARITIME' },
            { label: 'ÉDÉA I', division: 'SANAGA-MARITIME' },
            { label: 'ÉDÉA II', division: 'SANAGA-MARITIME' },
            { label: 'MASSOCK', division: 'SANAGA-MARITIME' },
            { label: 'MOUANKO', division: 'SANAGA-MARITIME' },
            { label: 'NDOM', division: 'SANAGA-MARITIME' },
            { label: 'NGAMBE', division: 'SANAGA-MARITIME' },
            { label: 'NGWEI', division: 'SANAGA-MARITIME' },
            { label: 'NYANON', division: 'SANAGA-MARITIME' },
            { label: 'POUMA', division: 'SANAGA-MARITIME' },
            { label: 'DOUALA I', division: 'WOURI' },
            { label: 'DOUALA II', division: 'WOURI' },
            { label: 'DOUALA III', division: 'WOURI' },
            { label: 'DOUALA IV', division: 'WOURI' },
            { label: 'DOUALA V', division: 'WOURI' },
            { label: 'DOUALA VI', division: 'WOURI' },
            { label: 'BELO', division: 'BOYO' },
            { label: 'BUM', division: 'BOYO' },
            { label: 'FUNDONG', division: 'BOYO' },
            { label: 'NJINIKOM', division: 'BOYO' },
            { label: 'OKU', division: 'BUI' },
            { label: 'JAKIRI', division: 'BUI' },
            { label: 'NONI', division: 'BUI' },
            { label: 'MBVEN', division: 'BUI' },
            { label: 'NKUM', division: 'BUI' },
            { label: 'MISAJE', division: 'DONGA MANTUNG' },
            { label: 'NDU', division: 'DONGA MANTUNG' },
            { label: 'NKAMBÉ', division: 'DONGA MANTUNG' },
            { label: 'NWA', division: 'DONGA MANTUNG' },
            { label: 'BENAKUMA', division: 'MENCHUM' },
            { label: 'FURU-AWA', division: 'MENCHUM' },
            { label: 'WUM', division: 'MENCHUM' },
            { label: 'ZHOA', division: 'MENCHUM' },
            { label: 'BAFUT', division: 'MEZAM' },
            { label: 'BALI', division: 'MEZAM' },
            { label: 'BAMENDA I', division: 'MEZAM' },
            { label: 'BAMENDA II', division: 'MEZAM' },
            { label: 'BAMENDA III', division: 'MEZAM' },
            { label: 'SANTA', division: 'MEZAM' },
            { label: 'TUBAH', division: 'MEZAM' },
            { label: 'MBENGWI', division: 'MOMO' },
            { label: 'NJIKWA', division: 'MOMO' },
            { label: 'WIDIKUM-MENKA', division: 'MOMO' },
            { label: 'NDOP', division: 'NGO-KETUNJA' },
            { label: 'BALIKUMBAT', division: 'NGO-KETUNJA' },
            { label: 'BABESSI', division: 'NGO-KETUNJA' },
            { label: 'BUEA', division: 'FAKO' },
            { label: 'LIMBÉ I', division: 'FAKO' },
            { label: 'LIMBÉ II', division: 'FAKO' },
            { label: 'LIMBÉ III', division: 'FAKO' },
            { label: 'MUYUKA', division: 'FAKO' },
            { label: 'TIKO', division: 'FAKO' },
            { label: 'WEST-COAST', division: 'FAKO' },
            { label: 'BANGEM', division: 'KOUPÈ-MANENGOUBA' },
            { label: 'TOMBEL', division: 'KOUPÈ-MANENGOUBA' },
            { label: 'NGUTI', division: 'KOUPÈ-MANENGOUBA' },
            { label: 'FONTEM', division: 'LEBIALEM' },
            { label: 'WABANE', division: 'LEBIALEM' },
            { label: 'EYUMOJOCK', division: 'MANYU' },
            { label: 'UPPER-BAYANG', division: 'MANYU' },
            { label: 'KONYE', division: 'MEME' },
            { label: 'KUMBA I', division: 'MEME' },
            { label: 'KUMBA II', division: 'MEME' },
            { label: 'KUMBA III', division: 'MEME' },
            { label: 'MBONGE', division: 'MEME' },
            { label: 'DIKOME-BALUE', division: 'NDIAN' },
            { label: 'EKONDO-TITI', division: 'NDIAN' },
            { label: 'IDABATO', division: 'NDIAN' },
            { label: 'ISANGUELE', division: 'NDIAN' },
            { label: 'KOMBO-ABEDIMO', division: 'NDIAN' },
            { label: 'KOMBO-ITINDI', division: 'NDIAN' },
            { label: 'MUNDEMBA', division: 'NDIAN' },
            { label: 'TOKO', division: 'NDIAN' },
            { label: 'BATCHAM', division: 'BAMBOUTOS' },
            { label: 'GALIM', division: 'BAMBOUTOS' },
            { label: 'MBOUDA', division: 'BAMBOUTOS' },
            { label: 'KÉKEM', division: 'HAUT-NKAM' },
            { label: 'BANWA', division: 'HAUT-NKAM' },
            { label: 'BATIÉ', division: 'HAUT-PLATEAUX' },
            { label: 'BAYANGAM', division: 'KOUNG-KHI' },
            { label: 'POUMOUGNÉ', division: 'KOUNG-KHI' },
            { label: 'DSCHANG', division: 'MENOUA' },
            { label: 'FOKOUÉ', division: 'MENOUA' },
            { label: 'FONGO-TONGO', division: 'MENOUA' },
            { label: 'NKONG-NDI', division: 'MENOUA' },
            { label: 'PENKA-MICHEL', division: 'MENOUA' },
            { label: 'SANTCHOU', division: 'MENOUA' },
            { label: 'BAFOUSSAM I', division: 'MIFI' },
            { label: 'BAFOUSSAM II', division: 'MIFI' },
            { label: 'BAFOUSSAM III', division: 'MIFI' },
            { label: 'BANGANGTÉ', division: 'NDÉ' },
            { label: 'BASSAMBA', division: 'NDÉ' },
            { label: 'BAZOU', division: 'NDÉ' },
            { label: 'TONGA', division: 'NDÉ' },
            { label: 'BALENGOU', division: 'NDÉ' },
            { label: 'FOUMBAN', division: 'NOUN' },
            { label: 'FOUMBOT', division: 'NOUN' },
            { label: 'KOUOPTAMO', division: 'NOUN' },
            { label: 'KOUTABA', division: 'NOUN' },
            { label: 'MAGBA', division: 'NOUN' },
            { label: 'MALENTOUEN', division: 'NOUN' },
            { label: 'MASSANGAM', division: 'NOUN' },
            { label: 'NJIMOM', division: 'NOUN' }
        ];
    }

    fetchScopes() {
        return ['NATIONAL', 'REGIONAL', 'DIVISIONAL', 'SUB_DIVISIONAL'];
    }

    fetchProjectTypes() {
        return ['Assistance Technique', 'Investissement (FBCF)', 'Renforcement des Capacités'];
    }

    fetchFinancingAgencies() {
        return [
            'Agence Francaise de Développement (AFD)',
            'Banque Africain de Développement (BAD)',
            'Banque de Développement des Etats d\'Afrique Centrale (BDEAC)',
            'Banque Europeen de Développement (BED)',
            'Banque Europeen d\'Développement (BEI)',
            'Banque Islamique (BI)',
            'Banque Mondiale (BM)',
            'Fond International de Développement (FIDA)'
        ];
    }

    fetchDSCESectors() {
        return ['Développement Rural', 'Développement Sociale', 'Education', 'Energie', 'Gouvernance', 'Industries et Service', 'Infrastructures', 'Santé'];
    }

    fetchCurrencies() {
        return ['$', '€', '£', 'XAF', '¥'];
    }

    fetchPreparationStatus() {
        return ['PREPARATION', 'MATURATION', 'SIGNATURE', 'IMPLEMENTATION'];
    }

    fetchPreparationArchiveStatus() {
        return ['IMPLEMENTED', 'CANCELED'];
    }

    fetchPortefeuilleStatus() {
        return ['IMPLEMENTED'];
    }

    fetchPortefeuilleArchiveStatus() {
        return ['CANCELED', 'TERMINATED'];
    }

    fetchArchiveStatus() {
        return ['CANCELED', 'TERMINATED'];
    }

    fetchStatus() {
        return ['PREPARATION', 'MATURATION', 'SIGNATURE', 'IMPLEMENTATION', 'IMPLEMENTED'];
    }

    fetchFilterStates() {
        return ['PREPARATION', 'MATURATION', 'SIGNATURE', 'IMPLEMENTED', 'TERMINATED', 'CANCELED'];
    }

    fetchStates() {
        return ['PREPARATION', 'MATURATION', 'SIGNATURE', 'IMPLEMENTATION', 'IMPLEMENTED', 'TERMINATED', 'CANCELED'];
    }

    fetchCategories() {
        return [
            'Cabinet',
            'Compléter Méthode',
            'Consultant Individuel',
            'Firme de Consultants',
            'Fournitures',
            'ONG',
            'Radio Locales des Zones d\'Implementation du Projet',
            'Service Autres que les Services de Consultants',
        ];
    }

    fetchApproaches() {
        return [
            'Arrangements Préférés pour les Activités de Faible-Valeur, Faible-Risques',
            'Marchés des Fournitures',
            'Service Autres que les Services de Consultants',
            'Service de Consultants'
        ];
    }

    fetchContractTypes() {
        return [
            'Fournitures',
            'Services',
            'Service Autres que les Services de Consultants',
            'Service de Consultants'
        ];
    }

    fetchMarketTypes() {
        return [
            {
                type: 'Fournitures et Services (Hors mis Prestation Intellectuelle)',
                methods: [
                    'Appel d\'Offres International (AOI)',
                    'Appel d\'Offres National (AON)',
                    'Consultation Fournisseurs (CF)',
                    'Sélection par Attende Directe'
                ],
                procurements: [
                    new Procurement(
                        'Date de Soumission DAO/DC en Commission',
                        new ProcDate(null),
                        true
                    ),
                    new Procurement(
                        'Date de Publication de la DAO/DC',
                        new ProcDate(null),
                        true,
                        'PUBLICATION'
                    ),
                    new Procurement(
                        'Date d\'Attribution du Marché',
                        new ProcDate(null),
                        true
                    ),
                    new Procurement(
                        'Date de Publication des Résultats',
                        new ProcDate(null),
                        true
                    ),
                    new Procurement(
                        'Date de Signature du Contrat',
                        new ProcDate(null),
                        true,
                        'SIGNATURE'
                    )
                ]
            },
            {
                type: 'Travaux',
                methods: [
                    'Appel d\'Offres International (AOI)',
                    'Appel d\'Offres National (AON)',
                    'Consultation Fournisseurs (CF)'
                ],
                procurements: [
                    new Procurement(
                        'Date de Soumission DAO/DC en Commission',
                        new ProcDate(null),
                        true
                    ),
                    new Procurement(
                        'Date de Publication de la DAO/DC',
                        new ProcDate(null),
                        true,
                        'PUBLICATION'
                    ),
                    new Procurement(
                        'Date d\'Attribution du Marché',
                        new ProcDate(null),
                        true
                    ),
                    new Procurement(
                        'Date de Publication des Résultats',
                        new ProcDate(null),
                        true
                    ),
                    new Procurement(
                        'Date de Signature du Contrat',
                        new ProcDate(null),
                        true,
                        'SIGNATURE'
                    )
                ]
            },
            {
                type: 'Service de Prestation Intellectuelle (SPI)',
                methods: [
                    'Sélection au Moindre Coût (SMC)',
                    'Sélection de Consultants Individuels (SCI)',
                    'Sélection Fondée sur Qualification et Contrat',
                    'Sélection Fondée sur la Qualification du Consultant (SFQ)'
                ],
                procurements : [
                    new Procurement(
                        'Date de Soumission des TDR à l\'ANO du PTF',
                        new ProcDate(null),
                        true
                    ),
                    new Procurement(
                        'Date de l\'Obtention de l\'ANO du Bailleur',
                        new ProcDate(null),
                        true
                    ),
                    new Procurement(
                        'Date de Sollicitation de Publication de la Sollicitation de Manifestement d\'Interet',
                        new ProcDate(null),
                        true
                    ),
                    new Procurement(
                        'Date de Soumission de la DP à l\'Examen de la Commission',
                        new ProcDate(null),
                        true
                    ),
                    new Procurement(
                        'Date de l\'Obtention de l\'Avis Favorable de la Commission de la DP',
                        new ProcDate(null),
                        true
                    ),
                    new Procurement(
                        'Date de Publication de la DP',
                        new ProcDate(null),
                        true,
                        'PUBLICATION'
                    ),
                    new Procurement(
                        'Date d\'Attribution du Marché',
                        new ProcDate(null),
                        true
                    ),
                    new Procurement(
                        'Date de Publication des Résultats',
                        new ProcDate(null),
                        true
                    ),
                    new Procurement(
                        'Date de Signature du Contrat',
                        new ProcDate(null),
                        true,
                        'SIGNATURE'
                    )
                ]
            }
        ];
    }

    fetchMethods() {
        return [
            'Accords-Cadres (AC)',
            'Achats Auprès des Agences des Nations Unies (AAANY)',
            'Appel d\'Offres International Restreint (AOIR)',
            'Appel d\'Offres National (AON)',
            'Appel d\'Offres Ouvert International (AOOI)',
            'Consultation Fournisseurs (CF)',
            'Passation des Marchés pour les Organisations Non-Gouvernementale (PMONG)',
            'Sélection au Moindre Coût (SMC)',
            'Sélection dans le Cadre d\'un Budget Déterminé (SCBD)',
            'Sélection de Consultants Individuels (SCI)',
            'Sélection Fondée sur la Qualification du Consultant (SFQ)',
            'Sélection Fondée sur la Qualité et le Coût (SFQC)',
            'Sélection Fondée sur la Qualité (SFQ)',
            'Sélection par Entente Directe (SED)'
        ];
    }

    fetchExamTypes() {
        return ['A Priori', 'A Postériori'];
    }

    fetchOrderTypes() {
        return ['Démarrage', 'Suspension', 'Reprise'];
    }

    fecthStatuts() {
        return ['NOT_STARTED', 'IN_PROGRESS', 'CANCELED', 'TERMINATED'];
    }

    fecthValidateLevels() {
        return ['LEVEL_0', 'LEVEL_I', 'LEVEL_II', 'LEVEL_III', 'LEVEL_IV'];
    }

    fetchFinanceMobilisations() {
        return [
            new FinanceMobil(
                'Processus de Négociation',
                new FinanceMobilDate(null),
                true,
                true
            ),
            new FinanceMobil(
                'Approbation de Financement',
                new FinanceMobilDate(null),
                true,
                true
            )
        ];
    }

    fetchMaturationEvalutions() {
        return [
            new MaturationEval(
                'Inscription du Projet dans la Base de Données du Gouvernement',
                new MaturationEvalDate(null),
                true,
                true
            ),
            new MaturationEval(
                'Inscription du Projet au CDMT du MO',
                new MaturationEvalDate(null),
                true,
                true
            ),
            new MaturationEval(
                'Tenue de la Session du Comité Interne de Maturation du MO',
                new MaturationEvalDate(null),
                true,
                true
            ),
            new MaturationEval(
                'Tenue de la Session du Comité Interministériel de Maturation',
                new MaturationEvalDate(null),
                true,
                true
            ),
            new MaturationEval(
                'Visa de Maturité',
                new MaturationEvalDate(null),
                true,
                true
            )
        ];
    }

    fetchSignatureProcesses() {
        return [
            new SignatureProc(
                'Rapport de Conformité',
                new SignatureProcDate(null),
                true,
                true
            ),
            new SignatureProc(
                'Sollicitation Avis CNDP',
                new SignatureProcDate(null),
                true,
                true
            ),
            new SignatureProc(
                'Obtention Avis CNDP',
                new SignatureProcDate(null),
                true,
                true
            ),
            new SignatureProc(
                'Sollicitation Decret d\'Habilitation',
                new SignatureProcDate(null),
                true,
                true
            ),
            new SignatureProc(
                'Obtention Decret d\'Habilitation',
                new SignatureProcDate(null),
                true,
                true
            ),
            new SignatureProc(
                'Signature Convention',
                new SignatureProcDate(null),
                true,
                true
            )
        ];
    }

    fetchImplementationProcesses() {
        return [
            new ImplementationProc(
                'Planification de la Mise en Vigueur',
                new ImplementationProcDate(null),
                true,
                true
            ),
            new ImplementationProc(
                'Ouverture des Comptes Designé',
                new ImplementationProcDate(null),
                true,
                true
            ),
            new ImplementationProc(
                'Signature des Contrats de l\'Equipe Projet',
                new ImplementationProcDate(null),
                true,
                true
            ),
            new ImplementationProc(
                'Obtention de la Mise en Vigueur',
                new ImplementationProcDate(null),
                true,
                true
            )
        ];
    }

    fetchProcurements() {
        return [
            new Procurement(
                'Date de Soumission DAO/DC en Commission',
                new ProcDate(null),
                true
            ),
            new Procurement(
                'Date de Publication de la DAO/DC',
                new ProcDate(null),
                true,
                'PUBLICATION'
            ),
            new Procurement(
                'Date d\'Attribution du Marché',
                new ProcDate(null),
                true
            ),
            new Procurement(
                'Date de Publication des Résultats',
                new ProcDate(null),
                true
            ),
            new Procurement(
                'Date de Signature du Contrat',
                new ProcDate(null),
                true,
                'SIGNATURE'
            )
        ];
    }

    fetchMarketExecutions() {
        return [
            new MarketExecution(
                'Délivrance de l\'Ordre de Service de Démarrage',
                new MarketExecutionDate(null),
                true,
                true
            ),
            new MarketExecution(
                'Délivrance des Autres Ordres de Service',
                new MarketExecutionDate(null),
                true,
                true
            ),
            new MarketExecution(
                'Date de Fin Planifié dans le Contrat',
                new MarketExecutionDate(null),
                true,
                true
            ),
        ];
    }

    fetchJudicialForm() {
        return [
            'Consultant',
            'GIE - Groupement d\'Intérêt Economique',
            'SA - Société Annonyme',
            'SARL - Société Annonyme à Responsabilité Limitée',
            'SCS - Société en Commandité Simple',
            'SNC - Société en Nom Collectif',
            'SAS - Société par Action Simplifié'
        ];
    }

    fetchGeneralInformations() {
        return new ProjectFile(1, 'I: ' + this.translate.instant('general_information'), null, [
            new ProjectFile(2, 'A: ' + this.translate.instant('project_title'), 1),
            new ProjectFile(3, 'B: ' + this.translate.instant('project-anchoring'), 1),
            new ProjectFile(4, 'C: ' + this.translate.instant('localisation'), 1),
            new ProjectFile(5, 'D: ' + this.translate.instant('activity-sector'), 1),
            new ProjectFile(6, 'E: ' + this.translate.instant('inputs.projectType.label'), 1),
            new ProjectFile(7, 'F: ' + this.translate.instant('project-description'), 1),
            new ProjectFile(8, 'G: ' + this.translate.instant('key-dates-related-document-in-attachement'), 1),
            new ProjectFile(9, 'H: ' + this.translate.instant('project-previsional-realisation-duration-in-months'), 1),
            new ProjectFile(10, 'I: ' + this.translate.instant('project-overall-state-progress'), 1),
            new ProjectFile(11, 'J: ' + this.translate.instant('institutional-disposition'), 1),
            new ProjectFile(12, 'K: ' + this.translate.instant('project-team-contact-project-preparation-team'), 1)
        ]);
    }

    fetchLogicalFrameworks() {
        return new ProjectFile(13, 'II: ' + this.translate.instant('logical-framework'), null, [
            new ProjectFile(14, 'A: ' + this.translate.instant('impact'), 13),
            new ProjectFile(15, 'B: ' + this.translate.instant('effect'), 13)
        ]);
    }

    fetchFinancialDatas() {
        return new ProjectFile(16, 'III: ' + this.translate.instant('finance-data'), null, [
            new ProjectFile(17, 'A: ' + this.translate.instant('project-cost'), 16),
            new ProjectFile(18, 'B: ' + this.translate.instant('project-financing'), 16),
            new ProjectFile(19, 'C: ' + this.translate.instant('multiannual-programming'), 16),
            new ProjectFile(20, 'D: ' + this.translate.instant('fcp-annual-disbursement-plan'), 16, null, false, true),
            new ProjectFile(21, 'E: ' + this.translate.instant('annual-disbursement-plan-for-external-financing'), 16, null, false, true)
        ]);
    }

    fetchImplementationStates() {
        return new ProjectFile(22, 'IV: ' + this.translate.instant('implementation-state'), null, [
            new ProjectFile(23, 'A: ' + this.translate.instant('indicators-evolution-level'), 22),
            new ProjectFile(24, 'B: ' + this.translate.instant('physical-finances-execution-summary'), 22),
            new ProjectFile(25, 'C: ' + this.translate.instant('fcp-execution'), 22, null, false, true),
            new ProjectFile(26, 'D: ' + this.translate.instant('external-financing-execution'), 22, null, false, true),
            new ProjectFile(27, 'E: ' + this.translate.instant('project-actual-situation'), 22),
            new ProjectFile(28, 'F: ' + this.translate.instant('principal-intervention-realisation'), 22),
            new ProjectFile(29, 'G: ' + this.translate.instant('learned-lesson-&-recommendations'), 22),
            new ProjectFile(30, 'H: ' + this.translate.instant('perspectives-&-innovations'), 22)
        ]);
    }

    fetchProjectColumns() {
        return [
            new AnalyseElement('numb', 'inputs.numb.label'),
            new AnalyseElement('govProjectNumb', 'inputs.govProjectNumb.label'),
            new AnalyseElement('abbr', 'inputs.abbr.label'),
            new AnalyseElement('title', 'inputs.entitle.label'),
            new AnalyseElement('calc_amountFcfa', 'inputs.amount_in_fcfa.label'),
            new AnalyseElement('calc_amount', 'inputs.amount_in_currency.label'),
            new AnalyseElement('calc_nature_gift', 'nature_gift'),
            new AnalyseElement('calc_nature_loan', 'nature_loan'),
            new AnalyseElement('calc_delay_consumption_rate', 'delay-consumption-rate'),
            new AnalyseElement('calc_disbursement_rate', 'disbursement-rate'),
            new AnalyseElement('calc_state', 'overall_status_of_the_project'),
            new AnalyseElement('calc_age', 'age_in_years'),
            new AnalyseElement('calc_categorie', 'category'),

        ];
    }

    fetchActivityColumns() {
        return [
            new AnalyseElement('numb', 'inputs.numb.label'),
            new AnalyseElement('title', 'inputs.entitle.label'),
            new AnalyseElement('amount_in_currency', 'inputs.amount_in_currency.label'),
            new AnalyseElement('amount_in_fcfa', 'inputs.amount_in_fcfa.label'),
            new AnalyseElement('physicalRate', 'physical-rate'),
            new AnalyseElement('financialRate', 'financial-rate'),
            new AnalyseElement('princResponsible', 'inputs.principal_responsible.label'),
            new AnalyseElement('partResponsible', 'inputs.implementation_partner.label'),
            new AnalyseElement('contextJustif', 'inputs.contextJustif.label'),
            new AnalyseElement('objective', 'inputs.objective.label'),
            new AnalyseElement('deliverable', 'inputs.deliverable.label'),
            new AnalyseElement('verifMeans', 'inputs.verifMeans.label'),
            new AnalyseElement('riskHypothese', 'inputs.riskHypothese.label'),
            new AnalyseElement('status', 'status'),
            new AnalyseElement('startDate', 'inputs.startDate.label'),
            new AnalyseElement('endDate', 'inputs.endDate.label'),
            new AnalyseElement('delayRate', 'delay-rate'),
            new AnalyseElement('component', 'inputs.component.label'),
            new AnalyseElement('subComponent', 'inputs.subComponent.label')
        ];
    }

    fetchRows() {
        return [
            new AnalyseElement('status', 'status')
        ];
    }

    fetchValues() {
        return [
            new AnalyseElement('nb-projects', 'nb-projects'),
            new AnalyseElement('amount', 'inputs.amount.label')
        ];
    }

    fetchPeriodicities() {
        return ['Journalière', 'Hebdomadaire', 'Mensuelle', 'Annuelle'];
    }

    fetchMeasuringUnits() {
        return ['Percentage', 'Value'];
    }

    fetchProjectOwners() {
        return [
            'MINAT', 'MINAS', 'MINADER', 'MINAC', 'MINCOMMERCE', 'MINCOM', 'MINCCSE', 'MINDDEVEL', 'MINDEF', 'MINDCAF', 'MINEE', 'MINEPAT',
            'MINEDUB', 'MINEPIA', 'MINEFOP', 'MINESEC', 'MINESUP', 'MINEPDED', 'MINFI', 'MINFOPRA', 'MINFOF', 'MINHDU', 'MINJEC', 'MINJUST',
            'MMP', 'MINMIDT', 'MINPMEESA', 'MINPOSTEL', 'MINPROFF', 'MINRESI', 'MINCRA', 'MINREX', 'MINSANTE', 'MINSEP', 'MINTOUR', 'MINTSS',
            'MINT', 'MINTP', 'DG/CCAA', 'DG/SONATREL', 'COMMUNE', 'AUTRES'
        ];
    }

    formatageDate(date: string): string {
        return date ? new Date(date).toLocaleDateString('fr-FR') : null;
    }

    roundNumber(value: any) {
        const number = parseFloat(value);
        if (Number.isNaN(number)) return 0
        if (number % 1 !== 0) {
            return number.toFixed(1);
        } else {
            return number.toString();
        }
    }
}
