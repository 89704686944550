<!DOCTYPE html>
<section *ngIf="isLoaded" class="animated fadeIn container-fluid">
    <div class="row py-2">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 forms">
            <form [formGroup]="userForm">
                <ngb-alert #selfClosingAlert (closed)="errorMessage = ''" *ngIf="errorMessage"
                           type="danger">{{ errorMessage }}
                </ngb-alert>

                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group">
                            <div class="profile-img img-responsive">
                                <img [src]="imgSrc" alt="file" class="avatar img-thumbnail"/>
                                <div class="file btn btn-lg btn-primary">{{ "import-profile-picture" | translate }}
                                    <input (change)="onFileSelect($event)" accept="image/*" formControlName="file"
                                           type="file"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-xs-12">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="form-group">
                                    <label class="control-label" for="name">{{ "inputs.name.label" | translate }}
                                        <span class="roboto-bold-font text-red">*</span>
                                    </label>
                                    <input [ngClass]="{ 'is-invalid': userFormSubmitted && updateForm.name.errors }"
                                           aria-describedby="basic-user" aria-label="Name" class="form-control"
                                           formControlName="name" id="name" type="text">
                                    <div *ngIf="userFormSubmitted && updateForm.name.errors" class="invalid-feedback">
                                        <div *ngIf="updateForm.name.errors.required">{{
                                                "inputs.name.validations.required" |
                                                    translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="surname">{{ "inputs.surname.label" | translate }}</label>
                                    <input [ngClass]="{ 'is-invalid': userFormSubmitted && updateForm.surname.errors }"
                                           aria-describedby="basic-user" aria-label="Name" class="form-control"
                                           formControlName="surname" id="surname" type="text">
                                    <div *ngIf="userFormSubmitted && updateForm.surname.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="updateForm.surname.errors.required">{{
                                                "inputs.surname.validations.required" |
                                                    translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group">
                            <label class="control-label" for="dob">{{ "inputs.dob.label" | translate }}</label>
                            <input [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                                   [ngClass]="{ 'is-invalid': userFormSubmitted && updateForm.dob.errors }"
                                   aria-describedby="basic-user" aria-label="Name" bsDatepicker class="form-control"
                                   formControlName="dob" id="dob" placeholder="{{ 'date-format' | translate }}"
                                   type="text">
                            <div *ngIf="userFormSubmitted && updateForm.dob.errors" class="invalid-feedback">
                                <div *ngIf="updateForm.dob.errors.required">{{
                                        "inputs.dob.validations.required" |
                                            translate
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group">
                            <label class="control-label" for="pob">{{ "inputs.pob.label" | translate }}</label>
                            <input [ngClass]="{ 'is-invalid': userFormSubmitted && updateForm.pob.errors }"
                                   aria-describedby="basic-user" aria-label="Name" class="form-control"
                                   formControlName="pob" id="pob" type="text">
                            <div *ngIf="userFormSubmitted && updateForm.pob.errors" class="invalid-feedback">
                                <div *ngIf="updateForm.pob.errors.required">{{
                                        "inputs.pob.validations.required" |
                                            translate
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group">
                            <label class="control-label" for="email">{{ "inputs.email.label" | translate }}
                                <span class="roboto-bold-font text-red">*</span>
                            </label>
                            <input [ngClass]="{ 'is-invalid': userFormSubmitted && updateForm.email.errors }"
                                   aria-describedby="basic-user" aria-label="Name" class="form-control"
                                   formControlName="email" id="email" type="text">
                            <div *ngIf="userFormSubmitted && updateForm.email.errors" class="invalid-feedback">
                                <div
                                    *ngIf="updateForm.email.errors.required">{{ "inputs.email.validations.required" | translate }}
                                </div>
                                <div
                                    *ngIf="updateForm.email.errors.email">{{ "inputs.email.validations.valid" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group">
                            <label class="control-label"
                                   for="phone">{{ "inputs.phone_number.label" | translate }}</label>
                            <input [ngClass]="{ 'is-invalid': userFormSubmitted && updateForm.phone.errors }"
                                   aria-describedby="basic-user" aria-label="Name" class="form-control"
                                   formControlName="phone" id="phone" type="text">
                            <div *ngIf="userFormSubmitted && updateForm.phone.errors" class="invalid-feedback">
                                <div *ngIf="updateForm.phone.errors.required">{{
                                        "inputs.phone_number.validations.required" |
                                            translate
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group">
                            <label class="control-label" for="username">{{ "inputs.username.label" | translate }}
                                <span class="roboto-bold-font text-red">*</span>
                            </label>
                            <input [ngClass]="{ 'is-invalid': userFormSubmitted && updateForm.username.errors }"
                                   aria-describedby="basic-user" aria-label="Name" class="form-control"
                                   formControlName="username" id="username" type="text">
                            <div *ngIf="userFormSubmitted && updateForm.username.errors" class="invalid-feedback">
                                <div
                                    *ngIf="updateForm.username.errors.required">{{ "inputs.username.validations.required" | translate }}
                                </div>
                                <div *ngIf="updateForm.username.errors.minlength">
                                    {{ "inputs.username.validations.min_length" | translate: {'size': '3'} }}
                                </div>
                                <div *ngIf="updateForm.username.errors.maxlength">
                                    {{ "inputs.username.validations.max_length" | translate: {'size': '60'} }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group">
                            <label class="control-label" for="lang">{{ "inputs.lang.label" | translate }}
                                <span class="roboto-bold-font text-red">*</span>
                            </label>
                            <ng-select [ngClass]="{ 'is-invalid': userFormSubmitted && updateForm.lang.errors }"
                                       [searchable]="true" formControlName="lang" id="lang"
                                       placeholder="{{ 'inputs.lang.placeholders' | translate }}">
                                <ng-option *ngFor="let lang of langs"
                                           [value]="lang.abbr">{{ lang.name | translate }}
                                </ng-option>
                            </ng-select>
                            <div *ngIf="userFormSubmitted && updateForm.lang.errors" class="invalid-feedback">
                                <div *ngIf="updateForm.lang.errors.required">{{
                                        "inputs.lang.validations.required" |
                                            translate
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end">
                        <button (click)="onSubmit()" class="btn btn-pill bg-primary px-5"
                                type="submit">{{ "modify" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
