import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UpdateIndicatorComponent} from './update-indicator/update-indicator.component';
import {CreateIndicatorComponent} from './create-indicator/create-indicator.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {NgSelectModule} from '@ng-select/ng-select';
import {MapsModule} from '@syncfusion/ej2-angular-maps';
import {PdfJsViewerModule} from 'ng2-pdfjs-viewer';
import {UiSwitchModule} from 'ngx-ui-switch';
import {TableModule} from 'primeng/table';
import {MultiSelectModule} from 'primeng/multiselect';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {ChartModule} from 'primeng/chart';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {DialogModule} from 'primeng/dialog';
import {ModalModule} from 'ngx-bootstrap/modal';


@NgModule({
    declarations: [
        UpdateIndicatorComponent,
        CreateIndicatorComponent
    ],
    exports: [
        UpdateIndicatorComponent,
        CreateIndicatorComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        NgbModule,
        MatTabsModule,
        MatSnackBarModule,
        MatCheckboxModule,
        NgSelectModule,
        TranslateModule,
        MapsModule,
        PdfJsViewerModule,
        UiSwitchModule,
        TableModule,
        MultiSelectModule,
        InputTextModule,
        ButtonModule,
        ChartModule,
        LeafletModule,
        DialogModule,
        ModalModule
    ]
})
export class IndicatorModule { }
