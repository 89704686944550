import {TimeagoIntl} from 'ngx-timeago';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DropdownComponent, HeaderComponent} from '@coreui/angular';
import {TokenStorageService} from '../../../services/security/token-storage.service';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RestAPIService} from '../../../services/rest-api.service';
import {ConfirmationDialogService} from '../confirmation-dialog/confirmation-dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {getRole} from '../../../_helpers';
import {User} from '../../../payloads/user.payload';
import {Role} from '../../../payloads/role.payload';
import {MessageType, Notification, NotifType} from '../../../payloads/notification.payload';
import {Location} from '@angular/common';
import {UserProfileComponent} from '../../dashboard/setting/user/user-profile/user-profile.component';
import {englishStrings, frenchStrings} from '../timeago-langs';


@Component({
    styleUrl: './default-header.component.scss',
    selector: 'app-default-header',
    templateUrl: './default-header.component.html',
})
export class DefaultHeaderComponent extends HeaderComponent implements OnInit {
    @Input() sidebarId: string = 'sidebar';

    titlee: string;
    imgSrc = '../../../../assets/img/default-avatar.jpeg';
    user: User;
    _role: Role = getRole();
    notifications: Notification[] = [];
    isLoaded: boolean = false;
    @ViewChild('cmp') dropdown!: DropdownComponent;
    idProject = Number(sessionStorage.getItem('project'));

    constructor(private intl: TimeagoIntl, public location: Location, private tokenStorage: TokenStorageService, private router: Router,
                private confirmationDialog: ConfirmationDialogService, private modalService: NgbModal,
                private translate: TranslateService, private restAPIService: RestAPIService) {
        super();

        const lang = translate.currentLang;
        if (lang === 'fr') {
            intl.strings = frenchStrings;
            intl.changes.next();
        } else {
            intl.strings = englishStrings;
            intl.changes.next();
        }

        const idUser = Number(this.tokenStorage.getUser().iduser);
        if (this.idProject > 0) {
            this.restAPIService.fetchNotifications(this.idProject, idUser).subscribe((notifs: Notification[]) => {
                if (notifs !== null) {
                    this.notifications = notifs.sort((a, b) => {
                        return b.createdAt - a.createdAt;
                    });
                }
                this.isLoaded = true;
            });
        }
    }

    ngOnInit() {
        this.translate;
        this.titlee = this.location?.prepareExternalUrl(this.location.path()).slice(2);

        if (this.tokenStorage.isloggedIn()) {
            this.user = this.tokenStorage.getUser();
            this.imgSrc = this.user.image;
            if (this.imgSrc === '../../../../assets/img/default-avatar.jpeg' && this.user.document !== null) {
                this.restAPIService.downloadDocument(this.user.document.iddocument).subscribe(document => {
                    if (document !== null) {
                        this.imgSrc = document.base64;
                    }
                });
            }

            if (this._role === null) {
                this._role = this.user.role;
            }

            return true;
        }
    }

    logout() {
        this.confirmationDialog.confirm(this.translate.instant('confirmations.headers.disconnection'),
            this.translate.instant('confirmations.contents.disconnection')
        ).then((confirmation) => {
            if (confirmation) {
                this.restAPIService.signOut(this.user.iduser).subscribe(() => {
                    this.tokenStorage.signOut();
                    this.router.navigate(['/']);
                });
            }
        });
    }

    viewProfile() {
        this.modalService.open(UserProfileComponent, {
            backdropClass: 'light-yellowish-backdrop',
            size: 'lg',
            centered: false,
            scrollable: false,
            backdrop: false
        });
    }

    changeIconColor(type: NotifType): any {
        switch (type) {
            case 'DANGER':
                return {'fa fa-times text-danger': true};
            case 'INFO':
                return {'fa fa-exclamation-circle text-primary': true};
            case 'SUCCESS':
                return {'fa fa-check text-success': true};
            case 'WARNING':
                return {'fa fa-exclamation-triangle text-warning': true};
            default:
                return {'': true};
        }
    }

    viewAll() {
        const idUser = Number(this.user.iduser);
        const allIDs = this.notifications.map(notification => notification.idnotification);
        this.restAPIService.markAllNotificationsAsRead(idUser, allIDs).subscribe({
            next: () => {
                this.notifications = [];
                setTimeout(() => {
                    this.dropdown.setVisibleState(false);
                }, 500);
            }
        });
    }

    viewNotification(notification: Notification) {
        const idUser = Number(this.tokenStorage.getUser().iduser);
        this.restAPIService.markNotificationAsRead(notification.idnotification, idUser).subscribe({
            next: () => {
                this.notifications = this.notifications.filter(notif => notif.idnotification !== notification.idnotification);
                if (this.notifications.length == 0) {
                    setTimeout(() => {
                        this.dropdown.setVisibleState(false);
                    }, 500);
                }

            }
        });
    }

    createMessage(message: string, messageType: MessageType): string {
        let res = '';
        let typeName = '';
        const subTab = message.split(':');
        switch (messageType) {
            case 'A':
                typeName = 'activity';
                break;
            case 'T':
                typeName = 'task';
                break;
            case 'M':
                typeName = 'market';
                break;
            case 'R':
                typeName = 'review';
                break;
            case 'CR':
                typeName = 'creview';
                break;
            default:
                typeName = 'project';
                break;
        }

        if (subTab[1].includes('status changed to')) {
            res = `${this.translate.instant('status changed to', {
                type: this.translate.instant(typeName),
                code: '',
                status: this.translate.instant(subTab[2].trim()),
                add: this.translate.instant('add-text')
            })} `;
        } else if (subTab[1].includes('validation changed to')) {
            res = `${this.translate.instant('validation changed to', {
                type: this.translate.instant(typeName),
                code: messageType == 'A' || messageType == 'M' ? subTab[0] : '',
                level: this.translate.instant(subTab[2].trim())
            })} `;
        } else if (subTab[1].includes('calendar has been updated')) {
            res = `${this.translate.instant('calendar has been updated', {
                type: this.translate.instant(typeName),
                code: this.translate.instant('new'),
                text: this.translate.instant('inputs.closureDate.label').toLowerCase(),
                date: subTab[2].trim()
            })} `;
        } else if (subTab[1].includes('continued review created')) {
            res = `${this.translate.instant(typeName)} ${this.translate.instant('review')} "${subTab[2]}"`;
        } else if (subTab[1].includes('review created')) {
            res = `${this.translate.instant(typeName)}: ${this.translate.instant('new')} ${this.translate.instant(typeName).toLowerCase()} "${subTab[2]}" ${this.translate.instant('created')}`;
        } else if (subTab[1].includes('will accuse a delay on status')) {
            res = `${this.translate.instant('status changed to', {
                type: this.translate.instant(typeName),
                code: '',
                status: this.translate.instant(subTab[2].trim()),
                add: this.translate.instant('will-delay')
            })} `;

        } else if (subTab[1].includes('is delay on status')) {
            res = `${this.translate.instant('status changed to', {
                type: this.translate.instant(typeName),
                code: '',
                status: this.translate.instant(subTab[2].trim()),
                add: this.translate.instant('delay')
            })} `;
        }
        return res;
    }

    getDateInTimeSTamp(date: any) {
        const dates: string[] = date.toString().split('T');
        if (dates.length > 0) {
            return new Date(date).getTime();
        }
        return Number(date) * 1000;
    }
}
