import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {reloadComponent} from '../../../../../_helpers';
import {NotificationFlashService} from '../../../../../services/notification-flash.service';
import {RestAPIService} from '../../../../../services/rest-api.service';
import {MaturationEval, MaturationEvalDate} from '../../../../../payloads/maturation-evaluation.payload';
import {Project} from '../../../../../payloads/project.payload';
import {UtilityService} from '../../../../../services/utility.service';
import {ConfirmationDialogService} from '../../../../shared-component/confirmation-dialog/confirmation-dialog.service';

@Component({
    selector: 'app-reset-matur-eval-date',
    templateUrl: './reset-matur-eval-date.component.html',
    styleUrls: ['./reset-matur-eval-date.component.scss']
})
export class ResetMaturEvalDateComponent implements OnInit {
    isLoaded: boolean = false;
    hideSave: boolean = false;
    private _danger = new Subject<string>();

    staticAlertClosed = false;
    errorMessage: string;

    @Input() project: Project;

    @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;
    maturationEvals: MaturationEval[];
    colspan: number;

    constructor(private formBuilder: UntypedFormBuilder, private activeModal: NgbActiveModal, private restAPIService: RestAPIService,
                private notification: NotificationFlashService, private translate: TranslateService, private router: Router,
                private utilityService: UtilityService, private confirmationDialog: ConfirmationDialogService) {
    }

    ngOnInit(): void {
        this._danger.subscribe((message) => (this.errorMessage = message));
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.maturationEvals = [...this.project.maturationEvals];
        this.maturationEvals.sort(
            (maturationEval1: MaturationEval, maturationEval2: MaturationEval) =>
                maturationEval1.idmaturationEval > maturationEval2.idmaturationEval ? 1 : -1
        );

        const dates: number[] = [];
        let hide = 0;
        this.maturationEvals.forEach((maturationEval: MaturationEval) => {
            maturationEval.maturationEvalDates.sort((maturationEvalDate1: MaturationEvalDate, maturationEvalDate2: MaturationEvalDate) =>
                maturationEvalDate1.idmaturationEvalDate > maturationEvalDate2.idmaturationEvalDate ? 1 : -1
            );

            dates.push(maturationEval.maturationEvalDates.length);

            if (maturationEval.executedDate) {
                ++hide;
            }
        });

        dates.sort((date1: number, date2: number) => date1 < date2 ? 1 : -1);

        this.colspan = dates[0];

        if (this.utilityService.fetchArchiveStatus().includes(this.project.status) || hide === this.maturationEvals.length) {
            this.hideSave = true;
        }

        this.isLoaded = true;
    }

    onSubmit() {
        this.confirmationDialog.confirm(this.translate.instant('reprogram'),
            this.translate.instant('confirmations.contents.reprogrammed-dates-validation'))
            .then((confirmation) => {
                    if (confirmation) {
                        this.restAPIService.updateMaturationEvaluation(this.maturationEvals).subscribe(() => {
                                this.closeModal();

                                reloadComponent(this.router);

                                this.notification.success(this.translate.instant('notifications.success.planned-date-reset'));
                            }, () => {
                                this.notification.danger(this.translate.instant('notifications.danger.planned-date-not-reset'));
                            }
                        );
                    }
                }
            );
    }

    closeModal() {
        this.activeModal.close();
    }

    dateFormat(date: string): string {
        return new Date(date).toLocaleDateString('fr-FR');
    }

    changePlannedDate(date: any, i: number) {
        this.maturationEvals[i].plannedDate = date;
        this.maturationEvals[i].maturationEvalDate = new MaturationEvalDate(date);
    }

    formatBSDate(date: string): Date {
        if (!date) {
            return null;
        }
        return new Date(date);
    }
}
