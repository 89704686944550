import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {TokenStorageService} from '../../../services/security/token-storage.service';
import {NotificationFlashService} from '../../../services/notification-flash.service';
import {MustMatch} from '../../../_helpers';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../../payloads/user.payload';
import {RestAPIService} from '../../../services/rest-api.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})

export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: UntypedFormGroup;
  resetPasswordSubmitted = false;
  private _danger = new Subject<string>();

  staticAlertClosed = false;
  errorMessage: string;

  @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
  @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;

  constructor(private restAPIService: RestAPIService, private formBuilder: UntypedFormBuilder, private translate: TranslateService,
              private notification: NotificationFlashService, private acRoute: ActivatedRoute, private router: Router,
              private tokenStorage: TokenStorageService) { }

  ngOnInit() {
    this._danger.subscribe(message => this.errorMessage = message);
    this._danger.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });

    if (this.tokenStorage.isloggedIn()) {
      this.redirectTo('');
    }

    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(100)]],
      confirm_password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(100)]],
    }, {
        validator: MustMatch('password', 'confirm_password')
    });
  }

  get reset() { return this.resetPasswordForm.controls; }

  onSubmit() {
    this.resetPasswordSubmitted = true;
    if (this.resetPasswordForm.invalid) {
        return;
    }

    const resetPassword = new User().resetsPassword(
        Number(this.tokenStorage.getUser().iduser),
        this.resetPasswordForm.value.password
    );

    this.restAPIService.resetUserPassword(resetPassword).subscribe(() => {
        this.notification.success(this.translate.instant('notifications.success.forgot_password_reset'));

        this.redirectTo('sign-in');

        this.tokenStorage.signOut();
      }, error => {
        if (error.status === 404) {
          this.errorMessage = this.translate.instant('notifications.danger.forgot_password_reset');
        } else {
          this.redirectTo('500');
        }
      }
    );
  }

  redirectTo(path: string) {
    this.router.navigate(['/' + path], {relativeTo: this.acRoute});
  }
}
