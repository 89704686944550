import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Subject} from "rxjs";
import {NgbActiveModal, NgbAlert} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {debounceTime} from "rxjs/operators";
import {Indicator} from "../../../../../payloads/indicator.payload";
import {RestAPIService} from "../../../../../services/rest-api.service";
import {NotificationFlashService} from "../../../../../services/notification-flash.service";
import {UtilityService} from "../../../../../services/utility.service";
import {reloadComponent} from "../../../../../_helpers";

@Component({
  selector: 'app-update-indicator',
  templateUrl: './update-indicator.component.html',
  styleUrls: ['./update-indicator.component.scss']
})
export class UpdateIndicatorComponent implements OnInit {
    isLoaded: boolean = false;
    indicatorForm: UntypedFormGroup;
    indicatorFormSubmitted: boolean = false;
    periodicities: string[] = [];
    private _danger = new Subject<string>();
    staticAlertClosed = false;
    errorMessage: string;

    @ViewChild('staticAlert', { static: false }) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert;
    measuringUnits: string[] = [];

    @Input() indicator: Indicator = null;

    constructor(private formBuilder: UntypedFormBuilder, private translate: TranslateService, private restAPIService: RestAPIService,
                private activeModal: NgbActiveModal, private router: Router, private notification: NotificationFlashService,
                private utilityService: UtilityService) { }

    ngOnInit(): void {
        this._danger.subscribe((message) => (this.errorMessage = message));
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.periodicities = this.utilityService.fetchPeriodicities();
        this.measuringUnits = this.utilityService.fetchMeasuringUnits();

        this.initForm();

        this.isLoaded = true;
    }

    get updateIndicator() { return this.indicatorForm.controls; }

    initForm() {
        this.indicatorForm = this.formBuilder.group({
            name: [this.indicator.name, [Validators.required]],
            unit_mesure: [this.indicator.measureUnit, [Validators.required]],
            reference_year: [this.indicator.referenceYear],
            reference_value: [this.indicator.referenceValue],
            cible_year: [this.indicator.targetYear],
            cible_value: [this.indicator.targetValue],
            infos_to_collect: [this.indicator.infoToCollect],
            calcul_method: [this.indicator.calculationMethod],
            infos_source: [this.indicator.infoSource],
            info_holder: [this.indicator.infoHolder],
            info_collector: [this.indicator.infoCollector],
            way_to_collect: [this.indicator.wayToCollect],
            periodicity: [this.indicator.periodicity],
            collection_time: [this.indicator.collectionTime],
            info_interpreter: [this.indicator.infoInterpreter],
            tools_to_use: [this.indicator.toolsToUse],
        });
    }

    closeModal() {
        this.activeModal.close();
    }

    onSubmit() {
        this.errorMessage = null;
        this.indicatorFormSubmitted = true;
        if (this.indicatorForm.invalid) {
            return;
        }

        const indicator = new Indicator(
            this.indicatorForm.value.name,
            this.indicatorForm.value.unit_mesure,
            this.indicatorForm.value.reference_year,
            this.indicatorForm.value.reference_value,
            this.indicatorForm.value.cible_year,
            this.indicatorForm.value.cible_value,
            this.indicatorForm.value.infos_to_collect,
            this.indicatorForm.value.calcul_method,
            this.indicatorForm.value.infos_source,
            this.indicatorForm.value.info_holder,
            this.indicatorForm.value.info_collector,
            this.indicatorForm.value.way_to_collect,
            this.indicatorForm.value.periodicity,
            this.indicatorForm.value.collection_time,
            this.indicatorForm.value.info_interpreter,
            this.indicatorForm.value.tools_to_use,
            this.indicatorForm.value.way_to_collect
        );

        this.restAPIService.updateIndicator(this.indicator.idindicator, indicator).subscribe(() => {
            this.closeModal();

            this.notification.success(
                this.translate.instant('notifications.success.indicator-updated'),
            );

            reloadComponent(this.router);
        }, (error) => {
            console.error(error);

            this.errorMessage = this.translate.instant(
                'notifications.danger.indicator-not-updated',
            );
        });
    }
}
