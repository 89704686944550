import {Injectable} from '@angular/core';
import jwt_decode from 'jwt-decode';
import {User} from '../../payloads/user.payload';

const TOKEN_KEY = 'AuthToken';
const USER_INFOS = 'AuthUser';

@Injectable({
    providedIn: 'root'
})

export class TokenStorageService {

    constructor() {
    }

    signOut() {
        localStorage.clear();
        sessionStorage.clear();
    }

    public saveToken(token: string) {
        localStorage.setItem(TOKEN_KEY, token);
        sessionStorage.setItem(TOKEN_KEY, token);
    }

    public getToken(): string {
        if (localStorage.getItem(TOKEN_KEY) !== null) {
            return localStorage.getItem(TOKEN_KEY);
        }
        return sessionStorage.getItem(TOKEN_KEY);
    }

    public saveUser(user: User) {
        localStorage.setItem(USER_INFOS, JSON.stringify(user));
        sessionStorage.setItem(USER_INFOS, JSON.stringify(user));
    }

    public getUser(): User {
        if (JSON.parse(localStorage.getItem(USER_INFOS)) !== null) {
            return JSON.parse(localStorage.getItem(USER_INFOS));
        }
        return JSON.parse(sessionStorage.getItem(USER_INFOS));
    }

    public isloggedIn(): boolean {
        const token = this.getToken();
        const user = this.getUser();

        if (user) {
            if (user.resetPassword) {
                return false;
            }

            try {
                jwt_decode(token);
                // @ts-expect-error;
                const {exp} = jwt_decode(token);
                if (exp < (new Date().getTime() + 1) / 1000) {
                    return false;
                }
            } catch (err) {
                return false;
            }
            return true;
        }
        return false;
    }
}
