import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NgbActiveModal, NgbAlert, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {NotificationFlashService} from '../../../../../services/notification-flash.service';
import {RestAPIService} from '../../../../../services/rest-api.service';
import {CompleteProcurement, Market, ProcDate, Procurement} from '../../../../../payloads/market.payload';
import {Document} from '../../../../../payloads/document.payload';
import {Contract} from '../../../../../payloads/contract.payload';
import {ConfirmationDialogService} from '../../../../shared-component/confirmation-dialog/confirmation-dialog.service';
import {debounceTime} from 'rxjs/operators';
import {FileViewerComponent} from '../../../../shared-component/file-viewer/file-viewer.component';
import {ViewContractComponent} from './view-contract/view-contract.component';
import {reloadComponent, viewDate} from '../../../../../_helpers';
import {CreateContractComponent} from './create-contract/create-contract.component';
import {ResetProcDateComponent} from './reset-proc-date/reset-proc-date.component';

@Component({
    selector: 'app-complete-procurement',
    templateUrl: './complete-procurement.component.html',
    styleUrls: ['./complete-procurement.component.scss']
})
export class CompleteProcurementComponent implements OnInit {
    isLoaded: boolean = false;

    methods: string[];

    procurements: Procurement[];

    hideSave: boolean = true;
    contractCreate: boolean = true;
    contractView: boolean = true;

    market: Market = null;
    contracts: Contract[] = [];
    contract: Contract = null;
    todayDate = new Date();
    staticAlertClosed = false;
    errorMessage: string;
    @Input() procurement: any;
    @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;
    private documents: Document[] = [];
    private _danger = new Subject<string>();

    constructor(private activeModal: NgbActiveModal, private restAPIService: RestAPIService, private router: Router,
                private notification: NotificationFlashService, private translate: TranslateService, private modalService: NgbModal,
                private confirmationDialog: ConfirmationDialogService) {
    }

    ngOnInit(): void {
        this._danger.subscribe(message => this.errorMessage = message);
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.market = this.procurement.market;

        this.procurements = [...this.market.procurements];
        this.procurements.sort((procurement1: Procurement, procurement2: Procurement) =>
            procurement1.idprocurement > procurement2.idprocurement ? 1 : -1
        );

        let hide = 0;
        this.procurements.forEach((procurement: Procurement) => {
            procurement.procDates.sort((procDate1: ProcDate, procDate2: ProcDate) =>
                procDate1.idprocDate > procDate2.idprocDate ? 1 : -1
            );

            if (!procurement.executedDate) {
                ++hide;
            }
        });

        if (hide > 0) {
            this.hideSave = false;
        }

        this.contracts = [...this.market.contracts];
        this.contracts.sort((contract1: Contract, contract2: Contract) =>
            contract1.numb > contract2.numb ? 1 : -1
        ).forEach((contract: Contract) => {
            if (contract.status !== 'CANCELED') {
                this.contract = contract;
            }
        });

        this.hideViewContractButton();

        this.isLoaded = true;
    }

    hideViewContractButton() {
        if (this.contract === null) {
            if (this.hideSave === true) {
                this.contractCreate = false;
            }
        } else {
            this.contractView = false;
        }
    }

    resetProcDate() {
        if (!this.hideSave) {
            this.closeModal();

            this.modalService.open(ResetProcDateComponent, {
                backdropClass: 'light-yellowish-backdrop',
                size: 'xl',
                centered: false,
                scrollable: false,
                backdrop: false
            }).componentInstance.update = {
                market: this.market,
                project: this.procurement.project,
                resetPlannedDate: true
            };
        } else {
            this.errorMessage = this.translate.instant('notifications.danger.procurement-is-completed-you-cannot-reset-date');
        }
    }

    onFileSelect(target: number, event: any) {
        const file: File = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = (read: any) => {
                this.documents.push(
                    new Document(
                        target, file.name,
                        file.type, read.target.result
                    )
                );
            };
        }
    }

    createContract() {
        this.modalService.open(CreateContractComponent, {
            backdropClass: 'light-yellowish-backdrop',
            size: 'xl',
            centered: false,
            scrollable: false,
            backdrop: false,
        }).componentInstance.signedMarket = {
            project: this.procurement.project,
            market: this.market,
            contract: this.contract,
            view: false
        };
    }

    onSubmit(completeProcurementForm) {
        this.errorMessage = null;

        const completeProcurements: CompleteProcurement[] = [];
        this.procurements.forEach((procurement: Procurement) => {
            const index = procurement.idprocurement.toString();
            let importedDocument: Document = null;
            this.documents.forEach((document: Document) => {
                if (document.target === Number(index)) {
                    importedDocument = document;
                }
            });

            const executedDate = completeProcurementForm['executedDate_'.concat(index)];
            const documentFile = importedDocument;

            if (executedDate !== null) {
                const completeProcurement: CompleteProcurement = new CompleteProcurement(
                    procurement.idprocurement, executedDate, documentFile, procurement.codeStatus
                );

                completeProcurements.push(completeProcurement);
            }
        });

        if (completeProcurements.length > 0) {
            this.confirmationDialog.confirm(this.translate.instant('follow-up-procurement'),
                this.translate.instant('confirmations.contents.date-validation',
                    {date: viewDate(completeProcurements[completeProcurements.length - 1].executedDate)}))
                .then((confirmation) => {
                        if (confirmation) {
                            this.restAPIService.completeProcurement(this.market.idmarket, completeProcurements).subscribe(() => {
                                    this.closeModal();

                                    reloadComponent(this.router);

                                    this.notification.success(this.translate.instant('notifications.success.procurement-modified'));
                                }, () => {
                                    this.notification.danger(this.translate.instant('notifications.danger.procurement-not-modified'));
                                }
                            );
                        }
                    }
                );
        }
    }

    dateFormat(date: string): string {
        return date ? new Date(date).toLocaleDateString('fr-FR') : null;
    }

    closeModal() {
        this.activeModal.close();
    }

    setDisabled(i: number): boolean {
        if (i > 0) {
            return !this.procurements[i - 1].executedDate;
        }
    }

    viewContract(contract: Contract) {
        if (contract) {
            this.modalService.open(ViewContractComponent, {
                backdropClass: 'light-yellowish-backdrop',
                size: 'xl',
                centered: false,
                scrollable: false,
                backdrop: false,
            }).componentInstance.signedMarket = {
                market: this.market,
                project: this.procurement.project,
                contract: contract,
                signatureDate: this.procurements[this.procurements.length - 1].plannedDate,
                view: true
            };
        }
    }

    view(idDocument: number) {
        this.modalService.open(FileViewerComponent, {
            windowClass: 'gr-modal-full',
            scrollable: false,
            backdrop: false,
            centered: true
        }).componentInstance.idDocument = idDocument;
    }

    formatBSDate(date: string): Date {
        return (!date) ? null : new Date(date);
    }
}
