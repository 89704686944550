import {Document} from './document.payload';

export class ActivityOper {
    idactivityOper: number;
    situation: string;
    encounteredDiff: string;
    learnedLesson: string;
    deadlineJustif: string;
    executedTasks: number;
    executionJustif: string;
    consumptedBudget: number;
    consumptionJustif: string;
    obtainedOutput: string;
    outputJustif: string;
    verifMeans: string;
    document: Document = null;
    geoCoordinate: string;
    difficulties: Difficulty[];
    gaps: Gap[];
    activity: number;
    createdAt: string;
    updatedAt: string;
    inPreparation: boolean;

    constructor(situation: string, encounteredDiff: string, learnedLesson: string, deadlineJustif: string, executedTasks: number,
                executionJustif: string, consumptedBudget: number, consumptionJustif: string, obtainedOutput: string, outputJustif: string,
                verifMeans: string, document: Document, geoCoordinate: string, difficulties: Difficulty[], gaps: Gap[], activity: number) {
        this.situation = situation;
        this.encounteredDiff = encounteredDiff;
        this.learnedLesson = learnedLesson;
        this.deadlineJustif = deadlineJustif;
        this.executedTasks = executedTasks;
        this.executionJustif = executionJustif;
        this.consumptedBudget = consumptedBudget;
        this.consumptionJustif = consumptionJustif;
        this.obtainedOutput = obtainedOutput;
        this.outputJustif = outputJustif;
        this.verifMeans = verifMeans;
        this.document = document;
        this.geoCoordinate = geoCoordinate;
        this.difficulties = difficulties;
        this.gaps = gaps;
        this.activity = activity;
    }
}

export class Difficulty {
    iddifficulty: number;
    title: string;
    action: string;
    princResponsible: string;
    delay: string;
    verifMeans: string;
    createdAt: string;
    updatedAt: string;

    constructor(title: string, action: string, princResponsible: string, delay: string, verifMeans: string) {
        this.title = title;
        this.action = action;
        this.princResponsible = princResponsible;
        this.delay = delay;
        this.verifMeans = verifMeans;
    }
}

export class Gap {
    idgap: number;
    title: string;
    action: string;
    princResponsible: string;
    delay: string;
    verifMeans: string;
    createdAt: string;
    updatedAt: string;

    constructor(title: string, action: string, princResponsible: string, delay: string, verifMeans: string) {
        this.title = title;
        this.action = action;
        this.princResponsible = princResponsible;
        this.delay = delay;
        this.verifMeans = verifMeans;
    }
}
