import {IL10nsStrings} from 'ngx-timeago';

export const englishStrings: IL10nsStrings = {
    prefixAgo: null,
    prefixFromNow: () => {
        return `in `;
    },
    suffixAgo: () => {
        return ` ago`;
    },
    suffixFromNow: null,
    second: (value: number) => {
        return `${value}sec`;
    },
    seconds: (value: number) => {
        return `${value}secs`;
    },
    minute: (value: number) => {
        return `${value}min`;
    },
    minutes: (value: number) => {
        return `${value}mins`;
    },
    hour: (value: number) => {
        return `${value}hr`;
    },
    hours: (value: number) => {
        return `${value}hrs`;
    },
    day: (value: number) => {
        return `${value}day`;
    },
    days: (value: number) => {
        return `${value}days`;
    },
    week: (value: number) => {
        return `${value}wk`;
    },
    weeks: (value: number) => {
        return `${value}wks`;
    },
    month: (value: number) => {
        return `${value}mon`;
    },
    months: (value: number) => {
        return `${value}mons`;
    },
    year: (value: number) => {
        return `${value}yr`;
    },
    years: (value: number) => {
        return `${value}yrs`;
    },
    wordSeparator: ` `,
    numbers: [`0`, `1`, `2`, `3`, `4`, `5`, `6`, `7`, `8`, `9`],
};


export const frenchStrings: IL10nsStrings = {
    prefixAgo: () => {
        return `il y a `;
    },
    prefixFromNow: null,
    suffixAgo: null,
    suffixFromNow: () => {
        return ` dans`;
    },
    second: (value: number) => {
        return `${value}sec`;
    },
    seconds: (value: number) => {
        return `${value}secs`;
    },
    minute: (value: number) => {
        return `${value}min`;
    },
    minutes: (value: number) => {
        return `${value}mins`;
    },
    hour: (value: number) => {
        return `${value}hr`;
    },
    hours: (value: number) => {
        return `${value}hrs`;
    },
    day: (value: number) => {
        return `${value}jr`;
    },
    days: (value: number) => {
        return `${value}jrs`;
    },
    week: (value: number) => {
        return `${value}sem`;
    },
    weeks: (value: number) => {
        return `${value}sems`;
    },
    month: (value: number) => {
        return `${value}mois`;
    },
    months: (value: number) => {
        return `${value}mois`;
    },
    year: (value: number) => {
        return `${value}an`;
    },
    years: (value: number) => {
        return `${value}ans`;
    },
    wordSeparator: ``,
    numbers: [`0`, `1`, `2`, `3`, `4`, `5`, `6`, `7`, `8`, `9`],
};
