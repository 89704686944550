import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {projectReportsNavItems} from '../../../../sidebars/project-reports';
import {Market, Procurement} from '../../../../../../../payloads/market.payload';
import {Project} from '../../../../../../../payloads/project.payload';
import {MarketFollowUp} from '../../../../../../../payloads/complete-execution.payload';
import {ExecutionDoc, FollowUpDoc, MarketDoc, ProcurementDoc} from '../../../../../../../payloads/utility.payload';
import {DefaultLayoutComponent} from '../../../../../../shared-component/default-layout/default-layout.component';
import {RestAPIService} from '../../../../../../../services/rest-api.service';
import {FileViewerComponent} from '../../../../../../shared-component/file-viewer/file-viewer.component';
import {Document} from '../../../../../../../payloads/document.payload';

@Component({
    selector: 'app-markets-portfolio-reports',
    templateUrl: './markets-portfolio-reports.component.html',
    styleUrls: ['./markets-portfolio-reports.component.scss']
})
export class MarketsProjectPortfolioReportsComponent implements OnInit, AfterViewInit {
    isLoaded: boolean = false;

    public idProject = Number(sessionStorage.getItem('project'));

    public project: Project;

    public markets: Market[] = [];
    public procurements: Procurement[] = [];
    public followUps: MarketFollowUp[] = [];

    public marketDocs: MarketDoc[] = [];

    constructor(private defaultLayout: DefaultLayoutComponent,
                private translate: TranslateService,
                private router: Router,
                private modalService: NgbModal,
                private restAPIService: RestAPIService) {
    }

    ngAfterViewInit(): void {
        setTimeout((): void => {
            this.defaultLayout.navItems = projectReportsNavItems(this.translate);
        }, 0);
    }

    ngOnInit(): void {
        if (this.idProject === 0) {
            this.router.navigate(['/reports']);
        }

        this.restAPIService.fetchProject(this.idProject).subscribe(
            project => {
                this.project = project;

                this.defaultLayout.navItems = projectReportsNavItems(this.translate);
                this.defaultLayout.reloadBreadCrumb(this.project.abbr);

                if (this.project.markets.length > 0) {
                    this.markets = [...this.project.markets];
                    this.markets.sort((market1: Market, market2: Market) =>
                        (market1.numb > market2.numb) ? 1 : -1
                    );

                    this.markets.forEach((market: Market) => {
                        this.procurements = [...market.procurements];
                        this.procurements.sort((procurement1: Procurement, procurement2: Procurement) =>
                            (procurement1.idprocurement > procurement2.idprocurement) ? 1 : -1
                        );

                        const procurementDocs: Document[] = [];
                        this.procurements.forEach((procurement: Procurement) => {
                            if (procurement.document !== null) {
                                procurementDocs.push(procurement.document);
                            }
                        });

                        this.followUps = [...market.marketFollowUps];
                        // this.followUps.sort((followUp1: MarketFollowUp, followUp2: MarketFollowUp) =>
                        //     (followUp1.idmarketFollowUp > followUp2.idmarketFollowUp) ? 1 : -1
                        // );

                        const followUpDocs: Document[] = [];
                        // this.followUps.forEach((followUp: MarketFollowUp) => {
                        //     if (followUp.document !== null) {
                        //         followUpDocs.push(followUp.document);
                        //     }
                        // });


                        this.marketDocs.push(
                            new MarketDoc(
                                market.numb,
                                market.title,
                                new ProcurementDoc(this.translate.instant('procurement'), procurementDocs),
                                new FollowUpDoc(this.translate.instant('follow-up'), followUpDocs),
                                new ExecutionDoc(this.translate.instant('execution'), [])
                            )
                        );
                    });
                }

                this.isLoaded = true;
            }
        );
    }

    view(idDocument: number) {
        this.modalService.open(FileViewerComponent,
            {
                windowClass: 'gr-modal-full',
                scrollable: false,
                backdrop: false,
                centered: true
            }).componentInstance.idDocument = idDocument;
    }

}
