import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ResetMarketExecDateComponent} from './reset-market-exec-date/reset-market-exec-date.component';
import {CompleteExecutionComponent} from './complete-execution.component';

import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';


import {HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {NgSelectModule} from '@ng-select/ng-select';
import {MapsModule} from '@syncfusion/ej2-angular-maps';
import {PdfJsViewerModule} from 'ng2-pdfjs-viewer';
import {UiSwitchModule} from 'ngx-ui-switch';
import {TableModule} from 'primeng/table';
import {MultiSelectModule} from 'primeng/multiselect';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {ChartModule} from 'primeng/chart';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {DialogModule} from 'primeng/dialog';
import {ModalModule} from 'ngx-bootstrap/modal';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';


@NgModule({
    declarations: [
        ResetMarketExecDateComponent,
        CompleteExecutionComponent
    ],
    exports: [
        ResetMarketExecDateComponent,
        CompleteExecutionComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,

        // BsDropdownModule.forRoot(),
        // TabsModule.forRoot(),
        HttpClientModule,
        
        
        NgbModule,
        MatTabsModule,
        MatSnackBarModule,
        MatCheckboxModule,
        NgSelectModule,
        TranslateModule,
        // DataTablesModule,
        MapsModule,
        PdfJsViewerModule,
        UiSwitchModule,
        TableModule,
        MultiSelectModule,
        InputTextModule,
        ButtonModule,
        ChartModule,
        LeafletModule,
        DialogModule,
        ModalModule,
        BsDatepickerModule,

    ]
})
export class CompleteExecutionModule { }
