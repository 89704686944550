import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {User} from '../../../payloads/user.payload';
import {TokenStorageService} from '../../../services/security/token-storage.service';
import {NotificationFlashService} from '../../../services/notification-flash.service';
import {RestAPIService} from '../../../services/rest-api.service';
import {Notification} from '../../../payloads/notification.payload';
import {JwtResponse} from '../../../services/security/jwt-response';
import {removeUserID, setLang, setUserID} from '../../../_helpers';
import {DeviceDetectorService} from 'ngx-device-detector';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
    loginForm: UntypedFormGroup;
    notifications: Notification[] = [];
    loginFormSubmitted = false;
    staticAlertClosed = false;
    errorMessage: string;
    ipAddress: string|null = null;
    @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;
    private _danger = new Subject<string>();

    constructor(private acRoute: ActivatedRoute, private router: Router, private tokenStorage: TokenStorageService,
                private restAPIService: RestAPIService, private notification: NotificationFlashService,
                private formBuilder: UntypedFormBuilder, private translate: TranslateService, private deviceDetectorService: DeviceDetectorService) {
    }

    get login() {
        return this.loginForm.controls;
    }

    ngOnInit() {
        this._danger.subscribe(message => this.errorMessage = message);
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        if (this.tokenStorage.isloggedIn()) {
            this.redirectTo('dashboard');
        }

        this.loginForm = this.formBuilder.group({
            username: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
            password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(100)]]
        });

        this.restAPIService.getIPAddress().subscribe(ipResponse => {
            this.ipAddress = ipResponse ? ipResponse.ip : null;
        });
    }

    onSubmit() {
        this.loginFormSubmitted = true;
        if (this.loginForm.invalid) {
            return;
        }

        const loginInfo: { username: string, password: string, ipAddress: string|null, platform: string } = new User().signIn(
            this.loginForm.value.username?.trim(),
            this.loginForm.value.password,
            this.ipAddress,
            JSON.stringify({
                deviceInfo: this.deviceDetectorService.getDeviceInfo(),
                isMobile: this.deviceDetectorService.isMobile(),
                isTablet: this.deviceDetectorService.isTablet(),
                isDesktop: this.deviceDetectorService.isDesktop()
            })
        );

        this.restAPIService.signIn(loginInfo).subscribe(jwtResponse => {
            this.tokenStorage.saveToken(jwtResponse.accessToken);
            setUserID(jwtResponse.userId);

            this.getUserInfos(jwtResponse);
        }, error => {
            if (error.status === 401) {
                this.errorMessage = this.translate.instant('notifications.danger.username_password_incorrect');
            } else {
                this.redirectTo('500');
            }
        });
    }

    getUserInfos(userData: JwtResponse) {
        this.restAPIService.fetchUser(userData.userId).subscribe((user: User) => {
            setLang(user.lang);
            this.translate.use(user.lang);

            if (user.resetPassword) {
                this.tokenStorage.saveUser(user);
                this.redirectTo('reset-password');
            } else {
                this.generateActiveUserOrRedirect(user);
            }
        }, userError => {
            this.notification.danger(userError.error.message);
        });
    }

    generateActiveUserOrRedirect(user: User) {
        if (user.active) {
            this.userImageAndRedirection(user);
        } else {
            this.notification.danger(this.translate.instant('notifications.danger.account-not-active'));
            this.redirectTo('sign-in');
        }
    }

    userImageAndRedirection(user: User) {
        if (user.document !== null) {
            this.restAPIService.downloadDocument(user.document.iddocument).subscribe(document => {
                user.image = document.base64;
                this.tokenStorage.saveUser(user);
                this.redirectTo('dashboard');
            });
        } else {
            user.image = './assets/img/default-avatar.jpeg';
            this.tokenStorage.saveUser(user);
            this.redirectTo('dashboard');
        }
        removeUserID();
    }

    redirectTo(path: string) {
        this.router.navigate(['/' + path], {relativeTo: this.acRoute});
    }

}
