import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {reloadComponent} from '../../../../../../_helpers';
import {NotificationFlashService} from '../../../../../../services/notification-flash.service';
import {RestAPIService} from '../../../../../../services/rest-api.service';
import {Market, ProcDate, Procurement} from '../../../../../../payloads/market.payload';
import {Project} from '../../../../../../payloads/project.payload';
import {UtilityService} from '../../../../../../services/utility.service';

@Component({
  selector: 'app-reset-proc-date',
  templateUrl: './reset-proc-date.component.html',
  styleUrls: ['./reset-proc-date.component.scss']
})
export class ResetProcDateComponent implements OnInit {
    isLoaded: boolean = false;
    project: Project;
    market: Market;

    markets: Market[] = [];
    procurements: Procurement[] = [];
    private _danger = new Subject<string>();

    staticAlertClosed = false;
    errorMessage: string;
    hideSave: boolean = false;
    @Input() update: any;

    @ViewChild('staticAlert', { static: false }) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert;
    colspan: number;

    constructor(private formBuilder: UntypedFormBuilder, private translate: TranslateService, private restAPIService: RestAPIService,
                private utilityService: UtilityService, private activeModal: NgbActiveModal, private router: Router,
                private notification: NotificationFlashService) {}

    ngOnInit(): void {
        this._danger.subscribe((message) => (this.errorMessage = message));
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.project = this.update.project;
        this.market = this.update.market;

        this.procurements = this.market.procurements;
        this.procurements.sort((procurement1: Procurement, procurement2: Procurement) =>
            procurement1.idprocurement > procurement2.idprocurement ? 1 : -1
        )

        const dates: number[] = [];
        this.procurements.forEach((procurement: Procurement) => {
            procurement.procDates.sort((procDate1: ProcDate, procDate2: ProcDate) =>
                procDate1.idprocDate > procDate2.idprocDate ? 1 : -1
            );

            dates.push(procurement.procDates.length);
        });

        dates.sort((date1: number, date2: number) => date1 < date2 ? 1 : -1 )

        this.colspan = dates[0];

        if (this.project.closed !== null || this.project.canceled !== null) {
            this.hideSave = true;
        }

        this.isLoaded = true;
    }

    onSubmit() {
        this.errorMessage = null;

        this.restAPIService.updateProcurement(this.procurements).subscribe(() => {
            this.notification.success(
                this.translate.instant('notifications.success.market-modified'),
            );

            reloadComponent(this.router);

            this.closeModal();
        }, (error) => {
            console.error(error);

            this.errorMessage = this.translate.instant(
                'notifications.danger.market-not-modified',
            );
        });
    }

    closeModal() {
        this.activeModal.close();
    }

    dateFormat(date: string): string {
        return date ? new Date(date).toLocaleDateString('fr-FR') : null;
    }

    changeProcurementPlannedDate(event: any, i: number) {
        this.procurements[i].plannedDate = event;
        this.procurements[i].procDate = new ProcDate(event);
    }

    disableDate(selected: string, date: string, dates: ProcDate[]): boolean {
        if (dates.length > 1) {
            return selected !== date;
        }
        return true;
    }

    formatBSDate(date: string): Date {
        if (!date) {
            return null;
        }
        return new Date(date);
    }
}
