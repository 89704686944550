<!DOCTYPE html>
<nav class="navbar navbar-expand-md" id="mainNav">
    <div class="container-fluid">
        <a class="navbar-brand pointer js-scroll pt-2" [routerLink]="['']">
            <span><img src="assets/img/logo-sisepcam.png" width="150" height="125" alt="SISEPCAM"
                       class="img-fluid rounded b-shadow-a d-inline-block align-top"></span>
        </a>
        <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                aria-label="Toggle navigation">
            <em class="fa fa-bars" aria-hidden="true"></em>
        </button>
        <div class="collapse navbar-collapse justify-content-md-end" id="navbarResponsive">
            <ul class="navbar-nav">
                <li class="nav-item pt-2">
                    <a [routerLink]="['/']" class="nav-link pointer js-scroll" [ngClass]="isActive('')">
                        <span><em aria-hidden="true" class="fa fa-home"></em> {{ "home.label" | translate }}</span>
                    </a>
                </li>
                <li class="nav-item pt-2">
                    <a [routerLink]="['/about-us']" class="nav-link pointer js-scroll" [ngClass]="isActive('about-us')">
                        <span><em class="fa fa-road" aria-hidden="true"></em> {{ "about_us" | translate }}</span>
                    </a>
                </li>
                <li class="nav-item pt-2">
                    <a [routerLink]="['/projects']" class="nav-link pointer js-scroll" [ngClass]="isActive('projects')">
                        <span><em class="fa fa-suitcase" aria-hidden="true"></em> {{ "projects" | translate }}</span>
                    </a>
                </li>
                <li class="nav-item pt-2">
                    <app-toggle-lang></app-toggle-lang>
                </li>
                <li class="nav-item pt-2" *ngIf="!isLogin()">
                    <a [routerLink]="['/sign-in']" class="nav-link pointer js-scroll btn btn-pill bg-warningish text-black">
                        <span><em class="fa fa-sign-in" aria-hidden="true"></em>
                            {{ "sign_in" | translate }}</span>
                    </a>
                </li>
                <li class="nav-item dropdown" *ngIf="isLogin()" dropdown>
                    <a id="link-dropdown" class="nav-link text-center" href dropdownToggle (click)="false"
                       aria-controls="user-avatar">
                        <div class="mb-0 ">
                          <img [src]="imgSrc" class="img-circle" width="35" height="35" *ngIf="user" alt="{{ user.username }}"/>
                          <div class="small text-wrap box">{{ user.name }}</div>
                          <div class="small text-wrap box">{{ role.name }}</div>
                        </div>
                    </a>
                    <div id="user-avatar" class="dropdown-menu dropdown-menu-right" aria-labelledby="link-dropdown"
                         *dropdownMenu>
                        <a class="dropdown-item" [routerLink]="['/dashboard']">
                            <em class="fa fa-home" aria-hidden="true"></em> {{ "main-menu" | translate }}
                        </a>
                        <div class="dropdown-header text-center"><strong>{{ "parameters" | translate }}</strong></div>
                        <a class="dropdown-item pointer" (click)="viewProfile()">
                            <em class="fa fa-user" aria-hidden="true"></em> {{ 'profile' | translate }}
                        </a>
                        <div class="divider"></div>
                        <a class="dropdown-item pointer" (click)="logout()">
                            <em class="fa fa-sign-out" aria-hidden="true"></em> {{ 'disconnection' | translate }}
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>
