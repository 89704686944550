<!DOCTYPE html>
<section class="animated fadeIn" *ngIf="isLoaded">
    <div class="modal-header d-flex justify-content-end">
        <span class="close fa fa-times text-red" aria-label="Close" (click)="closeModal()"></span>
    </div>
    <div class="modal-body text-black container-fluid">
        <div style="width: 100%; height: 100vh">
            <ng2-pdfjs-viewer #pdfViewerAutoLoad></ng2-pdfjs-viewer>
        </div>
    </div>
</section>
