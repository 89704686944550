import {MarketFollowUp} from './complete-execution.payload';
import {Document} from './document.payload';
import {Contract} from './contract.payload';
import {Cashout} from './task.payload';

export class Market {
    idmarket: number;
    numb: string;
    title: string;
    activity: any;
    task: any;
    deadLine: number;
    method: string;
    marketType: string;
    examType: string;
    amount: number;
    currency: string;
    exchangeRate: number;
    amountFcfa: number;
    description: string;
    startDate: string;
    signatureDate: string;
    publicationDate: string;
    endDate: string;
    status: string;
    validated: number;
    cashouts: Cashout[];
    procurements: Procurement[];
    marketFollowUps: MarketFollowUp[];
    project: number;
    createdAt: string;
    updatedAt: string;
    isValidated: boolean;
    contracts: Contract[];
    inPreparation: boolean;
    fcpRate: number;
    fcpYear: string;
    fcpBudgetLine: number;
    lessorRate: number;
    lessorYear: string;
    lessorBudgetLine: number;

    constructor(numb: string, title: string, description: string, amount: number, currency: string, exchangeRate: number, amountFcfa: number,
                activity: any, task: any, deadLine: number,  marketType: string, method: string, fcpRate: number, fcpYear: string,
                fcpBudgetLine: number, lessorRate: number, lessorYear: string, lessorBudgetLine: number, procurements: Procurement[],
                project: number, cashouts: Cashout[] = [], status: string = 'NOT_STARTED') {
        this.numb = numb;
        this.title = title;
        this.description = description;
        this.amount = amount;
        this.currency = currency;
        this.exchangeRate = exchangeRate;
        this.amountFcfa = amountFcfa;
        this.activity = activity;
        this.task = task;
        this.deadLine = deadLine;
        this.marketType = marketType;
        this.method = method;
        this.fcpRate = fcpRate;
        this.fcpYear = fcpYear;
        this.fcpBudgetLine = fcpBudgetLine;
        this.lessorRate = lessorRate;
        this.lessorYear = lessorYear;
        this.lessorBudgetLine = lessorBudgetLine;
        this.procurements = procurements;
        this.cashouts = cashouts;
        this.project = project;
        this.status = status;
    }
}

export class Procurement {
    idprocurement: number;
    title: string;
    procDate: ProcDate;
    procDates: ProcDate[];
    acceptFile: boolean;
    executedDate: string;
    codeStatus: string;
    document: Document = null;
    market: number;
    createdAt: string;
    updatedat: string;
    plannedDate: any;

    constructor(title: string, procDate: ProcDate, acceptFile: boolean = false, codeStatus: string = null) {
        this.title = title;
        this.procDate = procDate;
        this.acceptFile = acceptFile;
        this.codeStatus = codeStatus;
    }
}

export class ProcDate {
    idprocDate: number;
    plannedDate: string;
    procurement: number;
    createdAt: string;
    updatedAt: string;

    constructor(plannedDate: string) {
        this.plannedDate = plannedDate;
    }
}

export class CompleteProcurement {
    idprocurement: number;
    executedDate: string;
    document: Document = null;
    codeStatus: string;

    constructor(idprocurement: number, executedDate: string, document: Document, codeStatus: string) {
        this.idprocurement = idprocurement;
        this.executedDate = executedDate;
        this.document = document;
        this.codeStatus = codeStatus;
    }
}



