<!DOCTYPE html>
<section class="animated fadeIn" *ngIf="isLoaded">
    <div class="modal-header d-flex justify-content-between">
        <h3 class="modal-title roboto-bold-font">{{ "signature-process" | translate }}</h3>
        <span class="close fa fa-times text-red" aria-label="Close" (click)="closeModal()"></span>
    </div>
    <div class="modal-body text-black container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <ngb-alert #selfClosingAlert *ngIf="errorMessage" type="danger"
                           (closed)="errorMessage = ''">{{
                    errorMessage
                    }}</ngb-alert>

                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="table-responsive">
                            <table class="table table-hover table-striped">
                                <caption></caption>
                                <thead class="thead-dark">
                                <tr>
                                    <th class="nowrap">{{ "entitled" | translate }}</th>
                                    <th class="text-center" [colSpan]="colspan">{{ "planned-date" | translate }}</th>
                                    <th class="text-center">{{ "new-planned-date" | translate }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let signatureProc of signatureProcs; let i = index;">
                                    <td class="nowrap">{{ signatureProc.title }}</td>
                                    <td class="text-center" *ngFor="let signatureProcDate of signatureProc.signatureProcDates; let i = index;">
                                        {{ dateFormat(signatureProcDate.plannedDate) }}
                                    </td>
                                    <td class="text-center">
                                        <input type="text" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [daysDisabled]="[7,0]"
                                               placeholder="{{ 'date-format' | translate }}" [minDate]="formatBSDate(project.startDate)"
                                               [maxDate]="formatBSDate(project.endDate)" class="form-control"
                                               (bsValueChange)="changePlannedDate($event, i)"
                                               [disabled]="signatureProc.executedDate">
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="row py-2">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end">
                        <button type="submit" (click)="onSubmit()" [hidden]="hideSave"
                                class="btn btn-pill bg-primary px-5 text-right">{{ "save" | translate }}</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
