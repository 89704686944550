import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {RestAPIService} from '../../../../../../services/rest-api.service';
import {NotificationFlashService} from '../../../../../../services/notification-flash.service';
import {ActivityTask, Deliverable, Task} from '../../../../../../payloads/task.payload';
import {formatNumber, reloadComponent, taskNumber} from '../../../../../../_helpers';
import {Router} from '@angular/router';
import {Activity} from '../../../../../../payloads/activity.payload';
import {Map} from 'leaflet';
import {MapBoxService} from '../../../../../shared-component/map-box/map-box.service';
import {ConfirmationDialogService} from '../../../../../shared-component/confirmation-dialog/confirmation-dialog.service';

@Component({
    selector: 'app-create-task',
    templateUrl: './create-task.component.html',
    styleUrls: ['./create-task.component.scss']
})
export class CreateTaskComponent implements OnInit {
    isLoaded: boolean = false;
    activityTasks: any[];

    createTaskForm: UntypedFormGroup;

    createTaskFormSubmitted = false;

    task_start_date: Date;
    task_end_date: Date;
    task_end_start_date: Date;

    private _danger = new Subject<string>();

    staticAlertClosed = false;
    errorMessage: string;

    @Input() activity: Activity;

    showGeolocation = false;
    showGeolocationTask = false;

    @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;

    limitesCameroun = {
        latitude: {min: 1.6523, max: 12.6766},
        longitude: {min: 8.5057, max: 16.2015},
    };

    constructor(private formBuilder: UntypedFormBuilder, private mapBoxService: MapBoxService, private router: Router,
                private activeModal: NgbActiveModal, private notification: NotificationFlashService, private translate: TranslateService,
                private restAPIService: RestAPIService, private confirmationDialog: ConfirmationDialogService) {
    }

    private map: Map;
    private zoom: number = 15;
    latitude: any = 3.90499;
    longitude: any = 11.51828;
    latitude_task: any = 3.90499;
    longitude_task: any = 11.51828;

    receiveMap(map: Map) {
        this.map = map;
    }

    receiveZoom(zoom: number) {
        this.zoom = zoom;
    }

    setLocation_Long(locate: string) {
        this.longitude = locate;
    }

    setLocation_Lat(locate: string) {
        this.latitude = locate;
    }

    handleLongitudeState(event: any) {
        this.longitude = event;
        console.log(this.longitude);
    }

    handleLatitudeState(event: any) {
        this.latitude = event;
        console.log(this.latitude);
    }

    showGeolocationSection() {
        this.showGeolocation = true;
    }

    hideSections() {
        this.showGeolocation = false;
    }

    showGeolocationTaskSection() {
        this.showGeolocationTask = true;
    }

    hideTaskSections() {
        this.showGeolocationTask = false;
    }

    validerTaskCoordonnees() {
        const latitude = this.createTaskForm.get('T_latitude')?.value;
        const longitude = this.createTaskForm.get('T_longitude')?.value;

        if (
            latitude < this.limitesCameroun.latitude.min ||
            latitude > this.limitesCameroun.latitude.max ||
            longitude < this.limitesCameroun.longitude.min ||
            longitude > this.limitesCameroun.longitude.max
        ) {
            this.createTaskForm.setErrors({horsLimite: true});
            this.createTaskForm.get('T_latitude').setValue(this.latitude);
            this.createTaskForm.get('T_longitude').setValue(this.longitude);
        }
    }

    ngOnInit(): void {
        this._danger.subscribe(message => this.errorMessage = message);
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.mapBoxService.markerPosition$.subscribe((position) => {
            this.updateMarkerPosition(position);
        });

        this.task_start_date = new Date(this.activity.startDate);
        this.task_end_date = new Date(this.activity.endDate);
        this.task_end_start_date = new Date(this.activity.startDate);

        this.activityTasks = [...this.activity.tasks];
        this.activityTasks.sort((task1: Task, task2: Task) =>
            (task1.numb > task2.numb) ? 1 : -1
        );
        if (this.activityTasks.length > 0) {
            this.activityTasks.forEach((activityTask: ActivityTask) => {
                if (activityTask.deliverables.length > 0) {
                    activityTask.deliverables.sort((deliverable1: Deliverable, deliverable2: Deliverable) =>
                        (deliverable1.iddeliverable > deliverable2.iddeliverable) ? 1 : -1
                    );

                    activityTask.deliverable = activityTask.deliverables[0].title;
                    activityTask.verifMeans = activityTask.deliverables[0].verifMeans;
                } else {
                    activityTask.deliverable = null;
                    activityTask.verifMeans = null;
                }
            });
        }

        this.initForm();

        this.isLoaded = true;
    }

    updateMarkerPosition(newPosition: { lat: number; lng: number }) {
        this.createTaskForm.controls['T_latitude'].setValue(newPosition.lat);
        this.createTaskForm.controls['T_longitude'].setValue(newPosition.lng);
    }

    validerCoordonnees() {
        const latitude = this.createTaskForm.get('L_latitude')?.value;
        const longitude = this.createTaskForm.get('L_longitude')?.value;

        if (
            latitude < this.limitesCameroun.latitude.min ||
            latitude > this.limitesCameroun.latitude.max ||
            longitude < this.limitesCameroun.longitude.min ||
            longitude > this.limitesCameroun.longitude.max
        ) {
            this.createTaskForm.setErrors({horsLimite: true});
            this.createTaskForm.get('L_latitude').setValue(this.latitude_task);
            this.createTaskForm.get('L_longitude').setValue(this.longitude_task);
        }
    }

    get createTask() {
        return this.createTaskForm.controls;
    }

    initForm() {
        this.createTaskForm = this.formBuilder.group({
            conference: [null, [Validators.required]],
            numb: [taskNumber(this.activity.numb, (this.activityTasks.length + 1)), [Validators.required]],
            title: [null, [Validators.required]],
            amountFcfa: [null, [Validators.required]],
            deliverable: [null],
            verifMeans: [null],
            princResponsible: [null],
            startDate: [null, [Validators.required]],
            endDate: [null, [Validators.required]],
            T_longitude: [
                this.longitude_task,
                [
                    Validators.required,
                    Validators.min(-180),
                    Validators.max(180),
                ],
            ],
            T_latitude: [
                this.latitude_task,
                [Validators.required, Validators.min(-90), Validators.max(90)],
            ],
        });

        // Ajoutez des abonnements pour les changements de longitude et de latitude
        const T_longitudeControl = this.createTaskForm.get('T_longitude');
        const T_latitudeControl = this.createTaskForm.get('T_latitude');

        if (T_longitudeControl && T_latitudeControl) {
            // Abonnement aux changements de longitude
            T_longitudeControl.valueChanges.subscribe((newLongitudeValue) => {
                // Faites quelque chose avec la nouvelle valeur de longitude
                if (newLongitudeValue) {
                    this.longitude_task = parseFloat(newLongitudeValue);
                }
                // Appeler votre fonction ou effectuer l'action nécessaire ici
            });

            // Abonnement aux changements de latitude
            T_latitudeControl.valueChanges.subscribe((newLatitudeValue) => {
                // Faites quelque chose avec la nouvelle valeur de latitude
                if (newLatitudeValue) {
                    this.latitude_task = parseFloat(newLatitudeValue);
                }
                // Appeler votre fonction ou effectuer l'action nécessaire ici
            });
        }
    }

    addTask() {
        this.createTaskFormSubmitted = true;
        if (this.createTaskForm.invalid) {
            return;
        }

        const activityTask = new ActivityTask(
            this.createTaskForm.value.numb,
            this.createTaskForm.value.title,
            this.createTaskForm.value.amountFcfa,
            this.createTaskForm.value.deliverable,
            this.createTaskForm.value.verifMeans,
            this.createTaskForm.value.princResponsible,
            this.createTaskForm.value.startDate,
            this.createTaskForm.value.endDate,
            this.latitude_task,
            this.longitude_task,
            this.createTaskForm.value.conference
        );

        this.activityTasks.push(activityTask);

        this.createTaskFormSubmitted = false;

        this.createTaskForm.get('numb').setValue(taskNumber(this.activity.numb, this.activityTasks.length + 1));
        this.createTaskForm.get('title').setValue(null);
        this.createTaskForm.get('amountFcfa').setValue(null);
        this.createTaskForm.get('deliverable').setValue(null);
        this.createTaskForm.get('verifMeans').setValue(null);
        this.createTaskForm.get('princResponsible').setValue(null);
        this.createTaskForm.get('startDate').setValue(null);
        this.createTaskForm.get('endDate').setValue(null);
        this.createTaskForm.get('conference').setValue(null);
    }

    deleteTask(index: number) {
        const activityTask: ActivityTask = this.activityTasks[index];
        if (activityTask.idtask) {
            this.confirmationDialog.confirm(this.translate.instant('task'),
                this.translate.instant('confirmations.contents.delete-task'))
                .then((confirmation: boolean) => {
                    if (confirmation) {
                        this.restAPIService.deleteTask(activityTask.idtask).subscribe(() => {
                            this.notification.success(this.translate.instant('notifications.success.task-deleted'));

                            this.createTaskForm.get('numb').setValue(activityTask.numb);
                            this.activityTasks.splice(index, 1);
                        }, error => {
                            console.error(error);
                            this.notification.danger(this.translate.instant('notifications.danger.task-not-deleted'));
                        });
                    }
                });
        } else {
            this.createTaskForm.get('numb').setValue(activityTask.numb);
            this.activityTasks.splice(index, 1);
        }

        if (this.activityTasks.length <= 0) {
            this.createTaskForm.get('numb').setValue(taskNumber(this.activity.numb, 1));
        }
    }

    checkTaskAmount(amount: any) {
        let taskAmount = 0;
        if (this.activityTasks.length > 0) {
            this.activityTasks.forEach((activityTask: ActivityTask) => {
                taskAmount += activityTask.amountFcfa;
            });
        }

        const remainingAmount = this.activity.amountFcfa - taskAmount;

        if (amount > remainingAmount) {
            this.errorMessage = this.translate.instant('notifications.danger.tasks-amount-not-more-than-activity-amount',
                {amount: this.moneyFormat(remainingAmount)});

            this.createTaskForm.get('amountFcfa').setValue(Math.floor(amount / 10));
        } else {
            this.errorMessage = null;
        }
    }

    minTaskDate(date: string) {
        this.task_end_start_date = new Date(date);
    }

    onSubmit() {
        this.errorMessage = null;

        if (this.activityTasks.length > 0) {
            this.activityTasks.forEach((task: ActivityTask) => {
                if (task.deliverable !== '') {
                    task.deliverables.push(new Deliverable(task.deliverable, task.verifMeans, task.idtask));
                }
            });
        }

        this.restAPIService.createTask(this.activity.idactivity, this.activityTasks).subscribe(() => {
            this.closeModal();

            reloadComponent(this.router);

            this.notification.success(this.translate.instant('notifications.success.task-created'));
        }, error => {
            console.error(error);

            this.errorMessage = this.translate.instant('notifications.danger.task-not-created');
        });
    }

    closeModal() {
        this.activeModal.close();
    }

    moneyFormat(amount: number): string {
        return formatNumber(amount);
    }

    formatDate(date: string): string {
        return new Date(date).toLocaleDateString('fr-FR');
    }

    changeTaskTitle(title: string, index: number) {
        this.activityTasks[index].title = title;
    }

    changeTaskAmount(event: any, index) {
        const amount = Number(event.target.value);

        let taskAmount = 0;
        if (this.activityTasks.length > 0) {
            this.activityTasks.forEach((task: ActivityTask) => {
                taskAmount += task.amountFcfa;
            });
        }

        const remainingAmount = this.activity.amountFcfa - taskAmount;

        if (amount > remainingAmount) {
            this.errorMessage = this.translate.instant('notifications.danger.tasks-amount-not-more-than-activity-amount',
                {amount: this.moneyFormat(remainingAmount)});

            event.target.value = Math.floor(amount / 10);
            this.activityTasks[index].amountFcfa = event.target.value;
        } else {
            this.errorMessage = null;

            this.activityTasks[index].amountFcfa = amount;
        }
    }

    changeTaskDeliverable(deliverable: any, index: number) {
        this.activityTasks[index].deliverable = deliverable;
    }

    changeTaskVerifMeans(verifMeans: string, index: number) {
        this.activityTasks[index].verifMeans = verifMeans;
    }

    changeTaskResponsible(princResponsible: string, index: number) {
        this.activityTasks[index].princResponsible = princResponsible;
    }

    changeTaskStartDate(startDate: string, index: number) {
        this.task_end_start_date = new Date(startDate);
        this.activityTasks[index].startDate = startDate;
    }

    changeTaskEndDate(endDate: string, index: number) {
        this.activityTasks[index].endDate = endDate;
    }

    formatBSDate(date: string): Date {
        return (!date) ? null : new Date(date);
    }
}
