<c-sidebar #sidebar="cSidebar" class="d-print-none sidebar sidebar-fixed" id="sidebar" visible>
    <c-sidebar-brand [brandFull]="{
            src: 'assets/img/logo-sisepcam.png',
            width: 60,
            height: 46,
            alt: 'SISEPCAM Logo'
        }" [brandNarrow]="{
            src: 'assets/img/logo-sisepcam.png',
            width: 46,
            height: 40,
            alt: 'SISEPCAM Logo'
        }" [routerLink]="['']"/>

    <ng-scrollbar pointerEventsMethod="scrollbar">
        <c-sidebar-nav [navItems]="navItems" dropdownMode="close"/>
    </ng-scrollbar>
    <c-sidebar-toggler *ngIf="!sidebar.narrow" toggle="unfoldable" cSidebarToggle="sidebar"/>
</c-sidebar>
<!--main-->
<div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
    <!--app-header-->
    <app-default-header class="mb-4 d-print-none header header-sticky" position="sticky" sidebarId="sidebar"/>
    <!--app-body -->
    <div class="body flex-grow-1 px-3">
        <div class="h-auto">
            <ul class="roboto-bold-font py-2 breadTitle breadcrumb" [innerHTML]="breadTitle"></ul>

            <router-outlet/>

        </div>
    </div>
    <!--app footer-->
</div>
