<ng-container>
    <c-container [fluid]="true">
        <button toggle="visible" cHeaderToggler [cSidebarToggle]="sidebarId" class="ps-1">
            <em class="fa fa-bars" aria-hidden="true"></em>
        </button>
        <c-header-nav class="d-none d-lg-flex me-auto"></c-header-nav>

        <c-header-nav class=" d-lg-flex">
            <c-nav-item>
                <app-toggle-lang></app-toggle-lang>
            </c-nav-item>
            <c-nav-item>
                <c-dropdown #cmp autoClose="outside" alignment="end" variant="nav-item">
                    <button [disabled]="notifications?.length <=0" type="button" cButton color="" [caret]="false"
                            class="position-relative p-0 rounded-circle btn-light" cDropdownToggle>
                        <a class="nav-link" id="notif-dropdown" href (click)="false">
                            <i class="fa fa-bell"></i>
                            <span
                                class="position-absolute -top-80 start-10 translate-middle badge rounded-pill bg-danger"
                                *ngIf="notifications?.length > 0">{{
                                notifications?.length }}</span>
                        </a>
                    </button>
                    <div cDropdownMenu class="p-0 m-0" style="width: 450px;">
                        <div cDropdownHeader class="d-flex flex-column bg-light border-bottom">
                            <h5 class="border-bottom pb-2 font-weight-bold">Notifications</h5>
                            <span (click)="viewAll()"
                                  class="text-right mr-1 font-weight-semibold bg-light read pointer">{{"Marquer tout comme lu" | translate}}</span>
                        </div>
                        <div class="w-auto max-h overflow-auto">
                            <li class="border-bottom d-flex inline p-2 align-items-center justify-content-between notif-row"
                                *ngFor="let notification of notifications;">
                                <a class="dropdown-item"
                                   (click)="viewNotification(notification)">
                                    <em [ngClass]="changeIconColor(notification.notificationType)"
                                        aria-hidden="true"></em>
                                    <span
                                        class="pl-2 mw-80 text-wrap">{{ createMessage(notification.message, notification.messageType) }}</span>
                                </a>
                                <span class="text-muted text-small small text-nowrap" timeago
                                      [date]="getDateInTimeSTamp(notification.createdAt)" [live]="true"></span>
                            </li>
                        </div>
                    </div>
                </c-dropdown>
            </c-nav-item>
        </c-header-nav>
        <c-header-nav class="ms-3">
            <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
        </c-header-nav>
    </c-container>
</ng-container>

<ng-template #userDropdown>
    <c-dropdown alignment="end" variant="nav-item">
        <button cButton color="" [caret]="false" cDropdownToggle class="py-0">
            <c-avatar cTextColor="primary" shape="rounded-1" [src]="imgSrc" status="success"></c-avatar>
        </button>
        <ul cDropdownMenu class="pt-0 w-auto">
            <li>
                <p class="" cDropdownItem>{{ user.name }} {{ user.surname }} <br>
                    {{ _role.code }}
                </p>
            </li>
            <li cDropdownDivider></li>
            <li>
                <a class="dropdown-item pointer" [routerLink]="['/dashboard']">
                    <em aria-hidden="true" class="fa fa-home"></em> {{ "main-menu" |translate }}</a>
            </li>
            <li cDropdownDivider></li>
            <li cDropdownHeader class="bg-light font-weight-bold">{{ "parameters" | translate }}</li>
            <li>
                <a class="dropdown-item pointer" (click)="viewProfile()">
                    <em class="fa fa-user" aria-hidden="true"></em> {{ 'profile' | translate }}</a>
            </li>
            <li>
                <a class="dropdown-item pointer" (click)="logout()">
                    <em class="fa fa-sign-out" aria-hidden="true"></em> {{ 'disconnection' | translate }}</a>
            </li>
            <li></li>
        </ul>
    </c-dropdown>
</ng-template>
