import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NotificationFlashService} from '../../../../../../services/notification-flash.service';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationDialogService} from '../../../../../shared-component/confirmation-dialog/confirmation-dialog.service';
import {UntypedFormBuilder} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {UtilityService} from '../../../../../../services/utility.service';
import {RestAPIService} from '../../../../../../services/rest-api.service';
import {debounceTime, Subject} from 'rxjs';
import {Cashout, DeliverableCashout} from '../../../../../../payloads/task.payload';
import {formatNumber} from '../../../../../../_helpers';

@Component({
    selector: 'app-cashout-comfirm',
    templateUrl: './cashout-comfirm.component.html',
    styleUrls: ['./cashout-comfirm.component.css']
})
export class CashoutComfirmComponent implements OnInit {

    errorMessage: string;
    @Input() view = false;
    @Input() project: any;
    @Input() market: any;
    @Input() contract: any;
    @Output() editedCashoutsChanged = new EventEmitter<Cashout[]>();
    cashouts: Cashout[] = [];
    editedCashouts: Cashout[] = [];
    startDate: Date;
    endDate: Date;
    @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;
    private _danger = new Subject<string>();

    constructor(private formBuilder: UntypedFormBuilder, private confirmationDialog: ConfirmationDialogService, private translate: TranslateService,
                private restAPIService: RestAPIService, private utilityService: UtilityService, private activeModal: NgbActiveModal,
                private notification: NotificationFlashService) {
    }

    ngOnInit(): void {
        this._danger.subscribe((message) => (this.errorMessage = message));
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.startDate = new Date(this.project.startDate);
        this.endDate = new Date(this.project.endDate);

        this.cashouts = this.view ? this.contract.cashoutComfirms : this.market.task.cashouts;
        this.editedCashouts = JSON.parse(JSON.stringify(this.cashouts));
    }

    onCashoutDateChanged(event: any, i: number): void {
        this.cashouts[i].startDate = new Date(event);

        this.editedCashouts = JSON.parse(JSON.stringify(this.cashouts));
        this.editedCashoutsChanged.emit(this.editedCashouts);
    }

    onCashoutDescriptionChanged(description: any, i: number): void {
        this.cashouts[i].description = description;

        this.editedCashouts = JSON.parse(JSON.stringify(this.cashouts));
        this.editedCashoutsChanged.emit(this.editedCashouts);
    }

    onCashoutAmountChanged(amount: any, i: number): void {
        this.cashouts[i].amount = amount / this.market.exchangeRate;
        this.cashouts[i].amountFcfa = amount;

        this.editedCashouts = JSON.parse(JSON.stringify(this.cashouts));
        this.editedCashoutsChanged.emit(this.editedCashouts);
    }

    onAmountFcfaChange(i: number): void {
        const exchangeRate = this.market.exchangeRate;
        if (!isNaN(this.cashouts[i].amountFcfa) && !isNaN(exchangeRate)) {
            this.cashouts[i].amount = this.cashouts[i].amountFcfa / exchangeRate;
        } else {
            this.cashouts[i].amount = 0;
        }
    }

    onDeliverableDateChange(date: any, deliverableIndex: number, cashoutIndex: number): void {
        const cashout: Cashout = this.cashouts[cashoutIndex];
        const deliverable: DeliverableCashout = cashout.deliverablesList[deliverableIndex];
        const editedCashout = this.editedCashouts.find(ec => ec.idcashout === cashout.idcashout);

        deliverable.deliverableDate = new Date(date);

        if (editedCashout) {
            const editedDeliverable = editedCashout.deliverablesList.find(ed => ed.iddeliverablecashout === deliverable.iddeliverablecashout);

            if (editedDeliverable) {
                editedDeliverable.deliverableDate = new Date(deliverable.deliverableDate);
            } else {
                editedCashout.deliverablesList.push({...deliverable});
            }
        } else {
            this.editedCashouts.push({...cashout, deliverablesList: [{...deliverable}]});
        }
        this.editedCashoutsChanged.emit(this.editedCashouts);
    }

    onDeliverableRateChange(deliverableIndex: number, cashoutIndex: number): void {
        const cashout: Cashout = this.cashouts[cashoutIndex];
        const deliverable: DeliverableCashout = cashout.deliverablesList[deliverableIndex];

        let totalRate = 0;
        cashout.deliverablesList.forEach((deliverable) => {
            totalRate += deliverable.deliverableRate;
        });

        if (cashout && !isNaN(deliverable.deliverableRate) && totalRate <= 100 && !isNaN(cashout.amountFcfa)) {
            const editedCashout = this.editedCashouts.find(ec => ec.idcashout === cashout.idcashout);

            if (editedCashout) {
                const editedDeliverable = editedCashout.deliverablesList.find(
                    ed => ed.iddeliverablecashout === deliverable.iddeliverablecashout
                );

                if (editedDeliverable) {
                    editedDeliverable.deliverableRate = deliverable.deliverableRate;
                } else {
                    editedCashout.deliverablesList.push(JSON.parse(JSON.stringify(deliverable)));
                }
            } else {
                this.editedCashouts.push({
                    ...cashout,
                    deliverablesList: JSON.parse(JSON.stringify(cashout.deliverablesList))
                });
            }
            this.editedCashoutsChanged.emit(this.editedCashouts);
        } else {
            this.editedCashoutsChanged.emit(this.cashouts);
        }
    }

    showDeliverable(cashoutId: number): void {
        const cashout = this.cashouts.find(c => c.idcashout === cashoutId);

        if (cashout) {
            cashout.toggle = 1;
        }
    }

    hideDeliverable(cashoutId: number): void {
        const cashout = this.cashouts.find(c => c.idcashout === cashoutId);

        if (cashout) {
            cashout.toggle = 0;
        }
    }

    deliverableAmount(deliverableRate: number, cashoutAmountFCFA: number): string {
        return this.moneyFormat(Number(((deliverableRate * cashoutAmountFCFA) / 100).toFixed(0)));
    }

    moneyFormat(amount: number): string {
        return formatNumber(amount);
    }

    dateFormat(date: string): string {
        return date ? new Date(date).toLocaleDateString('fr-FR') : null;
    }

    deleteCashout(index: number, id: number): void {
        if (index >= 0 && index < this.cashouts.length) {
            this.confirmationDialog.confirm(
                this.translate.instant('cashout'),
                this.translate.instant('confirmations.contents.cashout-delete')
            ).then((confirmation: boolean) => {
                if (confirmation) {
                    this.restAPIService.deleteCashout(id).subscribe(
                        () => {
                            this.cashouts.splice(index, 1);
                            this.notification.success(
                                this.translate.instant('notifications.success.cashout-deleted')
                            );
                        }, () => {
                            this.notification.danger(this.translate.instant('notifications.success.cashout-not-deleted'));
                        }
                    );
                }
            });
        }
    }

    formatBSDate(date: string): Date {
        return date ? new Date(date) : null;
    }
}
