import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {projectReportsNavItems} from "../../../../sidebars/project-reports";
import {Document} from "../../../../../../../payloads/document.payload";
import {Project} from "../../../../../../../payloads/project.payload";
import {DefaultLayoutComponent} from "../../../../../../shared-component/default-layout/default-layout.component";
import {ImplementationProc} from "../../../../../../../payloads/implementation-process.payload";
import {RestAPIService} from "../../../../../../../services/rest-api.service";
import {FileViewerComponent} from "../../../../../../shared-component/file-viewer/file-viewer.component";
import {FollowUpsDoc, ImplementationProcDoc} from "../../../../../../../payloads/utility.payload";

@Component({
  selector: 'app-follow-up-portfolio-reports',
  templateUrl: './follow-up-portfolio-reports.component.html',
  styleUrls: ['./follow-up-portfolio-reports.component.scss']
})
export class FollowUpProjectPortfolioReportsComponent implements OnInit, AfterViewInit {
  isLoaded: boolean = false;

  public idProject = Number(sessionStorage.getItem('project'));

  public project: Project;

  public implementationProcs: ImplementationProc[] = [];

  public followUpDocs: FollowUpsDoc[] = [];

  constructor(private defaultLayout: DefaultLayoutComponent,
    private translate: TranslateService,
    private router: Router,
    private modalService: NgbModal,
    private restAPIService: RestAPIService) { }

  ngAfterViewInit(): void {
    setTimeout((): void => {
      this.defaultLayout.navItems = projectReportsNavItems(this.translate);
    }, 0);
  }

  ngOnInit(): void {
    if (this.idProject === 0) {
      this.router.navigate(['/reports']);
    }

    this.restAPIService.fetchProject(this.idProject).subscribe(
      project => {
        this.project = project;

        this.defaultLayout.navItems = projectReportsNavItems(this.translate);
        this.defaultLayout.reloadBreadCrumb(this.project.abbr);

        const implDocs: Document[] = [];

        if (this.project.implementationProcs.length > 0) {
          this.implementationProcs = [...this.project.implementationProcs];
          this.implementationProcs.sort((implProc1: ImplementationProc, implProc2: ImplementationProc) =>
            (implProc1.idimplementationProc > implProc2.idimplementationProc) ? 1 : -1
          );

          this.implementationProcs.forEach((implementationProc: ImplementationProc) => {
            implDocs.push(implementationProc.document);
          });
        }

        this.followUpDocs.push(
          new FollowUpsDoc(
            this.project.numb,
            this.project.title,
            null,
            null,
            null,
            new ImplementationProcDoc(this.translate.instant('implementation'), implDocs)
          )
        );

        this.isLoaded = true;
      }
    );
  }

  view(idDocument: number) {
    this.modalService.open(FileViewerComponent,
      {
        windowClass: 'gr-modal-full',
        scrollable: false,
        backdrop: false,
        centered: true
      }).componentInstance.idDocument = idDocument;
  }

}
