export class Document {
    iddocument: number;
    target: number | string;
    name: string;
    type: string;
    path: string;
    base64: string;
    createdAt: string;
    updatedAt: string;
    ordering: number;

    constructor(target: number | string, name: string, type: string, base64: string, ordering: number = 0) {
        this.target = target;
        this.name = name;
        this.type = type;
        this.base64 = base64;
        this.ordering = ordering;
    }
}

export class ProductServiceDoc {
    createdAt: string;
    updatedAt: string;
    inPreparation: boolean;
    validatedDated1: string | null;
    validatedDated2: string | null;
    validatedDated3: string | null;
    idproductServiceDoc: number;
    document: Document;
    ordering: number;

    constructor(createdAt: string, updatedAt: string, inPreparation: boolean, validatedDated1: string | null, validatedDated2: string | null,
                validatedDated3: string | null, idproductServiceDoc: number, document: Document, ordering: number = 0,) {
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.inPreparation = inPreparation;
        this.validatedDated1 = validatedDated1;
        this.validatedDated2 = validatedDated2;
        this.validatedDated3 = validatedDated3;
        this.idproductServiceDoc = idproductServiceDoc;
        this.document = document;
        this.ordering = ordering;
    }
}
