<!DOCTYPE html>
<section class="animated fadeIn" *ngIf="isLoaded">
    <div class="modal-header d-flex justify-content-between">
        <h3 class="modal-title roboto-bold-font"> {{ "market" | translate }} : {{ market.numb }}</h3>
        <span class="close fa fa-times text-red" aria-label="Close" (click)="closeModal(contract)"></span>
    </div>
    <div class="modal-body text-black container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <ngb-alert #selfClosingAlert *ngIf="errorMessage" type="danger"
                           (closed)="errorMessage = ''">{{
                    errorMessage
                    }}</ngb-alert>

                <section [hidden]="slide1">
                    <form [formGroup]="slide1Form">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-5 col-sm-5">
                                <div class="form-group">
                                    <label class="control-label" for="numb">{{ "inputs.numb.label" | translate }}
                                        <span class="roboto-bold-font text-red">*</span></label>
                                    <input type="text" formControlName="numb" readonly="{{ signedMarket.view }}"
                                           class="form-control text-right roboto-bold-font"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.numb.errors }"
                                           aria-label="Number" aria-describedby="basic-user" id="numb">
                                    <div *ngIf="slide1FormSubmitted && csrForm.numb.errors" class="invalid-feedback">
                                        <div *ngIf="csrForm.numb.errors.required">{{
                                            "inputs.numb.validations.required" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-8 col-lg-8 col-md-7 col-sm-7">
                                <div class="form-group">
                                    <label class="control-label" for="title">{{ "inputs.entitle.label" | translate }}
                                        <span class="roboto-bold-font text-red">*</span></label>
                                    <input type="text" formControlName="title" class="form-control"
                                           readonly="{{ signedMarket.view }}"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.title.errors }"
                                           aria-label="Title" aria-describedby="basic-user" id="title">
                                    <div *ngIf="slide1FormSubmitted && csrForm.title.errors" class="invalid-feedback">
                                        <div *ngIf="csrForm.title.errors.required">{{
                                            "inputs.entitle.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h5 class="py-2 roboto-bold-font">{{ "winning-company" | translate }}</h5>
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="socialReason">{{ "inputs.socialReason.label" | translate }}
                                        <span class="roboto-bold-font text-red">*</span></label>
                                    <input type="text" formControlName="socialReason" class="form-control"
                                           readonly="{{ signedMarket.view }}"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.socialReason.errors }"
                                           aria-label="Number" aria-describedby="basic-user" id="socialReason">
                                    <div *ngIf="slide1FormSubmitted && csrForm.socialReason.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="csrForm.socialReason.errors.required">{{
                                            "inputs.socialReason.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-8">
                                <div class="form-group">
                                    <label class="control-label" for="uin">{{ "inputs.uin.label" | translate }}
                                        <span class="roboto-bold-font text-red">*</span></label>
                                    <input type="text" formControlName="uin" class="form-control"
                                           readonly="{{ signedMarket.view }}"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.uin.errors }"
                                           aria-label="Number" aria-describedby="basic-user" id="uin">
                                    <div *ngIf="slide1FormSubmitted && csrForm.uin.errors" class="invalid-feedback">
                                        <div *ngIf="csrForm.uin.errors.required">{{
                                            "inputs.uin.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-4">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="uinFile">{{ "inputs.uinFile.label" | translate }}</label>
                                    <br>
                                    <span *ngIf="contract.document !== null">
                                        <em *ngIf="contract.document.iddocument"
                                            class="bi-sm bi-file-earmark-pdf pointer"
                                            (click)="view(contract.document.iddocument)"></em>
                                    </span>
                                    <input type="file" formControlName="uinFile" (change)="onFileSelect($event)"
                                           *ngIf="contract.document === null"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.uinFile.errors }"
                                           aria-label="Number" aria-describedby="basic-user" id="uinFile"
                                           readonly="{{ signedMarket.view }}">
                                    <div *ngIf="slide1FormSubmitted && csrForm.uinFile.errors" class="invalid-feedback">
                                        <div *ngIf="csrForm.uinFile.errors.required">{{
                                            "inputs.uinFile.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-3 col-lg-3 col-md-6">
                                <div class="form-group">
                                    <label class="control-label" for="rccm">{{ "inputs.rccm.label" | translate }}
                                        <span class="roboto-bold-font text-red">*</span></label>
                                    <input type="text" formControlName="rccm" class="form-control"
                                           readonly="{{ signedMarket.view }}"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.rccm.errors }"
                                           aria-label="Number" aria-describedby="basic-user" id="rccm">
                                    <div *ngIf="slide1FormSubmitted && csrForm.rccm.errors" class="invalid-feedback">
                                        <div *ngIf="csrForm.rccm.errors.required">{{
                                            "inputs.rccm.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-6">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="judicialForm">{{ "inputs.judicialForm.label" | translate }}
                                        <span class="roboto-bold-font text-red">*</span></label>
                                    <ng-select formControlName="judicialForm" id="judicialForm"
                                               placeholder="Forme Juridique"
                                               [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.judicialForm.errors }"
                                               [searchable]="true" [clearable]="false"
                                               readonly="{{ signedMarket.view }}">
                                        <ng-option *ngFor="let judicialForm of judicialForms"
                                                   [value]="judicialForm">{{ judicialForm }}</ng-option>
                                    </ng-select>
                                    <div *ngIf="slide1FormSubmitted && csrForm.judicialForm.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="csrForm.judicialForm.errors.required">{{
                                            "inputs.judicialForm.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-6">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="phone">{{ "inputs.phone_number.label" | translate }}</label>
                                    <input type="text" formControlName="phone" class="form-control"
                                           readonly="{{ signedMarket.view }}"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.phone.errors }"
                                           aria-label="Number" aria-describedby="basic-user" id="phone">
                                    <div *ngIf="slide1FormSubmitted && csrForm.phone.errors" class="invalid-feedback">
                                        <div *ngIf="csrForm.phone.errors.required">{{
                                            "inputs.phone_number.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-6">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="directorName">{{ "inputs.directorName.label" | translate }}</label>
                                    <input type="text" formControlName="directorName" class="form-control"
                                           readonly="{{ signedMarket.view }}"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.directorName.errors }"
                                           aria-label="Number" aria-describedby="basic-user" id="directorName">
                                    <div *ngIf="slide1FormSubmitted && csrForm.directorName.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="csrForm.directorName.errors.required">{{
                                            "inputs.directorName.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-3 col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="companyNationality">{{ "inputs.companyNationality.label" | translate }}</label>
                                    <input type="text" formControlName="companyNationality" class="form-control"
                                           readonly="{{ signedMarket.view }}"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.companyNationality.errors }"
                                           aria-label="Number" aria-describedby="basic-user" id="companyNationality">
                                    <div *ngIf="slide1FormSubmitted && csrForm.companyNationality.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="csrForm.companyNationality.errors.required">{{
                                            "inputs.companyNationality.validations.required" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-5 col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="signatoryName">{{ "inputs.signatoryName.label" | translate }}
                                        <span class="roboto-bold-font text-red">*</span></label>
                                    <input type="text" formControlName="signatoryName" class="form-control"
                                           readonly="{{ signedMarket.view }}"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.signatoryName.errors }"
                                           aria-label="Number" aria-describedby="basic-user" id="signatoryName">
                                    <div *ngIf="slide1FormSubmitted && csrForm.signatoryName.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="csrForm.signatoryName.errors.required">{{
                                            "inputs.signatoryName.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="signatoryFunction">{{ "inputs.signatoryFunction.label" | translate }}
                                        <span class="roboto-bold-font text-red">*</span></label>
                                    <input type="text" formControlName="signatoryFunction" class="form-control"
                                           readonly="{{ signedMarket.view }}"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.signatoryFunction.errors }"
                                           aria-label="Number" aria-describedby="basic-user" id="signatoryFunction">
                                    <div *ngIf="slide1FormSubmitted && csrForm.signatoryFunction.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="csrForm.signatoryFunction.errors.required">{{
                                            "inputs.signatoryFunction.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h5 class="py-2 roboto-bold-font">{{ "attributions-definition" | translate }}</h5>
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="projectOwner">{{ "inputs.projectOwner.label" | translate }}</label>
                                    <input type="text" formControlName="projectOwner" class="form-control"
                                           readonly="{{ signedMarket.view }}"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.projectOwner.errors }"
                                           aria-label="Number" aria-describedby="basic-user" id="projectOwner">
                                    <div *ngIf="slide1FormSubmitted && csrForm.projectOwner.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="csrForm.projectOwner.errors.required">{{
                                            "inputs.projectOwner.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="marketServiceChief">{{ "inputs.marketServiceChief.label" | translate }}</label>
                                    <input type="text" formControlName="marketServiceChief" class="form-control"
                                           readonly="{{ signedMarket.view }}"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.marketServiceChief.errors }"
                                           aria-label="Number" aria-describedby="basic-user" id="marketServiceChief">
                                    <div *ngIf="slide1FormSubmitted && csrForm.marketServiceChief.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="csrForm.marketServiceChief.errors.required">{{
                                            "inputs.marketServiceChief.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="delProjectOwner">{{ "inputs.marketEngineer.label" | translate }}/
                                        {{ "inputs.delegate_project_owner.label" | translate }}</label>
                                    <input type="text" formControlName="delProjectOwner" class="form-control"
                                           readonly="{{ signedMarket.view }}"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.delProjectOwner.errors }"
                                           aria-label="Number" aria-describedby="basic-user" id="delProjectOwner">
                                    <div *ngIf="slide1FormSubmitted && csrForm.delProjectOwner.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="csrForm.delProjectOwner.errors.required">{{
                                            "inputs.marketEngineer.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h5 class="py-2 roboto-bold-font">{{ "contract-amount" | translate }}</h5>
                        <div class="row">
                            <div class="col-xl-3 col-lg-3 col-md-6">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="amount_inCurrency">{{ "inputs.amount_in_currency.label" | translate }}</label>
                                    <div class="input-group mb-3">
                                        <input type="text" formControlName="amount"
                                               class="form-control roboto-bold-font text-right" readonly="true"
                                               [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.amount.errors }"
                                               aria-label="Number" aria-describedby="basic-user" id="amount_inCurrency">
                                        <div class="input-group-append">
                                            <ng-select formControlName="currency" id="currency" placeholder="Dévise"
                                                       readonly="true"
                                                       [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.currency.errors }"
                                                       [searchable]="true" [clearable]="false">
                                                <ng-option *ngFor="let devise of devises"
                                                           [value]="devise">{{ devise }}</ng-option>
                                            </ng-select>
                                            <div *ngIf="slide1FormSubmitted && csrForm.currency.errors"
                                                 class="invalid-feedback">
                                                <div
                                                    *ngIf="csrForm.currency.errors.required">{{ "inputs.currency.validations.required" | translate }}</div>
                                            </div>
                                        </div>
                                        <div *ngIf="slide1FormSubmitted && csrForm.amount.errors"
                                             class="invalid-feedback">
                                            <div *ngIf="csrForm.amount.errors.required">{{
                                                "inputs.amount_in_currency.validations.required" |
                                                    translate }}
                                            </div>
                                        </div>
                                        <div *ngIf="errorMessage" class="invalid-feedback">
                                            <div>{{ errorMessage }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-6">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="amountFcfa">{{ "inputs.amount_in_fcfa.label" | translate }}</label>
                                    <input type="text" formControlName="amountFcfa"
                                           class="form-control roboto-bold-font text-right" readonly="true"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.amountFcfa.errors }"
                                           aria-label="Number" aria-describedby="basic-user" id="amountFcfa">
                                    <div *ngIf="slide1FormSubmitted && csrForm.amountFcfa.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="csrForm.amountFcfa.errors.required">{{
                                            "inputs.amount_in_fcfa.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-6">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="beneficiaryAccountNumb">{{ "inputs.beneficiaryAccountNumb.label" | translate }}</label>
                                    <input type="text" formControlName="beneficiaryAccountNumb" class="form-control"
                                           readonly="{{ signedMarket.view }}"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.beneficiaryAccountNumb.errors }"
                                           aria-label="Number" aria-describedby="basic-user"
                                           id="beneficiaryAccountNumb">
                                    <div *ngIf="slide1FormSubmitted && csrForm.beneficiaryAccountNumb.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="csrForm.beneficiaryAccountNumb.errors.required">{{
                                            "inputs.beneficiaryAccountNumb.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-6">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="bankingInstitution">{{ "inputs.bankingInstitution.label" | translate }}</label>
                                    <input type="text" formControlName="bankingInstitution" class="form-control"
                                           readonly="{{ signedMarket.view }}"
                                           [ngClass]="{ 'is-invalid': slide1FormSubmitted && csrForm.bankingInstitution.errors }"
                                           aria-label="Number" aria-describedby="basic-user" id="bankingInstitution">
                                    <div *ngIf="slide1FormSubmitted && csrForm.bankingInstitution.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="csrForm.bankingInstitution.errors.required">{{
                                            "inputs.bankingInstitution.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end">
                            <a class="btn btn-pill bg-green px-5" (click)="changeSlide(2)">{{ "next" | translate }}</a>
                        </div>
                    </div>
                </section>

                <section [hidden]="slide2">
                    <h5 class="py-2 roboto-bold-font">{{ "inputs.payment-modalities.label" | translate }}</h5>
                    <form [formGroup]="slide1_1Form">
                        <div class="row" [hidden]="true">
                            <div class="col-xl-4 col-lg-4 col-md-3">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="amount_in_fcfa">{{ "inputs.amount_in_fcfa.label" | translate }}</label>
                                    <input type="number" formControlName="amount"
                                           class="form-control text-right roboto-bold-font"
                                           aria-label="Amount" aria-describedby="basic-user" id="amount_in_fcfa"
                                           (input)="checkPaymentAmount($event.target.value)"
                                           [ngClass]="{ 'is-invalid': slide1_1FormSubmitted && modForm.amount.errors }">
                                    <div *ngIf="slide1_1FormSubmitted && modForm.amount.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="modForm.amount.errors.required">{{
                                            "inputs.amount_in_fcfa.validations.required" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-8 col-lg-8 col-md-9">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="condition">{{ "inputs.condition.label" | translate }}</label>
                                    <input type="text" formControlName="condition" class="form-control"
                                           aria-label="Name" aria-describedby="basic-user" id="condition"
                                           [ngClass]="{ 'is-invalid': slide1_1FormSubmitted && modForm.condition.errors }">
                                    <div *ngIf="slide1_1FormSubmitted && modForm.condition.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="modForm.condition.errors.required">{{
                                            "inputs.condition.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-12 col-lg-12 d-flex justify-content-end text-white">
                                <a class="btn btn-pill bg-green my-2 mx-1" (click)="addContractPayment()"
                                   disabled="{{ signedMarket.view }}"><em
                                    class="fa fa-plus"></em></a>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="table-responsive">
                                    <table class="table table-hover table-striped">
                                        <caption></caption>
                                        <thead class="thead-dark">
                                        <tr>
                                            <th class="nowrap">{{ "inputs.amount_in_fcfa.label" | translate }}</th>
                                            <th>{{ "inputs.condition.label" | translate }}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let contractPayment of contractPayments; let i = index;">
                                            <td class="roboto-bold-font text-right nowrap">{{ formatMoney(contractPayment.amount) }}</td>
                                            <td>{{ contractPayment.condition }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </form>

                    <hr>

                    <h5 class="py-2 roboto-bold-font">{{ "revenue-commission" | translate }}</h5>
                    <form [formGroup]="slide2Form">
                        <div class="row" [hidden]="true">
                            <div class="col-xl-6 col-lg-6 col-md-7">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="name">{{ "inputs.name.label" | translate }}</label>
                                    <input type="text" formControlName="name" class="form-control"
                                           aria-label="Name" aria-describedby="basic-user" id="name"
                                           [ngClass]="{ 'is-invalid': slide2FormSubmitted && dForm.name.errors }">
                                    <div *ngIf="slide2FormSubmitted && dForm.name.errors" class="invalid-feedback">
                                        <div *ngIf="dForm.name.errors.required">{{
                                            "inputs.name.validations.required" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-5">
                                <div class="form-group">
                                    <label class="control-label"
                                           for="fonction">{{ "inputs.fonction.label" | translate }}</label>
                                    <input type="text" formControlName="fonction" class="form-control"
                                           aria-label="Name" aria-describedby="basic-user" id="fonction"
                                           [ngClass]="{ 'is-invalid': slide2FormSubmitted && dForm.fonction.errors }">
                                    <div *ngIf="slide2FormSubmitted && dForm.fonction.errors" class="invalid-feedback">
                                        <div *ngIf="dForm.fonction.errors.required">{{
                                            "inputs.fonction.validations.required" |
                                                translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-12 col-lg-12 d-flex justify-content-end text-white">
                                <a class="btn btn-pill bg-green my-2 mx-1" (click)="addCommissioner()"
                                   disabled="{{ signedMarket.view }}"><em
                                    class="fa fa-plus"></em></a>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="table-responsive">
                                    <table class="table table-hover table-striped">
                                        <caption></caption>
                                        <thead class="thead-dark">
                                        <tr>
                                            <th>{{ "inputs.name.label" | translate }}</th>
                                            <th>{{ "inputs.fonction.label" | translate }}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let commissioner of commissioners; let i = index;">
                                            <td>{{ commissioner.name }}</td>
                                            <td>{{ commissioner.fonction }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div class="row py-2">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-between">
                            <a class="btn btn-pill bg-warning px-5"
                               (click)="changeSlide(1)">{{ "previous" | translate }}</a>
                            <a class="btn btn-pill bg-green px-5" (click)="changeSlide(4)">{{ "next" | translate }}</a>
                        </div>
                    </div>
                </section>

                <section [hidden]="slide4">
                    <app-cashout-comfirm [contract]="contract" [project]="project" [view]="true"
                                         [market]="market"></app-cashout-comfirm>

                    <div class="row py-2">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-between">
                            <a class="btn btn-pill bg-warning px-5"
                               (click)="changeSlide(2)">{{ "previous" | translate }}</a>
                            <button type="submit" [hidden]="signedMarket.view"
                                    class="btn btn-pill bg-primary px-5">{{ "save" | translate }}</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>
