<!DOCTYPE html>

<app-header></app-header>

<div class="animated fadeIn py-5">
    <div class="row roboto-bold-font">
        <div class="col-xl-3 col-lg-3 col-md-2 col-sm-1"></div>
        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-xs-12 pointer">
            <div class="card prep-mat mx-2" (click)="preparationMaturation()">
                <div class="card-body my-5 py-5 clearfix">

                </div>
                <div class="card-footer py-4">
                    <a class="font-weight-bold font-xs btn-block">{{ "preparation_maturation" | translate }}</a>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-xs-12 pointer">
            <div class="card proj-port mx-2" (click)="projectPortFolio()">
                <div class="card-body my-5 py-5 clearfix">

                </div>
                <div class="card-footer py-4">
                    <a class="font-weight-bold font-xs btn-block">{{ "project_portfolio" | translate }}</a>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-2 col-sm-1"></div>
    </div>
<br>
    <div class="row roboto-bold-font">
        <div class="col-xl-3 col-lg-3 col-md-2 col-sm-1"></div>
        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-xs-12 pointer">
            <div class="card archive mx-2"  (click)="archives()">
                <div class="card-body my-5 py-5 clearfix">

                </div>
                <div class="card-footer py-4">
                    <a class="font-weight-bold font-xs btn-block">{{ "archives" | translate }}</a>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-xs-12 pointer" [hidden]="user.role.forProject">
            <div class="card setting mx-2" [routerLink]="['/setting']">
                <div class="card-body my-5 py-5 clearfix">

                </div>
                <div class="card-footer py-4">
                    <a class="font-weight-bold font-xs btn-block">{{ "setting" | translate }}</a>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-xs-12 pointer" [hidden]="!user.role.forProject">
            <div class="card about-sisepcam mx-2" [routerLink]="['/about-us']">
                <div class="card-body py-5 clearfix text-center">
                    <img src="../../../../assets/img/logo-sisepcam.png" alt="about_us">
                </div>
                <div class="card-footer py-4">
                    <a class="font-weight-bold font-xs btn-block">{{ "about_sisepcam" | translate }}</a>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-2 col-sm-1"></div>
    </div>
<br>
    <div class="row roboto-bold-font" [hidden]="user.role.forProject">
        <div class="col-xl-3 col-lg-3 col-md-2 col-sm-1"></div>
        <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 col-xs-12 pointer">
            <div class="card about-sisepcam mx-2" [routerLink]="['/about-us']">
                <div class="card-body py-5 clearfix text-center">
                    <img src="../../../../assets/img/logo-sisepcam.png" alt="about_us">
                </div>
                <div class="card-footer py-4">
                    <a class="font-weight-bold font-xs btn-block">{{ "about_sisepcam" | translate }}</a>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-2 col-sm-1"></div>
    </div>
</div>
