<!DOCTYPE html>
<section class="animated fadeIn" *ngIf="isLoaded">
    <div class="modal-header d-flex justify-content-between">
        <h3 class="modal-title roboto-bold-font">{{ "review" | translate }}</h3>
        <span class="close fa fa-times text-red" aria-label="Close" (click)="closeModal()"></span>
    </div>
    <div class="modal-body text-black container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 forms">
                <form [formGroup]="reviewForm">
                    <ngb-alert #selfClosingAlert *ngIf="errorMessage" type="danger" (closed)="errorMessage = ''">{{
                        errorMessage
                        }}</ngb-alert>

                    <div class="row">
                        <div class="col-xl-6 col-lg-6">
                            <div class="form-group">
                                <label class="control-label" for="date">{{ "inputs.date.label" | translate }}
                                    <span class="roboto-bold-font text-red">*</span>
                                </label>
                                <input type="text" formControlName="date" class="form-control" aria-label="Date"
                                       bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                                       placeholder="{{ 'date-format' | translate }}" aria-describedby="basic-user" id="date"
                                       [ngClass]="{ 'is-invalid': reviewFormSubmitted && createForm.date.errors }">
                                <div *ngIf="reviewFormSubmitted && createForm.date.errors" class="invalid-feedback">
                                    <div *ngIf="createForm.date.errors.required">{{
                                        "inputs.date.validations.required" |
                                            translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6">
                            <div class="form-group">
                                <label class="control-label" for="sender">{{ "inputs.sender.label" | translate }}
                                    <span class="roboto-bold-font text-red">*</span>
                                </label>
                                <input type="text" formControlName="sender" class="form-control"
                                       aria-label="Sender" aria-describedby="basic-user" id="sender" disabled
                                       [ngClass]="{ 'is-invalid': reviewFormSubmitted && createForm.sender.errors }">
                                <div *ngIf="reviewFormSubmitted && createForm.sender.errors" class="invalid-feedback">
                                    <div *ngIf="createForm.sender.errors.required">{{
                                        "inputs.sender.validations.required" |
                                            translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-12 col-lg-12">
                            <div class="form-group">
                                <label class="control-label" for="object">{{ "inputs.object.label" | translate }}
                                    <span class="roboto-bold-font text-red">*</span>
                                </label>
                                <input type="text" formControlName="object" class="form-control" aria-label="Object"
                                       aria-describedby="basic-user" id="object"
                                       [ngClass]="{ 'is-invalid': reviewFormSubmitted && createForm.object.errors }">
                                <div *ngIf="reviewFormSubmitted && createForm.object.errors" class="invalid-feedback">
                                    <div *ngIf="createForm.object.errors.required">{{
                                        "inputs.object.validations.required" |
                                            translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-12 col-lg-12">
                            <div class="form-group">
                                <label class="control-label" for="message">{{ "inputs.message.label" | translate }}
                                    <span class="roboto-bold-font text-red">*</span>
                                </label>
                                <textarea type="text" rows="5" formControlName="message" class="form-control"
                                          aria-label="Message" aria-describedby="basic-user" id="message"
                                          [ngClass]="{ 'is-invalid': reviewFormSubmitted && createForm.message.errors }"></textarea>
                                <div *ngIf="reviewFormSubmitted && createForm.message.errors" class="invalid-feedback">
                                    <div *ngIf="createForm.message.errors.required">{{
                                        "inputs.message.validations.required" |
                                            translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end">
                            <button type="submit" (click)="onSubmit()" class="btn btn-pill bg-primary px-5">{{ "save" | translate }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
