export class Privilege {
    canCreateActivity: boolean;
    canReadActivity: boolean;
    canUpdateActivity: boolean;
    canDeleteActivity: boolean;
    canCreateMarket: boolean;
    canReadMarket: boolean;
    canUpdateMarket: boolean;
    canDeleteMarket: boolean;
    canCreateFollow: boolean;
    canReadFollow: boolean;
    canUpdateFollow: boolean;
    canDeleteFollow: boolean;
    canValidate: boolean;
    validateLevel: string;
    canReEvaluate: boolean;
    reEvaluateRoles: any;
    canReValidate: boolean;
    reValidateRoles: any;

    constructor(canCreateActivity: boolean = true, canReadActivity: boolean = true, canUpdateActivity: boolean = true, canDeleteActivity: boolean = true,
                canCreateMarket: boolean = true, canReadMarket: boolean = true, canUpdateMarket: boolean = true, canDeleteMarket: boolean = true,
                canCreateFollow: boolean = true, canReadFollow: boolean = true, canUpdateFollow: boolean = true, canDeleteFollow: boolean = true,
                canValidate: boolean = true, canReEvaluate: boolean = false, reEvaluateRoles: any = null, canReValidate: boolean = false,
                reValidateRoles: any = null, validateLevel: string = 'LEVEL_IV') {
        this.canCreateActivity = canCreateActivity;
        this.canReadActivity = canReadActivity;
        this.canUpdateActivity = canUpdateActivity;
        this.canDeleteActivity = canDeleteActivity;
        this.canCreateMarket = canCreateMarket;
        this.canReadMarket = canReadMarket;
        this.canUpdateMarket = canUpdateMarket;
        this.canDeleteMarket = canDeleteMarket;
        this.canCreateFollow = canCreateFollow;
        this.canReadFollow = canReadFollow;
        this.canUpdateFollow = canUpdateFollow;
        this.canDeleteFollow = canDeleteFollow;
        this.canValidate = canValidate;
        this.validateLevel = validateLevel;
        this.canReEvaluate = canReEvaluate;
        this.reEvaluateRoles = reEvaluateRoles;
        this.canReValidate = canReValidate;
        this.reValidateRoles = reValidateRoles;
    }

}
