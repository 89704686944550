import {INavData} from '@coreui/angular';
import {TranslateService} from '@ngx-translate/core';

export const projectNavItems = (translate: TranslateService): INavData[] => {
    return [
        {
            name: translate.instant('dashboard'),
            url: '/project-portfolio/projects/project/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: translate.instant('update_project'),
            url: '/project-portfolio/projects/project/update',
            icon: 'fa fa-edit',
            children: [
                {
                    name: translate.instant('update_project_file'),
                    url: '/project-portfolio/projects/project/update/update-project',
                    icon: 'fa fa-edit',
                },
                {
                    name: translate.instant('generate-project-file'),
                    url: '/project-portfolio/projects/project/update/generate-project-file',
                    icon: 'fa fa-file',
                }
            ]
        },
        {
            name: translate.instant('planification'),
            url: '/project-portfolio/projects/project/planification',
            icon: 'fa fa-calendar',
        },
        {
            name: translate.instant('follow_up'),
            url: '/project-portfolio/projects/project/follow-up',
            icon: 'fa fa-long-arrow-right',
        },
        {
            name: translate.instant('review'),
            url: '/project-portfolio/projects/project/review',
            icon: 'fa fa-eye'
        },
        {
            name: translate.instant('analyse'),
            url: '/project-portfolio/projects/project/analyse',
            icon: 'fa fa-pie-chart'
        },
        {
            name: translate.instant('documents'),
            url: '/project-portfolio/projects/project/documents',
            icon: 'fa fa-folder-open'
        },
        {
            name: translate.instant('performance-evaluation'),
            url: '/project-portfolio/projects/project/performance-evaluation',
            icon: 'fa fa-star-o',
            children: [
                {
                    name: translate.instant('self-evaluate'),
                    url: '/project-portfolio/projects/project/performance-evaluation/self-evaluate',
                    icon: 'fa fa-user',
                },
                {
                    name: translate.instant('against-evaluation'),
                    url: '/project-portfolio/projects/project/performance-evaluation/against-evaluation',
                    icon: 'fa fa-opera',
                },
                {
                    name: translate.instant('valid-evaluation'),
                    url: '/project-portfolio/projects/project/performance-evaluation/valid-evaluation',
                    icon: 'fa fa-check',
                },
                {
                    name: translate.instant('report'),
                    url: '/project-portfolio/projects/project/performance-evaluation/report',
                    icon: 'fa fa-folder-open',
                }
            ]
        },
        // {
        //     name: translate.instant('markets'),
        //     url: '/project-portfolio/projects/project/markets',
        //     icon: 'fa fa-map',
        // },
        // {   name: translate.instant('beneficiaries'),
        //     url: '/project-portfolio/projects/project/beneficiaries',
        //     icon: 'fa fa-user'
        // },
        {
            name: translate.instant('maps'),
            url: '/project-portfolio/projects/project/project-map',
            icon: 'fa fa-map'
        }
    ];
};
