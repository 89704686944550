import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
    isLoaded: boolean = false;

    constructor(
        private activeModal: NgbActiveModal
    ) {}

    ngOnInit() {
        this.isLoaded = true;
    }

    closeModal() {
        this.activeModal.close();
    }
}
