import {Document} from './document.payload';
import {Observation, Realisation} from "./project.payload";
import {Indicator} from "./indicator.payload";

export class ImplementationProc {
    idimplementationProc: number;
    title: string;
    implementationProcDate: ImplementationProcDate;
    implementationProcDates: ImplementationProcDate[];
    acceptFile: boolean;
    acceptComment: boolean;
    executedDate: string;
    document: Document = null;
    comment: string;
    project: number;
    createdAt: string;
    updatedAt: string;

    plannedDate: any;
    plannDate: string;

    constructor(title: string, implementationProcDate: ImplementationProcDate, acceptFile: boolean = true, acceptComment: boolean = true) {
        this.title = title;
        this.implementationProcDate = implementationProcDate;
        this.acceptFile = acceptFile;
        this.acceptComment = acceptComment;
    }
}

export class ImplementationProcDate {
    idimplementationProcDate: number;
    plannedDate: string;
    implementationProc: number;
    createdAt: string;
    updatedAt: string;

    constructor(plannedDate: string) {
        this.plannedDate = plannedDate;
    }
}

export class ResetImplementationProcDate {
    idimplementationProcDate: number;
    plannedDate: string;
    implementationProc: number;
    createdAt: string;
    updatedAt: string;

    constructor(plannedDate: string, implementationProc: number) {
        this.plannedDate = plannedDate;
        this.implementationProc = implementationProc;
    }
}

export class CompleteImplementationProc {
    idimplementationProc: number;
    executedDate: string;
    document: Document = null;
    comment: string;

    constructor(idimplementationProc: number, executedDate: string, document: Document, comment: string) {
        this.idimplementationProc = idimplementationProc;
        this.executedDate = executedDate;
        this.document = document;
        this.comment = comment;
    }
}

export class ImplementationState {
    indicators: Indicator[];
    realisations: Realisation[];
    observations: Observation[];

    constructor(indicators: Indicator[], realisations: Realisation[], observations: Observation[]) {
        this.indicators = indicators;
        this.realisations = realisations;
        this.observations = observations;
    }
}
