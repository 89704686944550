import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {Activity} from '../../../../../payloads/activity.payload';
import {Market, ProcDate, Procurement} from '../../../../../payloads/market.payload';
import {Project} from '../../../../../payloads/project.payload';
import {NotificationFlashService} from '../../../../../services/notification-flash.service';
import {RestAPIService} from '../../../../../services/rest-api.service';
import {UtilityService} from '../../../../../services/utility.service';
import {formatNumber, marketNumber, reloadComponent,} from '../../../../../_helpers';
import {Cashout, Task} from '../../../../../payloads/task.payload';

@Component({
    selector: 'app-create-market',
    templateUrl: './create-market.component.html',
    styleUrls: ['./create-market.component.scss'],
})
export class CreateMarketComponent implements OnInit {
    isLoaded: boolean = false;

    idProject = Number(sessionStorage.getItem('project'));

    project: Project;

    markets: Market[] = [];
    cashouts: Cashout[] = [];
    market: Market;
    procurements: Procurement[] = [];

    markNumb: string;

    slide1Form: UntypedFormGroup;
    slide2Form: UntypedFormGroup;

    slide1FormSubmitted: boolean = false;
    slide2FormSubmitted: boolean = false;

    activities: Activity[] = [];
    tasks: Task[] = [];
    devises: string[];
    marketTypes: any[];
    methods: string[];
    staticAlertClosed = false;
    errorMessage: string;
    slide1: boolean = false;
    slide2: boolean = true;
    slide3: boolean = true;
    startDate: Date;
    endDate: Date;
    min_year: number;
    max_year: number;
    marketNo: any;
    cashOutTask: Task;
    @Input() create: any;
    @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;
    private _danger = new Subject<string>();

    constructor(
        private formBuilder: UntypedFormBuilder,
        private translate: TranslateService,
        private restAPIService: RestAPIService,
        private router: Router,
        private utilityService: UtilityService,
        private activeModal: NgbActiveModal,
        private notification: NotificationFlashService
    ) {
    }

    get csrForm() {
        return this.slide1Form.controls;
    }

    get dForm() {
        return this.slide2Form.controls;
    }

    ngOnInit(): void {
        this._danger.subscribe((message) => (this.errorMessage = message));
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.marketNo = this.create.marketNo;
        this.project = this.create.project;

        this.markets = this.project.markets;

        this.devises = this.utilityService.fetchCurrencies();
        this.activities = this.project.activities;
        this.activities.sort((activity1: Activity, activity2: Activity) =>
            activity1.numb > activity2.numb ? 1 : -1
        );

        for (let i = 0; i < this.activities.length; i++) {
            const activity: Activity = this.activities[i];
            if (Number(activity.validated) < 4) {
                this.activities.splice(i, 1);
            }
        }

        this.tasks = [];
        this.marketTypes = this.utilityService.fetchMarketTypes();
        this.methods = [];
        this.procurements = [];

        this.markNumb = marketNumber(this.project.numb, this.marketNo);

        this.initForm();

        this.isLoaded = true;
    }

    initForm() {
        this.slide1Form = this.formBuilder.group({
            numb: [this.markNumb, [Validators.required]],
            title: [null, [Validators.required]],
            description: [null],
            activity: [null, [Validators.required]],
            task: [null, [Validators.required]],
            amount: [null, [Validators.required]],
            currency: [this.project.currency, [Validators.required]],
            amountFcfa: [null, [Validators.required]],
            exchangeRate: [this.project.exchangeRate],
            fcpRate: [null, [Validators.max(100)]],
            fcpYear: [null, [Validators.max(this.max_year)]],
            fcpBudgetLine: [null],
            lessorRate: [null, [Validators.max(100)]],
            lessorYear: [null, [Validators.max(this.max_year)]],
            lessorBudgetLine: [null],
        });

        this.slide2Form = this.formBuilder.group({
            deadLine: [null, [Validators.required]],
            marketType: [null, [Validators.required]],
            method: [null],
        });
    }

    onActivityChange(value: any) {
        for (const activity of this.activities) {
            if (activity.idactivity === value) {
                this.tasks = activity.tasks;
                this.tasks.sort((task1: Task, task2: Task) =>
                    task1.numb > task2.numb ? 1 : -1
                );
            }
        }
    }

    onTaskChange(value: any) {
        for (const task of this.tasks) {
            if (task.idtask === value) {
                this.startDate = new Date(task.startDate);
                this.endDate = new Date(task.endDate);
                this.cashOutTask = task;
            }
        }
    }

    changeSlide(nextSlide: number) {
        this.errorMessage = null;

        if (nextSlide === 1) {
            this.slide1 = false;
            this.slide2 = true;
            this.slide3 = true;
        }

        if (nextSlide === 2) {
            this.slide1FormSubmitted = true;
            if (this.slide1Form.invalid) {
                return;
            }

            this.slide1 = true;
            this.slide2 = false;
            this.slide3 = true;
        }

        if (nextSlide === 3) {
            this.slide2FormSubmitted = true;
            if (this.slide2Form.invalid) {
                return;
            }

            this.slide1 = true;
            this.slide2 = true;
            this.slide3 = false;
        }

    }

    checkAmount(amount: any) {
        let taskAmount = 0;
        if (this.slide1Form.value.task !== null) {
            this.tasks.forEach((task: Task) => {
                if (task.idtask === this.slide1Form.value.task) {
                    taskAmount = task.amountFcfa;
                }
            });
        } else {
            this.slide1FormSubmitted = true;
        }

        if (amount > taskAmount) {
            this.errorMessage = this.translate.instant(
                'notifications.danger.markets-amount-not-more-than-task-amount',
                { amount: this.moneyFormat(taskAmount) },
            );

            this.slide1Form.get('amountFcfa').setValue(Math.floor(amount / 10));
        } else {
            this.errorMessage = null;
        }

        this.slide1Form.get('amount').setValue((this.slide1Form.value.amountFcfa / this.slide1Form.value.exchangeRate).toFixed(1));
    }

    checkRate(input: any) {
        const rate: number = 100 - input.value;

        if (input.id === 'fcpRate') {
            this.slide1Form.get('lessorRate').setValue(rate);
        }

        if (input.id === 'lessorRate') {
            this.slide1Form.get('fcpRate').setValue(rate);
        }
    }

    onMethodChange(index: any) {
        this.methods = this.marketTypes[Number(index)].methods;
        this.slide2Form.get('method').setValue(null);
        this.procurements = this.marketTypes[Number(index)].procurements;
    }

    onSubmit() {
        this.errorMessage = null;
        this.slide2FormSubmitted = true;
        if (this.slide2Form.invalid) {
            return;
        }

        const market = new Market(
            this.slide1Form.value.numb,
            this.slide1Form.value.title,
            this.slide1Form.value.description,
            this.slide1Form.value.amount,
            this.slide1Form.value.currency,
            this.slide1Form.value.exchangeRate,
            this.slide1Form.value.amountFcfa,
            this.slide1Form.value.activity,
            this.slide1Form.value.task,
            this.slide2Form.value.deadLine,
            this.marketTypes[Number(this.slide2Form.value.marketType)].type,
            this.slide2Form.value.method,
            this.slide1Form.value.fcpRate,
            this.slide1Form.value.fcpYear,
            this.slide1Form.value.fcpBudgetLine,
            this.slide1Form.value.lessorRate,
            this.slide1Form.value.lessorYear,
            this.slide1Form.value.lessorBudgetLine,
            this.procurements,
            this.project.idproject,
            this.cashouts
        );

        this.restAPIService.createMarket(market).subscribe(() => {
            this.notification.success(
                this.translate.instant('notifications.success.market-created'),
            );

            reloadComponent(this.router);

            this.closeModal();
        }, (error) => {
            console.error(error);

            this.errorMessage = this.translate.instant(
                'notifications.danger.market-not-created',
            );
        });
    }

    closeModal() {
        this.activeModal.close();
    }

    moneyFormat(amount: number) {
        return formatNumber(amount);
    }

    dateFormat(date: string): string {
        return new Date(date).toLocaleDateString('fr-FR');
    }

    changeProcurementPlannedDate(date: any, i: number, codeStatus: string) {
        this.procurements[i].codeStatus = codeStatus,
        this.procurements[i].procDate = new ProcDate(date);
    }

    onCashoutsUpdated(updatedCashouts: Cashout[]) {
        this.cashouts = updatedCashouts;
    }

    setDisabled(task: Task) {
        let existMarket = false;
        this.markets.forEach((market: Market) => {
            if ((this.slide1Form.value.task !== task.idtask) && (task.idtask === market.task.idtask)) {
                existMarket = true;
            }
        });
        return task.conference || existMarket;
    }
}
