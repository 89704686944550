import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {RestAPIService} from '../../../../../services/rest-api.service';
import {ConfirmationDialogService} from '../../../../shared-component/confirmation-dialog/confirmation-dialog.service';
import {NotificationFlashService} from '../../../../../services/notification-flash.service';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {Cashout, DeliverableCashout, Task} from '../../../../../payloads/task.payload';
import {NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {dbDate, formatNumber} from '../../../../../_helpers';
import {Market} from '../../../../../payloads/market.payload';
import {Project} from '../../../../../payloads/project.payload';

@Component({
    selector: 'app-cashout',
    templateUrl: './cashout.component.html',
    styleUrls: ['./cashout.component.scss']
})
export class CashoutComponent implements OnInit, AfterViewInit {

    @Input() task: Task;
    @Input() exchangeRate: number;
    @Input() marketAmountFCFA: number;
    @Input() project: Project;
    @Input() market: Market;

    @Output() cashoutsUpdated = new EventEmitter<Cashout[]>();

    isLoaded: boolean = false;
    slide3Form: UntypedFormGroup;
    errorMessage: string;
    staticAlertClosed = false;
    cashouts: Cashout[] = [];
    deliverablesList: UntypedFormArray;
    slide3FormSubmitted: boolean = false;
    numberOfDeliverables: number = 0;
    startDate: Date;
    endDate: Date;
    startingDate: Date;
    @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;
    private _danger = new Subject<string>();

    constructor(private cdRef: ChangeDetectorRef, private formBuilder: UntypedFormBuilder, private translate: TranslateService,
                private restAPIService: RestAPIService, private confirmationDialog: ConfirmationDialogService,
                private notification: NotificationFlashService) {}

    ngOnInit(): void {
        this._danger.subscribe((message) => (this.errorMessage = message));
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.initForm();

        this.startDate = new Date(this.project.startDate);
        this.endDate = new Date(this.project.endDate);

        this.isLoaded = true;
    }

    ngAfterViewInit(): void {
        setTimeout((): void => {
            if (this.task !== null) {
                this.startDate = new Date(this.task.startDate);
                this.endDate = new Date(this.task.endDate);
                this.cashouts = this.task.cashouts;
            }
        }, 0);
    }

    initForm() {
        this.slide3Form = this.formBuilder.group({
            startDate: [null, [Validators.required]],
            transmissionDateoftheEndReport: [null, [Validators.required]],
            transmissionDateoftheEndReportLabel: [null, [Validators.required]],
            decayRateEndReport: [null, [Validators.required]],
            startReport: [null, [Validators.required]],
            startReportLabel: [null, [Validators.required]],
            decayRateStartReport: [null, [Validators.required]],
            amount: [null, [Validators.required]],
            amountFcfa: [null, [Validators.required]],
            numberIntermediateDeliverables: [null, [Validators.required]],
            description: [null],
            deliverablesList: this.formBuilder.array([]),
        });

        this.deliverablesList = this.slide3Form.get('deliverablesList') as UntypedFormArray;
    }

    onGenerateFormDeliverable() {
        const numberOfDeliverables = this.slide3Form.get('numberIntermediateDeliverables').value;
        this.numberOfDeliverables = numberOfDeliverables;
        this.deliverablesList.clear();
        for (let i = 0; i < numberOfDeliverables; i++) {
            const group = this.formBuilder.group({
                deliverable_date: [null, [Validators.required]],
                deliverable_label: ['', [Validators.required]],
                deliverable_rate: ['', [Validators.required]],
            });
            this.deliverablesList.push(group);
        }
        this.cdRef.detectChanges();
    }

    getDeliverablesControls(): AbstractControl[] {
        return this.deliverablesList.controls;
    }

    checkAmount(amount: any) {
        let cashoutAmount = 0;
        if (this.cashouts !== null) {
            this.cashouts.forEach((cashout: Cashout) => {
                cashoutAmount += cashout.amountFcfa;
            });
        }
        const remainingAmount = this.marketAmountFCFA - cashoutAmount;
        if (amount > remainingAmount) {
            this.errorMessage = this.translate.instant(
                'notifications.danger.cashouts-amount-not-more-than-market-amount',
                {amount: this.moneyFormat(remainingAmount)}
            );

            this.slide3Form.get('amountFcfa').setValue(Math.floor(amount / 10));
        } else {
            this.errorMessage = null;
        }

        this.slide3Form.get('amount').setValue((this.slide3Form.value.amountFcfa / this.exchangeRate).toFixed(1));
    }

    addCashout() {
        const startDateString = dbDate(this.slide3Form.get('startDate').value);
        const numberOfDeliverables = this.slide3Form.get('numberIntermediateDeliverables').value;

        const deliverablesArray: DeliverableCashout[] = this.deliverablesList.controls.map((group: UntypedFormGroup) => {
            const deliverableDate = group.get('deliverable_date');
            const deliverableLabel = group.get('deliverable_label');
            const deliverableRate = group.get('deliverable_rate');
            const dateValue = deliverableDate ? deliverableDate.value : null;
            const labelValue = deliverableLabel ? deliverableLabel.value : null;
            const rateValue = deliverableRate ? deliverableRate.value : null;
            return new DeliverableCashout(dateValue, labelValue, rateValue);
        });


        deliverablesArray.unshift(new DeliverableCashout(
            this.slide3Form.get('startReport').value,
            this.slide3Form.get('startReportLabel').value,
            this.slide3Form.get('decayRateStartReport').value
        ));

        deliverablesArray.push(new DeliverableCashout(
            this.slide3Form.get('transmissionDateoftheEndReport').value,
            this.slide3Form.get('transmissionDateoftheEndReportLabel').value,
            this.slide3Form.get('decayRateEndReport').value
        ));

        const totalRate = deliverablesArray.reduce((sum, deliverable) => sum + Number(deliverable.deliverableRate), 0);

        if (totalRate !== 100) {
            this.errorMessage = this.translate.instant('notifications.danger.deliverable-rate-sum-not-100');
            return;
        }

        const newCashout = new Cashout(
            startDateString,
            this.slide3Form.get('amount').value,
            this.slide3Form.get('amountFcfa').value,
            this.slide3Form.get('description').value,
            numberOfDeliverables,
            deliverablesArray
        );

        const marketId = this.market && this.market.idmarket ? this.market.idmarket : null;

        this.restAPIService.createCashout(this.task.idtask, marketId, newCashout).subscribe(
            (response: any) => {
                const createdCashout = response.data;

                this.cashouts.push(createdCashout);
                this.cashoutsUpdated.emit(this.cashouts);
                this.notification.success(
                    this.translate.instant('notifications.success.cashout-created')
                );

                this.numberOfDeliverables = 0;
                this.slide3Form.reset();
                this.cdRef.detectChanges();
            },
            (error) => {
                console.error(error);
                if (error.status === 400) {
                    this.notification.danger(
                        this.translate.instant('notifications.error.bad-request')
                    );
                } else {
                    this.notification.danger(
                        this.translate.instant('notifications.success.cashout-not-created')
                    );
                }
            }
        );
    }

    showDeliverable(cashoutId: number): void {
        const cashout = this.cashouts.find(c => c.idcashout === cashoutId);

        if (cashout) {
            cashout.toggle = 1;
        }
    }

    deliverableAmount(deliverableRate: number, cashoutAmountFCFA: number): string {
        return this.moneyFormat(Number(((deliverableRate * cashoutAmountFCFA) / 100).toFixed(0)));
    }

    hideDeliverable(cashoutId: number): void {
        const cashout = this.cashouts.find(c => c.idcashout === cashoutId);

        if (cashout) {
            cashout.toggle = 0;
        }
    }

    moneyFormat(amount: number) {
        return formatNumber(amount);
    }

    dateFormat(date: string): string {
        return new Date(date).toLocaleDateString('fr-FR');
    }

    deleteCashout(index: number, id: number) {
        if (index >= 0 && index < this.cashouts.length) {
            this.confirmationDialog.confirm(
                this.translate.instant('cashout'),
                this.translate.instant('confirmations.contents.cashout-delete')
            ).then((confirmation: boolean) => {
                if (confirmation) {
                    this.restAPIService.deleteCashout(id).subscribe(
                        () => {
                            this.cashouts.splice(index, 1);

                            this.notification.success(
                                this.translate.instant('notifications.success.cashout-deleted')
                            );
                        }, () => {
                            this.notification.danger(this.translate.instant('notifications.success.cashout-not-deleted'));
                        }
                    );
                }
            });
        }
    }

    setStartDate($event: any) {
        this.startingDate = $event;
    }

    currencyAmount(amount: number) {
        if (this.project.exchangeRate !== null) {
            return (amount / this.project.exchangeRate).toFixed(1);
        }
        return 0;
    }
}
