import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompleteProcurementComponent} from './complete-procurement.component';
import {ViewContractComponent} from './view-contract/view-contract.component';
import {CreateContractComponent} from './create-contract/create-contract.component';
import {ResetProcDateComponent} from './reset-proc-date/reset-proc-date.component';

import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';


import {HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {NgSelectModule} from '@ng-select/ng-select';
import {MapsModule} from '@syncfusion/ej2-angular-maps';
import {PdfJsViewerModule} from 'ng2-pdfjs-viewer';
import {UiSwitchModule} from 'ngx-ui-switch';
import {TableModule} from 'primeng/table';
import {MultiSelectModule} from 'primeng/multiselect';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {ChartModule} from 'primeng/chart';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {DialogModule} from 'primeng/dialog';
import {ModalModule} from 'ngx-bootstrap/modal';
import {CashoutComfirmComponent} from './cashout-comfirm/cashout-comfirm.component';
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {DataTablesModule} from "angular-datatables";


@NgModule({
    declarations: [
        CompleteProcurementComponent,
        ViewContractComponent,
        CreateContractComponent,
        CashoutComfirmComponent,
        ResetProcDateComponent,
    ],
    exports: [
        ResetProcDateComponent,
        CreateContractComponent,
        CashoutComfirmComponent,
        ViewContractComponent,
        CashoutComfirmComponent,
        CompleteProcurementComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,

        // BsDropdownModule.forRoot(),
        // TabsModule.forRoot(),
        HttpClientModule,
        
        
        NgbModule,
        MatTabsModule,
        MatSnackBarModule,
        MatCheckboxModule,
        NgSelectModule,
        TranslateModule,
        // DataTablesModule,
        MapsModule,
        PdfJsViewerModule,
        UiSwitchModule,
        TableModule,
        MultiSelectModule,
        InputTextModule,
        ButtonModule,
        ChartModule,
        LeafletModule,
        DialogModule,
        ModalModule,
        BsDatepickerModule,
        DataTablesModule,

    ]
})
export class CompleteProcurementModule {
}
