import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {reloadComponent} from '../../../../../_helpers';
import {NotificationFlashService} from '../../../../../services/notification-flash.service';
import {RestAPIService} from '../../../../../services/rest-api.service';
import {ImplementationProc, ImplementationProcDate} from '../../../../../payloads/implementation-process.payload';
import {Project} from '../../../../../payloads/project.payload';
import {UtilityService} from '../../../../../services/utility.service';
import {ConfirmationDialogService} from '../../../../shared-component/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-reset-imple-proc-date',
  templateUrl: './reset-imple-proc-date.component.html',
  styleUrls: ['./reset-imple-proc-date.component.scss']
})
export class ResetImpleProcDateComponent implements OnInit {
    isLoaded: boolean = false;
    hideSave: boolean = false;
    private _danger = new Subject<string>();

    staticAlertClosed = false;
    errorMessage: string;

    @Input() project: Project;

    @ViewChild('staticAlert', { static: false }) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert;
    implementationProcs: ImplementationProc[];
    colspan: number;

    constructor(private formBuilder: UntypedFormBuilder, private activeModal: NgbActiveModal, private restAPIService: RestAPIService,
                private notification: NotificationFlashService, private translate: TranslateService, private router: Router,
                private utilityService: UtilityService, private confirmationDialog: ConfirmationDialogService) { }

    ngOnInit(): void {
        this._danger.subscribe((message) => (this.errorMessage = message));
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.implementationProcs = [...this.project.implementationProcs];
        this.implementationProcs.sort(
            (implementationProc1: ImplementationProc, implementationProc2: ImplementationProc) =>
                implementationProc1.idimplementationProc > implementationProc2.idimplementationProc ? 1 : -1
        );

        const dates: number[] = [];
        let hide = 0;
        this.implementationProcs.forEach((implementationProc: ImplementationProc) => {
            implementationProc.implementationProcDates.sort((implementationProcDate1: ImplementationProcDate,
                                                             implementationProcDate2: ImplementationProcDate) =>
                implementationProcDate1.idimplementationProcDate > implementationProcDate2.idimplementationProcDate ? 1 : -1
            );

            dates.push(implementationProc.implementationProcDates.length);

            if (implementationProc.executedDate) {
                ++hide;
            }
        });

        dates.sort((date1: number, date2: number) => date1 < date2 ? 1 : -1 )

        this.colspan = dates[0];

        if (this.utilityService.fetchArchiveStatus().includes(this.project.status) || hide === this.implementationProcs.length) {
            this.hideSave = true;
        }

        this.isLoaded = true;
    }

    onSubmit() {
        console.log(this.implementationProcs);
        this.confirmationDialog.confirm(this.translate.instant('reprogram'),
            this.translate.instant('confirmations.contents.reprogrammed-dates-validation'))
            .then((confirmation) => {
                    if (confirmation) {
                        this.restAPIService.updateImplementationProcess(this.implementationProcs).subscribe(() => {
                            this.closeModal();

                            reloadComponent(this.router);

                            this.notification.success(this.translate.instant('notifications.success.planned-date-reset'));
                        }, () => {
                            this.notification.danger(this.translate.instant('notifications.danger.planned-date-not-reset'));
                        });
                    }
                }
            );
    }

    closeModal() {
        this.activeModal.close();
    }

    dateFormat(date: string): string {
        return new Date(date).toLocaleDateString('fr-FR');
    }
    changePlannedDate(date: string, i: number) {
        this.implementationProcs[i].plannedDate = date;
        this.implementationProcs[i].implementationProcDate = new ImplementationProcDate(date);
    }

    changeTitle(event: any, i: number) {
        this.implementationProcs[i].title = event.target.value;
    }

    formatBSDate(date: string): Date {
        if (!date) {
            return null;
        }
        return new Date(date);
    }
}
