import { INavData } from '@coreui/angular';
import { TranslateService } from '@ngx-translate/core';

export const projectNavItems = (translate: TranslateService): INavData[] => {
    return [
        {
            name: translate.instant('dashboard'),
            url: '/archives/projects/project/dashboard',
            icon: 'fa fa-tachometer'
            
        },
        {
            name: translate.instant('update_project'),
            url: '/archives/projects/project/update',
            icon: 'fa fa-edit',
            children: [
                {
                    name: translate.instant('update_project_file'),
                    url: '/archives/projects/project/update/update-project',
                    icon: 'fa fa-edit',
                },
                {
                    name: translate.instant('generate-project-file'),
                    url: '/archives/projects/project/update/generate-project-file',
                    icon: 'fa fa-file',
                }
            ]
        },
        {
            name: translate.instant('planification'),
            url: '/archives/projects/project/planification',
            icon: 'fa fa-calendar',
        },
        {
            name: translate.instant('follow_up'),
            url: '/archives/projects/project/follow-up',
            icon: 'fa fa-long-arrow-right',
        },
        {
            name: translate.instant('review'),
            url: '/archives/projects/project/review',
            icon: 'fa fa-eye'
        },
        {
            name: translate.instant('analyse'),
            url: '/archives/projects/project/analyse',
            icon: 'fa fa-pie-chart'
        },
        {
            name: translate.instant('documents'),
            url: '/archives/projects/project/documents',
            icon: 'fa fa-folder-open'
        }
        // {
        //     name: translate.instant('markets'),
        //     url: '/archives/projects/project/markets',
        //     icon: 'fa fa-map',
        // },
        // {   name: translate.instant('beneficiaries'),
        //     url: '/archives/projects/project/beneficiaries',
        //     icon: 'fa fa-user'
        // },
        // {
        //     name: translate.instant('maps'),
        //     url: '/archives/projects/project/project-map',
        //     icon: 'fa fa-map'
        // }
    ];
};
