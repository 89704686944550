import {Location} from '@angular/common';
import {Component, HostListener, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../../payloads/user.payload';
import {TokenStorageService} from '../../../services/security/token-storage.service';
import {RestAPIService} from '../../../services/rest-api.service';
import {getRole} from '../../../_helpers';
import {UserProfileComponent} from '../../dashboard/setting/user/user-profile/user-profile.component';
import {ConfirmationDialogService} from '../confirmation-dialog/confirmation-dialog.service';
import {Role} from '../../../payloads/role.payload';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
    titlee: string;
    imgSrc = '../../../../assets/img/default-avatar.jpeg';
    user: User;
    role: Role = getRole();
    isLoaded: boolean = false;

    constructor(public location: Location, private tokenStorage: TokenStorageService, private router: Router, private modalService: NgbModal,
                private restAPIService: RestAPIService, private confirmationDialog: ConfirmationDialogService, private translate: TranslateService) {
    }

    ngOnInit() {
        this.titlee = this.location.prepareExternalUrl(this.location.path()).slice(2);

        if (this.tokenStorage.isloggedIn()) {
            this.user = this.tokenStorage.getUser();
            this.imgSrc = this.user.image;
            if (this.imgSrc === '../../../../assets/img/default-avatar.jpeg' && this.user.document !== null) {
                this.restAPIService.downloadDocument(this.user.document.iddocument).subscribe(document => {
                    if (document !== null) {
                        this.imgSrc = document.base64;
                    }
                });
            }

            if (this.role === null) {
                this.role = this.user.role;
            }

            return true;
        }
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll() {
        const navbar = <HTMLElement>document.getElementsByClassName('navbar')[0];

        if (window.scrollY > navbar.clientHeight) {
            navbar.classList.add('navbar-inverse');
        } else {
            navbar.classList.remove('navbar-inverse');
        }
    }

    isActive(path: string): any {
        let result: string = '';
        if (this.titlee.split('/')[0] === path) {
            result += 'active';
        }

        const links: string[] = ['sign-in', 'forgot-password', 'reset-password'];
        if (links.includes(this.titlee.split('/')[0])) {
            result += ' nav-link-white';
        } else {
            result += ' nav-link';
        }
        return result;
    }

    isLogin(): boolean {
        return this.tokenStorage.isloggedIn();
    }

    logout() {
        this.confirmationDialog.confirm(this.translate.instant('confirmations.headers.disconnection'),
            this.translate.instant('confirmations.contents.disconnection')
        ).then((confirmation) => {
            if (confirmation) {
                this.restAPIService.signOut(this.user.iduser).subscribe(() => {
                    this.tokenStorage.signOut();
                    this.router.navigate(['/']);
                });
            }
        });
    }

    viewProfile() {
        this.modalService.open(UserProfileComponent, {
            backdropClass: 'light-yellowish-backdrop',
            size: 'lg',
            centered: false,
            scrollable: false,
            backdrop: false
        });
    }
}
