import {User} from './user.payload';

export class Review {
    idreview: number;
    object: string;
    sender: User;
    user: number;
    message: string;
    reviewDate: string;
    project: number;
    contReviews: ContReview[];
    createdAt: string;
    updatedAt: string;
    inPreparation: boolean;

    constructor(user: number, object: string, message: string, project: number, reviewDate: string = null) {
        this.user = user;
        this.object = object;
        this.message = message;
        this.reviewDate = reviewDate;
        this.project = project;
    }
}

export class ContReview {
    idcontReview: number;
    sender: User;
    user: number;
    message: string;
    idProject: number;
    review: Review;
    reviewDate: string;
    createdAt: string;
    updatedAt: string;

    constructor(user: number, message: string, idProject: number, reviewDate: string = null) {
        this.user = user;
        this.message = message;
        this.idProject = idProject;
        this.reviewDate = reviewDate;
    }
}
