<div
    class="map-container"
    [leafletZoom]="leafletZoom"
    [leafletCenter]="leafletCenter"
    leafle
    (leafletClick)="onMapClick($event)"
    leaflet
    [leafletOptions]="options"
    (leafletMapReady)="onMapReady($event)"
    (leafletMapZoomEnd)="onMapZoomEnd($event)"
></div>
