import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {RestAPIService} from '../../../../../services/rest-api.service';
import {UtilityService} from '../../../../../services/utility.service';
import {Activity} from '../../../../../payloads/activity.payload';
import {activityNumber, formatNumber, reloadComponent, taskNumber,} from '../../../../../_helpers';
import {Composante, Project, SubComponent,} from '../../../../../payloads/project.payload';
import {ActivityTask} from '../../../../../payloads/task.payload';
import {Router} from '@angular/router';
import {NotificationFlashService} from '../../../../../services/notification-flash.service';
import {Market} from '../../../../../payloads/market.payload';
import {Indicator} from '../../../../../payloads/indicator.payload';
import {Map} from 'leaflet';
import {MapBoxService} from '../../../../shared-component/map-box/map-box.service';

@Component({
    selector: 'app-create-activity',
    templateUrl: './create-activity.component.html',
    styleUrls: ['./create-activity.component.scss'],
})
export class CreateActivityComponent implements OnInit {
    idProject = Number(sessionStorage.getItem('project'));
    isLoaded: boolean = false;
    project: Project;
    activities: Activity[] = [];
    activity: Activity;
    markets: Market[] = [];

    activityNumber: string;

    tasks: ActivityTask[] = [];

    slide1Form: UntypedFormGroup;
    slide2Form: UntypedFormGroup;
    slide3Form: UntypedFormGroup;

    indicators: Indicator[] = [];
    components: Composante[] = [];
    subComponents: SubComponent[] = [];
    devises: string[];

    slide1FormSubmitted: boolean = false;
    slide2FormSubmitted: boolean = false;
    slide3FormSubmitted: boolean = false;
    staticAlertClosed = false;
    errorMessage: string;
    slide1: boolean = false;
    slide2: boolean = true;
    slide3: boolean = true;
    startDate: Date;
    endDate: Date;
    end_startDate: Date;
    task_startDate: Date;
    task_endDate: Date;
    task_end_startDate: Date;
    min_year: number;
    max_year: number;
    @Input() createActivity: any;
    @ViewChild('staticAlert', { static: false }) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', { static: false })
    selfClosingAlert: NgbAlert;
    showGeolocation = false;
    showGeolocationTask = false;
    limitesCameroun = {
        latitude: { min: 1.6523, max: 12.6766 },
        longitude: { min: 8.5057, max: 16.2015 },
    };
    latitude: any = 3.90499;
    longitude: any = 11.51828;
    latitude_task: any = 3.90499;
    longitude_task: any = 11.51828;
    private _danger = new Subject<string>();
    private map: Map;
    private zoom: number = 2.8;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private translate: TranslateService,
        private restAPIService: RestAPIService,
        private utilityService: UtilityService,
        private activeModal: NgbActiveModal,
        private router: Router,
        private mapBoxService: MapBoxService,
        private notification: NotificationFlashService
    ) { }

    get csrForm() {
        return this.slide1Form.controls;
    }

    get edForm() {
        return this.slide2Form.controls;
    }

    get tForm() {
        return this.slide3Form.controls;
    }

    receiveMap(map: Map) {
        this.map = map;
    }

    receiveZoom(zoom: number) {
        this.zoom = zoom;
    }

    setLocation_Long(locate: string) {
        this.longitude = locate;
    }

    setLocation_Lat(locate: string) {
        this.latitude = locate;
    }

    handleLongitudeState(event: any) {
        this.longitude = event;
    }

    handleLatitudeState(event: any) {
        this.latitude = event;
    }

    updateMarkerPosition(newPosition: { lat: number; lng: number }) {
        this.latitude = newPosition.lat;
        this.longitude = newPosition.lng;
    }

    showGeolocationSection() {
        this.showGeolocation = true;
    }

    hideSections() {
        this.showGeolocation = false;
    }

    showGeolocationTaskSection() {
        this.showGeolocationTask = true;
    }

    hideTaskSections() {
        this.showGeolocationTask = false;
    }

    ngOnInit(): void {
        this._danger.subscribe((message) => (this.errorMessage = message));
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        });

        this.mapBoxService.markerPosition$.subscribe((position) => {
            this.updateMarkerPosition(position);
        });

        this.project = this.createActivity.project;

        this.activities = this.project.activities;

        this.startDate = new Date(this.project.startDate);
        this.endDate = new Date(this.project.endDate);
        this.end_startDate = new Date(this.project.startDate);

        this.min_year = new Date(this.project.startDate).getFullYear();
        this.max_year = new Date(this.project.endDate).getFullYear();

        this.devises = this.utilityService.fetchCurrencies();
        this.components = this.project.components;
        this.components.sort((component1: Composante, component2: Composante) =>
            component1.idcomponent > component2.idcomponent ? 1 : -1
        );
        this.subComponents = [];

        this.indicators = this.project.indicators;
        this.indicators.sort((indicator1: Indicator, indicator2: Indicator) =>
            indicator1.idindicator > indicator2.idindicator ? 1 : -1
        );

        this.activityNumber = activityNumber(
            this.project.numb,
            this.createActivity.activityNumb
        );






        this.initForm();

        this.isLoaded = true;

    }

    validerCoordonnees() {
        const latitude = this.slide1Form.get('L_latitude')?.value;
        const longitude = this.slide1Form.get('L_longitude')?.value;

        if (
            latitude < this.limitesCameroun.latitude.min ||
            latitude > this.limitesCameroun.latitude.max ||
            longitude < this.limitesCameroun.longitude.min ||
            longitude > this.limitesCameroun.longitude.max
        ) {
            this.slide1Form.setErrors({ horsLimite: true });
            this.slide3Form.get('L_latitude').setValue(this.latitude);
            this.slide3Form.get('L_longitude').setValue(this.longitude);
        }
    }

    validerTaskCoordonnees() {
        const latitude = this.slide3Form.get('T_latitude')?.value;
        const longitude = this.slide3Form.get('T_longitude')?.value;

        if (
            latitude < this.limitesCameroun.latitude.min ||
            latitude > this.limitesCameroun.latitude.max ||
            longitude < this.limitesCameroun.longitude.min ||
            longitude > this.limitesCameroun.longitude.max
        ) {
            this.slide3Form.setErrors({ horsLimite: true });
            this.slide3Form.get('T_latitude').setValue(this.latitude);
            this.slide3Form.get('T_longitude').setValue(this.longitude);

        }
    }

    initForm() {
        this.slide1Form = this.formBuilder.group({
            L_latitude: [
                this.latitude,
                [Validators.required, Validators.min(-90), Validators.max(90)],
            ],
            L_longitude: [
                this.longitude,
                [
                    Validators.required,
                    Validators.min(-180),
                    Validators.max(180),
                ],
            ],
            numb: [this.activityNumber, [Validators.required]],
            title: [null, [Validators.required]],
            amount: [null, [Validators.required]],
            currency: [this.project.currency, [Validators.required]],
            amountFcfa: [null, [Validators.required]],
            exchangeRate: [this.project.exchangeRate],
            fcpRate: [null, [Validators.max(100)]],
            fcpYear: [null, [Validators.max(this.max_year)]],
            fcpBudgetLine: [null],
            lessorRate: [null, [Validators.max(100)]],
            lessorYear: [null, [Validators.max(this.max_year)]],
            lessorBudgetLine: [null],
            component: [null, [Validators.required]],
            subComponent: [null, [Validators.required]],
            princResponsible: [null],
            partResponsible: [null],
        });

        // Ajoutez des abonnements pour les changements de longitude et de latitude
        const longitudeControl = this.slide1Form.get("L_longitude");
        const latitudeControl = this.slide1Form.get("L_latitude");
        if (longitudeControl && latitudeControl) {
            // Abonnement aux changements de longitude
            longitudeControl.valueChanges.subscribe((newLongitudeValue) => {
                // Faites quelque chose avec la nouvelle valeur de longitude
                if (newLongitudeValue) {
                    this.longitude = parseFloat(newLongitudeValue);
                }
                // Appeler votre fonction ou effectuer l'action nécessaire ici
            });

            // Abonnement aux changements de latitude
            latitudeControl.valueChanges.subscribe((newLatitudeValue) => {
                // Faites quelque chose avec la nouvelle valeur de latitude
                if (newLatitudeValue) {
                    this.latitude = parseFloat(newLatitudeValue);
                }
                // Appeler votre fonction ou effectuer l'action nécessaire ici
            });
        }

        this.slide2Form = this.formBuilder.group({
            contextJustif: [null],
            objective: [null, [Validators.required]],
            awaitedResult: [null],
            deliverable: [null],
            verifMeans: [null],
            riskHypothese: [null],
            title: [null],
            indicator: [null],
            baseline: [null],
            targets: [null],
            startDate: [null, [Validators.required]],
            endDate: [null, [Validators.required]],
        });

        this.slide3Form = this.formBuilder.group({
            numb: [taskNumber(this.activityNumber, 1), [Validators.required]],
            title: [null, [Validators.required]],
            conference: [null, [Validators.required]],
            amountFcfa: [null, [Validators.required]],
            deliverable: [null],
            verifMeans: [null],
            princResponsible: [null],
            startDate: [null, [Validators.required]],
            endDate: [null, [Validators.required]],
            T_longitude: [
                this.longitude_task,
                [
                    Validators.required,
                    Validators.min(-180),
                    Validators.max(180),
                ],
            ],
            T_latitude: [
                this.latitude_task,
                [Validators.required, Validators.min(-90), Validators.max(90)],
            ],
        });

        // Ajoutez des abonnements pour les changements de longitude et de latitude
        const T_longitudeControl = this.slide3Form.get('T_longitude');
        const T_latitudeControl = this.slide3Form.get('T_latitude');

        if (T_longitudeControl && T_latitudeControl) {
            // Abonnement aux changements de longitude
            T_longitudeControl.valueChanges.subscribe((newLongitudeValue) => {
                // Faites quelque chose avec la nouvelle valeur de longitude
                if (newLongitudeValue) {
                    this.longitude_task = parseFloat(newLongitudeValue);
                }
                // Appeler votre fonction ou effectuer l'action nécessaire ici
            });

            // Abonnement aux changements de latitude
            T_latitudeControl.valueChanges.subscribe((newLatitudeValue) => {
                // Faites quelque chose avec la nouvelle valeur de latitude
                if (newLatitudeValue) {
                    this.latitude_task = parseFloat(newLatitudeValue);
                }
                // Appeler votre fonction ou effectuer l'action nécessaire ici
            });
        }
    }

    onComponentChange(value: any) {
        for (const component of this.components) {
            if (component.idcomponent === value) {
                this.subComponents = component.subComponents;

                this.subComponents.sort(
                    (
                        subComponent1: SubComponent,
                        subComponent2: SubComponent
                    ) =>
                        subComponent1.idsubComponent >
                            subComponent2.idsubComponent
                            ? 1
                            : -1
                );
            }
        }
    }

    changeSlide(nextSlide: number) {
        this.errorMessage = null;

        if (nextSlide === 1) {
            this.slide1 = false;
            this.slide2 = true;
            this.slide3 = true;

            this.task_startDate = new Date(this.slide2Form.value.startDate);
            this.task_endDate = new Date(this.slide2Form.value.endDate);
            this.task_end_startDate = new Date(this.slide2Form.value.startDate);
        }

        if (nextSlide === 2) {
            this.slide1FormSubmitted = true;
            if (this.slide1Form.invalid) {
                return;
            }

            this.slide1 = true;
            this.slide2 = false;
            this.slide3 = true;

            this.task_startDate = new Date(this.slide2Form.value.startDate);
            this.task_endDate = new Date(this.slide2Form.value.endDate);
            this.task_end_startDate = new Date(this.slide2Form.value.startDate);
        }

        if (nextSlide === 3) {
            this.slide2FormSubmitted = true;
            if (this.slide2Form.invalid) {
                return;
            }

            this.slide1 = true;
            this.slide2 = true;
            this.slide3 = false;

            this.task_startDate = new Date(this.slide2Form.value.startDate);
            this.task_endDate = new Date(this.slide2Form.value.endDate);
            this.task_end_startDate = new Date(this.slide2Form.value.startDate);
        }
    }

    addTask() {
        this.slide3FormSubmitted = true;
        if (this.slide3Form.invalid) {
            return;
        }

        const task = new ActivityTask(
            this.slide3Form.value.numb,
            this.slide3Form.value.title,
            this.slide3Form.value.amountFcfa,
            this.slide3Form.value.deliverable,
            this.slide3Form.value.verifMeans,
            this.slide3Form.value.princResponsible,
            this.slide3Form.value.startDate,
            this.slide3Form.value.endDate,
            this.latitude_task,
            this.longitude_task,
            this.slide3Form.value.conference
        );

        this.tasks.push(task);

        this.slide3FormSubmitted = false;

        this.slide3Form
            .get('numb')
            .setValue(taskNumber(this.activityNumber, this.tasks.length + 1));
        this.slide3Form.get('title').setValue(null);
        this.slide3Form.get('amountFcfa').setValue(null);
        this.slide3Form.get('deliverable').setValue(null);
        this.slide3Form.get('verifMeans').setValue(null);
        this.slide3Form.get('princResponsible').setValue(null);
        this.slide3Form.get('startDate').setValue(null);
        this.slide3Form.get('endDate').setValue(null);
        this.slide3Form.get('conference').setValue(null);
    }

    deleteTask(index: number) {
        this.slide3Form.get('numb').setValue(this.tasks[index].numb);
        this.tasks.splice(index, 1);

        if (this.tasks.length <= 0) {
            this.slide3Form
                .get('numb')
                .setValue(taskNumber(this.activityNumber, 1));
        }
    }

    checkAmount(event: any) {
        const amount = Number(event.target.value);
        let activityAmount = 0;

        if (this.project.exchangeRate == null) {
            this.errorMessage = this.translate.instant('notifications.danger.project-rate');
            this.slide1Form.get('amountFcfa').setValue(0);
            return;
        }
        if (
            this.activities.length > 0 &&
            this.slide1Form.value.subComponent !== null
        ) {
            this.activities.forEach((activity: Activity) => {
                if (
                    activity.subComponent.idsubComponent ===
                    this.slide1Form.value.subComponent
                ) {
                    activityAmount += activity.amountFcfa;
                }
            });
        }

        let subCompAmount = 0;
        if (this.slide1Form.value.subComponent !== null) {
            const sub = this.subComponents.find((subComponent: SubComponent) => subComponent.idsubComponent == this.slide1Form.value.subComponent);
            subCompAmount = (sub.amount ?? 0) * this.project.exchangeRate;
        }

        const remainingAmount = subCompAmount - activityAmount;


        if (remainingAmount < 0) {
            this.errorMessage = this.translate.instant(
                'notifications.danger.activities-amount-not-more-than-component-subcomponent-amount',
                { amount: this.moneyFormat(0) }
            );

            this.slide1Form.get('amountFcfa').setValue(Math.floor(amount / 10));
        } else if (amount > remainingAmount && remainingAmount > 0) {
            this.errorMessage = this.translate.instant(
                'notifications.danger.activities-amount-not-more-than-component-subcomponent-amount',
                { amount: this.moneyFormat(remainingAmount) }
            );

            this.slide1Form.get('amountFcfa').setValue(Math.floor(amount / 10));
        } else {
            this.errorMessage = null;
            this.slide1Form
                .get('amount')
                .setValue(
                    (
                        this.slide1Form.value.amountFcfa /
                        this.slide1Form.value.exchangeRate
                    ).toFixed(1)
                );
        }

    }

    checkRate(input: any) {
        const rate: number = 100 - input.value;

        if (input.id === 'fcpRate') {
            this.slide1Form.get('lessorRate').setValue(rate);
        }

        if (input.id === 'lessorRate') {
            this.slide1Form.get('fcpRate').setValue(rate);
        }
    }

    checkTaskAmount(amount: any) {
        let taskAmount = 0;
        if (this.tasks.length > 0) {
            this.tasks.forEach((task: ActivityTask) => {
                taskAmount += task.amountFcfa;
            });
        }

        const remainingAmount = this.slide1Form.value.amountFcfa - taskAmount;

        if (amount > remainingAmount) {
            this.errorMessage = this.translate.instant(
                'notifications.danger.tasks-amount-not-more-than-activity-amount',
                { amount: this.moneyFormat(remainingAmount) }
            );

            this.slide3Form.get('amountFcfa').setValue(Math.floor(amount / 10));
        } else {
            this.errorMessage = null;
        }
    }

    minDate(date: string) {
        if (date) {
            this.end_startDate = new Date(date);
        }
    }

    minTaskDate(date: string) {
        if (date) {
            this.task_end_startDate = new Date(date);
        }
    }

    onSubmit() {
        this.errorMessage = null;

        if (this.tasks.length > 0) {
            this.tasks.forEach((task: ActivityTask) => {
                const deliverables: any = [];

                if (task.deliverable !== '') {
                    const deliverable = {
                        title: task.deliverable,
                        verifMeans: task.verifMeans,
                    };
                    deliverables.push(deliverable);
                }

                task.deliverables = deliverables;
            });
        }

        const activity = new Activity(
            this.latitude,
            this.longitude,
            this.slide1Form.value.numb,
            this.slide1Form.value.title,
            this.slide1Form.value.component,
            this.slide1Form.value.subComponent,
            this.slide1Form.value.princResponsible,
            this.slide1Form.value.partResponsible,
            this.slide2Form.value.contextJustif,
            this.slide2Form.value.objective,
            this.slide2Form.value.deliverable,
            this.slide2Form.value.verifMeans,
            this.slide2Form.value.riskHypothese,
            this.slide2Form.value.startDate,
            this.slide2Form.value.endDate,
            this.slide1Form.value.amount,
            this.slide1Form.value.currency,
            this.slide1Form.value.amountFcfa,
            this.slide1Form.value.exchangeRate,
            this.slide1Form.value.fcpRate,
            this.slide1Form.value.fcpYear,
            this.slide1Form.value.fcpBudgetLine,
            this.slide1Form.value.lessorRate,
            this.slide1Form.value.lessorYear,
            this.slide1Form.value.lessorBudgetLine,
            this.slide2Form.value.title,
            this.slide2Form.value.indicator,
            this.slide2Form.value.baseline,
            this.slide2Form.value.targets,
            this.tasks,
            this.project.idproject,
            this.slide2Form.value.conference
        );



        this.restAPIService.createActivity(activity).subscribe(
            () => {
                this.closeModal();

                reloadComponent(this.router);

                this.notification.success(
                    this.translate.instant(
                        'notifications.success.activity-created'
                    )
                );
            },
            () => {
                this.errorMessage = this.translate.instant(
                    'notifications.danger.activity-not-created'
                );
            }
        );
    }

    closeModal() {
        this.activeModal.close();
    }

    moneyFormat(amount: number) {
        return formatNumber(amount);
    }

    dateFormat(date: string): string {
        return new Date(date).toLocaleDateString('fr-FR');
    }
}
