<!DOCTYPE html>
<section *ngIf="isLoaded" class="animated fadeIn">
    <div class="modal-header d-flex justify-content-between">
        <h3 class="modal-title roboto-bold-font">{{ "role" | translate }}</h3>
        <span (click)="closeModal()" aria-label="Close" class="close fa fa-times text-red"></span>
    </div>
    <div class="modal-body text-black container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 forms">
                <form [formGroup]="roleForm">
                    <ngb-alert #selfClosingAlert (closed)="errorMessage = ''" *ngIf="errorMessage" type="danger">{{
                        errorMessage
                        }}</ngb-alert>

                    <div class="row">
                        <div class="col-xl-3 col-lg-3">
                            <div class="form-group">
                                <label class="control-label px-2 pt-1"
                                       for="forProject">{{ "project" | translate }}</label>
                                <input class="checkbox" formControlName="forProject" id="forProject" type="checkbox">
                            </div>
                        </div>
                        <div class="col-xl-9 col-lg-9">
                            <div class="form-group">
                                <input [ngClass]="{ 'is-invalid': roleFormSubmitted && roleForm.get('name').invalid }" aria-describedby="basic-user" aria-label="Name" class="form-control"
                                       formControlName="name" id="name"
                                       placeholder="{{ 'inputs.name.label' | translate }} *"
                                       type="text">
                                <div *ngIf="roleFormSubmitted && roleForm.get('name').errors" class="invalid-feedback">
                                    <div
                                        *ngIf="roleForm.get('name').hasError('required')">{{ "inputs.name.validations.required" | translate }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-3 col-lg-3">
                            <div class="form-group">
                                <label class="control-label px-2 pt-1" for="code">{{ "inputs.code.label" | translate }}
                                    <span class="roboto-bold-font text-red">*</span></label>
                            </div>
                        </div>
                        <div class="col-xl-9 col-lg-9">
                            <div class="form-group">
                                <input [ngClass]="{ 'is-invalid': roleFormSubmitted && roleForm.get('code').invalid }" aria-describedby="basic-user" aria-label="Code" class="form-control"
                                       formControlName="code" id="code"
                                       placeholder="{{ 'inputs.code.placeholders' | translate }}"
                                       type="text">
                                <div *ngIf="roleFormSubmitted && roleForm.get('code').errors" class="invalid-feedback">
                                    <div
                                        *ngIf="roleForm.get('code').hasError('required')">{{ "inputs.code.validations.required" | translate }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h4 class="py-2 roboto-bold-font">{{ "privilege" | translate }}s</h4>
                    <div class="row">
                        <div class="col-xl-3 col-lg-3">
                            <div class="form-group">
                                <label class="control-label px-2 pt-1"
                                       for="canValidate">{{ "canValidate" | translate }}</label>
                                <input (change)="onValidateChange($event.target.checked)" class="checkbox" formControlName="canValidate" id="canValidate"
                                       type="checkbox">
                            </div>
                        </div>
                        <div [hidden]="canValidate" class="col-xl-9 col-lg-9">
                            <div class="form-group">
                                <ng-select [ngClass]="{ 'is-invalid': roleFormSubmitted && updateForm.validateLevel.errors }" formControlName="validateLevel"
                                           id="validateLevel"
                                           placeholder="{{ 'inputs.validateLevel.label' | translate }}">
                                    <ng-option *ngFor="let validateLevel of validateLevels"
                                               [value]="validateLevel">{{ validateLevel | translate }}</ng-option>
                                </ng-select>
                                <div *ngIf="roleFormSubmitted && updateForm.validateLevel.errors"
                                     class="invalid-feedback">
                                    <div *ngIf="updateForm.validateLevel.errors.required">{{
                                        "inputs.validateLevel.validations.required" | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-3 col-lg-3">
                            <div class="form-group">
                                <label class="control-label px-2 pt-1"
                                       for="canReEvaluate">{{ "canReEvaluate" | translate }}</label>
                                <input (change)="onReEvaluateChange($event.target.checked)" class="checkbox" formControlName="canReEvaluate"
                                       id="canReEvaluate" type="checkbox">
                            </div>
                        </div>
                        <div [hidden]="canReEvaluate" class="col-xl-9 col-lg-9">
                            <div class="form-group">
                                <ng-select [clearable]="false" [closeOnSelect]="false" [multiple]="true"
                                           [ngClass]="{ 'is-invalid': roleFormSubmitted && updateForm.reEvaluateRoles.errors }"
                                           [searchable]="true" formControlName="reEvaluateRoles" id="reEvaluateRoles"
                                           placeholder="{{ 'inputs.reEvaluateRoles.label' | translate }}">
                                    <ng-option *ngFor="let role of roles"
                                               [value]="role.idrole">{{ role.name }}</ng-option>
                                </ng-select>
                                <div *ngIf="roleFormSubmitted && updateForm.reEvaluateRoles.errors"
                                     class="invalid-feedback">
                                    <div *ngIf="updateForm.reEvaluateRoles.errors.required">{{
                                        "inputs.reEvaluateRoles.validations.required" |
                                            translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-3 col-lg-3">
                            <div class="form-group">
                                <label class="control-label px-2 pt-1"
                                       for="canReValidate">{{ "canReValidate" | translate }}</label>
                                <input (change)="onReValidateChange($event.target.checked)" class="checkbox" formControlName="canReValidate"
                                       id="canReValidate" type="checkbox">
                            </div>
                        </div>
                        <div [hidden]="canReValidate" class="col-xl-9 col-lg-9">
                            <div class="form-group">
                                <ng-select [clearable]="false" [closeOnSelect]="false" [multiple]="true"
                                           [ngClass]="{ 'is-invalid': roleFormSubmitted && updateForm.reValidateRoles.errors }"
                                           [searchable]="true" formControlName="reValidateRoles" id="reValidateRoles"
                                           placeholder="{{ 'inputs.reValidateRoles.label' | translate }}">
                                    <ng-option *ngFor="let role of roles"
                                               [value]="role.idrole">{{ role.name }}</ng-option>
                                </ng-select>
                                <div *ngIf="roleFormSubmitted && updateForm.reValidateRoles.errors"
                                     class="invalid-feedback">
                                    <div *ngIf="updateForm.reValidateRoles.errors.required">{{
                                            "inputs.reValidateRoles.validations.required" |
                                                translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h5 class="py-2 roboto-bold-font">{{ "activities" | translate }}</h5>
                    <div class="row">
                        <div class="col-xl-3 col-lg-3">
                            <div class="form-group">
                                <label class="control-label px-2"
                                       for="canCreateActivity">{{ "canCreate" | translate }}</label>
                                <input class="checkbox" formControlName="canCreateActivity" id="canCreateActivity"
                                       type="checkbox">
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3">
                            <div class="form-group">
                                <label class="control-label px-2"
                                       for="canReadActivity">{{ "canRead" | translate }}</label>
                                <input class="checkbox" formControlName="canReadActivity" id="canReadActivity"
                                       type="checkbox">
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3">
                            <div class="form-group">
                                <label class="control-label px-2"
                                       for="canUpdateActivity">{{ "canUpdate" | translate }}</label>
                                <input class="checkbox" formControlName="canUpdateActivity" id="canUpdateActivity"
                                       type="checkbox">
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3">
                            <div class="form-group">
                                <label class="control-label px-2"
                                       for="canDeleteActivity">{{ "canDelete" | translate }}</label>
                                <input class="checkbox" formControlName="canDeleteActivity" id="canDeleteActivity"
                                       type="checkbox">
                            </div>
                        </div>
                    </div>

                    <h5 class="py-2 roboto-bold-font">{{ "markets" | translate }}</h5>
                    <div class="row">
                        <div class="col-xl-3 col-lg-3">
                            <div class="form-group">
                                <label class="control-label px-2"
                                       for="canCreateMarket">{{ "canCreate" | translate }}</label>
                                <input class="checkbox" formControlName="canCreateMarket" id="canCreateMarket"
                                       type="checkbox">
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3">
                            <div class="form-group">
                                <label class="control-label px-2"
                                       for="canReadMarket">{{ "canRead" | translate }}</label>
                                <input class="checkbox" formControlName="canReadMarket" id="canReadMarket"
                                       type="checkbox">
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3">
                            <div class="form-group">
                                <label class="control-label px-2"
                                       for="canUpdateMarket">{{ "canUpdate" | translate }}</label>
                                <input class="checkbox" formControlName="canUpdateMarket" id="canUpdateMarket"
                                       type="checkbox">
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3">
                            <div class="form-group">
                                <label class="control-label px-2"
                                       for="canDeleteMarket">{{ "canDelete" | translate }}</label>
                                <input class="checkbox" formControlName="canDeleteMarket" id="canDeleteMarket"
                                       type="checkbox">
                            </div>
                        </div>
                    </div>

                    <h5 class="py-2 roboto-bold-font">{{ "follow-up" | translate }}s</h5>
                    <div class="row">
                        <div class="col-xl-3 col-lg-3">
                            <div class="form-group">
                                <label class="control-label px-2"
                                       for="canCreateFollow">{{ "canCreate" | translate }}</label>
                                <input class="checkbox" formControlName="canCreateFollow" id="canCreateFollow"
                                       type="checkbox">
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3">
                            <div class="form-group">
                                <label class="control-label px-2"
                                       for="canReadFollow">{{ "canRead" | translate }}</label>
                                <input class="checkbox" formControlName="canReadFollow" id="canReadFollow"
                                       type="checkbox">
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3">
                            <div class="form-group">
                                <label class="control-label px-2"
                                       for="canUpdateFollow">{{ "canUpdate" | translate }}</label>
                                <input class="checkbox" formControlName="canUpdateFollow" id="canUpdateFollow"
                                       type="checkbox">
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3">
                            <div class="form-group">
                                <label class="control-label px-2"
                                       for="canDeleteFollow">{{ "canDelete" | translate }}</label>
                                <input class="checkbox" formControlName="canDeleteFollow" id="canDeleteFollow"
                                       type="checkbox">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end">
                            <button (click)="onSubmit()" class="btn btn-pill bg-primary px-5"
                                    type="submit">{{ "save" | translate }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>

