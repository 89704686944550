import {Location} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {INavData} from '@coreui/angular';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../../payloads/user.payload';
import {TokenStorageService} from '../../../services/security/token-storage.service';
import {RestAPIService} from '../../../services/rest-api.service';
import {getRole} from '../../../_helpers';
import {preparationMaturationNavItems} from '../../dashboard/preparation-maturation/sidebars/preparation-maturation';
import {ConfirmationDialogService} from '../confirmation-dialog/confirmation-dialog.service';
import {Role} from '../../../payloads/role.payload';
import {Notification} from '../../../payloads/notification.payload';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';

@Component({
    selector: 'app-dashboard',
    templateUrl: './default-layout.component.html',
    styleUrls: ['./default-layout.component.css'],
})
export class DefaultLayoutComponent implements OnInit {
    breadTitle: string;
    navItems: INavData[] = [];

    user: User = null;
    role: Role = getRole();
    imgSrc: string;
    notifications: Notification[] = [];
    isLoaded: boolean = false;

    constructor(private tokenStorage: TokenStorageService, private confirmationDialog: ConfirmationDialogService, private router: Router,
                private translate: TranslateService, public location: Location, private modalService: NgbModal,
                private localeService: BsLocaleService, private restAPIService: RestAPIService) {
        translate.onLangChange.subscribe(() => {
            this.reloadBreadCrumb();
            this.reloadComponent(router);
        });
    }

    ngOnInit() {
        this.navItems = preparationMaturationNavItems(this.translate);
        this.user = this.tokenStorage.getUser();
        this.imgSrc = this.user.image;
        if (this.imgSrc === './assets/img/default-avatar.jpeg' && this.user.document !== null) {
            this.restAPIService.downloadDocument(this.user.document.iddocument).subscribe(document => {
                if (document !== null) {
                    this.imgSrc = document.base64;
                }
            });
        }

        if (this.role === null) {
            this.role = this.user.role;
        }
    }

    reloadBreadCrumb(project: string = null) {
        if (this.role === null) {
            this.role = this.user.role;
        }

        const fullPath: string = this.location.prepareExternalUrl(this.location.path()).slice(2);

        const omits: string[] = ['reports-archive', 'graphs-analyse', 'status', 'update', 'statistic'];
        const fullPaths: string[] = fullPath.split('/');

        this.breadTitle = '<li><a class="routerlink" href="/#/dashboard">' + this.translate.instant('main-menu') + '</a></li>';
        for (let i = 0; i < fullPaths.length - 1; i++) {
            if (!omits.includes(fullPaths[i])) {
                const paths: string[] = fullPath.split('/', i + 1);

                let linkpath = '/#/';
                for (const path of paths) {
                    linkpath += path + '/';
                }

                if (project !== null) {
                    if (fullPaths[i] === 'project') {
                        this.breadTitle += '<li><a class="routerlink" href=' + linkpath + '>' + project + '</a></li>';
                    } else {
                        this.breadTitle += '<li><a class="routerlink" href=' + linkpath + '>' + this.translate.instant(fullPaths[i]) + '</a></li>';
                    }
                } else {
                    this.breadTitle += '<li><a class="routerlink" href=' + linkpath + '>' + this.translate.instant(fullPaths[i]) + '</a></li>';
                }
            }
        }

        this.breadTitle += '<li>' + this.translate.instant(fullPaths[fullPaths.length - 1]) + '</li>';
    }

    reloadComponent(router: Router) {
        const currentUrl = router.url;
        router.navigate([currentUrl]);
    }
}
