import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {getProject} from '../../../../../../../_helpers';
import {Project} from '../../../../../../../payloads/project.payload';
import {Indicator} from '../../../../../../../payloads/indicator.payload';
import {MultiAnnualProgram} from '../../../../../../../payloads/finance-data.payload';
import {NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormGroup} from '@angular/forms';
import {Document} from '../../../../../../../payloads/document.payload';
import {DefaultLayoutComponent} from '../../../../../../shared-component/default-layout/default-layout.component';
import {TranslateService} from '@ngx-translate/core';
import {updateProjectNavItems} from '../../../../sidebars/update-project';

const IMAGE = './assets/img/project-portfolio.jpg';

@Component({
  selector: 'app-implementation-state',
  templateUrl: './implementation-state.component.html',
  styleUrls: ['./implementation-state.component.scss']
})
export class ImplementationStateComponent implements AfterViewInit {
    isLoaded: boolean = false;
    idProject: number = getProject();
    project: Project;
    indicators: Indicator[];
    multiAnnualPrograms: MultiAnnualProgram[];

    staticAlertClosed = false;
    errorMessage: string;
    @ViewChild('staticAlert', { static: false }) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert;

    slide1: boolean = false;
    slide2: boolean = true;
    slide1Form: UntypedFormGroup;
    slide2Form: UntypedFormGroup;

    slide1FormSubmitted = false;
    slide2FormSubmitted = false;
    image: string = IMAGE;
    document: Document;

    constructor(private defaultLayout: DefaultLayoutComponent, private translate: TranslateService) { }

    ngAfterViewInit(): void {
        setTimeout((): void => {
            this.defaultLayout.navItems = updateProjectNavItems(this.translate);
        }, 0);
    }
}
