import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbAlert, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {fetchMonthsBetween, formatNumber, getPrivilege, reloadComponent, verify100} from '../../../../../_helpers';
import {Task} from '../../../../../payloads/task.payload';
import {RestAPIService} from '../../../../../services/rest-api.service';
import {ActivityOper, Difficulty, Gap} from '../../../../../payloads/show-activity.payload';
import {TranslateService} from '@ngx-translate/core';
import {NotificationFlashService} from '../../../../../services/notification-flash.service';
import {Document} from '../../../../../payloads/document.payload';
import {Activity} from '../../../../../payloads/activity.payload';
import {Privilege} from '../../../../../payloads/privilege.payload';
import {CreateTaskComponent} from '../task/create-task/create-task.component';
import {Map} from 'leaflet';
import {MapBoxService} from '../../../../shared-component/map-box/map-box.service';

@Component({
    selector: 'app-show-activity',
    templateUrl: './show-activity.component.html',
    styleUrls: ['./show-activity.component.scss'],
})
export class ShowActivityComponent implements OnInit {
    isLoaded: boolean = false;

    idProject = Number(sessionStorage.getItem('project'));
    privilege: Privilege = getPrivilege();
    activityOper: ActivityOper = null;
    activityOpers: ActivityOper[];

    document: Document = null;

    consumedAmounts = 0;
    executedTasks = 0;
    obtainedOutput: string = null;

    staticAlertClosed = false;
    errorMessage: string;

    slide1Form: UntypedFormGroup;
    slide2Form: UntypedFormGroup;
    slide3Form: UntypedFormGroup;
    slide4DiffForm: UntypedFormGroup;
    slide4DelayForm: UntypedFormGroup;

    slide1FormSubmitted: boolean = false;
    slide2FormSubmitted: boolean = false;
    slide3FormSubmitted: boolean = false;
    slide4DiffFormSubmitted: boolean = false;
    slide4DelayFormSubmitted: boolean = false;

    slide1: boolean = false;
    slide2: boolean = true;
    slide3: boolean = true;
    slide4: boolean = true;
    slide5: boolean = true;

    difficulties: Difficulty[] = [];
    gaps: Gap[] = [];

    @Input() activity: Activity;

    showGeolocation = false;

    tasks: Task[] = [];
    hideSave: boolean = false;

    @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', {static: false})
    selfClosingAlert: NgbAlert;

    constructor(private formBuilder: UntypedFormBuilder, private router: Router, private activeModal: NgbActiveModal,
        private restAPIService: RestAPIService, private modalService: NgbModal, private translate: TranslateService,
        private mapBoxService: MapBoxService,
        private notification: NotificationFlashService
    ) {
    }

    private map: Map;
    private zoom: number = 15;
    latitude: any = 51;
    longitude: any = 50;

    receiveMap(map: Map) {
        this.map = map;
    }

    receiveZoom(zoom: number) {
        this.zoom = zoom;
    }

    setLocation_Long(locate: string) {
        this.longitude = locate;
    }

    setLocation_Lat(locate: string) {
        this.latitude = locate;
    }

    handleLongitudeState(event: any) {
        this.longitude = event;
    }

    handleLatitudeState(event: any) {
        this.latitude = event;
    }

    showGeolocationSection() {
        this.showGeolocation = true;
    }

    hideSections() {
        this.showGeolocation = false;
    }

    ngOnInit(): void {
        const duration = fetchMonthsBetween(
            new Date(this.activity.startDate),
            new Date(this.activity.endDate)
        );
        const difference = fetchMonthsBetween(
            new Date(this.activity.startDate),
            new Date()
        );

        this.mapBoxService.markerPosition$.subscribe((position) => {
            this.updateMarkerPosition(position);
        });

        this.activity.deadlineRate = ((difference / duration) * 100).toFixed(1);

        if (this.activity.status === 'TERMINATED') {
            this.hideSave = true;
        }

        if (this.activity.tasks.length > 0) {
            this.tasks = [...this.activity.tasks];
            this.tasks.sort((task1: Task, task2: Task) =>
                task1.idtask > task2.idtask ? 1 : -1
            );
        }

        if (this.activity.activityOpers.length > 0) {
            this.activityOpers = [...this.activity.activityOpers];
            this.activityOpers.sort(
                (activityOper1: ActivityOper, activityOper2: ActivityOper) =>
                    activityOper1.idactivityOper > activityOper2.idactivityOper
                        ? 1
                        : -1
            );

            this.activityOpers.forEach((activityOper: ActivityOper) => {
                this.consumedAmounts += Number(activityOper.consumptedBudget);
                this.executedTasks += Number(activityOper.executedTasks);
            });
            this.activityOper =
                this.activityOpers[this.activityOpers.length - 1];
            this.obtainedOutput = this.activityOper.obtainedOutput;
        }

        const delay = fetchMonthsBetween(
            new Date(this.activity.startDate),
            new Date()
        );

        this.activity.obtainedOutput = this.obtainedOutput;
        this.activity.physicRate = verify100(Number(
            (this.executedTasks / this.tasks.length) * 100
        ).toFixed(1));
        this.activity.financeRate = verify100(Number(
            (this.consumedAmounts / this.activity.amountFcfa) * 100
        ).toFixed(1));
        this.activity.delayRate = verify100(Number((delay / duration) * 100).toFixed(1));

        if (this.activityOper !== null) {
            this.difficulties = this.activityOper.difficulties;
            this.gaps = this.activityOper.gaps;
        } else {
            this.difficulties = [];
            this.gaps = [];
        }

        this.initForm();

        this.isLoaded = true;
    }

    get irForm() {
        return this.slide1Form.controls;
    }

    get nnForm() {
        return this.slide2Form.controls;
    }

    get nForm() {
        return this.slide3Form.controls;
    }

    get diffForm() {
        return this.slide4DiffForm.controls;
    }

    get delayForm() {
        return this.slide4DelayForm.controls;
    }

    updateMarkerPosition(newPosition: { lat: number; lng: number }) {
        this.latitude = newPosition.lat;
        this.longitude = newPosition.lng;
    }

    initForm() {
        (this.latitude = this.activity.latitude),
            (this.longitude = this.activity.longitude),
            (this.slide1Form = this.formBuilder.group({
                L_latitude: [this.activity.latitude],
                L_longitude: [this.activity.longitude],
                numb: [{value: this.activity.numb, disabled: true}, [Validators.required]],
                title: [{value: this.activity.title, disabled: true}, [Validators.required]],
                date: [{value: new Date(), disabled: true}],
                objective: [{value: this.activity.objective, disabled: true}, [Validators.required]],
                situation: [null],
                princResponsible: [
                    {value: this.activity.princResponsible, disabled: true},
                    [Validators.required],
                ],
                partResponsible: [{value: this.activity.partResponsible, disabled: true}],
                encounteredDiff: [null],
                learnedLesson: [null],
            }));

        // Ajoutez des abonnements pour les changements de longitude et de latitude
        const longitudeControl = this.slide1Form.get('L_longitude');
        const latitudeControl = this.slide1Form.get('L_latitude');

        if (longitudeControl && latitudeControl) {
            // Abonnement aux changements de longitude
            longitudeControl.valueChanges.subscribe((newLongitudeValue) => {
                // Faites quelque chose avec la nouvelle valeur de longitude
                if (newLongitudeValue) {
                    this.longitude = parseFloat(newLongitudeValue);
                }
                // Appeler votre fonction ou effectuer l'action nécessaire ici
            });

            // Abonnement aux changements de latitude
            latitudeControl.valueChanges.subscribe((newLatitudeValue) => {
                // Faites quelque chose avec la nouvelle valeur de latitude
                if (newLatitudeValue) {
                    this.latitude = parseFloat(newLatitudeValue);
                }
                // Appeler votre fonction ou effectuer l'action nécessaire ici
            });
        }

        this.slide2Form = this.formBuilder.group({
            startDate: [{value: new Date(this.activity.startDate), disabled: true}, [Validators.required]],
            endDate: [{value: new Date(this.activity.endDate), disabled: true}, [Validators.required]],
            deadlineRate: [{value: this.activity.deadlineRate, disabled: true}, [Validators.required]],
            physicRate: [{value: this.activity.physicRate, disabled: true}, [Validators.required]],
            budgetRate: [{value: this.activity.financeRate, disabled: true}, [Validators.required]],
            deadlineJustif: [null],
            plannedTask: [
                {value: this.tasks.length - this.executedTasks, disabled: true},
                [Validators.required],
            ],
            executedTask: [null, [Validators.required]],
            executionRate: [null, [Validators.required]],
            executionDelay: [null, [Validators.required]],
            executionJustif: [null],
        });

        this.slide3Form = this.formBuilder.group({
            affectedBudget: [
                {value: formatNumber(this.activity.amountFcfa - this.consumedAmounts), disabled: true}
            ],
            consumptedBudget: [null, [Validators.required]],
            consumptionRate: [null, [Validators.required]],
            consumptionDelay: [null],
            consumptionJustif: [null],
            plannedOutput: [{value: this.activity.output, disabled: true}],
            obtainedOutput: [null, [Validators.required]],
            verifMeans: [null],
            outputDelay: [null],
            outputJustif: [null],
            geoCoordinate: [null],
            file: [null],
        });

        this.slide4DiffForm = this.formBuilder.group({
            title: [null, [Validators.required]],
            action: [null],
            princResponsible: [null],
            delay: [null],
            verifMeans: [null],
        });

        this.slide4DelayForm = this.formBuilder.group({
            title: [null, [Validators.required]],
            action: [null],
            princResponsible: [null],
            delay: [null],
            verifMeans: [null],
        });
    }

    changeSlide(nextSlide: number) {
        this.errorMessage = null;

        if (nextSlide === 1) {
            this.slide1 = false;
            this.slide2 = true;
            this.slide3 = true;
            this.slide4 = true;
            this.slide5 = true;
        }

        if (nextSlide === 2) {
            this.slide1FormSubmitted = true;
            if (this.slide1Form.invalid) {
                return;
            }

            this.slide1 = true;
            this.slide2 = false;
            this.slide3 = true;
            this.slide4 = true;
            this.slide5 = true;
        }

        if (nextSlide === 3) {
            this.slide2FormSubmitted = true;
            if (this.slide2Form.invalid) {
                return;
            }

            this.slide1 = true;
            this.slide2 = true;
            this.slide3 = false;
            this.slide4 = true;
            this.slide5 = true;
        }

        if (nextSlide === 4) {
            this.slide3FormSubmitted = true;
            if (this.slide3Form.invalid) {
                return;
            }

            this.slide1 = true;
            this.slide2 = true;
            this.slide3 = true;
            this.slide4 = false;
            this.slide5 = true;
        }

        if (nextSlide === 5) {
            this.slide1 = true;
            this.slide2 = true;
            this.slide3 = true;
            this.slide4 = true;
            this.slide5 = false;
        }
    }

    executionRate(event: any) {
        const val = event.target.value;
        const rate = (val / this.tasks.length) * 100;

        this.slide2Form.get('executionRate').setValue(verify100(rate.toFixed(1)));
        this.slide2Form.get('executionDelay').setValue(this.tasks.length - val);
    }

    consumptionRate(event: any) {
        const val = event.target.value;
        const rate = (val / this.activity.amountFcfa) * 100;

        this.slide3Form.get('consumptionRate').setValue(verify100(rate.toFixed(1)));
        this.slide3Form
            .get('consumptionDelay')
            .setValue(formatNumber(this.activity.amountFcfa - val));
    }

    addDifficulties() {
        this.slide4DiffFormSubmitted = true;
        if (this.slide4DiffForm.invalid) {
            return;
        }

        const difficulty = new Difficulty(
            this.slide4DiffForm.value.title,
            this.slide4DiffForm.value.action,
            this.slide4DiffForm.value.princResponsible,
            this.slide4DiffForm.value.delay,
            this.slide4DiffForm.value.verifMeans
        );

        this.difficulties.push(difficulty);

        this.slide4DiffFormSubmitted = false;

        this.slide4DiffForm.get('title').setValue(null);
        this.slide4DiffForm.get('action').setValue(null);
        this.slide4DiffForm.get('princResponsible').setValue(null);
        this.slide4DiffForm.get('delay').setValue(null);
        this.slide4DiffForm.get('verifMeans').setValue(null);
    }

    deleteDifficulty(index: number) {
        this.difficulties.splice(index, 1);
    }

    addGap() {
        this.slide4DelayFormSubmitted = true;
        if (this.slide4DelayForm.invalid) {
            return;
        }

        const gap = new Gap(
            this.slide4DelayForm.value.title,
            this.slide4DelayForm.value.action,
            this.slide4DelayForm.value.princResponsible,
            this.slide4DelayForm.value.delay,
            this.slide4DelayForm.value.verifMeans
        );

        this.gaps.push(gap);

        this.slide4DelayFormSubmitted = false;

        this.slide4DelayForm.get('title').setValue(null);
        this.slide4DelayForm.get('action').setValue(null);
        this.slide4DelayForm.get('princResponsible').setValue(null);
        this.slide4DelayForm.get('delay').setValue(null);
        this.slide4DelayForm.get('verifMeans').setValue(null);
    }

    deleteGap(index: number) {
        this.gaps.splice(index, 1);
    }

    addTask() {
        this.closeModal();

        this.modalService.open(CreateTaskComponent, {
            backdropClass: 'light-yellowish-backdrop',
            size: 'xl',
            centered: false,
            scrollable: false,
            backdrop: false,
        }).componentInstance.activity = this.activity;
    }

    onFileSelect(event: any) {
        const file: File = event.target.files[0];

        if (file) {
            // File Preview
            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = (read: any) => {
                this.document = new Document(
                    null,
                    file.name,
                    file.type,
                    read.target.result
                );
            };
        }
    }

    onSubmit() {
        const difficultes: Difficulty[] = [];
        const ecarts: Gap[] = [];

        this.difficulties.forEach((difficulty: Difficulty) => {
            if (!difficulty.iddifficulty) {
                difficultes.push(difficulty);
            }
        });

        this.gaps.forEach((gap: Gap) => {
            if (!gap.idgap) {
                ecarts.push(gap);
            }
        });

        const activityOper = new ActivityOper(
            this.slide1Form.value.situation,
            this.slide1Form.value.encounteredDiff,
            this.slide1Form.value.learnedLesson,
            this.slide2Form.value.deadlineJustif,
            this.slide2Form.value.executedTask,
            this.slide2Form.value.executionJustif,
            this.slide3Form.value.consumptedBudget,
            this.slide3Form.value.consumptionJustif,
            this.slide3Form.value.obtainedOutput,
            this.slide3Form.value.outputJustif,
            this.slide3Form.value.verifMeans,
            this.document,
            this.slide3Form.value.geoCoordinate,
            difficultes,
            ecarts,
            this.activity.idactivity
        );

        this.restAPIService.activityOperation(activityOper).subscribe(
            () => {
                reloadComponent(this.router);

                this.closeModal();

                if (
                    this.executedTasks + this.slide2Form.value.executedTask ===
                    this.tasks.length
                ) {
                    this.notification.success(
                        this.translate.instant(
                            'notifications.success.status-modified',
                            {status: this.translate.instant('closed')}
                        )
                    );
                } else {
                    this.notification.success(
                        this.translate.instant(
                            'notifications.success.activity-modified'
                        )
                    );
                }
            },
            () => {
                if (
                    this.executedTasks + this.slide2Form.value.executedTask ===
                    this.tasks.length
                ) {
                    this.notification.danger(
                        this.translate.instant(
                            'notifications.danger.status-not-modified',
                            {status: this.translate.instant('closed')}
                        )
                    );
                } else {
                    this.notification.danger(
                        this.translate.instant(
                            'notifications.danger.activity-not-modified'
                        )
                    );
                }
            }
        );
    }

    dateFormat(date: string): string {
        return new Date(date).toLocaleDateString('fr-FR');
    }

    closeModal() {
        this.activeModal.close();
    }

    moneyFormat(amount: number) {
        return formatNumber(amount);
    }
}
