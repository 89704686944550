import {Document} from './document.payload';

export class SignatureProc {
    idsignatureProc: number;
    title: string;
    signatureProcDate: SignatureProcDate;
    signatureProcDates: SignatureProcDate[];
    acceptFile: boolean;
    acceptComment: boolean;
    executedDate: string;
    document: Document = null;
    comment: string;
    project: number;
    createdAt: string;
    updatedAt: string;

    plannedDates: string[];
    plannedDate: any;

    constructor(title: string, signatureProcDate: SignatureProcDate, acceptFile: boolean = true, acceptComment: boolean = true) {
        this.title = title;
        this.signatureProcDate = signatureProcDate;
        this.acceptFile = acceptFile;
        this.acceptComment = acceptComment;
    }
}

export class SignatureProcDate {
    idsignatureProcDate: number;
    plannedDate: string;
    signatureProc: number;
    createdAt: string;
    updatedAt: string;

    constructor(plannedDate: string) {
        this.plannedDate = plannedDate;
    }
}

export class CompleteSignatureProc {
    idsignatureProc: number;
    executedDate: string;
    document: Document = null;
    comment: string;

    constructor(idsignatureProc: number, executedDate: string, document: Document, comment: string) {
        this.idsignatureProc = idsignatureProc;
        this.executedDate = executedDate;
        this.document = document;
        this.comment = comment;
    }
}
