import { Component, Input, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { DatatableLanguage } from '../datatable-langs';
import { AnalyseElement, AnalyseProperty } from '../../../payloads/utility.payload';
import { Project } from '../../../payloads/project.payload';
import { UtilityService } from '../../../services/utility.service';
import { checkRow, countProjectsByRegion, countProjectsBySector, getProjectAge, getProjectDelayConsumsionRate, getProjectDisbursementRate, handleCalcElement } from '../../../_helpers';
const NumbAnalyseElement = [
    new AnalyseElement('calc_amount', 'inputs.amount_in_currency.label'),
    new AnalyseElement('calc_amountFcfa', 'inputs.amount_in_fcfa.label'),
    new AnalyseElement('calc_delay_consumption_rate', 'delay-consumption-rate'),
    new AnalyseElement('calc_disbursement_rate', 'disbursement-rate'),
    new AnalyseElement('calc_age', 'age_in_years'),
];
const RegionAnalyseElement = [
    new AnalyseElement('all-projects', 'all-projects'),
];
@Component({
    selector: 'app-macro-general-diagramme',
    templateUrl: './diagramme-baton.component.html',
    styleUrls: ['./diagramme-baton.component.scss']
})
export class MacroGeneralDiagrammeComponent implements OnInit {

    dtOptions: any = {};
    dtLang: any = DatatableLanguage.fr;

    elements: AnalyseElement[] = [];
    selectedItems: string[] = [];
    diagrammeBatonForm: UntypedFormGroup;
    diagrammeBatonFormSubmitted: boolean = false;
    showDiagram: boolean = false;
    xElements: AnalyseElement[] = [
        new AnalyseElement('numb', 'inputs.numb.label'),
        new AnalyseElement('calc_region', 'inputs.region.label'),
        new AnalyseElement('calc_sector', 'inputs.dsceSector.label'),
    ]
    yElements: AnalyseElement[] = []
    private _danger = new Subject<string>();
    staticAlertClosed = false;
    errorMessage: string;

    @Input() graphType: "bar" | "pie" | "multi-bar" | "line";
    @Input() projects: Project[] = [];

    @ViewChild('staticAlert', { static: false }) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert;

    data: any;
    options: any;
    pieOptions: any;
    lineOptions: any;
    lineData: any;
    multiDatasets: any;
    pieData: any;
    showTable: boolean = false;
    multi: boolean = false;
    showDiagOnly: boolean = false;
    constructor(private formBuilder: UntypedFormBuilder,
        private translate: TranslateService,
        private utilityService: UtilityService, private sanitizer: DomSanitizer) { }

    ngOnInit(): void {            
        this._danger.subscribe(message => this.errorMessage = message);
        this._danger.pipe(debounceTime(5000)).subscribe(() => {
            if (this.selfClosingAlert) {
                this.selfClosingAlert.close();
            }
        }); 
        this.initForm();
        if (this.graphType == 'line') {
            this.drawDiagram()
            this.showDiagOnly = true;
            return;
        }
        if (this.graphType === 'multi-bar') {
            this.xElements = this.xElements.filter((element) => element.elementKey == 'numb');
            this.yElements = NumbAnalyseElement.filter((element) => element.elementKey.includes('rate'));
        }
        this.multi = this.graphType === 'multi-bar';
    }

    get diagrammeBaton() { return this.diagrammeBatonForm.controls; }

    initForm() {
        this.diagrammeBatonFormSubmitted = false;
        this.options = null;
        this.data = null
        this.pieData = null;
        this.pieOptions = null;
        this.multiDatasets = null;
        this.showTable = false;
        this.diagrammeBatonForm = this.formBuilder.group({
            itemx: [null, [Validators.required]],
            itemy: [null, [Validators.required]],
            direction: ['horizontal'],
        });
        this.selectedItems = [];
    }

    onXElementChange(value: AnalyseElement) {
        if (this.graphType != `multi-bar`) {
            this.yElements = [];
            this.diagrammeBatonForm.controls.itemy.setValue(null);
            if (value.elementKey === 'numb') {
                this.yElements = NumbAnalyseElement;
            } else if (value.elementKey === 'calc_region' || value.elementKey === 'calc_sector') {
                this.yElements = RegionAnalyseElement;
            }
        }
    }

    processData() {
        this.diagrammeBatonFormSubmitted = true;
        if (this.diagrammeBatonForm.invalid) {
            return;
        }
        this.showTable = true;

        this.elements = this.utilityService.fetchProjectColumns();
        this.selectedItems = ['numb', 'title'];
        if (this.graphType === 'multi-bar') {
            const selectedY = this.diagrammeBatonForm.value.itemy as AnalyseElement[];
            selectedY.forEach((element) => {
                this.selectedItems.push(element.elementKey);
            });
        } else {
            const selectedY = this.diagrammeBatonForm.value.itemy as AnalyseElement;
            this.selectedItems.push(selectedY.elementKey);
        }
        const selectedX = this.diagrammeBatonForm.value.itemx as AnalyseElement;
        if (selectedX.elementKey !== 'numb') {
            this.elements.push(selectedX)
            this.selectedItems.push(selectedX.elementKey);
        }

        const analyseProperties: AnalyseProperty[] = [];
        this.projects.forEach((project: Project) => {
            const analysePropertiesMap = {};
            project.analyseProperties = [];
            project.analyseProperties = analyseProperties;
            this.selectedItems.forEach((element) => {
                this.elements.forEach((analyseElement: AnalyseElement) => {
                    if (!element.includes("calc")) {
                        if (analyseElement.elementKey === element) {
                            if (analyseElement.related !== null) {
                                analyseProperties.push(new AnalyseProperty(this.translate.instant(analyseElement.element),
                                    project?.[analyseElement.related]?.[element]));
                            } else {
                                const attribute = this.translate.instant(analyseElement.element);
                                const value = project[element];

                                if (analysePropertiesMap[attribute]) {
                                    analysePropertiesMap[attribute].push(value);
                                } else {
                                    analysePropertiesMap[attribute] = [value];
                                }
                            }
                        }

                    } else {
                        if (analyseElement.elementKey === element) {


                            if (analyseElement.related == null) {

                                const attribute = this.translate.instant(analyseElement.element);
                                const value = handleCalcElement(element as any, project, this.translate)

                                if (analysePropertiesMap[attribute]) {
                                    analysePropertiesMap[attribute].push(value);
                                } else {
                                    analysePropertiesMap[attribute] = [value];
                                }
                            }
                        }

                    }
                });
            });
            project.analyseProperties = Object.keys(analysePropertiesMap).map((attribute) => {
                return new AnalyseProperty(attribute, analysePropertiesMap[attribute]);
            });

            if (this.translate.defaultLang !== 'fr') {
                this.dtLang = DatatableLanguage.en;
            }
            this.dtOptions = {
                stateSave: false,
                destroy: true,
                retrieve: true,
                pageLength: 10,
                processing: true,
                language: this.dtLang
            };
        });
        this.drawDiagram();
    }

    getTitle() {
        switch (this.graphType) {
            case 'bar':
                return this.translate.instant('choose-items-of-the-bar-chart');
            case 'pie':
                return this.translate.instant('choose-items-of-the-pie-chart');
            case 'multi-bar':
                return this.translate.instant('choose-items-of-the-multi-criteria-bar-chart');
            case 'line':
                return this.translate.instant('choose-items-of-the-curve'); 
            default:
                break;
        }
    }
    getLabels() {
        const Xabsisse = this.diagrammeBatonForm.value?.itemx?.elementKey;
        if (Xabsisse === 'numb') {
            return this.projects.map((project: Project) => project.numb)
        } else if (Xabsisse === 'calc_region') {
            return this.utilityService.fetchRegions().map((region) => region.label);
        } else if (Xabsisse === 'calc_sector') {
            return this.utilityService.fetchDSCESectors()
        }
    }

    handleData(key: string, projects: Project[]) {
        const Xabsisse = this.diagrammeBatonForm.value?.itemx?.elementKey;
        switch (key) {
            case "all-projects":
                if (Xabsisse === 'calc_region') {
                    return countProjectsByRegion(projects, this.utilityService.fetchRegions().map((region) => region.label));
                } else if (Xabsisse === 'calc_sector') {
                    return countProjectsBySector(projects, this.utilityService.fetchDSCESectors());
                }
                break;
            case 'calc_amount':
                return projects.map((project: Project) => project.amount);
            case 'calc_amountFcfa':
                return projects.map((project: Project) => {
                    const amount = project.amount;
                    const amountFcfa = amount * project.exchangeRate;
                    if (amountFcfa) {
                        return amountFcfa;
                    } else {
                        return 0;
                    }
                });
            case "calc_age":
                return projects.map((project: Project) => getProjectAge(project.startDate));
            case "calc_delay_consumption_rate":
                return projects.map((project: Project) => parseFloat(getProjectDelayConsumsionRate(project.startDate, project.duration)));
            case "calc_disbursement_rate":
                return projects.map((project: Project) => parseFloat(getProjectDisbursementRate(project)));
            default:
                break;
        }
    }

    sanitizeValue(value: any) {
        return this.sanitizer.sanitize(SecurityContext.HTML, checkRow(value))
    }

    hideDiagram() {
        this.initForm();
    }

    exportAsPDF() {}

    drawDiagram() {
        if (this.diagrammeBatonForm?.invalid && this.graphType !== "line") {
            return;
        }
        const direction = this.diagrammeBatonForm.value.direction
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        this.options = {
            indexAxis: direction == "vertical" ? 'x' : 'y',
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                        font: {
                            weight: 500
                        }
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                }

            }
        }
        this.pieOptions = {
            aspectRatio: 2,
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true,
                        color: textColor
                    }
                }
            }
        };
        this.lineOptions = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder,
                    drawBorder: false
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder,
                    drawBorder: false
                }
            }
        }
    };
        const labels = this.getLabels();
        const selectedY = this.diagrammeBatonForm?.value?.itemy as AnalyseElement;
        const data = this.handleData(selectedY?.elementKey, this.projects);
        const selectedsY = this.diagrammeBatonForm?.value?.itemy as AnalyseElement[];
        switch (this.graphType) {
            case 'bar':
                this.data = {
                    labels: labels,
                    datasets: [
                        {
                            label: this.translate.instant(selectedY?.element),
                            backgroundColor: documentStyle.getPropertyValue('--blue-500'),
                            borderColor: documentStyle.getPropertyValue('--blue-500'),
                            data: data
                        }
                    ]
                };
                break;
            case 'pie':
                this.pieData = {
                    labels: labels,
                    datasets: [
                        {
                            data: data,
                        }
                    ]
                };
                break;
            case 'multi-bar':
                this.multiDatasets = {
                    labels: labels,
                    datasets: selectedsY.map((element: AnalyseElement) => {
                        return {
                            label: this.translate.instant(element.element),
                            data: this.handleData(element.elementKey, this.projects)
                        }
                    })
                }
                break;
            case 'line':                
            this.lineData = {
                labels: this.getYears(),
                datasets: this.projects.map((project)=>{                    
                    return {
                        label: project.abbr,
                        fill: false,
                        data: this.getLineData(project).sort((a,b)=>{
                            return b.x - a.x
                        }),
                        tension: 0.4
                    }
                })
               
            }
            console.log(this.lineData);
            
            break;
            default:
                break;
        }
    }

    getYears (){
        const years: number[] = []        
        this.projects.forEach((project)=>{            
           const start = new Date(project.startDate);
           const end = new Date(project.endDate)
           years.push(start.getUTCFullYear())
           years.push(end.getUTCFullYear())
        })
        const max = Math.max(...years)
        const min = Math.min(...years)
       return Array.from({length: (max - min + 1)}, (_, index) => min + index);
    }

    getLineData(project: Project){
         return project.multiAnnualPrograms.map((data)=>{
            return {y: data.lessorAmount, x:parseInt(data.year)}
         })
    }
}


