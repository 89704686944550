<!DOCTYPE html>
<section *ngIf="isLoaded" class="animated fadeIn">
    <div class="modal-header d-flex justify-content-between">
        <h3 class="modal-title roboto-bold-font">
            {{ "activities" | translate }}
        </h3>
        <span
            (click)="closeModal()"
            aria-label="Close"
            class="close fa fa-times text-red"
        ></span>
    </div>
    <div class="modal-body text-black container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <ngb-alert
                    (closed)="errorMessage = ''"
                    *ngIf="errorMessage"
                    [dismissible]="true"
                    type="danger"
                >{{ errorMessage }}</ngb-alert
                >

                <section [hidden]="slide1">
                    <form [formGroup]="slide1Form">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-5 col-sm-5">
                                <div class="form-group">
                                    <label class="control-label" for="numb"
                                    >{{
                                        "inputs.reference-number.label"
                                            | translate
                                        }}
                                        <span class="roboto-bold-font text-red"
                                        >*</span
                                        ></label
                                    >
                                    <input
                                        [ngClass]="{
                                            'is-invalid':
                                                slide1FormSubmitted &&
                                                csrForm.numb.errors
                                        }"
                                        aria-describedby="basic-user"
                                        aria-label="Number"
                                        class="form-control text-right roboto-bold-font"
                                        formControlName="numb"
                                        id="numb"
                                        type="text"
                                    />
                                    <div
                                        *ngIf="
                                            slide1FormSubmitted &&
                                            csrForm.numb.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="csrForm.numb.errors.required"
                                        >
                                            {{
                                            "inputs.reference-number.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-8 col-lg-8 col-md-7 col-sm-7">
                                <div class="form-group">
                                    <label class="control-label" for="title"
                                    >{{
                                        "inputs.entitle.label" | translate
                                        }}
                                        <span class="roboto-bold-font text-red"
                                        >*</span
                                        ></label
                                    >
                                    <input
                                        [ngClass]="{
                                            'is-invalid':
                                                slide1FormSubmitted &&
                                                csrForm.title.errors
                                        }"
                                        aria-describedby="basic-user"
                                        aria-label="Title"
                                        class="form-control"
                                        formControlName="title"
                                        id="title"
                                        type="text"
                                    />
                                    <div
                                        *ngIf="
                                            slide1FormSubmitted &&
                                            csrForm.title.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                csrForm.title.errors.required
                                            "
                                        >
                                            {{
                                            "inputs.entitle.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xl-6 col-lg-6">
                                <div class="form-group">
                                    <label class="control-label" for="component"
                                    >{{
                                        "inputs.related_component.label"
                                            | translate
                                        }}<span
                                            class="roboto-bold-font text-red"
                                        >*</span
                                        ></label
                                    >
                                    <ng-select
                                        (change)="onComponentChange($event)"
                                        [clearable]="false"
                                        [ngClass]="{
                                            'is-invalid':
                                                slide1FormSubmitted &&
                                                csrForm.component.errors
                                        }"
                                        [searchable]="true"
                                        formControlName="component"
                                        id="component"
                                        placeholder="{{
                                            'inputs.related_component.placeholders'
                                                | translate
                                        }}"
                                    >
                                        <ng-option
                                            *ngFor="let component of components"
                                            [value]="component.idcomponent"
                                        >{{ component.title }}</ng-option
                                        >
                                    </ng-select>
                                    <div
                                        *ngIf="
                                            slide1FormSubmitted &&
                                            csrForm.component.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                csrForm.component.errors
                                                    .required
                                            "
                                        >
                                            {{
                                            "inputs.related_component.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="subComponent"
                                    >{{
                                        "inputs.related_sub_component.label"
                                            | translate
                                        }}<span
                                            class="roboto-bold-font text-red"
                                        >*</span
                                        ></label
                                    >
                                    <ng-select
                                        [clearable]="false"
                                        [ngClass]="{
                                            'is-invalid':
                                                slide1FormSubmitted &&
                                                csrForm.subComponent.errors
                                        }"
                                        [searchable]="true"
                                        formControlName="subComponent"
                                        id="subComponent"
                                        placeholder="{{
                                            'inputs.related_sub_component.placeholders'
                                                | translate
                                        }}"
                                    >
                                        <ng-option
                                            *ngFor="
                                                let subComponent of subComponents
                                            "
                                            [value]="
                                                subComponent.idsubComponent
                                            "
                                        >{{ subComponent.title }}</ng-option
                                        >
                                    </ng-select>
                                    <div
                                        *ngIf="
                                            slide1FormSubmitted &&
                                            csrForm.subComponent.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                csrForm.subComponent.errors
                                                    .required
                                            "
                                        >
                                            {{
                                            "inputs.related_sub_component.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h4 class="py-2 roboto-bold-font">
                            {{ "activity_over_all_amount" | translate }}
                        </h4>
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="form-group">
                                    <label class="control-label" for="amount"
                                    >{{
                                        "inputs.amount_in_currency.label"
                                            | translate
                                        }}
                                        <span class="roboto-bold-font text-red"
                                        >*</span
                                        ></label
                                    >
                                    <div class="input-group mb-3">
                                        <input
                                            [ngClass]="{
                                                'is-invalid':
                                                    slide1FormSubmitted &&
                                                    csrForm.amount.errors
                                            }"
                                            aria-describedby="basic-user"
                                            aria-label="Amount"
                                            class="form-control text-right roboto-bold-font"
                                            formControlName="amount"
                                            id="amount"
                                            min="0"
                                            readonly
                                            type="number"
                                        />
                                        <div class="input-group-append">
                                            <ng-select
                                                [clearable]="false"
                                                [ngClass]="{
                                                    'is-invalid':
                                                        slide1FormSubmitted &&
                                                        csrForm.currency.errors
                                                }"
                                                [searchable]="true"
                                                formControlName="currency"
                                                id="currency"
                                                placeholder="Dévise"
                                                readonly="true"
                                            >
                                                <ng-option
                                                    *ngFor="
                                                        let devise of devises
                                                    "
                                                    [value]="devise"
                                                >{{ devise }}</ng-option
                                                >
                                            </ng-select>
                                            <div
                                                *ngIf="
                                                    slide1FormSubmitted &&
                                                    csrForm.currency.errors
                                                "
                                                class="invalid-feedback"
                                            >
                                                <div
                                                    *ngIf="
                                                        csrForm.currency.errors
                                                            .required
                                                    "
                                                >
                                                    {{
                                                    "inputs.currency.validations.required"
                                                        | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            *ngIf="
                                                slide1FormSubmitted &&
                                                csrForm.amount.errors
                                            "
                                            class="invalid-feedback"
                                        >
                                            <div
                                                *ngIf="
                                                    csrForm.amount.errors
                                                        .required
                                                "
                                            >
                                                {{
                                                "inputs.amount.validations.required"
                                                    | translate
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            *ngIf="errorMessage"
                                            class="invalid-feedback"
                                        >
                                            <div>{{ errorMessage }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="exchangeRate"
                                    >{{
                                        "inputs.exchangeRate.label"
                                            | translate
                                        }}
                                        ({{
                                        "at_signature" | translate
                                        }})</label
                                    >
                                    <input
                                        aria-describedby="basic-user"
                                        aria-label="Exchange Rate"
                                        class="form-control"
                                        formControlName="exchangeRate"
                                        id="exchangeRate"
                                        min="0"
                                        readonly="true"
                                        type="number"
                                    />
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4">
                                <div class="form-group">
                                    <label class="control-label" for="amount"
                                    >{{
                                        "inputs.amount_in_fcfa.label"
                                            | translate
                                        }}
                                        <span class="roboto-bold-font text-red"
                                        >*</span
                                        ></label
                                    >
                                    <div class="input-group mb-3">
                                        <input
                                            (input)="
                                                checkAmount($event)
                                            "
                                            [ngClass]="{
                                                'is-invalid':
                                                    slide1FormSubmitted &&
                                                    csrForm.amountFcfa.errors
                                            }"
                                            aria-describedby="basic-user"
                                            aria-label="Amount"
                                            class="form-control text-right roboto-bold-font"
                                            formControlName="amountFcfa"
                                            id="amountFcfa"
                                            min="0"
                                            type="number"
                                        />
                                        <div class="input-group-append">
                                            <span
                                                class="input-group-text"
                                                id="XAF"
                                            >FCFA</span
                                            >
                                        </div>
                                        <div
                                            *ngIf="
                                                slide1FormSubmitted &&
                                                csrForm.amount.errors
                                            "
                                            class="invalid-feedback"
                                        >
                                            <div
                                                *ngIf="
                                                    csrForm.amountFcfa.errors
                                                        .required
                                                "
                                            >
                                                {{
                                                "inputs.amount.validations.required"
                                                    | translate
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h5 class="py-2 roboto-bold-font">
                            {{ "financing-source" | translate }}
                        </h5>
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="fcpRate"
                                    >{{
                                        "inputs.fcpRate.label" | translate
                                        }}</label
                                    >
                                    <div class="input-group mb-3">
                                        <input
                                            (input)="checkRate($event.target)"
                                            [ngClass]="{
                                                'is-invalid':
                                                    slide1FormSubmitted &&
                                                    csrForm.fcpRate.errors
                                            }"
                                            aria-describedby="basic-user"
                                            aria-label="FCP Rate"
                                            class="form-control"
                                            formControlName="fcpRate"
                                            id="fcpRate"
                                            max="100"
                                            min="0"
                                            type="number"
                                        />
                                        <div class="input-group-append">
                                            <span
                                                class="input-group-text"
                                                id="fcpRat"
                                            ><em
                                                aria-hidden="true"
                                                class="fa fa-percent"
                                            ></em
                                            ></span>
                                        </div>
                                        <div
                                            *ngIf="
                                                slide1FormSubmitted &&
                                                csrForm.fcpRate.errors
                                            "
                                            class="invalid-feedback"
                                        >
                                            <div
                                                *ngIf="
                                                    csrForm.fcpRate.errors
                                                        .required
                                                "
                                            >
                                                {{
                                                "inputs.fcpRate.validations.required"
                                                    | translate
                                                }}
                                            </div>
                                            <div
                                                *ngIf="
                                                    csrForm.fcpRate.errors.min
                                                "
                                            >
                                                {{
                                                "inputs.fcpRate.validations.min"
                                                    | translate
                                                    : {size: "0"}
                                                }}
                                            </div>
                                            <div
                                                *ngIf="
                                                    csrForm.fcpRate.errors.max
                                                "
                                            >
                                                {{
                                                "inputs.fcpRate.validations.max"
                                                    | translate
                                                    : {size: "100"}
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="fcpYear"
                                    >{{
                                        "inputs.fcpYear.label" | translate
                                        }}</label
                                    >
                                    <input
                                        [ngClass]="{
                                            'is-invalid':
                                                slide1FormSubmitted &&
                                                csrForm.fcpYear.errors
                                        }"
                                        aria-describedby="basic-user"
                                        aria-label="FCP Year"
                                        class="form-control"
                                        formControlName="fcpYear"
                                        id="fcpYear"
                                        max="{{ max_year }}"
                                        type="number"
                                    />
                                    <div
                                        *ngIf="
                                            slide1FormSubmitted &&
                                            csrForm.fcpYear.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                csrForm.fcpYear.errors.required
                                            "
                                        >
                                            {{
                                            "inputs.fcpYear.validations.required"
                                                | translate
                                            }}
                                        </div>
                                        <div *ngIf="csrForm.fcpYear.errors.min">
                                            {{
                                            "inputs.fcpYear.validations.min"
                                                | translate
                                                : {size: min_year}
                                            }}
                                        </div>
                                        <div *ngIf="csrForm.fcpYear.errors.max">
                                            {{
                                            "inputs.fcpYear.validations.max"
                                                | translate
                                                : {size: max_year}
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="fcpBudgetLine"
                                    >{{
                                        "inputs.fcpBudgetLine.label"
                                            | translate
                                        }}</label
                                    >
                                    <input
                                        [ngClass]="{
                                            'is-invalid':
                                                slide1FormSubmitted &&
                                                csrForm.fcpBudgetLine.errors
                                        }"
                                        aria-describedby="basic-user"
                                        aria-label="FCP Budgetary Line"
                                        class="form-control"
                                        formControlName="fcpBudgetLine"
                                        id="fcpBudgetLine"
                                        min="0"
                                        type="text"
                                    />
                                    <div
                                        *ngIf="
                                            slide1FormSubmitted &&
                                            csrForm.fcpBudgetLine.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                csrForm.fcpBudgetLine.errors
                                                    .required
                                            "
                                        >
                                            {{
                                            "inputs.fcpBudgetLine.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="lessorRate"
                                    >{{
                                        "inputs.lessorRate.label"
                                            | translate
                                        }}</label
                                    >
                                    <div class="input-group mb-3">
                                        <input
                                            (input)="checkRate($event.target)"
                                            [ngClass]="{
                                                'is-invalid':
                                                    slide1FormSubmitted &&
                                                    csrForm.lessorRate.errors
                                            }"
                                            aria-describedby="basic-user"
                                            aria-label="Lessor Rate"
                                            class="form-control"
                                            formControlName="lessorRate"
                                            id="lessorRate"
                                            max="100"
                                            min="0"
                                            type="number"
                                        />
                                        <div class="input-group-append">
                                            <span
                                                class="input-group-text"
                                                id="lessorRat"
                                            ><em
                                                aria-hidden="true"
                                                class="fa fa-percent"
                                            ></em
                                            ></span>
                                        </div>
                                        <div
                                            *ngIf="
                                                slide1FormSubmitted &&
                                                csrForm.lessorRate.errors
                                            "
                                            class="invalid-feedback"
                                        >
                                            <div
                                                *ngIf="
                                                    csrForm.lessorRate.errors
                                                        .required
                                                "
                                            >
                                                {{
                                                "inputs.lessorRate.validations.required"
                                                    | translate
                                                }}
                                            </div>
                                            <div
                                                *ngIf="
                                                    csrForm.lessorRate.errors
                                                        .min
                                                "
                                            >
                                                {{
                                                "inputs.lessorRate.validations.min"
                                                    | translate
                                                    : {size: "0"}
                                                }}
                                            </div>
                                            <div
                                                *ngIf="
                                                    csrForm.lessorRate.errors
                                                        .max
                                                "
                                            >
                                                {{
                                                "inputs.lessorRate.validations.max"
                                                    | translate
                                                    : {size: "100"}
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="lessorYear"
                                    >{{
                                        "inputs.lessorYear.label"
                                            | translate
                                        }}</label
                                    >
                                    <input
                                        [ngClass]="{
                                            'is-invalid':
                                                slide1FormSubmitted &&
                                                csrForm.lessorYear.errors
                                        }"
                                        aria-describedby="basic-user"
                                        aria-label="Lessor Year"
                                        class="form-control"
                                        formControlName="lessorYear"
                                        id="lessorYear"
                                        max="{{ max_year }}"
                                        type="number"
                                    />
                                    <div
                                        *ngIf="
                                            slide1FormSubmitted &&
                                            csrForm.lessorYear.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                csrForm.lessorYear.errors
                                                    .required
                                            "
                                        >
                                            {{
                                            "inputs.lessorYear.validations.required"
                                                | translate
                                            }}
                                        </div>
                                        <div
                                            *ngIf="
                                                csrForm.lessorYear.errors.min
                                            "
                                        >
                                            {{
                                            "inputs.lessorYear.validations.min"
                                                | translate
                                                : {size: min_year}
                                            }}
                                        </div>
                                        <div
                                            *ngIf="
                                                csrForm.lessorYear.errors.max
                                            "
                                        >
                                            {{
                                            "inputs.lessorYear.validations.max"
                                                | translate
                                                : {size: max_year}
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="lessorBudgetLine"
                                    >{{
                                        "inputs.lessorBudgetLine.label"
                                            | translate
                                        }}</label
                                    >
                                    <input
                                        [ngClass]="{
                                            'is-invalid':
                                                slide1FormSubmitted &&
                                                csrForm.lessorBudgetLine.errors
                                        }"
                                        aria-describedby="basic-user"
                                        aria-label="FCP Budgetary Line"
                                        class="form-control"
                                        formControlName="lessorBudgetLine"
                                        id="lessorBudgetLine"
                                        min="0"
                                        type="text"
                                    />
                                    <div
                                        *ngIf="
                                            slide1FormSubmitted &&
                                            csrForm.lessorBudgetLine.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                csrForm.lessorBudgetLine.errors
                                                    .required
                                            "
                                        >
                                            {{
                                            "inputs.lessorBudgetLine.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h4 class="py-2 roboto-bold-font">
                            {{ "implementation-princResponsible" | translate }}
                        </h4>
                        <div class="row">
                            <div class="col-xl-6 col-lg-6">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="princResponsible"
                                    >{{
                                        "inputs.principal_responsible.label"
                                            | translate
                                        }}</label
                                    >
                                    <input
                                        [ngClass]="{
                                            'is-invalid':
                                                slide1FormSubmitted &&
                                                csrForm.princResponsible.errors
                                        }"
                                        aria-describedby="basic-user"
                                        aria-label="Pricipale Responsible"
                                        class="form-control"
                                        formControlName="princResponsible"
                                        id="princResponsible1"
                                        type="text"
                                    />
                                    <div
                                        *ngIf="
                                            slide1FormSubmitted &&
                                            csrForm.princResponsible.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                csrForm.princResponsible.errors
                                                    .required
                                            "
                                        >
                                            {{
                                            "inputs.principal_responsible.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="partResponsible"
                                    >{{
                                        "inputs.implementation_partner.label"
                                            | translate
                                        }}</label
                                    >
                                    <input
                                        [ngClass]="{
                                            'is-invalid':
                                                slide1FormSubmitted &&
                                                csrForm.partResponsible.errors
                                        }"
                                        aria-describedby="basic-user"
                                        aria-label="Implementation Partner"
                                        class="form-control"
                                        formControlName="partResponsible"
                                        id="partResponsible"
                                        type="text"
                                    />
                                    <div
                                        *ngIf="
                                            slide1FormSubmitted &&
                                            csrForm.partResponsible.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                csrForm.partResponsible.errors
                                                    .required
                                            "
                                        >
                                            {{
                                            "inputs.implementation_partner.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h4 class="py-2 roboto-bold-font">{{ "localisation" | translate }}</h4>
                        <div>
                            <button
                                (click)="showGeolocationSection()"
                                *ngIf="!showGeolocation"
                                class="btn btn-pill bg-primary px-5"
                            >
                                Géolocaliser
                            </button>
                            <button
                                (click)="hideSections()"
                                *ngIf="showGeolocation"
                                class="btn btn-pill bg-danger px-5"
                            >
                                Annuler
                            </button>

                            <div *ngIf="showGeolocation">
                                <h4 class="py-2 roboto-bold-font">
                                    {{ "save_location" | translate }}
                                </h4>
                                <div class="row">
                                    <div class="col-md-6 col-12 p-3">
                                        <label for="latitude">Latitude</label>
                                        <input
                                            (ngModelChange)="
                                                validerCoordonnees()
                                            "
                                            class="form-control"
                                            formControlName="L_latitude"
                                            id="latitude"
                                            placeholder="Latitude"
                                            type="number"
                                        />
                                        <div
                                            *ngIf="
                                                slide1Form.hasError(
                                                    'horsLimite'
                                                )
                                            "
                                            class="text-danger"
                                        >
                                            Les coordonnées doivent être dans la
                                            zone du Cameroun.
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12 p-3">
                                        <label for="longitude">Longitude</label>
                                        <input
                                            (ngModelChange)="
                                                validerCoordonnees()
                                            "
                                            class="form-control"
                                            formControlName="L_longitude"
                                            id="longitude"
                                            placeholder="Longitude"
                                            type="number"
                                        />
                                        <div
                                            *ngIf="
                                                slide1Form.hasError(
                                                    'horsLimite'
                                                )
                                            "
                                            class="text-danger"
                                        >
                                            Les coordonnées doivent être dans la
                                            zone du Cameroun.
                                        </div>
                                    </div>
                                    <app-map-box
                                        [lat]="latitude"
                                        [lng]="longitude"
                                        class="map"
                                    ></app-map-box>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="row py-2">
                        <div
                            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end"
                        >
                            <a
                                (click)="changeSlide(2)"
                                class="btn btn-pill bg-green px-5"
                            >{{ "next" | translate }}</a
                            >
                        </div>
                    </div>
                </section>

                <section [hidden]="slide2">
                    <form [formGroup]="slide2Form">
                        <div class="row py-2">
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="contextJustif"
                                    >{{
                                        "inputs.contextJustif.label"
                                            | translate
                                        }}</label
                                    >
                                    <textarea
                                        [ngClass]="{
                                            'is-invalid':
                                                slide2FormSubmitted &&
                                                edForm.contextJustif.errors
                                        }"
                                        aria-describedby="basic-user"
                                        aria-label="Context and Justifications"
                                        class="form-control"
                                        formControlName="contextJustif"
                                        id="contextJustif"
                                        rows="10"
                                    ></textarea>
                                    <div
                                        *ngIf="
                                            slide2FormSubmitted &&
                                            edForm.contextJustif.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                edForm.contextJustif.errors
                                                    .required
                                            "
                                        >
                                            {{
                                            "inputs.contextJustif.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="form-group">
                                    <label class="control-label" for="objective"
                                    >{{
                                        "inputs.objective.label" | translate
                                        }}
                                        <span class="roboto-bold-font text-red"
                                        >*</span
                                        >
                                    </label>
                                    <textarea
                                        [ngClass]="{
                                            'is-invalid':
                                                slide2FormSubmitted &&
                                                edForm.objective.errors
                                        }"
                                        aria-describedby="basic-user"
                                        aria-label="Objective"
                                        class="form-control"
                                        formControlName="objective"
                                        id="objective"
                                        rows="10"
                                    ></textarea>
                                    <div
                                        *ngIf="
                                            slide2FormSubmitted &&
                                            edForm.objective.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                edForm.objective.errors.required
                                            "
                                        >
                                            {{
                                            "inputs.objective.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-12">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="riskHypothese"
                                    >{{
                                        "inputs.riskHypothese.label"
                                            | translate
                                        }}</label
                                    >
                                    <textarea
                                        [ngClass]="{
                                            'is-invalid':
                                                slide2FormSubmitted &&
                                                edForm.riskHypothese.errors
                                        }"
                                        aria-describedby="basic-user"
                                        aria-label="Risk Hypothese"
                                        class="form-control"
                                        formControlName="objective"
                                        id="riskHypothese"
                                        rows="10"
                                    ></textarea>
                                    <div
                                        *ngIf="
                                            slide2FormSubmitted &&
                                            edForm.riskHypothese.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                edForm.riskHypothese.errors
                                                    .required
                                            "
                                        >
                                            {{
                                            "inputs.riskHypothese.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h4 [hidden]="true" class="py-2 roboto-bold-font">
                            {{ "inputs.awaitedResult.label" | translate }}
                        </h4>
                        <div [hidden]="true" class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="deliverable"
                                    >{{
                                        "inputs.deliverable.label"
                                            | translate
                                        }}</label
                                    >
                                    <input
                                        [ngClass]="{
                                            'is-invalid':
                                                slide2FormSubmitted &&
                                                edForm.deliverable.errors
                                        }"
                                        aria-describedby="basic-user"
                                        aria-label="Title"
                                        class="form-control"
                                        formControlName="deliverable"
                                        id="deliverable2"
                                        type="text"
                                    />
                                    <div
                                        *ngIf="
                                            slide2FormSubmitted &&
                                            edForm.deliverable.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                edForm.deliverable.errors
                                                    .required
                                            "
                                        >
                                            {{
                                            "inputs.deliverable.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="verifMeans"
                                    >{{
                                        "inputs.verifMeans.label"
                                            | translate
                                        }}</label
                                    >
                                    <input
                                        [ngClass]="{
                                            'is-invalid':
                                                slide2FormSubmitted &&
                                                edForm.verifMeans.errors
                                        }"
                                        aria-describedby="basic-user"
                                        aria-label="Verification Means"
                                        class="form-control"
                                        formControlName="verifMeans"
                                        id="verifMeans2"
                                        type="text"
                                    />
                                    <div
                                        *ngIf="
                                            slide2FormSubmitted &&
                                            edForm.verifMeans.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                edForm.verifMeans.errors
                                                    .required
                                            "
                                        >
                                            {{
                                            "inputs.verifMeans.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h4 class="py-2 roboto-bold-font">
                            {{ "output" | translate }}
                        </h4>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label class="control-label" for="title">{{
                                        "inputs.entitle.label" | translate
                                        }}</label>
                                    <input
                                        [ngClass]="{
                                            'is-invalid':
                                                slide2FormSubmitted &&
                                                edForm.title.errors
                                        }"
                                        aria-describedby="basic-user"
                                        aria-label="Entitle"
                                        class="form-control"
                                        formControlName="title"
                                        id="OutputTitle"
                                        type="text"
                                    />
                                    <div
                                        *ngIf="
                                            slide2FormSubmitted &&
                                            edForm.title.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="edForm.title.errors.required"
                                        >
                                            {{
                                            "inputs.entitle.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="indicator"
                                    >{{
                                        "inputs.indicator.label" | translate
                                        }}</label
                                    >
                                    <ng-select
                                        [clearable]="true"
                                        [ngClass]="{
                                            'is-invalid':
                                                slide2FormSubmitted &&
                                                edForm.indicator.errors
                                        }"
                                        [searchable]="true"
                                        formControlName="indicator"
                                        id="indicator"
                                    >
                                        <ng-option
                                            *ngFor="let indicator of indicators"
                                            [value]="indicator"
                                        >
                                            {{ indicator.name }}</ng-option
                                        >
                                    </ng-select>
                                    <div
                                        *ngIf="
                                            slide2FormSubmitted &&
                                            edForm.indicator.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                edForm.indicator.errors.required
                                            "
                                        >
                                            {{
                                            "inputs.indicator.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-3 col-lg-3 col-md-6">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="baseline"
                                    >{{
                                        "inputs.baseline.label" | translate
                                        }}</label
                                    >
                                    <input
                                        [ngClass]="{
                                            'is-invalid':
                                                slide2FormSubmitted &&
                                                edForm.baseline.errors
                                        }"
                                        aria-describedby="basic-user"
                                        aria-label="Baseline"
                                        class="form-control"
                                        formControlName="baseline"
                                        id="baseline"
                                        type="number"
                                    />
                                    <div
                                        *ngIf="
                                            slide2FormSubmitted &&
                                            edForm.baseline.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                edForm.baseline.errors.required
                                            "
                                        >
                                            {{
                                            "inputs.baseline.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-6">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="targets"
                                    >{{
                                        "inputs.targets.label" | translate
                                        }}</label
                                    >
                                    <input
                                        [ngClass]="{
                                            'is-invalid':
                                                slide2FormSubmitted &&
                                                edForm.targets.errors
                                        }"
                                        aria-describedby="basic-user"
                                        aria-label="Targets"
                                        class="form-control"
                                        formControlName="targets"
                                        id="targets"
                                        type="number"
                                    />
                                    <div
                                        *ngIf="
                                            slide2FormSubmitted &&
                                            edForm.targets.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                edForm.targets.errors.required
                                            "
                                        >
                                            {{
                                            "inputs.targets.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h4 class="py-2 roboto-bold-font">
                            {{ "dates" | translate }}
                        </h4>
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <label class="control-label" for="startDate"
                                    >{{
                                        "inputs.startDate.label"
                                            | translate
                                        }}<span
                                            class="roboto-bold-font text-red"
                                        >*</span
                                        ></label
                                    >
                                    <input
                                        (ngModelChange)="minDate($event)" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [daysDisabled]="[7,0]"
                                        [maxDate]="endDate" [minDate]="startDate"
                                        [ngClass]="{
                                            'is-invalid':
                                                slide2FormSubmitted &&
                                                edForm.startDate.errors
                                        }" aria-describedby="basic-user"
                                        aria-label="Date Début"
                                        bsDatepicker class="form-control" formControlName="startDate"
                                        id="startDate" placeholder="{{ 'date-format' | translate }}"
                                        placement="top"
                                        type="text"
                                    />
                                    <div
                                        *ngIf="
                                            slide2FormSubmitted &&
                                            edForm.startDate.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                edForm.startDate.errors.required
                                            "
                                        >
                                            {{
                                            "inputs.startDate.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <label class="control-label" for="endDate"
                                    >{{ "inputs.endDate.label" | translate
                                        }}<span
                                            class="roboto-bold-font text-red"
                                        >*</span
                                        ></label
                                    >
                                    <input
                                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [daysDisabled]="[7,0]" [maxDate]="endDate"
                                        [minDate]="end_startDate" [ngClass]="{
                                            'is-invalid':
                                                slide2FormSubmitted &&
                                                edForm.endDate.errors
                                        }"
                                        aria-describedby="basic-user" aria-label="Date Fin"
                                        bsDatepicker
                                        class="form-control" formControlName="endDate"
                                        id="endDate" placeholder="{{ 'date-format' | translate }}" placement="top"
                                        type="text"
                                    />
                                    <div
                                        *ngIf="
                                            slide2FormSubmitted &&
                                            edForm.endDate.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                edForm.endDate.errors.required
                                            "
                                        >
                                            {{
                                            "inputs.endDate.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div class="row py-2">
                        <div
                            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-between"
                        >
                            <a
                                (click)="changeSlide(1)"
                                class="btn btn-pill bg-warning px-5"
                            >{{ "previous" | translate }}</a
                            >
                            <a
                                (click)="changeSlide(3)"
                                class="btn btn-pill bg-green px-5"
                            >{{ "next" | translate }}</a
                            >
                        </div>
                    </div>
                </section>

                <section [hidden]="slide3">
                    <form [formGroup]="slide3Form">
                        <h4 class="py-2 roboto-bold-font">
                            {{ "tasks" | translate }}
                        </h4>
                        <div class="row">
                            <div class="col-xl-4 col-lg-4">
                                <div class="form-group row">
                                    <label class="form-check-label col-xl-7 col-lg-7 col-md-7 col-sm-7 col-xs-12" for="yes">{{ "inputs.conference.label" | translate }}
                                        <span class="roboto-bold-font text-red">*</span></label>
                                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-12 text-center">
                                        <div class="form-check form-check-inline">
                                            <input [ngClass]="{ 'is-invalid': slide3FormSubmitted && tForm.conference.errors}" [value]="true" class="form-check-input" formControlName="conference"
                                                   id="yes" type="radio">
                                            <label class="form-check-label" for="yes">{{ "yes" | translate }}</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input [ngClass]="{ 'is-invalid': slide3FormSubmitted && tForm.conference.errors}" [value]="false" class="form-check-input" formControlName="conference"
                                                   id="no" type="radio">
                                            <label class="form-check-label" for="no">{{ "no" | translate }}</label>
                                        </div>
                                    </div>
                                    <div *ngIf="slide3FormSubmitted && tForm.conference.errors" class="invalid-feedback">
                                        <div *ngIf="tForm.conference.errors.required">{{ "inputs.conference.validations.required" | translate }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-8 col-lg-8"></div>

                            <div class="col-xl-3 col-lg-3">
                                <div class="form-group">
                                    <label class="control-label" for="numb">{{
                                        "inputs.numb.label" | translate
                                        }}</label>
                                    <input
                                        [ngClass]="{
                                            'is-invalid':
                                                slide3FormSubmitted &&
                                                tForm.numb.errors
                                        }"
                                        aria-describedby="basic-user"
                                        aria-label="Number"
                                        class="form-control text-right roboto-bold-font"
                                        formControlName="numb"
                                        id="task_numb"
                                        type="text"
                                    />
                                    <div *ngIf="slide3FormSubmitted && tForm.numb.errors" class="invalid-feedback">
                                        <div *ngIf="tForm.numb.errors.required">{{ "inputs.numb.validations.required" | translate }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="task_title"
                                    >{{
                                        "inputs.entitle.label" | translate
                                        }}</label
                                    >
                                    <input
                                        [ngClass]="{
                                            'is-invalid':
                                                slide3FormSubmitted &&
                                                tForm.title.errors
                                        }"
                                        aria-describedby="basic-user"
                                        aria-label="Title"
                                        class="form-control"
                                        formControlName="title"
                                        id="task_title"
                                        type="text"
                                    />
                                    <div
                                        *ngIf="
                                            slide3FormSubmitted &&
                                            tForm.title.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="tForm.title.errors.required"
                                        >
                                            {{
                                            "inputs.entitle.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-6">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="task_amount_fcfa"
                                    >{{
                                        "inputs.amount_in_fcfa.label"
                                            | translate
                                        }}</label
                                    >
                                    <div class="input-group mb-3">
                                        <input
                                            (input)="
                                                checkTaskAmount(
                                                    $event.target.value
                                                )
                                            "
                                            [ngClass]="{
                                                'is-invalid':
                                                    slide3FormSubmitted &&
                                                    tForm.amountFcfa.errors
                                            }"
                                            aria-describedby="basic-user"
                                            aria-label="Amount"
                                            class="form-control text-right roboto-bold-font"
                                            formControlName="amountFcfa"
                                            id="task_amount_fcfa"
                                            min="0"
                                            type="number"
                                        />
                                        <div class="input-group-append">
                                            <span
                                                class="input-group-text"
                                                id="amountXAF"
                                            >FCFA</span
                                            >
                                        </div>
                                        <div
                                            *ngIf="
                                                slide3FormSubmitted &&
                                                tForm.amountFcfa.errors
                                            "
                                            class="invalid-feedback"
                                        >
                                            <div
                                                *ngIf="
                                                    tForm.amountFcfa.errors
                                                        .required
                                                "
                                            >
                                                {{
                                                "inputs.amount.validations.required"
                                                    | translate
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="deliverable"
                                    >{{
                                        "inputs.deliverable.label"
                                            | translate
                                        }}</label
                                    >
                                    <input
                                        [ngClass]="{
                                            'is-invalid':
                                                slide3FormSubmitted &&
                                                tForm.deliverable.errors
                                        }"
                                        aria-describedby="basic-user"
                                        aria-label="Title"
                                        class="form-control"
                                        formControlName="deliverable"
                                        id="deliverable"
                                        type="text"
                                    />
                                    <div
                                        *ngIf="
                                            slide3FormSubmitted &&
                                            tForm.deliverable.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                tForm.deliverable.errors
                                                    .required
                                            "
                                        >
                                            {{
                                            "inputs.deliverable.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="verifMeans"
                                    >{{
                                        "inputs.verifMeans.label"
                                            | translate
                                        }}</label
                                    >
                                    <input
                                        [ngClass]="{
                                            'is-invalid':
                                                slide3FormSubmitted &&
                                                tForm.verifMeans.errors
                                        }"
                                        aria-describedby="basic-user"
                                        aria-label="Verification Means"
                                        class="form-control"
                                        formControlName="verifMeans"
                                        id="verifMeans"
                                        type="text"
                                    />
                                    <div
                                        *ngIf="
                                            slide3FormSubmitted &&
                                            tForm.verifMeans.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                tForm.verifMeans.errors.required
                                            "
                                        >
                                            {{
                                            "inputs.verifMeans.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="princResponsible"
                                    >{{
                                        "inputs.responsible.label"
                                            | translate
                                        }}</label
                                    >
                                    <input
                                        [ngClass]="{
                                            'is-invalid':
                                                slide3FormSubmitted &&
                                                tForm.princResponsible.errors
                                        }"
                                        aria-describedby="basic-user"
                                        aria-label="Pricipale Responsible"
                                        class="form-control"
                                        formControlName="princResponsible"
                                        id="princResponsible"
                                        type="text"
                                    />
                                    <div
                                        *ngIf="
                                            slide3FormSubmitted &&
                                            tForm.princResponsible.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                tForm.princResponsible.errors
                                                    .required
                                            "
                                        >
                                            {{
                                            "inputs.responsible.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="task_start_date"
                                    >{{
                                        "inputs.startDate.label" | translate
                                        }}</label
                                    >
                                    <input (ngModelChange)="minTaskDate($event)" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [daysDisabled]="[7,0]"
                                           [maxDate]="task_endDate"
                                           [minDate]="task_startDate" [ngClass]="{
                                            'is-invalid':
                                                slide3FormSubmitted &&
                                                tForm.startDate.errors
                                        }"
                                           aria-describedby="basic-user"
                                           aria-label="Date Début" bsDatepicker class="form-control"

                                           formControlName="startDate"
                                           id="task_start_date" placeholder="{{ 'date-format' | translate }}"
                                           type="text"
                                    />
                                    <div
                                        *ngIf="
                                            slide3FormSubmitted &&
                                            tForm.startDate.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                tForm.startDate.errors.required
                                            "
                                        >
                                            {{
                                            "inputs.startDate.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <label
                                        class="control-label"
                                        for="task_end_date"
                                    >{{
                                        "inputs.endDate.label" | translate
                                        }}</label
                                    >
                                    <input [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [daysDisabled]="[7,0]" [maxDate]="task_endDate"
                                           [minDate]="task_end_startDate"
                                           [ngClass]="{
                                            'is-invalid':
                                                slide3FormSubmitted &&
                                                tForm.endDate.errors
                                        }" aria-describedby="basic-user"
                                           aria-label="Date Début"
                                           bsDatepicker class="form-control" formControlName="endDate"
                                           id="task_end_date" placeholder="{{ 'date-format' | translate }}"
                                           type="text"
                                    />
                                    <div
                                        *ngIf="
                                            slide3FormSubmitted &&
                                            tForm.endDate.errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                tForm.endDate.errors.required
                                            "
                                        >
                                            {{
                                            "inputs.endDate.validations.required"
                                                | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- espace de geolocalisation -->
                            <div class="col-12">
                                <button
                                    (click)="showGeolocationTaskSection()"
                                    *ngIf="!showGeolocationTask"
                                    class="btn btn-pill bg-primary px-5"
                                >
                                    Géolocaliser
                                </button>
                                <button
                                    (click)="hideTaskSections()"
                                    *ngIf="showGeolocationTask"
                                    class="btn btn-pill bg-danger px-5"
                                >
                                    Annuler
                                </button>

                                <div *ngIf="showGeolocationTask">
                                    <h4 class="py-2 roboto-bold-font">
                                        {{ "save_location" | translate }}
                                    </h4>
                                    <div class="row">
                                        <div class="col-md-6 col-12 p-3">
                                            <label for="T_latitude">Latitude</label>
                                            <input
                                                (ngModelChange)="
                                                validerTaskCoordonnees()
                                            "
                                                class="form-control"
                                                formControlName="T_latitude"
                                                id="T_latitude"
                                                placeholder="Latitude"

                                                type="number"
                                            />
                                            <div
                                                *ngIf="
                                                slide3Form.hasError(
                                                    'horsLimite'
                                                )
                                            "
                                                class="text-danger"
                                            >
                                                Les coordonnées doivent être dans la
                                                zone du Cameroun.
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-12 p-3">
                                            <label for="T_longitude">Longitude</label>
                                            <input
                                                (ngModelChange)="
                                                validerCoordonnees()
                                            "
                                                class="form-control"
                                                formControlName="T_longitude"
                                                id="T_longitude"
                                                placeholder="Longitude"

                                                type="number"
                                            />
                                            <div
                                                *ngIf="
                                                slide3Form.hasError(
                                                    'horsLimite'
                                                )
                                            "
                                                class="text-danger"
                                            >
                                                Les coordonnées doivent être dans la
                                                zone du Cameroun.
                                            </div>
                                        </div>
                                        <app-map-box
                                            [lat]="latitude_task"
                                            [lng]="longitude_task"
                                            class="map"
                                        ></app-map-box>
                                    </div>
                                </div>
                            </div>

                            <!-- end geolocalisation-->

                            <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end text-white"
                            >
                                <a
                                    (click)="addTask()"
                                    class="btn btn-pill bg-green my-2 mx-1"
                                ><em class="fa fa-plus"></em
                                ></a>
                            </div>

                            <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                            >
                                <div class="table-responsive">
                                    <table class="table table-hover table-striped">
                                        <caption></caption>
                                        <thead class="thead-dark">
                                        <tr>
                                            <th
                                                class="text-center"
                                                rowspan="2"
                                                scope="row">
                                                {{
                                                "reference-number"
                                                    | translate
                                                }}
                                            </th>
                                            <th rowspan="2" scope="row">
                                                {{ "tasks" | translate }}
                                            </th>
                                            <th rowspan="2" scope="row">
                                                {{
                                                "cost-fcfa" | translate
                                                }}
                                            </th>
                                            <th rowspan="2" scope="row">
                                                {{
                                                "deliverables"
                                                    | translate
                                                }}
                                            </th>
                                            <th rowspan="2" scope="row">
                                                {{
                                                "princResponsible"
                                                    | translate
                                                }}
                                            </th>
                                            <th
                                                class="text-center"
                                                colspan="2"
                                                scope="row"
                                            >
                                                {{ "calendar" | translate }}
                                            </th>
                                            <th rowspan="2" scope="row"></th>
                                        </tr>
                                        <tr>
                                            <th class="text-center" scope="row">
                                                {{
                                                "inputs.startDate.label"
                                                    | translate
                                                }}
                                            </th>
                                            <th class="text-center" scope="row">
                                                {{
                                                "inputs.endDate.label"
                                                    | translate
                                                }}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                            *ngFor="
                                                    let task of tasks;
                                                    let i = index
                                                "
                                        >
                                            <td class="text-center">
                                                {{ task.numb }}
                                            </td>
                                            <td>{{ task.title }}</td>
                                            <td class="roboto-bold-font">
                                                {{
                                                moneyFormat(
                                                    task.amountFcfa
                                                )
                                                }}
                                            </td>
                                            <td>{{ task.deliverable }}</td>
                                            <td>
                                                {{ task.princResponsible }}
                                            </td>
                                            <td class="text-center">
                                                {{
                                                dateFormat(
                                                    task.startDate
                                                )
                                                }}
                                            </td>
                                            <td class="text-center">
                                                {{
                                                dateFormat(task.endDate)
                                                }}
                                            </td>
                                            <td class="text-center">
                                                <a
                                                    (click)="deleteTask(i)"
                                                    class="btn btn-pill bg-danger my-2 mx-1 text-white"
                                                ><em
                                                    class="fa fa-times"
                                                ></em
                                                ></a>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div class="row py-2">
                        <div
                            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-between"
                        >
                            <a
                                (click)="changeSlide(2)"
                                class="btn btn-pill bg-warning px-5"
                            >{{ "previous" | translate }}</a
                            >
                            <button
                                (click)="onSubmit()"
                                class="btn btn-pill bg-primary px-5"
                                type="submit"
                            >
                                {{ "save" | translate }}
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>
