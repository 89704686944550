<!DOCTYPE html>
<section class="animated fadeIn" *ngIf="isLoaded">
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <table class="table table-hover table-striped table-condensed" datatable [dtOptions]="dtOptions">
                <caption></caption>
                <thead class="roboto-bold-font thead-dark">
                <tr>
                    <th> {{ "date" | translate }}</th>
                    <th> {{ "object" | translate }}</th>
                    <th> {{ "sender" | translate }}</th>
                </tr>
                </thead>

                <tbody>
                <tr title="{{ 'click-to-continuo-review' | translate }}" *ngFor="let review of reviews" (click)="continuoReview(review)">
                    <td class="text-center">{{ dateFormat(review.createdAt) }}</td>
                    <td>{{ review.object }}</td>
                    <td>{{ review.sender.name }} {{ review.sender.surname }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</section>
