import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MapBoxAllComponent} from './map-box-all/map-box-all.component';
import {MapBoxComponent} from './map-box/map-box.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {FileViewerComponent} from './file-viewer/file-viewer.component';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BsModalService, ModalModule} from 'ngx-bootstrap/modal';
import {DialogModule} from 'primeng/dialog';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {ChartModule} from 'primeng/chart';
import {InputTextModule} from 'primeng/inputtext';
import {MultiSelectModule} from 'primeng/multiselect';
import {TableModule} from 'primeng/table';
import {PdfJsViewerModule} from 'ng2-pdfjs-viewer';
import {MapsModule} from '@syncfusion/ej2-angular-maps';
import {DataTablesModule} from 'angular-datatables';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {SharedModalModule} from '../dashboard/shared-modal/shared-modal.module';
import {DefaultLayoutComponent} from './default-layout/default-layout.component';
import {DefaultHeaderComponent} from './default-header/default-header.component';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {IconModule} from '@coreui/icons-angular';
import {TimeagoCustomFormatter, TimeagoFormatter, TimeagoIntl, TimeagoModule} from 'ngx-timeago';
import {
    AvatarModule,
    BadgeModule,
    BreadcrumbModule,
    ButtonGroupModule,
    ButtonModule,
    CardModule,
    DropdownModule,
    FooterModule,
    FormModule,
    GridModule,
    HeaderModule,
    ListGroupModule,
    NavModule,
    ProgressModule,
    SharedModule,
    SidebarModule,
    TabsModule,
    UtilitiesModule,
} from '@coreui/angular';
import {ToggleLangComponent} from './toggle-lang/toggle-lang.component';
import {RouterModule} from '@angular/router';
import {MacroGeneralDiagrammeComponent} from './general-diagramme/diagramme-baton.component';


@NgModule({
    declarations: [
        DefaultHeaderComponent,
        MapBoxAllComponent,
        MapBoxAllComponent,
        MapBoxComponent,
        HeaderComponent,
        FooterComponent,
        FileViewerComponent,
        DefaultLayoutComponent,
        ConfirmationDialogComponent,
        ToggleLangComponent,
        MacroGeneralDiagrammeComponent,
    ],
    exports: [
        MapBoxAllComponent,
        MapBoxComponent,
        HeaderComponent,
        FooterComponent,
        FileViewerComponent,
        ConfirmationDialogComponent,
        MacroGeneralDiagrammeComponent,
    ],
    imports: [
        TimeagoModule.forRoot({
            intl: {provide: TimeagoIntl, useClass: TimeagoIntl},
            formatter: {provide: TimeagoFormatter, useClass: TimeagoCustomFormatter},
        }),
        NgScrollbarModule,
        CommonModule,
        TranslateModule,
        RouterModule,
        ReactiveFormsModule,
        AvatarModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonGroupModule,
        ButtonModule,
        CardModule,
        DropdownModule,
        FooterModule,
        FormModule,
        GridModule,
        HeaderModule,
        ListGroupModule,
        NavModule,
        ProgressModule,
        SharedModule,
        SidebarModule,
        TabsModule,
        UtilitiesModule,
        IconModule,
        BsDropdownModule.forRoot(),
        FormsModule,
        HttpClientModule,
        NgbModule,
        MatTabsModule,
        MatSnackBarModule,
        MatCheckboxModule,
        NgSelectModule,
        TranslateModule,
        DataTablesModule,
        MapsModule,
        PdfJsViewerModule,
        TableModule,
        MultiSelectModule,
        InputTextModule,
        ChartModule,
        LeafletModule,
        DialogModule,
        ModalModule,
        SharedModalModule,
    ],
    providers: [
        BsModalService
    ]
})
export class SharedComponentModule {
}
