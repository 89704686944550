import {UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {Document} from './payloads/document.payload';
import {Privilege} from './payloads/privilege.payload';
import {Project, Region, Sector, StatusType} from './payloads/project.payload';
import {Role} from './payloads/role.payload';
import {TranslateService} from '@ngx-translate/core';
import {Activity} from './payloads/activity.payload';
import {ActivityOper} from './payloads/show-activity.payload';
import {ImplementationProc} from './payloads/implementation-process.payload';
import {FinanceMobil} from './payloads/finance-mobilisation.payload';
import {MaturationEval} from './payloads/maturation-evaluation.payload';
import {SignatureProc} from './payloads/signature-process.payload';
import {User} from './payloads/user.payload';


const PROJECT = 'project';
const ROLE = 'role';
const USER = 'AuthUser';
const USERID = 'userID';
const PRIVILEGE = 'privilege';
const PROJECT_PAYLOAD = 'projectPayload';
const LANGUAGE = 'lang';
const PERFORMANCEEVAL = 'performanceEval';

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string, type: boolean = true) {
    return (formGroup: UntypedFormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        const doValuesMatch = control.value === matchingControl.value;

        if ((type && !doValuesMatch) || (!type && doValuesMatch)) {
            matchingControl.setErrors({mustMatch: true});
        } else {
            matchingControl.setErrors(null);
        }
    };
}

// Compare two dates and retreive months
export function fetchMonthsBetween(startingDate: Date, endingDate: Date, roundUpFractionalMonths: boolean = true): number {
    // Months will be calculated between start and end dates.
    // Make sure start date is less than end date.
    // But remember if the difference should be negative.
    let startDate = startingDate;
    let endDate = endingDate;
    let inverse = false;
    if (startDate > endDate) {
        startDate = endDate;
        endDate = startDate;
        inverse = true;
    }

    // Calculate the differences between the start and end dates
    const yearsDifference = endDate.getFullYear() - startDate.getFullYear();
    const monthsDifference = endDate.getMonth() - startDate.getMonth();
    const daysDifference = endDate.getDate() - startDate.getDate();

    let monthCorrection = 0;
    // If roundUpFractionalMonths is true, check if an extra month needs to be added from rounding up.
    // The difference is done by ceiling (round up), e.g. 3 months and 1 day will be 4 months.
    if (roundUpFractionalMonths === true && daysDifference > 0) {
        monthCorrection = 1;
    } else if (roundUpFractionalMonths !== true && daysDifference < 0) {
        monthCorrection = -1;
    }

    const months = (inverse ? -1 : 1) * (yearsDifference * 12 + monthsDifference + monthCorrection);

    return (Number(months) > 0) ? months : 0;
}

export function fetchDaysBetween(startingDate: Date, endingDate: Date): number {
    let startDate = startingDate;
    let endDate = endingDate;

    if (startDate > endDate) {
        startDate = endDate;
        endDate = startDate;
    }

    const differenceInTime = endDate.getTime() - startDate.getTime();

    return Math.floor(differenceInTime / (1000 * 3600 * 24));
}

export function fetchListOfMonthsBetween(startingDate: string, endingDate: string): number[] {
    const startDate = startingDate ? new Date(startingDate).toLocaleDateString('fr-FR') : null;
    const endDate = endingDate ? new Date(endingDate).toLocaleDateString('fr-FR') : null;

    let startMonth = Number(startDate.split('/')[1]);
    const endMonth = Number(endDate.split('/')[1]);
    const responses: number[] = [];

    while (Number(startMonth) <= Number(endMonth)) {
        responses.push(Number(startMonth));
        startMonth += 1;
    }

    return responses;
}

/**
 * Returns the date with added `months` of delay.
 *
 * @param date
 * @param {Number} months - the delay in months
 *
 * @returns {Date}
 */
export function addMonths(date: Date, months: number): Date {
    return new Date(date.setMonth(date.getMonth() + months));
}

export function formatNumber(amount: number): string {
    if (amount) {
        return new Intl.NumberFormat(['en-En'], {}).format(amount);
    }
    return '';
}

export function fixedValue(value: number | string, toFix: number): string {
    const valueToFix: number = Number(value);
    return !isNaN(valueToFix) ? verify100(valueToFix).toFixed(toFix) : Number(0).toFixed(toFix)
}

export function viewDate(date: any): string {
    if (date !== null) {
        const oldDate = date;
        if (isTimestamp(date)) {
            date = Number(date) * 1000;
        }

        if (isNaN(date) && isDateFormatValid(oldDate)) {
            return new Date(oldDate).toLocaleDateString('fr-FR');
        }

        return new Date(date).toLocaleDateString('fr-FR');
    }
    return null;
}

function isTimestamp(timeStamp: any): boolean {
    try {
        timeStamp = parseFloat(timeStamp);
        return !isNaN(timeStamp);
    } catch (error) {
        return false;
    }
}

function isDateFormatValid(dateString: string): boolean {
    const iso8601Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,})?Z$/;

    return iso8601Pattern.test(dateString);
}

export function viewTime(date: string, lang: string = 'fr'): string {
    if (date !== null) {
        moment.updateLocale(lang, {
            meridiem: function (hours: number, isLower: number) {
                if (hours >= 12) {
                    return isLower ? 'pm' : 'PM';
                } else {
                    return isLower ? 'am' : 'AM';
                }
            }
        });

        return moment(date).format('hh:mm A');
    }
    return null;
}

export function dbDate(date: string): string {
    if (date !== null) {
        return new Date(date).getTime().toString();
    }
    return null;
}

export function addDays(date: string | any, days: number, EP: boolean = false): string | Date {
    if (date !== null) {
        if (EP === false) {
            return moment(date).add(days, 'days').format('YYYY-MM-DD');
        } else {
            const result = new Date(date);
            result.setDate(date.getDate() + days);

            return result;
        }
    }
    return null;
}

export function reloadComponent(router: Router = null) {
    if (router !== null) {
        router.routeReuseStrategy.shouldReuseRoute = () => false;
        router.onSameUrlNavigation = 'reload';
        router.navigate([router.url]);
    } else {
        window.location.reload();
    }
}

/**
 * @param text
 * @param size
 * @param type
 * @returns {string|*}
 */
export function pad(text: any, size: number = 2, type: string = 'left'): string {
    const length = text.toString().length;
    let output = '';

    if (type === 'left') {
        for (let i = 0; i < size - length; i++) {
            output += '0';
        }
        return output + '' + text;
    } else {
        output = text;
        for (let i = 0; i < size - length; i++) {
            output += '0';
        }
        return output;
    }
}

export function projectNumber(project: number = 1): string {
    return 'P'.concat(pad(project, 6, 'left'));
}

export function activityNumber(project: string, activity: number): string {
    return project.concat('-A', pad(activity, 3, 'left'));
}

export function taskNumber(activity: string, task: number): string {
    return activity.concat('-T', pad(task, 2, 'left'));
}

export function marketNumber(project: string, market: number): string {
    return project.concat('-M', pad(market, 3, 'left'));
}

export function contractNumber(market: string, contract: number = 0): string {
    return market.concat('-CON', pad(contract, 2, 'left'));
}

export function setProject(idProject: any) {
    localStorage.setItem(PROJECT, idProject);
    sessionStorage.setItem(PROJECT, idProject);
}

export function getProject(): number {
    if (localStorage.getItem(PROJECT) !== null) {
        return Number(localStorage.getItem(PROJECT));
    }
    return Number(sessionStorage.getItem(PROJECT));
}

export function setProjectPayload(projectPayload: Project) {
    localStorage.setItem(PROJECT_PAYLOAD, JSON.stringify(projectPayload));
    sessionStorage.setItem(PROJECT_PAYLOAD, JSON.stringify(projectPayload));
}

export function getProjectPayload(): Project {
    if (JSON.parse(localStorage.getItem(PROJECT_PAYLOAD)) !== null) {
        return JSON.parse(localStorage.getItem(PROJECT_PAYLOAD));
    }
    return JSON.parse(sessionStorage.getItem(PROJECT_PAYLOAD));
}

export function removeProjectPayload() {
    localStorage.removeItem(PROJECT_PAYLOAD);
    sessionStorage.removeItem(PROJECT_PAYLOAD);
}

export function setUserID(userID: number) {
    removeUserID();

    localStorage.setItem(USERID, JSON.stringify(userID));
    sessionStorage.setItem(USERID, JSON.stringify(userID));
}

export function getUserID(): number {
    if (JSON.parse(localStorage.getItem(USERID)) !== null) {
        return JSON.parse(localStorage.getItem(USERID));
    }
    return JSON.parse(sessionStorage.getItem(USERID));
}

export function removeUserID() {
    localStorage.removeItem(USERID);
    sessionStorage.removeItem(USERID);
}

export function setUser(user: User) {
    removeUser();

    localStorage.setItem(USER, JSON.stringify(user));
    sessionStorage.setItem(USER, JSON.stringify(user));
}

export function getUser(): User {
    if (JSON.parse(localStorage.getItem(USER)) !== null) {
        return JSON.parse(localStorage.getItem(USER));
    }
    return JSON.parse(sessionStorage.getItem(USER));
}

export function removeUser() {
    localStorage.removeItem(USER);
    sessionStorage.removeItem(USER);
}

export function setPrivilege(privilege: Privilege) {
    removePrivilege();

    localStorage.setItem(PRIVILEGE, JSON.stringify(privilege));
    sessionStorage.setItem(PRIVILEGE, JSON.stringify(privilege));
}

export function getPrivilege(): Privilege {
    if (JSON.parse(localStorage.getItem(PRIVILEGE)) !== null) {
        return JSON.parse(localStorage.getItem(PRIVILEGE));
    }
    return JSON.parse(sessionStorage.getItem(PRIVILEGE));
}

export function removePrivilege() {
    localStorage.removeItem(PRIVILEGE);
    sessionStorage.removeItem(PRIVILEGE);
}

export function setRole(role: Role) {
    removeRole();

    localStorage.setItem(ROLE, JSON.stringify(role));
    sessionStorage.setItem(ROLE, JSON.stringify(role));
}

export function getRole(): Role {
    if (JSON.parse(localStorage.getItem(ROLE)) !== null) {
        return JSON.parse(localStorage.getItem(ROLE));
    }
    return JSON.parse(sessionStorage.getItem(ROLE));
}

export function removeRole() {
    localStorage.removeItem(ROLE);
    sessionStorage.removeItem(ROLE);
}

export function setPerformanceEval(performanceEval: any[]) {
    removePerformanceEval();

    localStorage.setItem(PERFORMANCEEVAL, JSON.stringify(performanceEval));
    sessionStorage.setItem(PERFORMANCEEVAL, JSON.stringify(performanceEval));
}

export function getPerformanceEval(): any {
    if (JSON.parse(localStorage.getItem(PERFORMANCEEVAL)) !== null) {
        return JSON.parse(localStorage.getItem(PERFORMANCEEVAL));
    }
    return JSON.parse(sessionStorage.getItem(PERFORMANCEEVAL));
}

export function removePerformanceEval() {
    localStorage.removeItem(PERFORMANCEEVAL);
    sessionStorage.removeItem(PERFORMANCEEVAL);
}

export function splitBase64String(base64String: string): Document {
    const splits = base64String.split(';');

    const type = (splits[0].split(':'))[1];
    const base64 = (splits[1].split(','))[1];

    return new Document(null, null, type, base64);
}

export function setLang(lang: string) {
    localStorage.setItem(LANGUAGE, encryptData(lang));
    sessionStorage.setItem(LANGUAGE, encryptData(lang));
}

export function getLang(): string {
    if (localStorage.getItem(LANGUAGE) !== null) {
        return decryptData(localStorage.getItem(LANGUAGE));
    }
    return decryptData(sessionStorage.getItem(LANGUAGE));
}

export function encryptData(data: string): string {
    return data;
}

export function decryptData(data: string): string {
    return data;
}

export function years(startDate: string, endDate: string): number[] {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    if (endDateObj < startDateObj) {
        throw new Error('La date de fin doit être égale ou postérieure à la date de début.');
    }

    const startYear = startDateObj.getUTCFullYear();
    const endYear = endDateObj.getUTCFullYear();

    return Array.from({length: endYear - startYear + 1}, (_, index) => startYear + index);
}

export const LIMITES_CAMEROUN = {
    latitude: {min: 1.6523, max: 12.6766},
    longitude: {min: 8.5057, max: 16.2015},
};

export const isInCountryBounds = (longitude: number, latitude: number) => {

    return !(latitude < LIMITES_CAMEROUN.latitude.min ||
        latitude > LIMITES_CAMEROUN.latitude.max ||
        longitude < LIMITES_CAMEROUN.longitude.min ||
        longitude > LIMITES_CAMEROUN.longitude.max);

};

export function verify100(amount: any) {
    amount = isNaN(amount) ? 0 : amount;
    return (Number(amount) > 100) ? 100 : Number(amount);
}

export function checkRow(value: any) {
    if (value[0] === null) {
        return ' ';
    }
    if (isFinite(value)) {
        return formatNumber(value);
    } else if (value[0].includes('%')) {
        return value;
    } else if (isValidDateString(value[0])) {
        return viewDate(value);
    } else {
        return value;
    }
}

export function handleCalcElement(element: any, project: Project, translate: TranslateService) {
    const delayRate = getProjectDelayConsumsionRate(project.startDate, project.duration);
    const disburseRate = getProjectDisbursementRate(project);
    switch (element) {
        case 'calc_age':
            return `<center>${getProjectAge(project.startDate)}</center>`;
        case 'calc_sector':
            return parseSectors(project.dsceSector).toString().replaceAll(',', ', ');
        case 'calc_region':
            return getProjectRegion(project).toString().replaceAll(',', ', ');
        case 'calc_delay_consumption_rate':
            return `<center>${delayRate}</center>`;
        case 'calc_disbursement_rate':
            return `<center>${disburseRate}</center>`;
        case 'calc_categorie':
            if (parseFloat(disburseRate) > parseFloat(delayRate)) {
                return translate.instant('category-1');
            } else if (Math.abs(parseFloat(disburseRate) - parseFloat(delayRate)) <= 9.9) {
                return translate.instant('category-2');
            } else if (parseFloat(disburseRate) < parseFloat(delayRate)) {
                return translate.instant('category-3');
            }
            break;
        case 'calc_state':
            return getStateByStatus(project, translate);
        case 'calc_nature_gift':
            return getPaternDonation(project, translate);
        case 'calc_nature_loan':
            return getPaternLoan(project, translate);
        case 'calc_amount':
            return `<span class="a-left text-nowrap">${formatNumber(project.amount)} ${project.currency}</span>`;
        case 'calc_amountFcfa':
            return project.amountFcfa === null ? `<span class="a-left text-nowrap">${formatNumber(project.amount * project.exchangeRate)}</span>` : `<span class="a-left text-nowrap">${formatNumber(project.amountFcfa)}</span>`;
        default:
            return null;
    }
}

export function handleCalcElementNoHtml(element: any, project: Project, translate: TranslateService) {
    const delayRate = getProjectDelayConsumsionRate(project.startDate, project.duration);
    const disburseRate = getProjectDisbursementRate(project);
    switch (element) {
        case 'calc_age':
            return `${getProjectAge(project.startDate)}`;
        case 'calc_sector':
            return parseSectors(project.dsceSector).toString().replaceAll(',', ', ');
        case 'calc_region':
            return getProjectRegion(project).toString().replaceAll(',', ', ');
        case 'calc_delay_consumption_rate':
            return `${delayRate}`;
        case 'calc_disbursement_rate':
            return `${disburseRate}`;
        case 'calc_categorie':
            if (parseFloat(disburseRate) > parseFloat(delayRate)) {
                return translate.instant('category-1');
            } else if (Math.abs(parseFloat(disburseRate) - parseFloat(delayRate)) <= 9.9) {
                return translate.instant('category-2');
            } else if (parseFloat(disburseRate) < parseFloat(delayRate)) {
                return translate.instant('category-3');
            }
            break;
        case 'calc_state':
            return getStateByStatus(project, translate);
        case 'calc_nature_gift':
            return getPaternDonation(project, translate);
        case 'calc_nature_loan':
            return getPaternLoan(project, translate);
        case 'calc_amount':
            return `${formatNumber(project.amount)} ${project.currency}`;
        case 'calc_amountFcfa':
            return project.amountFcfa === null ? `${formatNumber(project.amount * project.exchangeRate)}` : `${formatNumber(project.amountFcfa)}`;
        default:
            return null;
    }
}

export function getProjectAge(startDate: string) {
    const presentDuration = fetchMonthsBetween(new Date(startDate), new Date());
    return (presentDuration / 12).toFixed(2);
}

function getPaternDonation(project: Project, translate: TranslateService) {
    const devise = project.currency;
    const partners = project.partners;
    const donationPartner = partners.filter((partner) => partner.financingType == 'Don');
    const donationAmt = donationPartner.reduce((total, partner) => {
        return total + partner.amount;
    }, 0);
    const donationAmtFCFA = donationPartner.reduce((total, partner) => {
        return total + partner.amountFcfa;
    }, 0);

    return donationAmt > 0 ? `${formatNumber(donationAmt)} ${devise} ${translate.instant('equal_to')} ${formatNumber(donationAmtFCFA)} FCFA` : null;
}

function getPaternLoan(project: Project, translate: TranslateService) {
    const devise = project.currency;
    const partners = project.partners;
    const LoanPartner = partners.filter((partner) => partner.financingType == 'Prêt');
    const loanAmt = LoanPartner.reduce((total, partner) => {
        return total + partner.amount;
    }, 0);
    const loanAmtFCFA = LoanPartner.reduce((total, partner) => {
        return total + partner.amountFcfa;
    }, 0);

    return loanAmt > 0 ? `${formatNumber(loanAmt)} ${devise} ${translate.instant('equal_to')} ${formatNumber(loanAmtFCFA)} FCFA` : null;
}

export function getProjectDelayConsumsionRate(startDate: string, duration: number) {
    const presentDuration = fetchMonthsBetween(new Date(startDate), new Date());
    return `${(verify100((presentDuration / duration) * 100)).toFixed(1)}%`;
}

export function getProjectDisbursementRate(project: Project) {

    let activityOper: ActivityOper;
    let activityOpers: ActivityOper[];
    let advance: number = 0;
    const activities = [...project.activities];
    activities.sort((activity1: Activity, activity2: Activity) => activity1.numb > activity2.numb ? 1 : -1);

    activities.forEach((activity: Activity) => {


        if (activity.activityOpers.length > 0) {
            activityOpers = [...activity.activityOpers];
            activityOpers.sort((activityOper1: ActivityOper, activityOper2: ActivityOper) =>
                activityOper1.idactivityOper > activityOper2.idactivityOper ? 1 : -1,
            );

            activityOper = activityOpers[activityOpers.length - 1];

            advance += Number(activityOper.consumptedBudget);
        }
    });
    return `${verify100(advance)}%`;
}

function showIntlDate(date: any) {
    return new Date(date).toLocaleDateString('fr-FR');
}

export function isValidDateString(dateString: string) {
    const date = new Date(dateString.split(' ').join(''));
    return !isNaN(date.getTime());
}

function verifImplementation(project: Project, translate: TranslateService) {

    if (project.implementationProcs !== null && project.implementationProcs.length > 0) {
        const implementationProcs: ImplementationProc[] = [...project.implementationProcs];
        implementationProcs.sort((implementationProc1: ImplementationProc, implementationProc2: ImplementationProc) =>
            implementationProc1.idimplementationProc > implementationProc2.idimplementationProc ? 1 : -1
        );


        return `${translate.instant('implemention_project_start', {date: showIntlDate(implementationProcs[implementationProcs.length - 1].plannedDate)})}`;

    }
    return `${translate.instant('implemention_project')}`;
}

function VerifPreparationProject(project: Project, translate: TranslateService) {
    const financeMobils: FinanceMobil[] = [...project.financeMobils];
    financeMobils.sort((financeMobil1: FinanceMobil, financeMobil2: FinanceMobil) =>
        financeMobil1.idfinanceMobil > financeMobil2.idfinanceMobil ? 1 : -1
    );
    return `${translate.instant('preparation_project', {date: showIntlDate(financeMobils[financeMobils.length - 1].plannedDate),})}`;
}

function VerifMaturationProject(project: Project, translate: TranslateService) {
    const maturationEvals: MaturationEval[] = [...project.maturationEvals];
    maturationEvals.sort((maturationEval1: MaturationEval, maturationEval2: MaturationEval) =>
        maturationEval1.idmaturationEval > maturationEval2.idmaturationEval ? 1 : -1
    );
    return `${translate.instant('maturation_project', {date: showIntlDate(maturationEvals[maturationEvals.length - 1].plannedDate),})}`;
}

function VerifSignarureProject(project: Project, translate: TranslateService) {
    const signatureProcs: SignatureProc[] = [...project.signatureProcs];
    signatureProcs.sort((signatureProc1: SignatureProc, signatureProc2: SignatureProc) =>
        signatureProc1.idsignatureProc > signatureProc2.idsignatureProc ? 1 : -1
    );
    return `${translate.instant('signature_project', {date: showIntlDate(signatureProcs[signatureProcs.length - 1].plannedDate),})}`;
}

function getStateByStatus(project: Project, translate: TranslateService) {
    const status = project.status as StatusType;
    switch (status) {
        case 'CANCELED':
            return `${translate.instant('cancel_project', {date: showIntlDate(project.endDate)})}`;
        case 'TERMINATED':
            return `${translate.instant('terminate_project', {date: showIntlDate(project.endDate)})}`;
        case 'IMPLEMENTED':
            return `${translate.instant('implemented_project', {date: showIntlDate(project.endDate)})}`;
        case 'IMPLEMENTATION':
            return verifImplementation(project, translate);
        case 'PREPARATION':
            return VerifPreparationProject(project, translate);
        case 'MATURATION':
            return VerifMaturationProject(project, translate);
        case 'SIGNATURE':
            return VerifSignarureProject(project, translate);
        default:
            break;
    }

}

function getProjectRegion(project: Project) {
    const regions: string[] = [];
    if (project.scope == 'NATIONAL') {
        return ['National'];
    } else {
        project.localisations.forEach(localisation => {
            regions.push(localisation.region);
        });
    }

    return regions.length > 9 ? ['National'] : regions;
}

export function countProjectsByRegion(projects: Project[], regions: string[]): number[] {
    const regionCount: Record<Region, number> = {
        'OUEST': 0,
        'EST': 0,
        'LITTORAL': 0,
        'EXTRÊME-NORD': 0,
        'NORD': 0,
        'ADAMAOUA': 0,
        'NORD-OUEST': 0,
        'CENTRE': 0,
        'SUD-OUEST': 0,
        'SUD': 0
    };

    projects.forEach(project => {
        if (project.scope === 'NATIONAL') {
            regions.forEach(region => {
                regionCount[region]++;
            });
        } else {
            project.localisations.forEach(localisation => {
                if (regions.includes(localisation.region as any)) {
                    regionCount[localisation.region]++;
                }
            });
        }
    });

    return regions.map(region => regionCount[region]);
}

function parseSectors(sectorString: string): string[] {
    return sectorString.replace(/[[\]]/g, '').split(',').map(s => s.trim() as string);
}

export function countProjectsBySector(projects: Project[], ALLSECTORS: string[]) {
    const sectorCount: { [key in Sector]?: { count: number, ids: number[] } } = {};

    projects.forEach(project => {
        const sectors = parseSectors(project.dsceSector);
        sectors.forEach(sector => {
            if (ALLSECTORS.includes(sector)) {
                if (!sectorCount[sector]) {
                    sectorCount[sector] = {count: 0, ids: []};
                }
                sectorCount[sector]!.count++;
                sectorCount[sector]!.ids.push(project.idproject);
            }
        });
    });

    return ALLSECTORS.map(sector => sectorCount?.[sector]?.count);
}

export function getYears(projects: Project[]): number[] {
    const years: number[] = [];
    projects.forEach((project) => {
        const start = new Date(project.startDate);
        const end = new Date(project.endDate);
        years.push(start.getUTCFullYear());
        years.push(end.getUTCFullYear());
    });
    const max = Math.max(...years);
    const min = Math.min(...years);
    return Array.from({length: (max - min + 1)}, (_, index) => min + index);
}

export function getNumericValue(code: string) {
    const numberIndex = code.search(/\d/);
    return Number(code.slice(numberIndex));
}
