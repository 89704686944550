<!DOCTYPE html>
<section class="animated fadeIn" *ngIf="isLoaded">
    <div class="modal-header d-flex justify-content-between">
        <h4 class="modal-title roboto-bold-font">{{ "object" | translate }} : {{ review.object }}</h4>
        <span class="close fa fa-times text-red" aria-label="Close" (click)="closeModal()"></span>
    </div>
    <div class="modal-body text-black container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 forms">
                <form [formGroup]="contReviewForm">
                    <ngb-alert #selfClosingAlert *ngIf="errorMessage" type="danger" (closed)="errorMessage = ''">{{
                        errorMessage
                        }}</ngb-alert>

                    <div class="row">
                        <div class="col-xl-12 col-lg-12">
                            <div class="position-relative">
                                <div class="chat-messages p-4">
                                    <div [ngClass]="isSender(review.sender)" class="pb-4">
                                        <div class="text-center">
                                            <img src="{{ user.image }}" class="rounded-circle mr-1" alt="{{ review.sender.name }}" width="40" height="40">
                                            <div class="text-muted small text-nowrap mt-2"> {{ dateFormat(review.createdAt) }} <br> {{ timeFormat(review.createdAt) }}</div>
                                        </div>
                                        <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                                            <div class="font-weight-bold mb-1">{{ review.sender.name }} {{ review.sender.surname }}</div>
                                            <span class="new-line">{{ review.message }}</span>
                                        </div>
                                    </div>
                                    <div [ngClass]="isSender(contReview.sender)" class="pb-4" *ngFor="let contReview of contReviews">
                                        <div class="text-center">
                                            <img src="{{ contReview.sender.image }}" class="rounded-circle mr-1" alt="{{ contReview.sender.name }}" width="40" height="40">
                                            <div class="text-muted small text-nowrap mt-2"> {{ dateFormat(contReview.createdAt) }} <br> {{ timeFormat(contReview.createdAt) }}</div>
                                        </div>
                                        <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                                            <div class="font-weight-bold mb-1">{{ contReview.sender.name }} {{ contReview.sender.surname }}</div>
                                            <span class="new-line">{{ contReview.message }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-xl-12 col-lg-12">
                                        <div class="form-group">
                                            <textarea type="text" rows="5" formControlName="message" class="form-control"
                                                aria-label="Message" aria-describedby="basic-user" id="message"
                                                placeholder="{{ 'inputs.message.placeholders' | translate }}"
                                                [ngClass]="{ 'is-invalid': contReviewFormSubmitted && continuoForm.message.errors }">
                                            </textarea>
                                            <div *ngIf="contReviewFormSubmitted && continuoForm.message.errors" class="invalid-feedback">
                                                <div *ngIf="continuoForm.message.errors.required">{{
                                                    "inputs.message.validations.required" |
                                                    translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end">
                            <button type="submit" (click)="onSubmit()" class="btn btn-pill bg-primary px-5">{{ "save" | translate }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
