import {Privilege} from './privilege.payload';

export class Role {
  idrole: number;
  name: string;
  code: string;
  forProject: boolean;
  privilege: Privilege;

  constructor(name: string, code: string, privilege: Privilege, forProject: boolean = false) {
    this.name = name;
    this.code = code;
    this.forProject = forProject;
    this.privilege = privilege;
  }
}
