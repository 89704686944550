<!DOCTYPE html>
<section class="animated fadeIn" *ngIf="isLoaded">
    <div class="modal-header d-flex justify-content-between">
        <h3 class="modal-title roboto-bold-font">{{ "execution-monitoring" | translate }}</h3>
        <span class="close fa fa-times text-red" aria-label="Close" (click)="closeModal()"></span>
    </div>
    <div class="modal-body text-black container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 forms">
                <form [formGroup]="resetMarketExecutionDateForm">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ngb-alert #selfClosingAlert *ngIf="errorMessage" type="danger"
                                       (closed)="errorMessage = ''">{{
                                errorMessage
                                }}</ngb-alert>

                            <div class="row">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="form-group">
                                        <label class="control-label"
                                               for="plannedDate">{{ "inputs.plannedDate.label" | translate }}<span
                                            class="roboto-bold-font text-red">*</span></label>
                                        <input [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [daysDisabled]="[7,0]"
                                               type="text" bsDatepicker placeholder="{{ 'date-format' | translate }}"
                                               formControlName="plannedDate"
                                               class="form-control" [minDate]="startDate" id="plannedDate"
                                               [ngClass]="{ 'is-invalid': resetMarketExecutionDateFormSubmitted && executionForm.plannedDate.errors }">
                                        <div
                                            *ngIf="resetMarketExecutionDateFormSubmitted && executionForm.plannedDate.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="executionForm.plannedDate.errors.required">{{
                                                "inputs.plannedDate.validations.required" |
                                                    translate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div
                                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end">
                                    <button type="submit" (click)="onSubmit()"
                                            class="btn btn-pill bg-primary text-center text-white px-5">{{ "save" | translate }}</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
