import {Document} from './document.payload';
import {CompleteDeliverable, CompletePayment, Endorsement, ServiceOrder} from "./contract.payload";

export class MarketFollowUp {
    serviceOrders: ServiceOrder[];
    endorsements: Endorsement[];
    deliverables: CompleteDeliverable[];
    payments: CompletePayment[];

    constructor(serviceOrders: ServiceOrder[], endorsements: Endorsement[], deliverables: CompleteDeliverable[],
                payments: CompletePayment[]) {
        this.serviceOrders = serviceOrders;
        this.endorsements = endorsements;
        this.deliverables = deliverables;
        this.payments = payments;
    }
}

export class MarketExecution {
    idmarketExecution: number;
    title: string;
    marketExecutionDate: MarketExecutionDate;
    marketExecutionDates: MarketExecutionDate[];
    acceptFile: boolean;
    acceptComment: boolean;
    executedDate: string;
    document: Document = null;
    comment: string;
    market: number;
    createdAt: string;
    updatedAt: string;
    plannedDate: any;

    constructor(title: string, marketExecutionDate: MarketExecutionDate, acceptComment: boolean = true, acceptFile: boolean = true) {
        this.title = title;
        this.marketExecutionDate = marketExecutionDate;
        this.acceptComment = acceptComment;
        this.acceptFile = acceptFile;
    }
}

export class MarketExecutionDate {
    idmarketExecutionDate: number;
    plannedDate: string;
    createdAt: string;
    updatedAt: string;

    constructor(plannedDate: string) {
        this.plannedDate = plannedDate;
    }
}

export class CompleteMarketExecution {
    idmarketExecution: number;
    executedDate: string;
    document: Document = null;
    comment: string;

    constructor(idmarketExecution: number, executedDate: string, document: Document, comment: string) {
        this.idmarketExecution = idmarketExecution;
        this.executedDate = executedDate;
        this.document = document;
        this.comment = comment;
    }
}

export class MarketPayment {
    idmarketPayment: number;
    title: string;
    amount: number;
    date: string;

    constructor(title: string, amount: number, date: string) {
        this.title = title;
        this.amount = amount;
        this.date = date;
    }
}

export class MarketEndorsement {
    idmarketEndorsement: number;
    title: string;
    object: string;
    amount: number;
    date: string;

    constructor(title: string, object: string, amount: number, date: string) {
        this.title = title;
        this.object = object;
        this.amount = amount;
        this.date = date;
    }
}
