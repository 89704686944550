import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {TokenStorageService} from './token-storage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {

    constructor(private tokenStorage: TokenStorageService, private router: Router) {
    }

    canActivate(
        _next: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot): boolean {
        if (!this.tokenStorage.isloggedIn()) {
            this.tokenStorage.signOut();
            this.router.navigate(['/sign-in']);
        } else {
            return true;
        }
    }
}
